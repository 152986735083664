import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  AppBar,
  Toolbar,
  IconButton,
  Button, 
  Avatar,
  Menu,
  MenuItem,
  Typography,
  Box,
  Divider,
  CircularProgress,
  Tooltip,
  useTheme,
  useMediaQuery,
  styled
} from '@mui/material';
import {
  Brightness4,
  Brightness7,
  Menu as MenuIcon,
  Logout as LogoutIcon,
  Help as HelpIcon,
  Info as InfoIcon,
  Gavel as GavelIcon,
  Build as BuildIcon,
} from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import { useThemeContext } from '../contexts/ThemeContext';
import { useLayoutContext } from '../contexts/LayoutContext';
import { getImageUrl } from '../utils/imageUtils';
import { useQueryClient } from '@tanstack/react-query';
import { MenuItems } from './MenuItems';
import NotificationIcon from './notifications/NotificationIcon';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.background.paper,
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  position: 'fixed'
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: 72,
  padding: theme.spacing(0, 2),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 1),
  }
}));

const LogoSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const CenterSection = styled(Box)({
  flex: 1
});

const RightSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const PublicLinks = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'none',
  fontSize: '16px',
  fontWeight: 500,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  transition: 'background-color 0.2s',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  }
}));

interface UserRole {
  roleId: string;
  role: {
    name: string;
  };
}

export const TopBar: React.FC = () => {
  const auth = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mode, toggleTheme } = useThemeContext();
  const { setShowSidebar } = useLayoutContext();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [profileImage, setProfileImage] = useState<string>('/assets/illustrations/miscellaneous/default-avatar.png');
  const [imageKey, setImageKey] = useState<number>(Date.now());
  
  // Menu state
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  
  const handleMenuItemClick = (path: string) => {
    handleMenuClose();
    navigate(path);
  };
  
  const handleLogout = async () => {
    await auth?.logout?.();
    queryClient.clear();
    navigate('/login');
  };
  
  const goToProfile = () => {
    if (auth?.user?.id) {
      navigate(`/profile/${auth.user.id}`);
    }
  };

  useEffect(() => {
    if (auth?.user?.profile_image_url) {
      let imageUrl = auth.user.profile_image_url;
      
      if (!imageUrl.startsWith('/profile-images/') && !imageUrl.startsWith('http')) {
        imageUrl = `/profile-images/${imageUrl}`;
      }
      
      const transformedUrl = getImageUrl(imageUrl);
      
      console.log('Setting profile image:', {
        original: auth.user.profile_image_url,
        formatted: imageUrl,
        transformed: transformedUrl
      });
      
      // Only add timestamp if URL doesn't already have one
      if (!transformedUrl.includes('?t=')) {
        const timestamp = Date.now();
        setProfileImage(`${transformedUrl}?t=${timestamp}`);
        setImageKey(timestamp);
      } else {
        setProfileImage(transformedUrl);
        // Extract timestamp from URL for imageKey
        const match = transformedUrl.match(/t=(\d+)/);
        if (match) {
          setImageKey(parseInt(match[1]));
        }
      }
    } else {
      setProfileImage('/assets/illustrations/miscellaneous/default-avatar.png');
    }
  }, [auth?.user]);

  useEffect(() => {
    setShowSidebar(!isMobile);
  }, [isMobile, setShowSidebar]);

  return (
    <StyledAppBar>
      <StyledToolbar>
        {/* Left Section: Logo and Hamburger */}
        <LogoSection>
          {/* Logo */}
          <Link to="/">
            <img 
              src={`/assets/brand/logo_${mode === 'dark' ? 'white' : 'black'}.png`} 
              alt="trueconn"
              style={{ height: 40 }}
            />
          </Link>

          {/* Hamburger Menu - Now next to logo */}
          {auth?.isAuthenticated && (
            <>
              <IconButton
                onClick={handleMenuClick}
                color="inherit"
                aria-label="menu"
                sx={{ color: theme.palette.text.primary }}
              >
                <MenuIcon />
              </IconButton>
              
              <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
                PaperProps={{
                  sx: {
                    width: 250,
                    maxHeight: '80vh',
                    overflowY: 'auto'
                  }
                }}
              >
                <MenuItems 
                  onItemClick={handleMenuItemClick}
                  isSmallScreen={isMobile}
                />
              </Menu>
            </>
          )}

          {/* Mobile Menu for Non-authenticated Users */}
          {!auth?.isAuthenticated && isMobile && (
            <>
              <IconButton 
                onClick={handleMenuClick} 
                color="inherit"
                sx={{ color: theme.palette.text.primary }}
              >
                <MenuIcon />
              </IconButton>
              
              <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={() => handleMenuItemClick('/tools')}>
                  <BuildIcon sx={{ mr: 1 }} />
                  <Typography>Tools</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('/faq')}>
                  <HelpIcon sx={{ mr: 1 }} />
                  <Typography>FAQ</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('/about')}>
                  <InfoIcon sx={{ mr: 1 }} />
                  <Typography>About</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('/terms')}>
                  <GavelIcon sx={{ mr: 1 }} />
                  <Typography>Terms</Typography>
                </MenuItem>
              </Menu>
            </>
          )}
        </LogoSection>
        
        {/* Center Section: Empty space and Public Links */}
        <CenterSection>
          {!isMobile && (
            <PublicLinks>
              <StyledLink to="/tools">TOOLS</StyledLink>
              <StyledLink to="/faq">FAQ</StyledLink>
              <StyledLink to="/about">ABOUT</StyledLink>
              <StyledLink to="/terms">TERMS</StyledLink>
            </PublicLinks>
          )}
        </CenterSection>
        
        {/* Right Section: Theme Toggle and Auth Controls */}
        <RightSection>
          {/* Theme Toggle */}
          <Tooltip title={`Switch to ${mode === 'dark' ? 'light' : 'dark'} mode`}>
            <IconButton 
              onClick={toggleTheme} 
              color="inherit" 
              sx={{ color: theme.palette.text.primary }}
            >
              {mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
            </IconButton>
          </Tooltip>
          
          {/* Add NotificationIcon here */}
          {auth?.isAuthenticated && <NotificationIcon />}
          
          {/* User Profile and Logout */}
          {auth?.isAuthenticated ? (
            <>
              <Tooltip title="Your Profile">
                <IconButton 
                  onClick={goToProfile}
                  sx={{ color: theme.palette.text.primary }}
                >
                  <Avatar 
                    src={profileImage} 
                    key={imageKey}
                    alt={auth?.user ? `${auth.user.first_name} ${auth.user.last_name}` : 'User'} 
                    sx={{
                      width: 32,
                      height: 32,
                      bgcolor: 'primary.main'
                    }}
                  >
                    {auth?.user ? (auth.user.first_name?.[0] || '') + (auth.user.last_name?.[0] || '') : 'U'}
                  </Avatar>
                </IconButton>
              </Tooltip>
              
              <Tooltip title="Logout">
                <IconButton 
                  onClick={handleLogout} 
                  color="inherit"
                  sx={{ color: theme.palette.text.primary }}
                >
                  <LogoutIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : auth?.loading ? (
            <CircularProgress size={24} />
          ) : (
            <>
              <Button
                component={Link}
                to="/login"
                variant="outlined"
                color="primary"
                sx={{ mx: 1 }}
              >
                Login
              </Button>
              
              <Button
                component={Link}
                to="/register"
                variant="contained"
                color="primary"
              >
                Register
              </Button>
            </>
          )}
        </RightSection>
      </StyledToolbar>
    </StyledAppBar>
  );
};