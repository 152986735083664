import React, { useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Divider,
  Autocomplete,
  SelectChangeEvent,
} from '@mui/material';
import { Contact, ContactStatus, PriorityLevel, ContactMethodType } from '../../types/contact';

interface ContactFormProps {
  initialData?: Partial<Contact>;
  onSubmit: (data: Partial<Contact>) => Promise<void>;
  onCancel?: () => void;
}

const ContactForm: React.FC<ContactFormProps> = ({ initialData = {}, onSubmit, onCancel }) => {
  const [formData, setFormData] = useState<Partial<Contact>>({
    first_name: '',
    last_name: '',
    email_personal: '',
    email_work: '',
    phone_mobile: '',
    phone_work: '',
    phone_home: '',
    status: ContactStatus.POTENTIAL,
    priority_level: undefined,
    preferred_contact_method: undefined,
    notes: '',
    ...initialData,
  });

  const [error, setError] = useState<string | null>(null);

  const handleChange = (field: keyof Contact) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null
  ) => {
    setFormData((prev: Partial<Contact>) => ({
      ...prev,
      [field]: event?.target.value || ''
    }));
  };

  const handleSelectChange = (field: keyof Contact) => (
    event: SelectChangeEvent<string>
  ) => {
    setFormData((prev: Partial<Contact>) => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    console.log('Submitting form data:', formData);
    try {
      await onSubmit(formData);
      setError(null);
    } catch (error) {
      console.error('Error submitting form:', error);
      setError(error instanceof Error ? error.message : 'Failed to save contact');
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <Grid container spacing={3}>
        {/* Basic Information */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Basic Information
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label="First Name"
            value={formData.first_name}
            onChange={handleChange('first_name')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label="Last Name"
            value={formData.last_name}
            onChange={handleChange('last_name')}
          />
        </Grid>

        {/* Contact Information */}
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>
            Contact Information
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Personal Email"
            type="email"
            value={formData.email_personal}
            onChange={handleChange('email_personal')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Work Email"
            type="email"
            value={formData.email_work}
            onChange={handleChange('email_work')}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Mobile Phone"
            value={formData.phone_mobile}
            onChange={handleChange('phone_mobile')}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Work Phone"
            value={formData.phone_work}
            onChange={handleChange('phone_work')}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Home Phone"
            value={formData.phone_home}
            onChange={handleChange('phone_home')}
          />
        </Grid>

        {/* Status and Priority */}
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>
            Status and Priority
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={formData.status || ContactStatus.POTENTIAL}
              label="Status"
              onChange={handleSelectChange('status')}
            >
              {Object.values(ContactStatus).map((status: ContactStatus) => (
                <MenuItem key={status} value={status}>
                  {status.replace(/_/g, ' ')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Priority Level</InputLabel>
            <Select
              value={formData.priority_level || ''}
              label="Priority Level"
              onChange={handleSelectChange('priority_level')}
            >
              <MenuItem value="">None</MenuItem>
              {Object.values(PriorityLevel).map((level: PriorityLevel) => (
                <MenuItem key={level} value={level}>
                  {level}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Preferred Contact Method</InputLabel>
            <Select
              value={formData.preferred_contact_method || ''}
              label="Preferred Contact Method"
              onChange={handleSelectChange('preferred_contact_method')}
            >
              <MenuItem value="">None</MenuItem>
              {Object.values(ContactMethodType).map((method: ContactMethodType) => (
                <MenuItem key={method} value={method}>
                  {method.replace(/_/g, ' ')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Notes */}
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>
            Additional Information
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Notes"
            value={formData.notes}
            onChange={handleChange('notes')}
          />
        </Grid>

        {/* Submit and Cancel Buttons */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
            {onCancel && (
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                onClick={onCancel}
              >
                Cancel
              </Button>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              {initialData.id ? 'Update Contact' : 'Create Contact'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactForm;
