import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  FormControlLabel,
  Switch,
  MenuItem,
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  Collapse,
  Autocomplete,
  Typography
} from '@mui/material';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { api } from '../services/api';
import DOMPurify from 'dompurify';
import { Duration } from '../types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { MentionInput } from './MentionInput';

interface OneOnOneMeetup {
  id: string;
  name: string;
  short_description?: string;
  long_description?: string;
  notes?: string;
  is_debate: boolean;
  is_friends: boolean;
  is_secret: boolean;
  duration: Duration;
  event_start_date: string;
  event_end_date: string;
  topics: string[];
  question?: string;
  answers: string[];
  next_url?: string;
  attendees?: string[];
}

interface OneOnOneMeetupDialogProps {
  open: boolean;
  onClose: () => void;
  meetup?: OneOnOneMeetup | null;
}

interface User {
  id: string;
  first_name: string;
  last_name: string;
  username: string;
}

const searchUsers = async (search: string): Promise<User[]> => {
  const searchTerm = search.startsWith('@') ? search.slice(1) : search;
  const response = await api.get(`/users/search?q=${encodeURIComponent(searchTerm)}`);
  return response.data;
};

export const OneOnOneMeetupDialog = ({ open, onClose, meetup }: OneOnOneMeetupDialogProps) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState<string | null>(null);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [formData, setFormData] = useState<Partial<OneOnOneMeetup>>({
    name: '',
    short_description: '',
    long_description: '',
    notes: '',
    question: '',
    answers: [],
    is_debate: false,
    is_friends: false,
    is_secret: false,
    duration: Duration.FIVE_MINUTES,
    event_start_date: new Date().toISOString(),
    event_end_date: new Date(Date.now() + 60 * 60 * 1000).toISOString(),
    topics: []
  });
  const [attendeeInput, setAttendeeInput] = useState('');
  const [userSearchTerm, setUserSearchTerm] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  const { data: userSuggestions = [] } = useQuery({
    queryKey: ['users', userSearchTerm],
    queryFn: () => searchUsers(userSearchTerm),
    enabled: userSearchTerm.length > 1,
    staleTime: 5000
  });

  useEffect(() => {
    if (meetup) {
      setFormData(meetup);
    } else {
      setFormData({
        name: '',
        short_description: '',
        long_description: '',
        notes: '',
        question: '',
        answers: [],
        is_debate: false,
        is_friends: false,
        is_secret: false,
        duration: Duration.FIVE_MINUTES,
        event_start_date: new Date().toISOString(),
        event_end_date: new Date(Date.now() + 60 * 60 * 1000).toISOString(),
        topics: []
      });
    }
    setShowAdvanced(false);
  }, [meetup]);

  const mutation = useMutation({
    mutationFn: async (data: Partial<OneOnOneMeetup>) => {
      console.log('Submitting meetup:', data);
      const sanitizedData = {
        ...data,
        name: DOMPurify.sanitize(data.name || ''),
        short_description: data.short_description ? DOMPurify.sanitize(data.short_description) : undefined,
        long_description: data.long_description ? DOMPurify.sanitize(data.long_description) : undefined,
        notes: data.notes ? DOMPurify.sanitize(data.notes) : undefined,
      };

      if (meetup) {
        return api.patch(`/oneononemeetups/${meetup.id}`, sanitizedData);
      }
      return api.post('/oneononemeetups', sanitizedData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['oneononemeetups'] });
      onClose();
    },
    onError: (error: any) => {
      console.error('Mutation error:', error);
      setError(error.response?.data?.error || error.message || 'Failed to save meetup');
    }
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!formData.name?.trim()) {
      setError('Name is required');
      return;
    }

    if (formData.short_description && formData.short_description.length > 255) {
      setError('Short description must be 255 characters or less');
      return;
    }

    mutation.mutate(formData);
  };

  const handleToggleDebate = (checked: boolean) => {
    if (checked && formData.is_friends) {
      setFormData({ ...formData, is_debate: checked, is_friends: false });
    } else {
      setFormData({ ...formData, is_debate: checked });
    }
  };

  const handleToggleFriends = (checked: boolean) => {
    if (checked && formData.is_debate) {
      setFormData({ ...formData, is_friends: checked, is_debate: false });
    } else {
      setFormData({ ...formData, is_friends: checked });
    }
  };

  const handleAttendeeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAttendeeInput(value);
    
    const match = value.match(/@(\w*)$/);
    if (match) {
      setUserSearchTerm(match[1]);
    } else {
      setUserSearchTerm('');
    }
  };

  const handleStartDateChange = (newStartDate: string) => {
    const startDate = new Date(newStartDate);
    const endDate = new Date(startDate.getTime() + 60 * 60 * 1000);
    setFormData({
      ...formData,
      event_start_date: startDate.toISOString(),
      event_end_date: endDate.toISOString()
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {meetup ? 'Edit 1:1 Meetup' : 'Create 1:1 Meetup'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            
            <TextField
              label="Name"
              required
              value={formData.name || ''}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              error={!!error && !formData.name?.trim()}
            />

            <TextField
              label="Short Description"
              value={formData.short_description || ''}
              onChange={(e) => setFormData({ ...formData, short_description: e.target.value })}
              helperText={`${formData.short_description?.length || 0}/255`}
              error={!!formData.short_description && formData.short_description.length > 255}
            />

            <TextField
              label="Long Description"
              multiline
              rows={4}
              value={formData.long_description || ''}
              onChange={(e) => setFormData({ ...formData, long_description: e.target.value })}
              helperText={`${formData.long_description?.length || 0}/1535`}
              error={!!formData.long_description && formData.long_description.length > 1535}
            />

            <TextField
              label="Notes"
              value={formData.notes || ''}
              onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
              helperText={`${formData.notes?.length || 0}/255`}
              error={!!formData.notes && formData.notes.length > 255}
            />

            <FormControl>
              <InputLabel>Duration</InputLabel>
              <Select
                value={formData.duration || Duration.FIVE_MINUTES}
                onChange={(e) => setFormData({ ...formData, duration: e.target.value as Duration })}
                label="Duration"
              >
                <MenuItem value={Duration.ONE_MINUTE}>1 Minute</MenuItem>
                <MenuItem value={Duration.FIVE_MINUTES}>5 Minutes</MenuItem>
                <MenuItem value={Duration.SEVEN_MINUTES}>7 Minutes</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Start Date"
              type="datetime-local"
              value={formData.event_start_date?.slice(0, 16) || ''}
              onChange={(e) => handleStartDateChange(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              label="End Date"
              type="datetime-local"
              value={formData.event_end_date?.slice(0, 16) || ''}
              onChange={(e) => setFormData({ ...formData, event_end_date: new Date(e.target.value).toISOString() })}
              InputLabelProps={{ shrink: true }}
            />

            <Button
              onClick={() => setShowAdvanced(!showAdvanced)}
              endIcon={showAdvanced ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              sx={{ alignSelf: 'flex-start' }}
            >
              Advanced Options
            </Button>

            <Collapse in={showAdvanced}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.is_debate || false}
                      onChange={(e) => handleToggleDebate(e.target.checked)}
                    />
                  }
                  label="Is Debate"
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.is_friends || false}
                      onChange={(e) => handleToggleFriends(e.target.checked)}
                    />
                  }
                  label="Friends Only"
                />

                {(formData.is_debate || formData.is_friends) && (
                  <>
                    <TextField
                      label="Question"
                      value={formData.question || ''}
                      onChange={(e) => setFormData({ ...formData, question: e.target.value })}
                      helperText={`${formData.question?.length || 0}/255`}
                      error={!!formData.question && formData.question.length > 255}
                    />
                    
                    <TextField
                      label="Answers (one per line)"
                      multiline
                      rows={4}
                      fullWidth
                      InputProps={{
                        style: { whiteSpace: 'pre-wrap' }
                      }}
                      value={formData.answers?.join('\n') || ''}
                      onChange={(e) => setFormData({ 
                        ...formData, 
                        answers: e.target.value.split('\n')
                      })}
                      placeholder="Enter answers, one per line"
                    />
                  </>
                )}

                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.is_secret || false}
                      onChange={(e) => setFormData({ ...formData, is_secret: e.target.checked })}
                    />
                  }
                  label="Secret"
                />

                {formData.is_secret && (
                  <MentionInput
                    label="Attendees"
                    multiline
                    rows={4}
                    value={attendeeInput}
                    onChange={(newValue) => {
                      setAttendeeInput(newValue);
                      // Extract emails and usernames
                      const attendees = newValue.split(/[\s,]+/).filter(item => 
                        item.includes('@') || 
                        (item.startsWith('@') && item.length > 1)
                      );
                      setFormData({
                        ...formData,
                        attendees
                      });
                    }}
                    placeholder="Enter email addresses or @usernames"
                    helperText="Enter email addresses or type @ to mention users"
                  />
                )}

                <TextField
                  label="Topics (one per line)"
                  multiline
                  rows={4}
                  fullWidth
                  InputProps={{
                    style: { whiteSpace: 'pre-wrap' }
                  }}
                  value={formData.topics?.join('\n') || ''}
                  onChange={(e) => setFormData({
                    ...formData,
                    topics: e.target.value.split('\n')
                  })}
                  placeholder="Enter topics, one per line"
                />

                <TextField
                  label="Next URL"
                  value={formData.next_url || ''}
                  onChange={(e) => setFormData({ ...formData, next_url: e.target.value })}
                  helperText={`${formData.next_url?.length || 0}/255`}
                  error={!!formData.next_url && formData.next_url.length > 255}
                />
              </Box>
            </Collapse>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            disabled={mutation.isPending}
            startIcon={mutation.isPending ? <CircularProgress size={20} /> : null}
          >
            {meetup ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}; 