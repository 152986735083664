import { useState } from 'react';
import { Box, IconButton, Popover, Typography, Badge } from '@mui/material';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import { User } from '../types';

interface Reaction {
  emoji: string;
  count: number;
  users: User[];
}

interface EmojiReactionsProps {
  contentId: string;
  contentType: 'comment' | 'chat_message';
  conversationId?: string; // Only needed for chat messages
  reactions: Reaction[];
  onReactionAdded: (reaction: Reaction) => void;
  onReactionRemoved: (emoji: string) => void;
  user?: User;
}

const COMMON_EMOJIS = ['👍', '❤️', '😄', '🎉', '🤔', '👀', '🚀', '💯'];

export const EmojiReactions = ({ 
  contentId, 
  contentType,
  conversationId,
  reactions, 
  onReactionAdded,
  onReactionRemoved,
  user
}: EmojiReactionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleReactionClick = async (emoji: string) => {
    try {
      const existingReaction = reactions.find(r => r.emoji === emoji);
      if (!existingReaction) {
        onReactionAdded({ emoji, count: 1, users: [] });
      } else {
        const isCurrentUserReaction = existingReaction.users.some(u => u.id === user?.id);
        if (isCurrentUserReaction) {
          onReactionRemoved(emoji);
        } else {
          onReactionAdded({ emoji, count: existingReaction.count + 1, users: existingReaction.users });
        }
      }
    } catch (error) {
      console.error('Failed to toggle reaction:', error);
    }
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
      {reactions.map(({ emoji, count, users }) => (
        <Badge
          key={emoji}
          badgeContent={count}
          color="primary"
          sx={{ '& .MuiBadge-badge': { fontSize: '0.7rem' } }}
        >
          <Box
            onClick={() => handleReactionClick(emoji)}
            sx={{ 
              fontSize: '1.2rem',
              padding: '4px',
              cursor: 'pointer',
              borderRadius: '50%',
              '&:hover': { backgroundColor: 'action.hover' },
              color: 'text.primary',
              opacity: 1,
              '& *': {
                opacity: 1
              }
            }}
          >
            {emoji}
          </Box>
        </Badge>
      ))}
      
      <IconButton
        size="small"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{ padding: '4px' }}
      >
        <AddReactionIcon fontSize="small" />
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 1, display: 'flex', flexWrap: 'wrap', maxWidth: 200 }}>
          {COMMON_EMOJIS.map(emoji => (
            <Box
              key={emoji}
              onClick={() => handleReactionClick(emoji)}
              sx={{ 
                fontSize: '1.2rem',
                padding: '8px',
                cursor: 'pointer',
                borderRadius: '50%',
                '&:hover': { backgroundColor: 'action.hover' },
                color: 'text.primary',
                opacity: 1,
                '& *': {
                  opacity: 1
                }
              }}
            >
              {emoji}
            </Box>
          ))}
        </Box>
      </Popover>
    </Box>
  );
}; 