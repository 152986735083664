import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { PotentialMatch } from '../../services/connectedLunch.service';

interface WheelProps {
  items: PotentialMatch[];
  onStop: (item: PotentialMatch) => void;
  selectedItem: PotentialMatch | null;
}

export const Wheel: React.FC<WheelProps> = ({ items, onStop, selectedItem }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const requestRef = useRef<number>();
  const startTimeRef = useRef<number>();
  const isSpinningRef = useRef(false);
  const currentRotationRef = useRef<number>();

  const formatUserName = (user: PotentialMatch['user']) => {
    const firstName = user.firstName || 'Anonymous';
    const lastName = user.lastName || '';
    const company = user.company?.name ? ` (${user.company.name})` : '';
    return `${firstName} ${lastName}${company}`.trim();
  };

  const drawWheel = (rotation: number) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Save current rotation for selection calculation
    currentRotationRef.current = rotation;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.save();
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate(rotation);

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = Math.min(centerX, centerY) - 10;

    // Draw wheel segments
    const segmentAngle = (2 * Math.PI) / items.length;
    items.forEach((item, index) => {
      const startAngle = index * segmentAngle;
      const endAngle = startAngle + segmentAngle;

      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.arc(0, 0, radius, startAngle, endAngle);
      ctx.closePath();

      // Use a more vibrant color scheme
      ctx.fillStyle = index % 2 === 0 ? '#e3f2fd' : '#bbdefb';  // Light blue alternating shades
      ctx.strokeStyle = '#1976d2';  // Darker blue border
      ctx.lineWidth = 2;
      ctx.fill();
      ctx.stroke();

      // Draw text
      ctx.save();
      ctx.rotate(startAngle + segmentAngle / 2);
      ctx.textAlign = 'right';
      ctx.fillStyle = '#1565c0';  // Dark blue text
      ctx.font = 'bold 14px Roboto, Arial, sans-serif';
      const text = formatUserName(item.user);
      
      // Add text wrapping if the name is too long
      const maxWidth = radius - 40;
      if (ctx.measureText(text).width > maxWidth) {
        ctx.font = 'bold 12px Roboto, Arial, sans-serif';
      }
      
      ctx.fillText(text, radius - 20, 0);
      ctx.restore();
    });

    ctx.restore();

    // Draw arrow
    ctx.beginPath();
    ctx.moveTo(centerX + radius + 10, centerY);
    ctx.lineTo(centerX + radius + 30, centerY - 10);
    ctx.lineTo(centerX + radius + 30, centerY + 10);
    ctx.closePath();
    ctx.fillStyle = '#f44336';  // Material red
    ctx.strokeStyle = '#d32f2f';  // Darker red border
    ctx.lineWidth = 2;
    ctx.fill();
    ctx.stroke();
  };

  const animate = (timestamp: number) => {
    if (!startTimeRef.current) {
      startTimeRef.current = timestamp;
    }

    const elapsed = timestamp - startTimeRef.current;
    const duration = 3000; // 3 seconds spin

    if (elapsed < duration && isSpinningRef.current) {
      // Easing function for smooth deceleration
      const progress = elapsed / duration;
      
      // Custom easing function for slower start and smooth end
      const easing = progress < 0.5 
        ? 2 * Math.pow(progress, 3) // Slow start
        : 1 - Math.pow(-2 * progress + 2, 3) / 2; // Smooth end
      
      // Only 2-3 rotations total for slower speed
      const totalRotations = 2 + Math.random();
      
      // Add random offset to make final selection random
      const randomOffset = Math.random() * (2 * Math.PI);
      const rotation = (totalRotations * 2 * Math.PI * easing + randomOffset) % (2 * Math.PI);

      drawWheel(rotation);
      requestRef.current = requestAnimationFrame(animate);
    } else if (isSpinningRef.current) {
      // Spinning just finished
      isSpinningRef.current = false;
      startTimeRef.current = undefined;
      
      // Calculate which item is selected based on final rotation
      const segmentAngle = (2 * Math.PI) / items.length;
      const finalRotation = (2 * Math.PI - currentRotationRef.current) % (2 * Math.PI);
      const selectedIndex = Math.floor(finalRotation / segmentAngle);
      
      // Ensure the index is valid
      const validIndex = ((selectedIndex % items.length) + items.length) % items.length;
      onStop(items[validIndex]);
    }
  };

  const startSpin = () => {
    if (!isSpinningRef.current) {
      isSpinningRef.current = true;
      startTimeRef.current = undefined;
      requestRef.current = requestAnimationFrame(animate);
    }
  };

  // Handle click to spin again
  const handleClick = () => {
    if (!isSpinningRef.current && items.length > 0) {
      // Reset any previous animation
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
      startTimeRef.current = undefined;
      startSpin();
    }
  };

  useEffect(() => {
    // Set canvas size
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.width = 500;
      canvas.height = 500;
    }

    // Draw initial wheel
    if (items.length > 0) {
      drawWheel(0);
      // Start spinning if no item is selected
      if (!selectedItem) {
        requestAnimationFrame(() => startSpin());
      }
    }
    
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [items, selectedItem]);

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        p: 2,
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: 2,
        minHeight: 500  // Add minimum height
      }}
    >
      <canvas
        ref={canvasRef}
        onClick={handleClick}
        style={{ 
          cursor: isSpinningRef.current ? 'default' : 'pointer',
          width: '500px',
          height: '500px'
        }}
      />
    </Box>
  );
};
