import { useEngagement } from './useEngagement';
import { useQuery } from '@tanstack/react-query';
import { api } from '../services/api';

export const useEngagementWithPrivacy = (contentType: string, contentId: string) => {
  const { mutation, trackEngagement } = useEngagement();
  
  // Get engagement status
  const { data: engagementStatus } = useQuery({
    queryKey: ['engagement', contentType, contentId],
    queryFn: () => api.get(`/engagements/${contentType.toUpperCase()}/${contentId}`).then(res => res.data),
    retry: (failureCount, error: any) => {
      // Don't retry on 429 (rate limit) or 404
      if (error?.response?.status === 429 || error?.response?.status === 404) {
        return false;
      }
      return failureCount < 2;
    },
    retryDelay: 2000,
    staleTime: 30000, // Consider data fresh for 30 seconds
    cacheTime: 5 * 60 * 1000, // Cache for 5 minutes
    refetchOnWindowFocus: false // Don't refetch when window regains focus
  });

  const handleEngagement = async (type: string, metadata?: any) => {
    await trackEngagement[type](contentId, contentType, metadata);
  };

  return {
    handleEngagement,
    engagementStatus
  };
};