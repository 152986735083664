import React, { useState, useEffect } from 'react';
import { 
  IconButton, 
  Badge, 
  Menu, 
  MenuItem, 
  Typography, 
  Box, 
  Divider, 
  List, 
  ListItem, 
  ListItemText, 
  Button,
  useTheme,
  CircularProgress,
  Tooltip
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import { formatDistanceToNow } from 'date-fns';

interface Notification {
  id: string;
  title: string;
  message: string;
  is_read: boolean;
  created_at: string;
  type: string;
  metadata?: any;
}

const NotificationIcon: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const auth = useAuth();
  const theme = useTheme();
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    fetchNotifications();
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const fetchNotifications = async () => {
    if (!auth?.isAuthenticated) return;
    
    setIsLoading(true);
    
    try {
      const response = await axios.get('/api/notifications', {
        headers: { Authorization: `Bearer ${auth.token}` }
      });
      
      if (response.data.success) {
        setNotifications(response.data.data.notifications);
        setUnreadCount(response.data.data.unreadCount);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const markAllAsRead = async () => {
    if (!auth?.isAuthenticated) return;
    
    try {
      const response = await axios.post(
        '/api/notifications/mark-all-read', 
        {},
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      
      if (response.data.success) {
        setNotifications(notifications.map(notif => ({ ...notif, is_read: true })));
        setUnreadCount(0);
      }
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };
  
  const markAsRead = async (notificationId: string) => {
    if (!auth?.isAuthenticated) return;
    
    try {
      const response = await axios.post(
        `/api/notifications/${notificationId}/read`, 
        {},
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      
      if (response.data.success) {
        setNotifications(
          notifications.map(notif => 
            notif.id === notificationId 
              ? { ...notif, is_read: true } 
              : notif
          )
        );
        setUnreadCount(prev => Math.max(0, prev - 1));
      }
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };
  
  // Check for new notifications periodically
  useEffect(() => {
    if (!auth?.isAuthenticated) return;
    
    const checkUnreadCount = async () => {
      try {
        const response = await axios.get('/api/notifications/unread-count', {
          headers: { Authorization: `Bearer ${auth.token}` }
        });
        
        if (response.data.success) {
          setUnreadCount(response.data.data.count);
        }
      } catch (error) {
        console.error('Error checking unread notifications:', error);
      }
    };
    
    // Check unread count initially
    checkUnreadCount();
    
    // Set up interval to check for new notifications
    const intervalId = setInterval(checkUnreadCount, 60000); // Check every minute
    
    return () => clearInterval(intervalId);
  }, [auth?.isAuthenticated, auth?.token]);
  
  const renderNotificationItem = (notification: Notification) => {
    const timeAgo = formatDistanceToNow(new Date(notification.created_at), { addSuffix: true });
    
    return (
      <ListItem 
        key={notification.id}
        onClick={() => markAsRead(notification.id)}
        sx={{ 
          py: 1.5, 
          px: 2,
          backgroundColor: notification.is_read 
            ? 'transparent' 
            : theme.palette.mode === 'dark' 
              ? 'rgba(144, 202, 249, 0.08)' 
              : 'rgba(33, 150, 243, 0.08)',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: theme.palette.mode === 'dark' 
              ? 'rgba(255, 255, 255, 0.05)' 
              : 'rgba(0, 0, 0, 0.04)'
          }
        }}
      >
        <ListItemText
          primary={
            <Typography variant="subtitle2" color={notification.is_read ? 'text.primary' : 'primary'}>
              {notification.title}
            </Typography>
          }
          secondary={
            <>
              <Typography variant="body2" color="text.secondary" sx={{ my: 0.5 }}>
                {notification.message}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {timeAgo}
              </Typography>
            </>
          }
        />
      </ListItem>
    );
  };
  
  if (!auth?.isAuthenticated) {
    return null;
  }
  
  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          color="inherit"
          onClick={handleClick}
          aria-label="notifications"
          sx={{ 
            color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.primary 
          }}
        >
          <Badge badgeContent={unreadCount} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: 320,
            maxHeight: 400,
            overflow: 'auto'
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ px: 2, py: 1.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" color="text.primary">
            Notifications
          </Typography>
          
          {unreadCount > 0 && (
            <Button size="small" onClick={markAllAsRead}>
              Mark all as read
            </Button>
          )}
        </Box>
        
        <Divider />
        
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
            <CircularProgress size={24} />
          </Box>
        ) : notifications.length > 0 ? (
          <List disablePadding>
            {notifications.map(renderNotificationItem)}
          </List>
        ) : (
          <Box sx={{ py: 4, textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              No notifications yet
            </Typography>
          </Box>
        )}
      </Menu>
    </>
  );
};

export default NotificationIcon; 