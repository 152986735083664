import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { Box, Button, Dialog, DialogActions, DialogContent, Slider, Typography } from '@mui/material';
import type { ImageCropperProps } from './types';

const ImageCropper: React.FC<ImageCropperProps> = ({
  image,
  onCropComplete,
  onCancel,
  aspectRatio = 16 / 9,
  config
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropChange = (crop: { x: number; y: number }) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom: number) => {
    setZoom(zoom);
  };

  const onCropCompleteHandler = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const createCroppedImage = async () => {
    try {
      const img = new Image();
      img.src = image;
      await new Promise((resolve) => {
        img.onload = resolve;
      });

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx || !croppedAreaPixels) return;

      // Set canvas size to the cropped size
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;

      // Draw the cropped image onto the canvas
      ctx.drawImage(
        img,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );

      // Convert canvas to blob
      const blob = await new Promise<Blob>((resolve) => {
        canvas.toBlob((blob) => {
          resolve(blob as Blob);
        }, 'image/jpeg', 0.95);
      });

      onCropComplete(blob);
    } catch (error) {
      console.error('Error creating cropped image:', error);
    }
  };

  return (
    <>
      <DialogContent sx={{ height: 400, position: 'relative', p: 0 }}>
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={aspectRatio}
          onCropChange={onCropChange}
          onZoomChange={onZoomChange}
          onCropComplete={onCropCompleteHandler}
        />
      </DialogContent>
      <Box sx={{ px: 3, pb: 2 }}>
        <Typography gutterBottom>Zoom</Typography>
        <Slider
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="zoom"
          onChange={(e, zoom) => onZoomChange(zoom as number)}
        />
      </Box>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={createCroppedImage} variant="contained" color="primary">
          Crop Image
        </Button>
      </DialogActions>
    </>
  );
};

export default ImageCropper; 