// frontend/src/pages/CreateAnnouncementPage.tsx
import React, { useState, useRef } from 'react';
import {
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  Paper,
  FormHelperText,
  Switch,
  FormControlLabel,
  IconButton,
  Dialog,
  Snackbar,
  Alert,
  SelectChangeEvent,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { City, Country } from '../types';
import { uploadImage } from '../services/upload';
import { createAnnouncement } from '../services/announcements';
import { PhotoCamera, Delete as DeleteIcon } from '@mui/icons-material';
import { LocationSelector } from '../components/LocationSelector';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ImageCropper from '../components/ImageCropper';
import { validateImage } from '../utils/imageValidation';
import { ImageUploader } from '../components/images';
import { ImageType } from '../components/images/constants';

export interface FormData {
  name: string;
  short_description: string;
  long_description: string;
  announcement_type: string;
  announcement_area: string;
  venue: string;
  city_id: string | null;
  country_id: string | null;
  event_start_date: Date;
  event_end_date: Date;
  announcement_start_date: Date;
  announcement_end_date: Date;
  is_active: boolean;
  image_url?: string;
}

interface UploadResponse {
  url: string;
}

const CreateAnnouncementPage = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string>('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showCropper, setShowCropper] = useState(false);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [cities, setCities] = useState<City[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  
  // Set default dates when form initializes
  const getDefaultDates = () => {
    const now = new Date();
    const eventStart = new Date();
    const eventEnd = new Date(eventStart.getTime() + 24 * 60 * 60 * 1000); // 24 hours after event start
    const announcementStart = new Date();
    const announcementEnd = new Date(announcementStart.getTime() + 24 * 60 * 60 * 1000); // 24 hours after announcement start

    return {
      event_start_date: eventStart,
      event_end_date: eventEnd,
      announcement_start_date: announcementStart,
      announcement_end_date: announcementEnd
    };
  };

  const defaultDates = getDefaultDates();

  const [formData, setFormData] = useState<FormData>({
    name: '',
    short_description: '',
    long_description: '',
    announcement_type: 'EVENT',
    announcement_area: 'BUSINESS',
    venue: '',
    city_id: null,
    country_id: null,
    event_start_date: defaultDates.event_start_date,
    event_end_date: defaultDates.event_end_date,
    announcement_start_date: defaultDates.announcement_start_date,
    announcement_end_date: defaultDates.announcement_end_date,
    is_active: true,
    image_url: '',
  });

  // Validate dates when they change
  const validateDates = (dates: {
    event_start_date: Date,
    event_end_date: Date,
    announcement_start_date: Date,
    announcement_end_date: Date
  }) => {
    const errors = [];

    // Check if event end is after event start
    if (dates.event_end_date <= dates.event_start_date) {
      errors.push('Event end date must be at least one minute after event start date');
    }

    // Check if announcement end is after announcement start
    if (dates.announcement_end_date <= dates.announcement_start_date) {
      errors.push('Announcement end date must be after announcement start date');
    }

    // Check if announcement end is not after event end
    if (dates.announcement_end_date > dates.event_end_date) {
      errors.push('Announcement end date cannot be after event end date');
    }

    return errors;
  };

  const handleDateChange = (field: string) => (date: Date | null) => {
    if (!date) return;

    const newDates = {
      ...formData,
      [field]: date
    };

    // If changing start dates, automatically update end dates
    if (field === 'event_start_date') {
      newDates.event_end_date = new Date(date.getTime() + 24 * 60 * 60 * 1000);
    } else if (field === 'announcement_start_date') {
      newDates.announcement_end_date = new Date(date.getTime() + 24 * 60 * 60 * 1000);
    }

    const dateErrors = validateDates(newDates);
    if (dateErrors.length > 0) {
      setError(dateErrors.join('. '));
      return;
    }

    setError(null);
    setFormData(newDates);
  };

  const handleImageSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      // Use the existing image validation utility
      await validateImage(file);
      
      // Show the cropper dialog
      setImageFile(file);
      setShowCropper(true);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Invalid image file');
    }
  };

  const handleCropComplete = async (croppedImageBlob: Blob) => {
    setShowCropper(false);
    
    // Convert blob to File
    const croppedFile = new File([croppedImageBlob], imageFile?.name || 'cropped-image.jpg', {
      type: 'image/jpeg'
    });
    
    setImageFile(croppedFile);
    setImagePreview(URL.createObjectURL(croppedImageBlob));
    setCroppedImage(URL.createObjectURL(croppedImageBlob));
  };

  const handleRemoveImage = () => {
    setImageFile(null);
    setImagePreview('');
    setCroppedImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleImageUpload = async (file: File): Promise<string> => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await uploadImage(file);
      return response;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw new Error('Failed to upload image');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const dateErrors = validateDates(formData);
    if (dateErrors.length > 0) {
      setError(dateErrors.join('. '));
      setShowErrorMessage(true);
      return;
    }

    setLoading(true);
    try {
      let imageUrl = formData.image_url;
      
      if (imageFile) {
        imageUrl = await handleImageUpload(imageFile);
      }

      const submissionData = {
        ...formData,
        city_id: selectedCity?.id,
        country_id: selectedCountry?.id,
        image_url: imageUrl || undefined
      };

      await createAnnouncement(submissionData);
      setShowSuccessMessage(true);
      setTimeout(() => {
        navigate('/announcements');
      }, 1500);
    } catch (err) {
      console.error('Error creating announcement:', err);
      setError('Failed to create announcement. Please try again.');
      setShowErrorMessage(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCityChange = (event: SelectChangeEvent<string>) => {
    const cityId = event.target.value;
    const city = cities.find((c: City) => c.id === cityId);
    setSelectedCity(city || null);
    setFormData(prev => ({
      ...prev,
      city_id: cityId || null
    }));
  };

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    const countryId = event.target.value;
    const country = countries.find((c: Country) => c.id === countryId);
    setSelectedCountry(country || null);
    setFormData(prev => ({
      ...prev,
      country_id: countryId || null
    }));
  };

  const handleChange = (field: keyof FormData) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData((prev) => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleSelectChange = (field: keyof FormData) => (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFormData((prev) => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  return (
    <Box component={Paper} sx={{ p: 3, maxWidth: 800, mx: 'auto', mt: 3 }}>
      <Typography variant="h5" gutterBottom>
        Create New Announcement
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Title"
              value={formData.name}
              onChange={handleChange('name')}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Short Description"
              value={formData.short_description}
              onChange={handleChange('short_description')}
              multiline
              rows={2}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Long Description"
              value={formData.long_description}
              onChange={handleChange('long_description')}
              multiline
              rows={4}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Announcement Type</InputLabel>
              <Select
                value={formData.announcement_type}
                label="Announcement Type"
                onChange={handleSelectChange('announcement_type')}
              >
                <MenuItem value="GENERAL">General</MenuItem>
                <MenuItem value="EVENT">Event</MenuItem>
                <MenuItem value="NEWS">News</MenuItem>
                <MenuItem value="MAINTENANCE">Maintenance</MenuItem>
                <MenuItem value="ALERT">Alert</MenuItem>
                <MenuItem value="PROMOTION">Promotion</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Area</InputLabel>
              <Select
                value={formData.announcement_area}
                label="Area"
                onChange={handleSelectChange('announcement_area')}
              >
                <MenuItem value="BUSINESS">Business</MenuItem>
                <MenuItem value="SCIENCE">Science</MenuItem>
                <MenuItem value="TECHNOLOGY">Technology</MenuItem>
                <MenuItem value="EDUCATION">Education</MenuItem>
                <MenuItem value="HEALTH">Health</MenuItem>
                <MenuItem value="ENVIRONMENT">Environment</MenuItem>
                <MenuItem value="POLITICS">Politics</MenuItem>
                <MenuItem value="CULTURE">Culture</MenuItem>
                <MenuItem value="SPORTS">Sports</MenuItem>
                <MenuItem value="ENTERTAINMENT">Entertainment</MenuItem>
                <MenuItem value="OTHER">Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.is_active}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    is_active: e.target.checked
                  }))}
                  color="primary"
                />
              }
              label={formData.is_active ? "Active (visible to users)" : "Inactive (draft mode)"}
            />
          </Grid>

          <Grid item xs={12}>
            <ImageUploader
              onImageSelect={(file) => setImageFile(file)}
              onImageRemove={handleRemoveImage}
              imagePreview={imagePreview}
              imageType={ImageType.ANNOUNCEMENT}
              aspectRatio={1200/630}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Location Details
            </Typography>
            <LocationSelector
              selectedCity={selectedCity}
              selectedCountry={selectedCountry}
              onCityChange={setSelectedCity}
              onCountryChange={setSelectedCountry}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Venue"
              value={formData.venue}
              onChange={handleChange('venue')}
              placeholder="Optional venue details"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" gutterBottom>
              Event Period
            </Typography>
            <Box sx={{ mb: 2 }}>
              <DatePicker
                selected={formData.event_start_date}
                onChange={handleDateChange('event_start_date')}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                customInput={<TextField fullWidth label="Event Start Date" />}
              />
            </Box>
            <DatePicker
              selected={formData.event_end_date}
              onChange={handleDateChange('event_end_date')}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
              customInput={<TextField fullWidth label="Event End Date" />}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" gutterBottom>
              Announcement Display Period
            </Typography>
            <Box sx={{ mb: 2 }}>
              <DatePicker
                selected={formData.announcement_start_date}
                onChange={handleDateChange('announcement_start_date')}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                customInput={<TextField fullWidth label="Display Start Date" />}
              />
            </Box>
            <DatePicker
              selected={formData.announcement_end_date}
              onChange={handleDateChange('announcement_end_date')}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
              customInput={<TextField fullWidth label="Display End Date" />}
            />
          </Grid>

          {error && (
            <Grid item xs={12}>
              <FormHelperText error>{error}</FormHelperText>
            </Grid>
          )}

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                onClick={() => navigate('/announcements')}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                Create Announcement
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>

      {/* Success Message */}
      <Snackbar
        open={showSuccessMessage}
        autoHideDuration={1500}
        onClose={() => setShowSuccessMessage(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ zIndex: 9999 }}
      >
        <Alert 
          onClose={() => setShowSuccessMessage(false)} 
          severity="success" 
          sx={{ width: '100%', fontSize: '1rem', fontWeight: 'bold' }}
          elevation={6}
        >
          SUCCESS
        </Alert>
      </Snackbar>

      {/* Error Message */}
      <Snackbar
        open={showErrorMessage}
        autoHideDuration={3000}
        onClose={() => setShowErrorMessage(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ zIndex: 9999 }}
      >
        <Alert 
          onClose={() => setShowErrorMessage(false)} 
          severity="error" 
          sx={{ width: '100%', fontSize: '1rem', fontWeight: 'bold' }}
          elevation={6}
        >
          FAILED: {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CreateAnnouncementPage;