import React from 'react';
import { MenuItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import {
  Search as SearchIcon,
  Article as ArticleIcon,
  VideoChat as VideoChatIcon,
  People as PeopleIcon,
  Restaurant as RestaurantIcon,
  Campaign as AnnouncementIcon,
  Language as LanguageIcon,
  Business as BusinessIcon,
  PersonAdd as PersonAddIcon,
  BarChart as BarChartIcon,
  Analytics as AnalyticsIcon,
  Help as HelpIcon,
  Info as InfoIcon,
  Gavel as GavelIcon,
  Build as BuildIcon,
  Chat as ChatIcon,
} from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';

interface UserRole {
  roleId: string;
  role: {
    name: string;
  };
}

interface MenuItemData {
  text: string;
  icon: JSX.Element;
  path: string;
  adminOnly?: boolean;
}

export interface MenuItemsProps {
  onItemClick: (path: string) => void;
  isSmallScreen: boolean;
}

const regularItems: MenuItemData[] = [
  { text: 'Search', icon: <SearchIcon />, path: '/search' },
  { text: 'Posts', icon: <ArticleIcon />, path: '/posts' },
  { text: 'Announcements', icon: <AnnouncementIcon />, path: '/announcements' },
  { text: '1:1 Meetups', icon: <VideoChatIcon />, path: '/oneononemeetups' },
  { text: 'Contacts', icon: <PeopleIcon />, path: '/contacts' },
  { text: 'Connected Lunch', icon: <RestaurantIcon />, path: '/connected-lunch' },
  { text: 'Chat', icon: <ChatIcon />, path: '/chat' },
];

const adminItems: MenuItemData[] = [
  { text: 'Languages', icon: <LanguageIcon />, path: '/admin/languages', adminOnly: true },
  { text: 'Organizations', icon: <BusinessIcon />, path: '/admin/organisations', adminOnly: true },
  { text: 'White List', icon: <PersonAddIcon />, path: '/admin/whitelist', adminOnly: true },
  { text: 'Waiting List', icon: <PeopleIcon />, path: '/admin/waitinglist', adminOnly: true },
  { text: 'Meetup Stats', icon: <BarChartIcon />, path: '/admin/oneononemeetups', adminOnly: true },
  { text: 'Analytics', icon: <AnalyticsIcon />, path: '/admin/analytics', adminOnly: true },
];

const publicItems: MenuItemData[] = [
  { text: 'Tools', icon: <BuildIcon />, path: '/tools' },
  { text: 'FAQ', icon: <HelpIcon />, path: '/faq' },
  { text: 'About', icon: <InfoIcon />, path: '/about' },
  { text: 'Terms', icon: <GavelIcon />, path: '/terms' },
];

export const MenuItems: React.FC<MenuItemsProps> = ({ onItemClick, isSmallScreen }) => {
  const auth = useAuth();
  const hasAdminRole = auth?.user?.roles?.some((role: UserRole) => 
    ['Superuser', 'Admin', 'Content Manager'].includes(role.role?.name)
  );

  const renderItems = (items: MenuItemData[]) => {
    return items.map((item) => {
      if (item.adminOnly && !hasAdminRole) return null;
      return (
        <MenuItem key={item.path} onClick={() => onItemClick(item.path)}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
        </MenuItem>
      );
    });
  };

  return (
    <>
      {auth?.isAuthenticated && (
        <>
          {renderItems(regularItems)}
          {hasAdminRole && (
            <>
              <Divider />
              {renderItems(adminItems)}
            </>
          )}
          {isSmallScreen && (
            <>
              <Divider />
              {renderItems(publicItems)}
            </>
          )}
        </>
      )}
      {!auth?.isAuthenticated && isSmallScreen && renderItems(publicItems)}
    </>
  );
}; 