import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  Paper,
  FormHelperText,
} from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { fetchAnnouncement, updateAnnouncement } from '../services/announcements';
import type { Announcement } from '../services/announcements';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { LocationSelector } from '../components/LocationSelector';
import type { City, Country } from '../types';

interface FormData extends Omit<Announcement, 'id' | 'created_at' | 'updated_at' | 'author' | 'city' | 'country'> {
  city_id?: string;
  country_id?: string;
}

const EditAnnouncementPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  
  const [formData, setFormData] = useState<FormData>({
    name: '',
    short_description: '',
    long_description: '',
    announcement_type: 'GENERAL',
    announcement_area: 'BUSINESS',
    event_start_date: new Date(),
    event_end_date: new Date(),
    announcement_start_date: new Date(),
    announcement_end_date: new Date(),
    is_active: true,
    venue: '',
    author_id: '',
    city_id: undefined,
    country_id: undefined
  });

  useEffect(() => {
    const loadAnnouncement = async () => {
      try {
        setLoading(true);
        // Try to get announcement from location state first
        let announcement = location.state?.announcement;
        
        // If not in state, fetch it
        if (!announcement && id) {
          const response = await fetchAnnouncement(id);
          announcement = response;
        }

        if (announcement) {
          setFormData({
            ...announcement,
            event_start_date: new Date(announcement.event_start_date),
            event_end_date: new Date(announcement.event_end_date),
            announcement_start_date: new Date(announcement.announcement_start_date),
            announcement_end_date: new Date(announcement.announcement_end_date),
          });
          
          if (announcement.city) {
            setSelectedCity(announcement.city);
          }
          if (announcement.country) {
            setSelectedCountry(announcement.country);
          }
        }
      } catch (err) {
        console.error('Error loading announcement:', err);
        setError('Failed to load announcement');
      } finally {
        setLoading(false);
      }
    };

    loadAnnouncement();
  }, [id, location.state]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!id) {
      setError('Announcement ID is missing');
      return;
    }

    try {
      const dataToSubmit = {
        ...formData,
        city_id: selectedCity?.id,
        country_id: selectedCountry?.id,
      };

      await updateAnnouncement(id, dataToSubmit);
      navigate('/announcements');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update announcement');
    }
  };

  const handleChange = (field: keyof FormData) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData((prev) => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleSelectChange = (field: keyof FormData) => (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFormData((prev) => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleDateChange = (field: keyof FormData) => (date: Date | null) => {
    if (date) {
      setFormData((prev) => ({
        ...prev,
        [field]: date
      }));
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box component={Paper} sx={{ p: 3, maxWidth: 800, mx: 'auto', mt: 3 }}>
      <Typography variant="h5" gutterBottom>
        Edit Announcement
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Title"
              value={formData.name}
              onChange={handleChange('name')}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Short Description"
              value={formData.short_description}
              onChange={handleChange('short_description')}
              multiline
              rows={2}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Long Description"
              value={formData.long_description}
              onChange={handleChange('long_description')}
              multiline
              rows={4}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Announcement Type</InputLabel>
              <Select
                value={formData.announcement_type}
                label="Announcement Type"
                onChange={handleSelectChange('announcement_type')}
              >
                <MenuItem value="GENERAL">General</MenuItem>
                <MenuItem value="EVENT">Event</MenuItem>
                <MenuItem value="NEWS">News</MenuItem>
                <MenuItem value="MAINTENANCE">Maintenance</MenuItem>
                <MenuItem value="ALERT">Alert</MenuItem>
                <MenuItem value="PROMOTION">Promotion</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Area</InputLabel>
              <Select
                value={formData.announcement_area}
                label="Area"
                onChange={handleSelectChange('announcement_area')}
              >
                <MenuItem value="BUSINESS">Business</MenuItem>
                <MenuItem value="SCIENCE">Science</MenuItem>
                <MenuItem value="TECHNOLOGY">Technology</MenuItem>
                <MenuItem value="EDUCATION">Education</MenuItem>
                <MenuItem value="HEALTH">Health</MenuItem>
                <MenuItem value="ENVIRONMENT">Environment</MenuItem>
                <MenuItem value="POLITICS">Politics</MenuItem>
                <MenuItem value="CULTURE">Culture</MenuItem>
                <MenuItem value="SPORTS">Sports</MenuItem>
                <MenuItem value="ENTERTAINMENT">Entertainment</MenuItem>
                <MenuItem value="OTHER">Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Location Details
            </Typography>
            <LocationSelector
              selectedCity={selectedCity}
              selectedCountry={selectedCountry}
              onCityChange={setSelectedCity}
              onCountryChange={setSelectedCountry}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Venue"
              value={formData.venue}
              onChange={handleChange('venue')}
              placeholder="Optional venue details"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" gutterBottom>
              Event Period
            </Typography>
            <Box sx={{ mb: 2 }}>
              <DatePicker
                selected={formData.event_start_date}
                onChange={handleDateChange('event_start_date')}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                customInput={<TextField fullWidth label="Event Start Date" />}
              />
            </Box>
            <DatePicker
              selected={formData.event_end_date}
              onChange={handleDateChange('event_end_date')}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
              customInput={<TextField fullWidth label="Event End Date" />}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" gutterBottom>
              Announcement Display Period
            </Typography>
            <Box sx={{ mb: 2 }}>
              <DatePicker
                selected={formData.announcement_start_date}
                onChange={handleDateChange('announcement_start_date')}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                customInput={<TextField fullWidth label="Display Start Date" />}
              />
            </Box>
            <DatePicker
              selected={formData.announcement_end_date}
              onChange={handleDateChange('announcement_end_date')}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
              customInput={<TextField fullWidth label="Display End Date" />}
            />
          </Grid>

          {error && (
            <Grid item xs={12}>
              <FormHelperText error>{error}</FormHelperText>
            </Grid>
          )}

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                onClick={() => navigate('/announcements')}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                Save Changes
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default EditAnnouncementPage; 