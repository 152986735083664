import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '../services/api';
import { PostDialog } from '../components/PostDialog';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { PostCard } from '../components/PostCard';
import { Post } from '../types';

const PostsPage = () => {
  const [editingPost, setEditingPost] = useState<Post | null>(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: postsData, isLoading } = useQuery({
    queryKey: ['posts'],
    queryFn: async () => {
      const response = await api.get('/posts');
      return response.data;
    }
  });

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const posts = postsData?.posts || [];

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1">
          Posts
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/post/create')}
        >
          Create
        </Button>
      </Box>

      {posts.map((post: Post) => (
        <PostCard
          key={post.id}
          post={post}
          onEdit={() => setEditingPost(post)}
        />
      ))}

      {editingPost && (
        <PostDialog
          open={!!editingPost}
          onClose={() => setEditingPost(null)}
          post={editingPost}
          onSave={() => {
            setEditingPost(null);
            queryClient.invalidateQueries({ queryKey: ['posts'] });
          }}
        />
      )}
    </Box>
  );
};

export default PostsPage;