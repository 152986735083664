import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Tabs,
  Tab,
  Button,
  Chip,
  Avatar,
  IconButton,
  Dialog,
  Divider,
  Link,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Business as BusinessIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  Home as HomeIcon,
  LinkedIn as LinkedInIcon,
  Chat as ChatIcon,
} from '@mui/icons-material';
import { Contact, Interaction } from '../types/contact';
import { fetchContact, deleteContact, updateContact } from '../services/contacts';
import ContactForm from '../components/contacts/ContactForm';
import InteractionList from '../components/contacts/InteractionList';
import InteractionForm from '../components/contacts/InteractionForm';
import TagManager from '../components/contacts/TagManager';
import { getImageUrl } from '../utils/imageUtils';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`contact-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const ContactDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [contact, setContact] = useState<Contact | null>(null);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isInteractionDialogOpen, setIsInteractionDialogOpen] = useState(false);
  const [isTagManagerOpen, setIsTagManagerOpen] = useState(false);

  useEffect(() => {
    loadContact();
  }, [id]);

  const loadContact = async () => {
    try {
      setLoading(true);
      const data = await fetchContact(id!);
      setContact(data);
    } catch (error) {
      console.error('Error loading contact:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleEdit = async (updatedData: Partial<Contact>) => {
    try {
      console.log('Handling edit with data:', updatedData);
      await updateContact(id!, updatedData);
      setIsEditDialogOpen(false);
      await loadContact();
    } catch (error) {
      console.error('Error updating contact:', error);
      alert('Failed to update contact: ' + (error instanceof Error ? error.message : 'Unknown error'));
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this contact?')) {
      try {
        await deleteContact(id!);
        navigate('/contacts');
      } catch (error) {
        console.error('Error deleting contact:', error);
      }
    }
  };

  const handleStartChat = () => {
    if (contact && contact.linked_user_id) {
      // Navigate to chat page with contact info as query params
      navigate(`/chat?contactId=${contact.id}&userId=${contact.linked_user_id}`);
    } else {
      alert('This contact is not connected to a user account and cannot be messaged directly.');
    }
  };

  if (loading || !contact) {
    return <Typography>Loading...</Typography>;
  }

  const currentPosition = contact.positions?.find(p => p.is_current);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={3}>
          {/* Header */}
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Avatar
                src={getImageUrl(contact.linked_user?.profile_image_url)}
                alt={`${contact.first_name} ${contact.last_name}`}
                sx={{ width: 80, height: 80 }}
              >
                {contact.first_name[0]}{contact.last_name[0]}
              </Avatar>
              <Box>
                <Typography variant="h4">
                  {contact.first_name} {contact.last_name}
                </Typography>
                {contact.linkedin_url && (
                  <Link 
                    href={contact.linkedin_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ display: 'flex', alignItems: 'center', gap: 0.5, color: 'text.secondary' }}
                  >
                    <LinkedInIcon fontSize="small" />
                    LinkedIn Profile
                  </Link>
                )}
                {currentPosition && (
                  <Typography variant="subtitle1" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <BusinessIcon fontSize="small" />
                    {currentPosition.title} at {currentPosition.organisation?.name || currentPosition.organisation_other}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box>
              {contact.linked_user_id ? (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<ChatIcon />}
                  onClick={handleStartChat}
                  sx={{ mr: 2 }}
                >
                  Chat
                </Button>
              ) : null}
              <IconButton onClick={() => setIsEditDialogOpen(true)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={handleDelete} color="error">
                <DeleteIcon />
              </IconButton>
            </Box>
          </Grid>

          {/* Status and Tags */}
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', alignItems: 'center' }}>
              <Chip
                label={contact.status.replace(/_/g, ' ')}
                color="primary"
              />
              {contact.priority_level && (
                <Chip
                  label={contact.priority_level}
                  color={
                    contact.priority_level === 'HIGH' || contact.priority_level === 'VIP'
                      ? 'error'
                      : contact.priority_level === 'MEDIUM'
                      ? 'warning'
                      : 'success'
                  }
                />
              )}
              {contact.contact_tags?.map(({ tag }) => (
                <Chip
                  key={tag.id}
                  label={tag.name}
                  style={{
                    backgroundColor: tag.color || undefined,
                    color: tag.color ? 'white' : undefined,
                  }}
                />
              ))}
              <Button
                size="small"
                startIcon={<AddIcon />}
                onClick={() => setIsTagManagerOpen(true)}
              >
                Manage Tags
              </Button>
            </Box>
          </Grid>

          {/* Contact Info */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>Contact Information</Typography>
            {(contact.email_work || contact.email_personal) && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                <EmailIcon color="action" />
                <Typography>
                  {contact.email_work && (
                    <div>Work: {contact.email_work}</div>
                  )}
                  {contact.email_personal && (
                    <div>Personal: {contact.email_personal}</div>
                  )}
                </Typography>
              </Box>
            )}
            {(contact.phone_mobile || contact.phone_work || contact.phone_home) && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                <PhoneIcon color="action" />
                <Typography>
                  {contact.phone_mobile && (
                    <div>Mobile: {contact.phone_mobile}</div>
                  )}
                  {contact.phone_work && (
                    <div>Work: {contact.phone_work}</div>
                  )}
                  {contact.phone_home && (
                    <div>Home: {contact.phone_home}</div>
                  )}
                </Typography>
              </Box>
            )}
          </Grid>

          {/* Location Info */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>Location</Typography>
            {((contact.work_street || contact.work_city || contact.work_state) || 
              (contact.home_street || contact.home_city || contact.home_state)) && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                {contact.work_city || contact.work_country ? 
                  <BusinessIcon color="action" /> : 
                  <HomeIcon color="action" />}
                <Typography>
                  {[
                    // Try work address first
                    contact.work_street || contact.home_street,
                    (contact.work_city?.name || contact.home_city?.name),
                    contact.work_state || contact.home_state,
                    (contact.work_country?.name || contact.home_country?.name)
                  ].filter(Boolean).join(', ')}
                </Typography>
              </Box>
            )}

            {/* Show additional address if both work and home are available */}
            {(contact.work_city || contact.work_country) && 
             (contact.home_street || contact.home_city || contact.home_state) && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                <HomeIcon color="action" />
                <Typography>
                  {[
                    contact.home_street,
                    contact.home_city?.name,
                    contact.home_state,
                    contact.home_country?.name
                  ].filter(Boolean).join(', ')}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>

      {/* Tabs */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Interactions" />
          <Tab label="Notes" />
          <Tab label="Details" />
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <Box sx={{ mb: 2 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setIsInteractionDialogOpen(true)}
          >
            Add Interaction
          </Button>
        </Box>
        <InteractionList
          contactId={contact.id}
          onInteractionAdded={loadContact}
        />
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Typography variant="body1" whiteSpace="pre-wrap">
          {contact.notes || 'No notes available.'}
        </Typography>
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>Social Media Profiles</Typography>
            {/* Add social media links */}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>Additional Information</Typography>
            {/* Add other details */}
          </Grid>
        </Grid>
      </TabPanel>

      {/* Dialogs */}
      <Dialog
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <Typography variant="h6" sx={{ p: 2 }}>Edit Contact</Typography>
        <Divider />
        <Box sx={{ p: 2 }}>
          <ContactForm
            initialData={contact}
            onSubmit={handleEdit}
            onCancel={() => setIsEditDialogOpen(false)}
          />
        </Box>
      </Dialog>

      <Dialog
        open={isInteractionDialogOpen}
        onClose={() => setIsInteractionDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <Typography variant="h6" sx={{ p: 2 }}>Add Interaction</Typography>
        <Divider />
        <Box sx={{ p: 2 }}>
          <InteractionForm
            contactId={contact.id}
            onSubmit={() => {
              setIsInteractionDialogOpen(false);
              loadContact();
            }}
          />
        </Box>
      </Dialog>

      <Dialog
        open={isTagManagerOpen}
        onClose={() => setIsTagManagerOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <Typography variant="h6" sx={{ p: 2 }}>Manage Tags</Typography>
        <Divider />
        <Box sx={{ p: 2 }}>
          <TagManager
            contactId={contact.id}
            currentTags={contact.contact_tags?.map(ct => ct.tag) || []}
            onUpdate={loadContact}
          />
        </Box>
      </Dialog>
    </Container>
  );
};

export default ContactDetailPage;
