// src/api/apiClient.ts

import axios from 'axios';

// Create a base axios instance
export const apiClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL || '/api',
  withCredentials: true,  // Enable cookies for session-based auth
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor for logging
apiClient.interceptors.request.use(
  (config) => {
    console.log(`[ChatAPI] Request:`, {
      method: config.method?.toUpperCase(),
      url: `${config.baseURL}${config.url}`,
    });
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor for logging
apiClient.interceptors.response.use(
  (response) => {
    console.log(`[ChatAPI] Response:`, {
      url: response.config.url,
      status: response.status,
    });
    return response;
  },
  (error) => {
    console.error(`[ChatAPI] Error:`, {
      url: error.config?.url,
      status: error.response?.status,
      data: error.response?.data,
    });
    return Promise.reject(error);
  }
);

export default apiClient;