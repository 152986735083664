import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Paper, Grid, Button, Chip, Link, IconButton, Autocomplete, TextField, FormControl, InputLabel, Select, MenuItem, Avatar, CircularProgress, Container, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { SkillAutocomplete } from '../components/SkillAutocomplete';
import LoadingSpinner from '../components/common/LoadingSpinner';
import { api } from '../services/api';
import { User, UserSkill, Post } from '../types';
import DeleteIcon from '@mui/icons-material/Delete';
import BusinessIcon from '@mui/icons-material/Business';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PublicIcon from '@mui/icons-material/Public';
import WorkIcon from '@mui/icons-material/Work';
import { PostCard } from '../components/PostCard';
import Pagination from '@mui/material/Pagination';
import { PostDialog } from '../components/PostDialog';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import EditIcon from '@mui/icons-material/Edit';
import { getImageUrl } from '../utils/imageUtils';
import QrCodeGenerator from '../components/profile/QrCodeGenerator';

interface Role {
  roleId: string;
  role: {
    name: string;
  };
}

const ProfilePage = () => {
  const { userId } = useParams();
  const { user: currentUser, isLoading: authLoading } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const qrCodeSize = isMobile ? 100 : 150; // Match QR code size
  
  const [selectedSkill, setSelectedSkill] = useState<null | { id: string; name: string }>(null);
  const [selectedProficiency, setSelectedProficiency] = useState<'BASIC' | 'ADVANCED' | 'EXPERT'>('BASIC');
  const [skills, setSkills] = useState<Array<{ id: string; name: string }>>([]);
  const [page, setPage] = useState(1);
  const [createPostDialogOpen, setCreatePostDialogOpen] = useState(false);

  // Add a state to force re-render of the profile image
  const [imageKey, setImageKey] = useState<number>(Date.now());

  // Fetch profile data using react-query
  const {
    data: profileUser,
    isLoading
  } = useQuery({
    queryKey: ['profile', userId || currentUser?.id],
    queryFn: async () => {
      const targetId = userId || currentUser?.id;
      if (!targetId) return null;
      
      const response = await api.get(`/users/${targetId}`);
      return response.data;
    },
    enabled: !!userId || !!currentUser?.id
  });

  // Fetch skills using react-query
  const {
    data: userSkillsData,
  } = useQuery({
    queryKey: ['userSkills', userId || currentUser?.id],
    queryFn: async () => {
      const targetId = userId || currentUser?.id;
      if (!targetId) return [];
      
      const response = await api.get(`/users/${targetId}/skills`);
      return response.data;
    },
    enabled: !!userId || !!currentUser?.id
  });

  const [userSkills, setUserSkills] = useState<UserSkill[]>([]);

  useEffect(() => {
    if (userSkillsData) {
      setUserSkills(userSkillsData);
    }
  }, [userSkillsData]);

  const { 
    data: postsData,
    isLoading: loadingPosts
  } = useQuery({
    queryKey: ['userPosts', userId, page],
    queryFn: async () => {
      const response = await api.get(`/users/${userId}/posts?page=${page}`);
      return response.data;
    }
  });

  const posts = postsData?.posts || [];
  const totalPages = postsData?.pagination?.pages || 1;

  const isOwnProfile = !userId || (currentUser && userId === currentUser.id);

  // Redirect to /profile/{userId} if we're on /profile and have currentUser
  useEffect(() => {
    if (!userId && currentUser && !authLoading) {
      navigate(`/profile/${currentUser.id}`);
    }
  }, [userId, currentUser, authLoading, navigate]);

  // Force refresh of the profile image when the component mounts or userId changes
  useEffect(() => {
    // Force a re-render of the profile image
    setImageKey(Date.now());
    
    // Invalidate the profile query to ensure fresh data
    if (userId) {
      queryClient.invalidateQueries({ queryKey: ['profile', userId] });
    }
  }, [userId, queryClient]);

  const hasManagementRoles = (roles?: Role[]) => {
    const managementRoles = ['Superuser', 'Admin', 'Content Manager'];
    return roles?.some(role => managementRoles.includes(role.role.name)) ?? false;
  };

  const canManagePosts = currentUser?.roles?.some(role => 
    ['Superuser', 'Admin', 'Content Manager'].includes(role.role.name)
  );

  const handleAddSkill = async (skillId: string, proficiency: 'BASIC' | 'ADVANCED' | 'EXPERT') => {
    try {
      await api.post('/user-skills/me/skills', { skillId, proficiency });
      const response = await api.get('/user-skills/me/skills');
      setUserSkills(response.data);
    } catch (error) {
      console.error('Failed to add skill:', error);
    }
  };

  const handleRemoveSkill = async (skillId: string) => {
    try {
      await api.delete(`/user-skills/me/skills/${skillId}`);
      const response = await api.get('/user-skills/me/skills');
      setUserSkills(response.data);
    } catch (error) {
      console.error('Failed to remove skill:', error);
    }
  };

  const handleSkillProficiencyChange = async (skillId: string, proficiency: 'BASIC' | 'ADVANCED' | 'EXPERT') => {
    try {
      await api.patch(`/user-skills/me/skills/${skillId}`, { proficiency });
      const response = await api.get('/user-skills/me/skills');
      setUserSkills(response.data);
    } catch (error) {
      console.error('Failed to update skill proficiency:', error);
    }
  };

  const handleSkillSearch = async (query: string) => {
    try {
      const response = await api.get(`/skills/search?q=${encodeURIComponent(query)}`);
      const simplifiedSkills = response.data.map((skill: any) => ({
        id: skill.id,
        name: skill.name
      }));
      setSkills(simplifiedSkills);
    } catch (error) {
      console.error('Failed to search skills:', error);
      setSkills([]);
    }
  };

  const handlePostUpdate = (updatedPost?: Post) => {
    if (updatedPost) {
      // Update only the specific post in the posts array
      setPosts(currentPosts => 
        currentPosts.map(post => 
          post.id === updatedPost.id ? updatedPost : post
        )
      );
    } else {
      // Full refresh only if no specific post was updated
      fetchUserPosts(page);
    }
  };

  if (authLoading || isLoading) {
    return <LoadingSpinner />;
  }

  if (!profileUser) {
    return (
      <Typography variant="h6" sx={{ textAlign: 'center', mt: 4 }}>
        User not found
      </Typography>
    );
  }

  const getSearchLink = (type: string, value: string) => {
    const searchParams = new URLSearchParams();
    searchParams.set(type, value);
    return `/search?${searchParams.toString()}`;
  };

  return (
    <Box sx={{ py: 4 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={3}>
          {/* Images row - centered with both profile picture and QR code side by side */}
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', gap: 3 }}>
            {/* Profile Picture */}
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  position: 'relative',
                  background: theme.palette.background.paper,
                  width: qrCodeSize + 40, // Add padding for the Paper
                  height: qrCodeSize + 80 // Add extra space for text and buttons
                }}
              >
                {/* Edit button at top, matching help icon position */}
                <Box 
                  sx={{ 
                    display: 'flex', 
                    justifyContent: 'flex-end', 
                    width: '100%',
                    mb: 1
                  }}
                >
                  {(isOwnProfile || 
                    (hasManagementRoles(currentUser?.roles) && profileUser.is_managed)
                  ) && (
                    <IconButton
                      onClick={() => navigate(`/profile/${profileUser.id}/edit`)}
                      size="small"
                      sx={{
                        bgcolor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.1)',
                        '&:hover': {
                          bgcolor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.2)',
                        }
                      }}
                    >
                      <EditIcon 
                        fontSize="small" 
                        sx={{ 
                          color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
                          fontWeight: 'bold'
                        }}
                      />
                    </IconButton>
                  )}
                </Box>
                
                <Box sx={{ 
                  height: qrCodeSize, 
                  width: qrCodeSize, 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  borderRadius: 1
                }}>
                  <Avatar
                    src={getImageUrl(profileUser?.profile_image_url)}
                    alt={`${profileUser?.first_name} ${profileUser?.last_name}`}
                    sx={{ 
                      width: qrCodeSize,
                      height: qrCodeSize,
                      bgcolor: 'primary.main'
                    }}
                    key={imageKey}
                  >
                    {profileUser?.first_name?.[0]}{profileUser?.last_name?.[0]}
                  </Avatar>
                </Box>
                
              </Paper>
            </Box>
            
            {/* QR Code */}
            {isOwnProfile && (
              <QrCodeGenerator 
                isProfileOwner={isOwnProfile} 
                isManagedUser={profileUser?.is_managed || false}
                size={qrCodeSize}
              />
            )}
          </Grid>
          
          {/* Name and details row - below the images */}
          <Grid item xs={12}>
            <Box sx={{
              textAlign: 'center',
              mt: 2
            }}>
              {/* User Name */}
              <Typography variant="h4" gutterBottom>
                {profileUser?.first_name} {profileUser?.last_name}
              </Typography>

              {/* Position and Company */}
              <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                mt: 1
              }}>
                {(profileUser?.position || profileUser?.company) && (
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}>
                    <WorkIcon />
                    {profileUser?.position && (
                      <Link
                        component={RouterLink}
                        to={getSearchLink('position', profileUser.position)}
                        color="inherit"
                        sx={{ '&:hover': { textDecoration: 'underline' } }}
                      >
                        {profileUser.position}
                      </Link>
                    )}
                    {profileUser?.position && profileUser?.company && (
                      <Typography>at</Typography>
                    )}
                    {profileUser?.company && (
                      <Link
                        component={RouterLink}
                        to={getSearchLink('company', profileUser.company.name)}
                        color="inherit"
                        sx={{ '&:hover': { textDecoration: 'underline' } }}
                      >
                        {profileUser.company.name}
                      </Link>
                    )}
                  </Box>
                )}
              </Box>

              {/* Location */}
              {(profileUser?.city || profileUser?.country) && (
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt: 1,
                  gap: 1
                }}>
                  <LocationCityIcon />
                  {profileUser?.city && (
                    <Link
                      component={RouterLink}
                      to={getSearchLink('city', profileUser.city.name)}
                      color="inherit"
                      sx={{ '&:hover': { textDecoration: 'underline' } }}
                    >
                      {profileUser.city.name}
                    </Link>
                  )}
                  {profileUser?.city && profileUser?.country && (
                    <PublicIcon />
                  )}
                  {profileUser?.country && (
                    <Link
                      component={RouterLink}
                      to={getSearchLink('country', profileUser.country.name)}
                      color="inherit"
                      sx={{ '&:hover': { textDecoration: 'underline' } }}
                    >
                      {profileUser.country.name}
                    </Link>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* Description Section */}
      {(profileUser.long_description || profileUser.short_description) && (
        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography 
            variant="body1" 
            color="text.secondary"
            sx={{ 
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word'
            }}
          >
            {profileUser.long_description || profileUser.short_description}
          </Typography>
        </Paper>
      )}

      {(isOwnProfile || (!isOwnProfile && userSkills.length > 0)) && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" gutterBottom>
            Skills
          </Typography>

          {/* Display existing skills in 3 columns */}
          {userSkills.length > 0 && (
            <Box sx={{ mb: isOwnProfile ? 4 : 0 }}>
              <Grid container spacing={3}>
                {['BASIC', 'ADVANCED', 'EXPERT'].map(level => {
                  const levelSkills = userSkills
                    .filter(us => us.proficiency === level)
                    .sort((a, b) => a.skill.name.localeCompare(b.skill.name));

                  return (
                    <Grid item xs={12} md={4} key={level}>
                      <Typography 
                        variant="subtitle1" 
                        color="primary" 
                        sx={{ 
                          mb: 1,
                          pb: 0.5,
                          borderBottom: '1px solid',
                          borderColor: 'divider'
                        }}
                      >
                        {level.charAt(0) + level.slice(1).toLowerCase()} Level 
                        ({levelSkills.length})
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        {levelSkills.map((userSkill) => (
                          <Box key={userSkill.skillId} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Link
                              component={RouterLink}
                              to={`/skill/${userSkill.skillId}`}
                              sx={{ 
                                color: 'text.primary',
                                textDecoration: 'none',
                                '&:hover': { 
                                  textDecoration: 'underline',
                                  color: 'primary.main'
                                },
                                flexGrow: 1
                              }}
                            >
                              {userSkill.skill.name}
                            </Link>
                            {isOwnProfile && (
                              <IconButton
                                size="small"
                                onClick={() => handleRemoveSkill(userSkill.skillId)}
                                sx={{ 
                                  ml: 1,
                                  color: 'error.main',
                                  '&:hover': {
                                    color: 'error.dark'
                                  }
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            )}
                          </Box>
                        ))}
                        {levelSkills.length === 0 && (
                          <Typography color="text.secondary" variant="body2">
                            No {level.toLowerCase()} level skills
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          )}

          {/* Add skill section for own profile */}
          {isOwnProfile && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Add a new skill
              </Typography>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                <Autocomplete
                  sx={{ flexGrow: 1 }}
                  options={skills.filter(skill => 
                    !userSkills.some(us => us.skillId === skill.id)
                  )}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(_, newValue) => setSelectedSkill(newValue)}
                  onInputChange={(_, newInputValue) => {
                    if (newInputValue.length >= 2) {
                      handleSkillSearch(newInputValue);
                    }
                  }}
                  filterOptions={(x) => x}
                  renderInput={(params) => (
                    <TextField {...params} label="Search for a skill" />
                  )}
                />
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel>Proficiency</InputLabel>
                  <Select
                    value={selectedProficiency}
                    onChange={(e) => setSelectedProficiency(e.target.value as 'BASIC' | 'ADVANCED' | 'EXPERT')}
                    label="Proficiency"
                  >
                    <MenuItem value="BASIC">Basic</MenuItem>
                    <MenuItem value="ADVANCED">Advanced</MenuItem>
                    <MenuItem value="EXPERT">Expert</MenuItem>
                  </Select>
                </FormControl>
                {selectedSkill && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleAddSkill(selectedSkill.id, selectedProficiency);
                      setSelectedSkill(null);
                      setSelectedProficiency('BASIC');
                    }}
                  >
                    Add
                  </Button>
                )}
              </Box>
            </Box>
          )}
        </Box>
      )}

      {/* Posts Section */}
      <Paper sx={{ p: 3, mt: 3 }}>
        <Typography variant="h6" gutterBottom>
          Posts
          {isOwnProfile ? (
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
              onClick={() => setCreatePostDialogOpen(true)}
            >
              Create Post
            </Button>
          ) : (profileUser?.is_managed && canManagePosts) && (
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
              onClick={() => setCreatePostDialogOpen(true)}
            >
              Create Post for User
            </Button>
          )}
        </Typography>
        
        {loadingPosts ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : posts.length > 0 ? (
          <>
            {posts.map((post) => (
              <PostCard 
                key={post.id} 
                post={post}
                onPostUpdated={handlePostUpdate}
              />
            ))}
            {totalPages > 1 && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Pagination 
                  count={totalPages} 
                  page={page} 
                  onChange={(_, value) => setPage(value)}
                  color="primary"
                />
              </Box>
            )}
          </>
        ) : (
          <Typography color="text.secondary" sx={{ textAlign: 'center', py: 3 }}>
            No posts yet
          </Typography>
        )}
      </Paper>

      <PostDialog
        post={null}
        open={createPostDialogOpen}
        onClose={() => {
          setCreatePostDialogOpen(false);
          queryClient.invalidateQueries({ queryKey: ['userPosts', userId] });
        }}
        authorId={userId}
      />

      {/* Privacy Settings - Only visible to profile owner */}
      {isOwnProfile && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            Privacy Settings
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography>
              Do not track: {profileUser.do_not_track ? 'Enabled' : 'Disabled'}
            </Typography>
            <Tooltip title="Edit privacy settings">
              <IconButton
                onClick={() => navigate(`/profile/${currentUser.id}/edit`)}
                size="small"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ProfilePage; 