import { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import { api } from '../../services/api';

interface WaitinglistEntry {
  id: string;
  name: string;
  email: string;
  reason?: string;
  notes?: string;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
}

const statusOptions = [
  { value: 'PENDING', label: 'Pending' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'REJECTED', label: 'Rejected' },
];

const sanitizeText = (text: string = ''): string => {
  return DOMPurify.sanitize(text.trim());
};

export const WaitinglistPage = () => {
  const [entries, setEntries] = useState<WaitinglistEntry[]>([]);
  const [selectedEntry, setSelectedEntry] = useState<WaitinglistEntry | null>(null);
  const [open, setOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [errors, setErrors] = useState<{
    name?: string;
    email?: string;
    submit?: string;
  }>({});

  const fetchEntries = async () => {
    try {
      console.log('Fetching waiting list entries...');
      const response = await api.get('/waitinglist');
      console.log('Response:', response.data);
      setEntries(response.data);
    } catch (error) {
      console.error('Failed to fetch waitinglist entries:', {
        error,
        response: error.response?.data,
        status: error.response?.status
      });
    }
  };

  useEffect(() => {
    fetchEntries();
  }, []);

  const handleEdit = (entry: WaitinglistEntry) => {
    setIsNew(false);
    setSelectedEntry(entry);
    setOpen(true);
  };

  const handleCreate = () => {
    setIsNew(true);
    setSelectedEntry({
      id: '',
      name: '',
      email: '',
      reason: '',
      notes: '',
      status: 'PENDING'
    });
    setOpen(true);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this entry?')) {
      try {
        await api.delete(`/waitinglist/${id}`);
        fetchEntries();
      } catch (error) {
        console.error('Failed to delete waitinglist entry:', error);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedEntry(null);
    setIsNew(false);
  };

  const validateForm = (): boolean => {
    const newErrors: { name?: string; email?: string } = {};
    
    if (!selectedEntry?.name?.trim()) {
      newErrors.name = 'Name is required';
    }
    
    if (!selectedEntry?.email?.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(selectedEntry.email)) {
      newErrors.email = 'Invalid email format';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!selectedEntry) return;
    setErrors({});

    if (!validateForm()) {
      return;
    }

    try {
      const updateData: Partial<WaitinglistEntry> = {};

      if (selectedEntry.name !== undefined && selectedEntry.name !== null) {
        updateData.name = sanitizeText(selectedEntry.name);
      }
      if (selectedEntry.email !== undefined && selectedEntry.email !== null) {
        updateData.email = sanitizeText(selectedEntry.email);
      }
      if (selectedEntry.reason !== undefined && selectedEntry.reason !== null) {
        updateData.reason = sanitizeText(selectedEntry.reason);
      }
      if (selectedEntry.notes !== undefined && selectedEntry.notes !== null) {
        updateData.notes = sanitizeText(selectedEntry.notes);
      }
      if (selectedEntry.status) {
        updateData.status = selectedEntry.status;
      }

      if (isNew) {
        await api.post('/waitinglist', updateData);
      } else {
        await api.patch(`/waitinglist/${selectedEntry.id}`, updateData);
      }
      
      fetchEntries();
      handleClose();
    } catch (error) {
      console.error(`Failed to ${isNew ? 'create' : 'update'} waitinglist entry:`, error);
      if (error.response?.data?.error) {
        setErrors({ submit: error.response.data.error });
      } else {
        setErrors({ submit: 'An unexpected error occurred' });
      }
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h5">Waiting List</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          Create New
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map((entry) => (
              <TableRow key={entry.id}>
                <TableCell>{entry.name}</TableCell>
                <TableCell>{entry.email}</TableCell>
                <TableCell>{entry.reason}</TableCell>
                <TableCell>{entry.notes}</TableCell>
                <TableCell>{entry.status}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Tooltip title="Edit">
                      <IconButton onClick={() => handleEdit(entry)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => handleDelete(entry.id)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          {isNew ? 'Create New Waiting List Entry' : 'Edit Waiting List Entry'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
            {errors.submit && (
              <Typography color="error" variant="body2">
                {errors.submit}
              </Typography>
            )}
            <TextField
              label="Name"
              value={selectedEntry?.name || ''}
              onChange={(e) => setSelectedEntry(prev => 
                prev ? { ...prev, name: e.target.value } : null
              )}
              error={!!errors.name}
              helperText={errors.name}
              required
            />
            <TextField
              label="Email"
              value={selectedEntry?.email || ''}
              onChange={(e) => setSelectedEntry(prev => 
                prev ? { ...prev, email: e.target.value } : null
              )}
              error={!!errors.email}
              helperText={errors.email}
              required
            />
            <TextField
              label="Reason"
              multiline
              rows={3}
              value={selectedEntry?.reason || ''}
              onChange={(e) => setSelectedEntry(prev => 
                prev ? { ...prev, reason: e.target.value } : null
              )}
            />
            <TextField
              label="Notes"
              multiline
              rows={3}
              value={selectedEntry?.notes || ''}
              onChange={(e) => setSelectedEntry(prev => 
                prev ? { ...prev, notes: e.target.value } : null
              )}
            />
            <TextField
              select
              label="Status"
              value={selectedEntry?.status || 'PENDING'}
              onChange={(e) => setSelectedEntry(prev => 
                prev ? { ...prev, status: e.target.value as WaitinglistEntry['status'] } : null
              )}
            >
              {statusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} variant="contained">Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}; 