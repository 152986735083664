import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Box, CircularProgress } from '@mui/material';

export const ProtectedRoute = () => {
  const auth = useAuth();
  
  if (!auth) {
    console.error('[ProtectedRoute] Auth context is null');
    return <Navigate to="/login" replace />;
  }

  // Show loading state while checking auth
  if (auth.loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  // Only redirect if we're sure there's no authenticated user
  if (!auth.isAuthenticated || !auth.user) {
    console.log('[ProtectedRoute] Redirecting to login, auth state:', { 
      isAuthenticated: auth.isAuthenticated, 
      user: !!auth.user 
    });
    return <Navigate to="/login" replace />;
  }

  // Check if email is verified
  if (!auth.user.is_email_verified) {
    console.log('[ProtectedRoute] Redirecting to verification pending');
    return <Navigate to="/verification-pending" replace />;
  }

  return <Outlet />;
};