import { createTheme } from '@mui/material/styles';

const getTheme = (mode: 'light' | 'dark') => createTheme({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // Light mode colors
          primary: {
            main: '#1A1A1A',
          },
          background: {
            default: '#FFFFFF',
            paper: 'rgba(255, 255, 255, 0.95)',
          },
        }
      : {
          // Dark mode colors
          primary: {
            main: '#FFFFFF',
          },
          background: {
            default: '#1A1A1A',
            paper: 'rgba(26, 26, 26, 0.95)',
          },
        }),
  },
  typography: {
    fontFamily: '"Space Grotesk", "Archivo", sans-serif',
  },
});

export default getTheme; 