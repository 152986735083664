import { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Paper,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../services/api';
import { OneOnOneMeetupDialog } from '../components/OneOnOneMeetupDialog';
import { format } from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../contexts/AuthContext';

interface OneOnOneMeetup {
  id: string;
  name: string;
  short_description?: string;
  event_start_date: string;
  organiser_id: string;
}

export const OneOnOneMeetupsPage = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const [search, setSearch] = useState('');
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editMeetup, setEditMeetup] = useState<OneOnOneMeetup | null>(null);

  const { data, isLoading } = useQuery({
    queryKey: ['oneononemeetups', search],
    queryFn: async () => {
      const response = await api.get(`/oneononemeetups?page=1&search=${encodeURIComponent(search)}`);
      return response.data;
    }
  });

  const deleteMutation = useMutation({
    mutationFn: async (id: string) => {
      await api.delete(`/oneononemeetups/${id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['oneononemeetups'] });
    },
  });

  const meetups = data?.meetups || [];

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4">1:1 Meetups</Typography>
        <Button
          variant="contained"
          onClick={() => setCreateDialogOpen(true)}
        >
          Create
        </Button>
      </Box>

      <TextField
        fullWidth
        placeholder="Search meetups..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        sx={{ mb: 3 }}
      />

      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
          <CircularProgress />
        </Box>
      ) : meetups.length === 0 ? (
        <Paper sx={{ p: 4, textAlign: 'center' }}>
          <Typography color="textSecondary" sx={{ mb: 2 }}>
            {search ? 'No meetups found matching your search' : 'No upcoming meetups found'}
          </Typography>
          <Button
            variant="outlined"
            onClick={() => setCreateDialogOpen(true)}
          >
            {search ? 'Create a new meetup' : 'Create your first meetup'}
          </Button>
        </Paper>
      ) : (
        <Box sx={{ display: 'grid', gap: 2 }}>
          {meetups.map((meetup: OneOnOneMeetup) => (
            <Paper key={meetup.id} sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box>
                  <Typography variant="h6">{meetup.name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Start: {format(new Date(meetup.event_start_date), 'PPp')}
                  </Typography>
                  {meetup.short_description && (
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {meetup.short_description}
                    </Typography>
                  )}
                </Box>
                {user?.id === meetup.organiser_id && (
                  <Box>
                    <Tooltip title="Edit">
                      <IconButton
                        onClick={() => setEditMeetup(meetup)}
                        sx={{ '&:hover': { color: 'primary.main' } }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => {
                          if (window.confirm('Are you sure you want to delete this meetup?')) {
                            deleteMutation.mutate(meetup.id);
                          }
                        }}
                        sx={{ '&:hover': { color: 'error.main' } }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </Box>
            </Paper>
          ))}
        </Box>
      )}

      <OneOnOneMeetupDialog
        open={createDialogOpen || !!editMeetup}
        onClose={() => {
          setCreateDialogOpen(false);
          setEditMeetup(null);
        }}
        meetup={editMeetup}
      />
    </Box>
  );
}; 