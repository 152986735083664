import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Box, Typography, CircularProgress, Container, Grid, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAuth } from '../contexts/AuthContext';
import { useThemeContext } from '../contexts/ThemeContext';
import { api } from '../services/api';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { postService } from '../services/postService';

// ============================
//   STYLED COMPONENTS
// ============================

// Hero container
const HeroSection = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  background: theme.palette.mode === 'dark' ? '#1A1A1A' : '#FFFFFF',
  overflow: 'hidden',
  marginTop: '80px', // Space for TopBar
}));

// Hero image
const HeroImage = styled('img')({
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
  filter: 'brightness(1.2)',
  display: 'block'
});

// Connect section
const ConnectSection = styled(Box)(({ theme }) => ({
  padding: '60px 0',
  background: theme.palette.mode === 'dark' ? '#121212' : '#F5F5F5',
  textAlign: 'center'
}));

// Feature cards section
const FeaturesSection = styled(Box)(({ theme }) => ({
  padding: '60px 0',
  background: theme.palette.mode === 'dark' ? '#1A1A1A' : '#FFFFFF',
}));

// Individual feature card
const FeatureCard = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '12px',
  overflow: 'hidden',
  position: 'relative',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  boxShadow: theme.palette.mode === 'dark' 
    ? '0 8px 20px rgba(0, 0, 0, 0.5)' 
    : '0 8px 20px rgba(0, 0, 0, 0.1)',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.palette.mode === 'dark' 
      ? '0 12px 30px rgba(0, 0, 0, 0.7)' 
      : '0 12px 30px rgba(0, 0, 0, 0.15)',
    '& img': {
      transform: 'scale(1.05)',
    }
  }
}));

const FeatureImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  display: 'block',
  transition: 'transform 0.3s ease'
});

const FeatureText = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(2),
  background: theme.palette.mode === 'dark' 
    ? 'linear-gradient(to bottom, transparent, rgba(26, 26, 26, 0.95))' 
    : 'linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.95))',
  backdropFilter: 'blur(8px)',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '40%', // Default height for xs
  [theme.breakpoints.up('sm')]: {
    height: '35%',
  },
  [theme.breakpoints.up('md')]: {
    height: '30%',
  },
  transform: 'none',
  '& h4': {
    textShadow: theme.palette.mode === 'dark'
      ? '0 2px 4px rgba(0,0,0,0.5)'
      : '0 2px 4px rgba(255,255,255,0.5)',
    marginBottom: theme.spacing(0.5)
  }
}));

// Feature type definition
interface Feature {
  title: string;
  description: string;
  imageBase: string;
  imageCount: number; // Maximum number of available images
}

// Define the maximum number of images for each feature
// This ensures we only try to load images that actually exist
const MAX_IMAGES = {
  search: 3,      
  networking: 6,  
  announcements: 3, 
  posts: 4,       
  lunch: 8,       
  contacts: 4     
};

// Feature route mapping
const FEATURE_ROUTES: Record<string, string> = {
  'SEARCH': '/search',
  'SPEED NETWORKING': '/oneononemeetups',
  'ANNOUNCEMENTS': '/announcements',
  'POSTS': '/posts',
  'CONNECTED LUNCH': '/connected-lunch',
  'CONTACT MANAGEMENT': '/contacts'
};

// ============================
//      MAIN COMPONENT
// ============================
const HomePage = () => {
  const { user } = useAuth() || {};
  const { mode } = useThemeContext();
  const isMobile = useMediaQuery('(max-width:600px)');
  
  // State to store selected image indices and loading status for each feature
  const [featureImages, setFeatureImages] = useState<Record<string, number>>({});
  const [imagesLoaded, setImagesLoaded] = useState<Record<string, boolean>>({});
  
  // Memoize the query config to prevent unnecessary re-renders
  const queryConfig = useMemo(() => ({
    queryKey: ['posts'],
    enabled: !!user,
    queryFn: () => postService.getPosts(),
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 30, // 30 minutes
  }), [user]);

  // Query is configured but we're not using the results yet
  useQuery(queryConfig);

  // Ref for hero image to measure real height
  const heroRef = useRef<HTMLImageElement>(null);
  
  // Check if this is the first visit
  const isFirstVisit = useRef<boolean>(localStorage.getItem('hasVisitedBefore') !== 'true');

  // Features data - with image base paths and exact counts
  const features: Feature[] = [
    {
      title: 'SEARCH',
      description: 'Find Your Next Opportunity',
      imageBase: 'search',
      imageCount: MAX_IMAGES.search
    },
    {
      title: 'SPEED NETWORKING',
      description: 'Connect in Real-Time',
      imageBase: 'networking',
      imageCount: MAX_IMAGES.networking
    },
    {
      title: 'ANNOUNCEMENTS',
      description: 'Share your events and business opportunities',
      imageBase: 'announcements',
      imageCount: MAX_IMAGES.announcements
    },
    {
      title: 'POSTS',
      description: 'Share Your Journey',
      imageBase: 'posts',
      imageCount: MAX_IMAGES.posts
    },
    {
      title: 'CONNECTED LUNCH',
      description: 'Expand Your Network Over a Meal',
      imageBase: 'lunch',
      imageCount: MAX_IMAGES.lunch
    },
    {
      title: 'CONTACT MANAGEMENT',
      description: 'Organize Your Professional Relationships',
      imageBase: 'contacts',
      imageCount: MAX_IMAGES.contacts
    }
  ];

  // Function to get a random image number for a feature
  const getRandomImageNumber = (maxImages: number): number => {
    // If there's only one image, return 1
    if (maxImages <= 1) return 1;
    
    // Otherwise, return a random number between 1 and maxImages (inclusive)
    return Math.floor(Math.random() * maxImages) + 1;
  };

  // Function to get image path for a feature
  const getFeatureImagePath = (feature: Feature) => {
    const imageIndex = featureImages[feature.title] || 1;
    return `/assets/illustrations/feature-cards/feature-card_${feature.imageBase}_${imageIndex}.webp`;
  };

  // Function to preload images
  const preloadImage = useCallback((src: string, featureTitle: string) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImagesLoaded(prev => ({
        ...prev,
        [featureTitle]: true
      }));
    };
    img.onerror = () => {
      console.error(`Failed to load image: ${src}`);
      setImagesLoaded(prev => ({
        ...prev,
        [featureTitle]: false
      }));
    };
  }, []);

  // Initialize random images and start preloading on mount
  useEffect(() => {
    // Mark that the user has visited before
    if (isFirstVisit.current) {
      localStorage.setItem('hasVisitedBefore', 'true');
      
      // For first visit, use image 1 for all features
      const initialImages: Record<string, number> = {};
      features.forEach(feature => {
        initialImages[feature.title] = 1;
      });
      setFeatureImages(initialImages);
    } else {
      // For subsequent visits, select random images
      const randomImages: Record<string, number> = {};
      features.forEach(feature => {
        randomImages[feature.title] = getRandomImageNumber(feature.imageCount);
      });
      setFeatureImages(randomImages);
    }
    
    // Debug log
    console.log('HomePage mounted - ' + new Date().toISOString());
    return () => console.log('HomePage unmounted - ' + new Date().toISOString());
  }, []);

  // Preload images when featureImages state is set
  useEffect(() => {
    if (Object.keys(featureImages).length > 0) {
      features.forEach(feature => {
        const imagePath = getFeatureImagePath(feature);
        preloadImage(imagePath, feature.title);
      });
    }
  }, [featureImages, preloadImage, getFeatureImagePath]);

  // Get hero image based on theme
  const heroImage = mode === 'dark' 
    ? '/assets/brand/trueconn-hero_dark.png' 
    : '/assets/brand/trueconn-hero_light.png';

  return (
    <>
      {/* Hero Section */}
      <HeroSection>
        <HeroImage
          ref={heroRef}
          src={heroImage}
          alt="Professional Network"
        />
      </HeroSection>

      {/* Connect with Professionals Section */}
      <ConnectSection>
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography
              variant="h2"
              sx={{
                fontWeight: 600,
                lineHeight: 1.2,
                color: theme => theme.palette.mode === 'dark' ? '#FFFFFF' : '#1A1A1A',
                fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                marginBottom: '16px'
              }}
            >
              Connect with Professionals
            </Typography>
            
            <Typography
              sx={{
                fontWeight: 400,
                color: theme => theme.palette.mode === 'dark' 
                  ? 'rgba(255,255,255,0.7)' 
                  : 'rgba(0,0,0,0.7)',
                fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' },
                maxWidth: '800px',
                margin: '0 auto 8px'
              }}
            >
              A Community Network for connecting and sharing knowledge
            </Typography>
            
            <Typography
              sx={{
                fontWeight: 400,
                color: theme => theme.palette.mode === 'dark' 
                  ? 'rgba(255,255,255,0.7)' 
                  : 'rgba(0,0,0,0.7)',
                fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' },
                marginTop: '8px'
              }}
            >
              Made with ❤️ in Europe
            </Typography>
          </motion.div>
        </Container>
      </ConnectSection>

      {/* Features Section */}
      <FeaturesSection>
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
              textAlign: 'center',
              marginBottom: '40px',
              color: theme => theme.palette.mode === 'dark' ? '#FFFFFF' : '#1A1A1A',
            }}
          >
            Our Features
          </Typography>
          
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} key={feature.title}>
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ 
                    duration: 0.6, 
                    delay: index * 0.1,
                    type: 'spring',
                    stiffness: 50
                  }}
                  style={{ height: '100%', aspectRatio: '4/3' }}
                >
                  <Link 
                    to={FEATURE_ROUTES[feature.title]} 
                    style={{ textDecoration: 'none' }}
                  >
                    <FeatureCard>
                      {!imagesLoaded[feature.title] ? (
                        <Box 
                          display="flex" 
                          justifyContent="center" 
                          alignItems="center" 
                          height="300px"
                          sx={{ background: theme => theme.palette.background.paper }}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        <FeatureImage
                          src={getFeatureImagePath(feature)}
                          alt={feature.title}
                          loading="eager"
                          onError={(e) => {
                            const target = e.target as HTMLImageElement;
                            if (!target.src.includes('_1.webp')) {
                              console.warn(`Image not found: ${target.src}, falling back to default`);
                              target.src = `/assets/illustrations/feature-cards/feature-card_${feature.imageBase}_1.webp`;
                            }
                          }}
                        />
                      )}
                      <FeatureText>
                        <Typography
                          variant="h4"
                          sx={(theme) => ({ 
                            fontSize: {
                              xs: '1.1rem',
                              sm: '1.3rem',
                              md: '1.5rem',
                              lg: '1.6rem'
                            },
                            fontWeight: 600, 
                            color: theme.palette.mode === 'dark' ? '#fff' : '#1A1A1A',
                            marginBottom: theme.spacing(0.5),
                            lineHeight: 1.2
                          })}
                        >
                          {feature.title}
                        </Typography>
                        <Typography 
                          sx={(theme) => ({ 
                            fontSize: {
                              xs: '0.8rem',
                              sm: '0.9rem',
                              md: '0.95rem',
                              lg: '1rem'
                            },
                            color: theme.palette.mode === 'dark' 
                              ? 'rgba(255,255,255,0.9)' 
                              : 'rgba(0,0,0,0.9)',
                            textShadow: theme.palette.mode === 'dark'
                              ? '0 1px 2px rgba(0,0,0,0.3)'
                              : '0 1px 2px rgba(255,255,255,0.3)',
                            lineHeight: 1.3
                          })}
                        >
                          {feature.description}
                        </Typography>
                      </FeatureText>
                    </FeatureCard>
                  </Link>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </FeaturesSection>
    </>
  );
};

export default HomePage;
