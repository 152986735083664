import React from 'react';
import {
  Typography,
  Paper,
  Box,
  Chip,
  Grid,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Card,
  CardContent,
} from '@mui/material';
import { 
  CheckCircleOutline, 
  HighlightOff, 
  EmojiEvents, 
  TrendingUp, 
  Psychology,
  School,
  Timer
} from '@mui/icons-material';
import { Quiz, QuizSubmission, QuizAnswer, QuizQuestion } from '../../types/quiz';
import ScoreDisplay from './ScoreDisplay';

interface QuizResultsComponentProps {
  quiz: Quiz;
  submission: QuizSubmission;
  loading?: boolean;
}

const QuizResultsComponent: React.FC<QuizResultsComponentProps> = ({
  quiz,
  submission,
  loading = false,
}) => {
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="300px">
        <CircularProgress />
      </Box>
    );
  }

  if (!submission) {
    return (
      <Box mt={4}>
        <Typography variant="h6" align="center">
          No results available for this quiz.
        </Typography>
      </Box>
    );
  }

  // Calculate score percentage
  const scorePercentage = submission.percentage || 
    (submission.score && submission.answers.reduce((sum, a) => sum + (a.points_earned || 0), 0) > 0
      ? (submission.score / submission.answers.reduce((sum, a) => {
          const question = quiz.questions.find(q => q.id === a.question_id);
          return sum + (question?.points || 0);
        }, 0)) * 100
      : 0);
  
  // Determine if the user passed the quiz
  const passed = submission.passed !== undefined 
    ? submission.passed 
    : scorePercentage >= (quiz.passing_score || 0);

  // Generate feedback based on score
  const getFeedback = () => {
    if (scorePercentage >= 90) {
      return "Excellent! You have an outstanding grasp of this subject.";
    } else if (scorePercentage >= 80) {
      return "Great job! You have a strong understanding of this material.";
    } else if (scorePercentage >= 70) {
      return "Good work! You've demonstrated a solid knowledge base.";
    } else if (scorePercentage >= 60) {
      return "Not bad! You've got the basics down, but there's room for improvement.";
    } else {
      return "This topic seems challenging for you. We recommend reviewing the material again.";
    }
  };

  // Map answers to questions
  const getAnswersMap = () => {
    const map = new Map<string, QuizAnswer>();
    
    submission.answers.forEach(answer => {
      map.set(answer.question_id, answer);
    });
    
    return map;
  };
  
  const answersMap = getAnswersMap();

  // Get explanation for a question
  const getExplanation = (question: QuizQuestion): string | undefined => {
    // First check if the question has an explanation
    if (question.explanation) {
      return question.explanation;
    }
    
    // If not, check if the correct option has an explanation (legacy format)
    const options = typeof question.options === 'string' 
      ? JSON.parse(question.options) 
      : question.options;
    
    // For single choice, find the correct option
    if (question.is_correct !== undefined && typeof question.is_correct === 'number') {
      // Get explanation from the correct option
      return options[question.is_correct]?.explanation;
    }
    
    // Legacy format: find the option marked as correct
    const correctOption = options.find(o => o.is_correct);
    return correctOption?.explanation;
  };

  return (
    <Box mb={6}>
      <ScoreDisplay 
        score={scorePercentage} 
        passingScore={quiz.passing_score} 
        loading={loading}
      />

      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h5">Your Performance</Typography>
          <Chip 
            icon={passed ? <CheckCircleOutline /> : <HighlightOff />}
            label={passed ? "PASSED" : "FAILED"}
            color={passed ? "success" : "error"}
            variant="outlined"
          />
        </Box>

        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <CardContent>
                <Box display="flex" alignItems="center">
                  <EmojiEvents sx={{ color: 'primary.main', mr: 1 }} />
                  <Typography variant="h6">Score</Typography>
                </Box>
                <Typography variant="h4" align="center" mt={2}>
                  {submission.score || 0} / {submission.answers.reduce((sum, a) => {
                    const question = quiz.questions.find(q => q.id === a.question_id);
                    return sum + (question?.points || 0);
                  }, 0)}
                </Typography>
                <Typography variant="body2" color="text.secondary" align="center">
                  points earned
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <CardContent>
                <Box display="flex" alignItems="center">
                  <TrendingUp sx={{ color: 'primary.main', mr: 1 }} />
                  <Typography variant="h6">Percentage</Typography>
                </Box>
                <Typography variant="h4" align="center" mt={2}>
                  {Math.round(scorePercentage)}%
                </Typography>
                <Typography variant="body2" color="text.secondary" align="center">
                  of total points
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <CardContent>
                <Box display="flex" alignItems="center">
                  <School sx={{ color: 'primary.main', mr: 1 }} />
                  <Typography variant="h6">Required</Typography>
                </Box>
                <Typography variant="h4" align="center" mt={2}>
                  {quiz.passing_score}%
                </Typography>
                <Typography variant="body2" color="text.secondary" align="center">
                  passing score
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Box mb={3} p={2} bgcolor="background.default" borderRadius={1}>
          <Box display="flex" alignItems="center" mb={1}>
            <Psychology sx={{ color: 'primary.main', mr: 1 }} />
            <Typography variant="h6">Personalized Feedback</Typography>
          </Box>
          <Typography variant="body1">{getFeedback()}</Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h6" gutterBottom>Question Results</Typography>
        
        <List>
          {/* Only show questions that were actually answered */}
          {submission.answers.map((answer, index) => {
            const question = quiz.questions.find(q => q.id === answer.question_id);
            if (!question) return null;
            
            const isCorrect = answer.is_correct;
            const explanation = getExplanation(question);
            const timeExpired = answer.time_expired;
            
            return (
              <React.Fragment key={question.id}>
                <ListItem 
                  alignItems="flex-start"
                  sx={timeExpired ? { 
                    backgroundColor: 'rgba(211, 47, 47, 0.08)', 
                    borderRadius: 1,
                    mb: 1
                  } : {}}
                >
                  <ListItemIcon>
                    {timeExpired ? (
                      // Always show error icon for time-expired questions
                      <HighlightOff color="error" />
                    ) : isCorrect === true ? (
                      <CheckCircleOutline color="success" />
                    ) : isCorrect === false ? (
                      <HighlightOff color="error" />
                    ) : (
                      <Box ml={1}>{index + 1}.</Box>
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Box display="flex" alignItems="center">
                        <Typography 
                          variant="body1" 
                          component="span"
                          sx={timeExpired ? { fontWeight: 'bold' } : {}}
                        >
                          {question.question_text}
                        </Typography>
                        {timeExpired && (
                          <Chip 
                            icon={<Timer />} 
                            label="Time Expired" 
                            size="small" 
                            color="error" 
                            sx={{ ml: 1 }} 
                          />
                        )}
                      </Box>
                    }
                    secondary={
                      <>
                        {answer.text_answer && (
                          <Typography component="span" variant="body2" color="text.primary">
                            Your answer: {answer.text_answer}
                          </Typography>
                        )}
                        {answer.selected_option_ids && answer.selected_option_ids.length > 0 && (
                          <Typography component="span" variant="body2" color="text.primary">
                            Your choice: {
                              timeExpired 
                                ? "Time expired - no selection made" 
                                : answer.selected_option_ids.map(optionId => {
                                    // Don't show placeholder IDs used for validation
                                    if (optionId === 'placeholder-id' || optionId === 'timeout-placeholder') {
                                      return 'No selection (time expired)';
                                    }
                                    const option = question.options.find(opt => opt.id === optionId);
                                    return option?.option_text || 'Unknown option';
                                  }).join(', ')
                            }
                          </Typography>
                        )}
                        {answer.points_earned !== undefined && (
                          <Typography component="span" variant="body2" color="text.secondary" display="block">
                            Points: {answer.points_earned} / {question.points}
                          </Typography>
                        )}
                        {explanation && (
                          <Typography component="span" variant="body2" color="text.secondary" display="block">
                            Explanation: {explanation}
                          </Typography>
                        )}
                      </>
                    }
                  />
                </ListItem>
                {index < submission.answers.length - 1 && <Divider variant="inset" component="li" />}
              </React.Fragment>
            );
          })}
        </List>
      </Paper>
    </Box>
  );
};

export default QuizResultsComponent;