/**
 * ChatContext.tsx
 * 
 * A React Context that manages the global state and functionality for the chat system.
 * It handles real-time communication through WebSocket and provides chat functionality
 * to all chat-related components.
 * 
 * Key features:
 * - WebSocket connection management for real-time updates
 * - Conversation management (create, select, update)
 * - Message handling (send, receive, delete)
 * - Read receipts and unread counts
 * - Message reactions
 * - Real-time updates for all chat activities
 * 
 * The context maintains the state of:
 * - Active conversations
 * - Current messages
 * - Loading states
 * - Error states
 * 
 * It provides methods for all chat operations and automatically handles
 * WebSocket events to keep the UI in sync with the server state.
 */

// src/contexts/ChatContext.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode, useRef } from 'react';
import { chatApi, ChatConversation, ChatMessage } from '../api/chatApi';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

interface ChatContextType {
  conversations: ChatConversation[];
  activeConversation: ChatConversation | null;
  messages: ChatMessage[];
  loading: boolean;
  error: string | null;
  createConversation: (participantIds: string[], isGroup: boolean, metadata?: Record<string, any>) => Promise<ChatConversation>;
  selectConversation: (conversationId: string | null) => Promise<void>;
  sendMessage: (content: string, mediaUrls?: string[], mediaTypes?: string[]) => Promise<ChatMessage>;
  loadMoreMessages: (before?: Date) => Promise<void>;
  markAsRead: () => Promise<void>;
  updateMessage: (updatedMessage: ChatMessage) => void;
  deleteMessage: (messageId: string) => void;
  deleteGroupChat: (conversationId: string) => Promise<void>;
  leaveGroupChat: (conversationId: string) => Promise<void>;
  isGroupAdmin: (conversationId: string) => Promise<boolean>;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChatContext must be used within a ChatProvider');
  }
  return context;
};

interface ChatProviderProps {
  children: ReactNode;
}

export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [conversations, setConversations] = useState<ChatConversation[]>([]);
  const [activeConversation, setActiveConversation] = useState<ChatConversation | null>(null);
  const activeConversationRef = useRef<ChatConversation | null>(null);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [wsConnected, setWsConnected] = useState<boolean>(false);

  // Update ref when activeConversation changes
  useEffect(() => {
    activeConversationRef.current = activeConversation;
    console.log('Active conversation updated:', activeConversation?.id);
  }, [activeConversation]);

  // WebSocket connection setup
  useEffect(() => {
    if (!user?.id) return;

    // Create a WebSocket connection
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const host = window.location.host;
    
    // In production, WebSocket endpoint is at /api/ws
    // In development, it's at /ws
    const wsPath = import.meta.env.PROD ? '/api/ws' : '/ws';
    
    // Use explicit port in development to avoid proxy issues
    let wsUrl;
    if (import.meta.env.DEV) {
      // In development, connect directly to the backend
      wsUrl = `ws://localhost:3000${wsPath}`;
    } else {
      // In production, use the same host
      wsUrl = `${protocol}//${host}${wsPath}`;
    }
    
    console.log(`Connecting to WebSocket at: ${wsUrl}`);
    
    const socket = new WebSocket(wsUrl);
    
    // Add global debug object for WebSocket messages
    if (typeof window !== 'undefined') {
      // @ts-ignore
      window.wsDebug = window.wsDebug || {
        messages: [],
        activeConversation: null,
        socket: null
      };
      // @ts-ignore
      window.wsDebug.socket = socket;
    }
    
    socket.onopen = () => {
      console.log('WebSocket connection established');
      setWsConnected(true);
      
      // Authenticate with the WebSocket server
      socket.send(JSON.stringify({
        type: 'auth',
        userId: user.id
      }));
    };
    
    socket.onerror = (error) => {
      console.error('WebSocket connection error:', error);
      setWsConnected(false);
    };
    
    socket.onclose = (event) => {
      console.log(`WebSocket connection closed. Code: ${event.code}, Reason: ${event.reason || 'No reason provided'}`);
      setWsConnected(false);
    };
    
    socket.onmessage = (event) => {
      console.log('WebSocket message received:', event.data);
      try {
        const data = JSON.parse(event.data);
        console.log('Parsed WebSocket data:', data);
        
        // Store in debug object
        if (typeof window !== 'undefined') {
          // @ts-ignore
          window.wsDebug.messages.push(data);
          // @ts-ignore
          window.wsDebug.activeConversation = activeConversationRef.current?.id;
        }
        
        // Handle different message types
        switch (data.type) {
          case 'auth_success':
            console.log(`Authentication successful with client ID: ${data.clientId}`);
            break;
            
          case 'conversation_created':
            console.log('Processing conversation_created event');
            handleNewConversation(data);
            break;
            
          case 'message_added':
            console.log('Processing message_added event');
            console.log('Active conversation:', activeConversationRef.current?.id);
            console.log('Message conversation:', data.conversationId);
            console.log('Match?', activeConversationRef.current?.id === data.conversationId);
            handleNewMessage(data);
            break;
            
          case 'message_updated':
            console.log('Processing message_updated event');
            handleMessageUpdated(data);
            break;
            
          case 'conversation_updated':
            console.log('Processing conversation_updated event');
            handleConversationUpdated(data);
            break;
            
          case 'message_deleted':
            console.log('Processing message_deleted event');
            handleMessageDeleted(data);
            break;
            
          default:
            console.log('Unhandled WebSocket message type:', data.type);
        }
      } catch (error) {
        console.error('Error processing WebSocket message:', error);
      }
    };
    
    // Cleanup on unmount
    return () => {
      socket.close();
    };
  }, [user?.id]);
  
  // Handle new message from WebSocket
  const handleNewMessage = (data: any) => {
    console.log('Received new message via WebSocket:', data);
    
    // Check if the message is from another user
    const isFromOtherUser = data.message.senderId !== user?.id;
    
    // Only update UI if this message belongs to the active conversation
    if (data.conversationId === activeConversationRef.current?.id) {
      console.log('Message belongs to active conversation, updating messages');
      setMessages(prevMessages => {
        // Check if message already exists
        const exists = prevMessages.some(m => m.id === data.message.id);
        if (exists) {
          console.log('Message already exists in state, skipping');
          return prevMessages;
        }
        
        console.log('Adding new message to state');
        // Add new message with all required properties
        const newMessage: ChatMessage = {
          id: data.message.id,
          conversationId: data.conversationId,
          senderId: data.message.senderId,
          content: data.message.content,
          contentType: data.message.contentType || 'text',
          status: data.message.status || 'sent',
          readBy: [],
          createdAt: data.message.timestamp || new Date().toISOString(),
          updatedAt: data.message.timestamp || new Date().toISOString(),
          reactions: Array.isArray(data.message.reactions) ? data.message.reactions.map((reaction: any) => ({
            id: reaction.id,
            message_id: data.message.id,
            user_id: reaction.user_id,
            emoji: reaction.emoji,
            created_at: new Date(reaction.created_at),
            updated_at: new Date(reaction.updated_at),
            user: reaction.user
          })) : []
        };
        
        return [...prevMessages, newMessage];
      });

      // If message is from another user and conversation is active, mark it as read
      if (isFromOtherUser) {
        markAsRead();
      }
    }
    
    // Always update conversation list with latest message info, regardless of active conversation
    setConversations(prevConversations => {
      // First update the conversation with new message
      const updatedConversations = prevConversations.map(conv => {
        if (conv.id !== data.conversationId) return conv;

        // Determine if we should increment unread count
        const shouldIncrementUnread = isFromOtherUser && 
          (!activeConversationRef.current || activeConversationRef.current.id !== conv.id);

        return { 
          ...conv, 
          lastMessage: {
            id: data.message.id,
            conversationId: data.conversationId,
            senderId: data.message.senderId,
            content: data.message.content,
            contentType: data.message.contentType || 'text',
            status: data.message.status || 'sent',
            readBy: [],
            createdAt: data.message.timestamp || new Date().toISOString(),
            updatedAt: data.message.timestamp || new Date().toISOString(),
            reactions: []
          },
          updatedAt: new Date(data.message.timestamp || new Date()),
          // Only increment unread count if message is from another user and conversation is not active
          unreadCount: shouldIncrementUnread ? (conv.unreadCount || 0) + 1 : conv.unreadCount || 0
        };
      });

      // Then sort conversations to move the updated one to top
      return updatedConversations.sort((a, b) => {
        const aTime = a.lastMessage?.createdAt || a.updatedAt || new Date(0);
        const bTime = b.lastMessage?.createdAt || b.updatedAt || new Date(0);
        return new Date(bTime).getTime() - new Date(aTime).getTime();
      });
    });
  };
  
  // Handle message updated from WebSocket (e.g., reactions)
  const handleMessageUpdated = (data: any) => {
    console.log('Processing message update:', data);
    
    // Only update UI if this message belongs to the active conversation
    if (data.conversationId === activeConversationRef.current?.id) {
      console.log('Message belongs to active conversation, updating reactions');
      setMessages(prevMessages => 
        prevMessages.map(message => 
          message.id === data.messageId 
            ? {
                ...message,
                reactions: Array.isArray(data.updates.reactions) ? data.updates.reactions.map((reaction: any) => ({
                  id: reaction.id,
                  message_id: data.messageId,
                  user_id: reaction.user_id,
                  emoji: reaction.emoji,
                  created_at: new Date(reaction.created_at),
                  updated_at: new Date(reaction.updated_at),
                  user: reaction.user
                })) : message.reactions
              }
            : message
        )
      );
    }
  };
  
  // Handle conversation updated from WebSocket
  const handleConversationUpdated = (data: any) => {
    setConversations(prevConversations => 
      prevConversations.map(conv => 
        conv.id === data.conversationId 
          ? { ...conv, ...data.updates } 
          : conv
      )
    );
  };

  // Handle message deleted from WebSocket
  const handleMessageDeleted = async (data: any) => {
    console.log('Processing message deletion:', data);
    
    // Only update UI if this message belongs to the active conversation
    if (data.conversationId === activeConversationRef.current?.id) {
      console.log('Message belongs to active conversation, removing from messages');
      setMessages(prevMessages => 
        prevMessages.filter(message => message.id !== data.messageId)
      );
    }
    
    // Update conversation list
    setConversations(prevConversations => {
      return prevConversations.map(conv => {
        // Skip conversations that aren't affected
        if (conv.id !== data.conversationId) {
          return conv;
        }

        // If this wasn't the last message, just return the conversation as is
        if (conv.lastMessage?.id !== data.messageId) {
          return conv;
        }

        // If we have a nextLastMessage from the server, use it
        if (data.nextLastMessage) {
          return {
            ...conv,
            lastMessage: data.nextLastMessage,
            updatedAt: new Date(data.nextLastMessage.createdAt || Date.now())
          };
        }

        // If there's no nextLastMessage, this means there are no more messages
        return {
          ...conv,
          lastMessage: undefined,
          updatedAt: new Date() // Set to current time to maintain proper sorting
        };
      });
    });
  };

  // Load conversations on mount
  useEffect(() => {
    const loadConversations = async () => {
      try {
        setLoading(true);
        const data = await chatApi.getConversations();
        
        console.log('All conversations:', data.length);
        
        // Only filter out conversations that are completely empty
        const validConversations = data.filter(conversation => 
          conversation && 
          conversation.id && 
          conversation.participants && 
          conversation.participants.length > 0
        );
        
        console.log('Valid conversations:', validConversations.length);
        
        setConversations(validConversations);
        setLoading(false);
      } catch (err) {
        console.error('Error loading conversations:', err);
        setError('Failed to load conversations');
        setLoading(false);
      }
    };
    
    loadConversations();
  }, []);

  // Create a new conversation
  const createConversation = async (participantIds: string[], isGroup: boolean, metadata?: Record<string, any>) => {
    try {
      setLoading(true);
      
      console.log('Creating conversation with participants:', participantIds, 'isGroup:', isGroup, 'metadata:', metadata);
      
      const newConversation = await chatApi.createConversation(participantIds, isGroup, metadata);
      console.log('New conversation created:', newConversation);
      
      if (!newConversation || !newConversation.id) {
        console.error('Invalid conversation response:', newConversation);
        throw new Error('Failed to create conversation: Invalid response from server');
      }
      
      // Always add the new conversation to the list
      setConversations(prev => [newConversation, ...prev]);
      
      // Set the active conversation first
      setActiveConversation(newConversation);
      
      // Then select it to load messages
      await selectConversation(newConversation.id);
      
      // Navigate to the conversation route using React Router
      navigate(`/chat/${newConversation.id}`, { replace: true });
      
      setLoading(false);
      return newConversation;
    } catch (err) {
      console.error('Failed to create conversation:', err);
      setError('Failed to create conversation');
      setLoading(false);
      throw err;
    }
  };

  // Select a conversation and load its messages
  const selectConversation = async (conversationId: string | null) => {
    try {
      if (!conversationId) {
        // Clear the active conversation and messages
        setActiveConversation(null);
        setMessages([]);
        return;
      }
      
      setLoading(true);
      
      // Get conversation details
      const conversation = await chatApi.getConversation(conversationId);
      setActiveConversation(conversation);
      
      // Get messages
      const messageData = await chatApi.getMessages(conversationId, 50);
      if (Array.isArray(messageData)) {
        // Ensure each message has a reactions array and properly formatted reactions
        const messagesWithReactions = messageData.map(msg => ({
          ...msg,
          reactions: Array.isArray(msg.reactions) ? msg.reactions.map(reaction => ({
            id: reaction.id,
            message_id: reaction.message_id,
            user_id: reaction.user_id,
            emoji: reaction.emoji,
            created_at: reaction.created_at,
            updated_at: reaction.updated_at,
            user: reaction.user
          })) : []
        }));
        
        setMessages(messagesWithReactions.reverse()); // Show newest at the bottom
      } else {
        console.error('Invalid message data:', messageData);
        setMessages([]);
      }
      
      // Mark as read
      await chatApi.markAsRead(conversationId);
      
      // Update conversations list to reflect read status
      setConversations(prev => 
        prev.map(conv => 
          conv.id === conversationId 
            ? { ...conv, unreadCount: 0 } 
            : conv
        )
      );
      
      setLoading(false);
    } catch (err) {
      console.error('Failed to select conversation:', err);
      setError('Failed to load conversation');
      setLoading(false);
    }
  };

  // Send a message
  const sendMessage = async (content: string, mediaUrls?: string[], mediaTypes?: string[]) => {
    if (!activeConversation) {
      console.error('Cannot send message: No active conversation selected');
      setError('No active conversation selected');
      throw new Error('No active conversation selected');
    }
    
    if (!activeConversation.id) {
      console.error('Cannot send message: Conversation ID is undefined', activeConversation);
      setError('Conversation ID is undefined');
      throw new Error('Conversation ID is undefined');
    }
    
    try {
      // Create a temporary message for immediate display
      const tempMessage = {
        id: 'temp-' + Date.now(),
        conversationId: activeConversation.id,
        senderId: user?.id || 'current-user', // Use actual user ID if available
        content: content,
        contentType: 'text',
        status: 'sending',
        readBy: [],
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        reactions: []
      };

      // Immediately add the temporary message to the UI
      setMessages(prev => [...prev, tempMessage]);

      console.log('Sending message to conversation ID:', activeConversation.id);
      const newMessage = await chatApi.sendMessage(
        activeConversation.id,
        content,
        mediaUrls,
        mediaTypes
      );
      
      // Replace the temporary message with the real one
      setMessages(prev => prev.map(msg => 
        msg.id === tempMessage.id ? newMessage : msg
      ));
      
      // Update the conversation in the list with the new message
      setConversations(prev => {
        // First update the conversation with new message
        const updatedConversations = prev.map(conv => 
          conv.id === activeConversation.id 
            ? { 
                ...conv, 
                lastMessage: newMessage,
                updatedAt: new Date(), // Use Date object instead of string
                unreadCount: 0 // Reset unread count for sender
              }
            : conv
        );

        // Then sort conversations to move the updated one to top
        return updatedConversations.sort((a, b) => {
          const aTime = a.lastMessage?.createdAt || a.updatedAt || new Date(0);
          const bTime = b.lastMessage?.createdAt || b.updatedAt || new Date(0);
          return new Date(bTime).getTime() - new Date(aTime).getTime();
        });
      });
      
      return newMessage;
    } catch (err) {
      // Remove the temporary message if sending failed
      setMessages(prev => prev.filter(msg => msg.id !== 'temp-' + Date.now()));
      console.error('Failed to send message:', err);
      setError('Failed to send message');
      throw err;
    }
  };

  // Load more messages (pagination)
  const loadMoreMessages = async (before?: Date) => {
    if (!activeConversation) return;
    
    try {
      setLoading(true);
      const olderMessages = await chatApi.getMessages(
        activeConversation.id,
        before ? before.getTime() : undefined
      );

      // Filter out any messages that already exist in the current messages array
      const existingMessageIds = new Set(messages.map(msg => msg.id));
      const uniqueNewMessages = olderMessages
        .filter(msg => !existingMessageIds.has(msg.id))
        .map(msg => ({
          ...msg,
          reactions: Array.isArray(msg.reactions) ? msg.reactions.map(reaction => ({
            id: reaction.id,
            message_id: reaction.message_id,
            user_id: reaction.user_id,
            emoji: reaction.emoji,
            created_at: reaction.created_at,
            updated_at: reaction.updated_at,
            user: reaction.user
          })) : []
        }));

      if (uniqueNewMessages.length > 0) {
        setMessages(prev => [...prev, ...uniqueNewMessages]);
      }
    } catch (error) {
      console.error('Failed to load more messages:', error);
      setError('Failed to load more messages');
    } finally {
      setLoading(false);
    }
  };

  // Mark messages as read
  const markAsRead = async () => {
    if (!activeConversation) return;
    
    try {
      await chatApi.markAsRead(activeConversation.id);
      
      // Immediately update unread count in conversations list
      setConversations(prev => 
        prev.map(conv => 
          conv.id === activeConversation.id 
            ? { ...conv, unreadCount: 0 } 
            : conv
        )
      );

      // Update the messages to show they've been read
      setMessages(prev => 
        prev.map(msg => ({
          ...msg,
          readBy: [...(msg.readBy || []), 'current-user']
        }))
      );
    } catch (err) {
      console.error('Failed to mark messages as read:', err);
      setError('Failed to mark messages as read');
    }
  };

  // Add effect to automatically mark messages as read when conversation is active
  useEffect(() => {
    if (activeConversation) {
      markAsRead();
    }
  }, [activeConversation?.id, messages.length]);

  // Update a message (used for reactions)
  const updateMessage = (updatedMessage: ChatMessage) => {
    setMessages(prev => prev.map(msg => 
      msg.id === updatedMessage.id ? updatedMessage : msg
    ));
  };

  const deleteMessage = (messageId: string) => {
    // Remove the message from the messages array
    setMessages(prevMessages => prevMessages.filter(msg => msg.id !== messageId));

    // Update conversations list
    setConversations(prevConversations => {
      return prevConversations.map(conv => {
        // Skip conversations that aren't affected
        if (conv.id !== activeConversation?.id) {
          return conv;
        }

        // If this wasn't the last message, just return the conversation as is
        if (conv.lastMessage?.id !== messageId) {
          return conv;
        }

        // Find the next most recent message from the remaining messages
        const remainingMessages = messages.filter(msg => msg.id !== messageId);
        const nextLastMessage = remainingMessages.length > 0
          ? remainingMessages.sort((a, b) => {
              const dateA = new Date(a.sentAt || a.createdAt).getTime();
              const dateB = new Date(b.sentAt || b.createdAt).getTime();
              return dateB - dateA;
            })[0]
          : undefined;  // Use undefined instead of null to match ChatConversation type

        // Return updated conversation with next most recent message
        return {
          ...conv,
          lastMessage: nextLastMessage,
          updatedAt: nextLastMessage?.createdAt 
            ? new Date(nextLastMessage.createdAt)
            : conv.updatedAt instanceof Date 
              ? conv.updatedAt 
              : new Date(conv.updatedAt || Date.now())
        };
      });
    });
  };

  // Add the new conversation handler
  const handleNewConversation = (data: any) => {
    console.log('Received new conversation via WebSocket:', data);
    
    // Update conversations list, either adding new or updating existing
    setConversations(prevConversations => {
      // Find existing conversation
      const existingIndex = prevConversations.findIndex(c => c.id === data.conversation.id);
      
      // If conversation exists, update it
      if (existingIndex !== -1) {
        console.log('Updating existing conversation');
        const updatedConversations = [...prevConversations];
        updatedConversations[existingIndex] = {
          ...updatedConversations[existingIndex],
          ...data.conversation,
          // Preserve existing lastMessage if the new conversation doesn't have one
          lastMessage: data.conversation.lastMessage || updatedConversations[existingIndex].lastMessage
        };
        return updatedConversations;
      }
      
      // If it's a new conversation, add it to the beginning
      console.log('Adding new conversation');
      return [data.conversation, ...prevConversations];
    });
  };

  // Delete a group chat (admin/owner only)
  const deleteGroupChat = async (conversationId: string) => {
    try {
      setLoading(true);
      await chatApi.deleteGroupChat(conversationId);
      
      // Remove the conversation from the list
      setConversations(prev => prev.filter(conv => conv.id !== conversationId));
      
      // If this was the active conversation, clear it
      if (activeConversation?.id === conversationId) {
        setActiveConversation(null);
        setMessages([]);
        navigate('/chat');
      }
      
      setLoading(false);
    } catch (err) {
      console.error('Failed to delete group chat:', err);
      setError('Failed to delete group chat');
      setLoading(false);
      throw err;
    }
  };

  // Leave a group chat (non-admin participants)
  const leaveGroupChat = async (conversationId: string) => {
    try {
      setLoading(true);
      await chatApi.leaveGroupChat(conversationId);
      
      // Remove the conversation from the list
      setConversations(prev => prev.filter(conv => conv.id !== conversationId));
      
      // If this was the active conversation, clear it
      if (activeConversation?.id === conversationId) {
        setActiveConversation(null);
        setMessages([]);
        navigate('/chat');
      }
      
      setLoading(false);
    } catch (err) {
      console.error('Failed to leave group chat:', err);
      setError('Failed to leave group chat');
      setLoading(false);
      throw err;
    }
  };

  // Check if user is group admin/owner
  const isGroupAdmin = async (conversationId: string) => {
    try {
      return await chatApi.isGroupAdmin(conversationId);
    } catch (err) {
      console.error('Failed to check admin status:', err);
      return false;
    }
  };

  const value = {
    conversations,
    activeConversation,
    messages,
    loading,
    error,
    createConversation,
    selectConversation,
    sendMessage,
    loadMoreMessages,
    markAsRead,
    updateMessage,
    deleteMessage,
    deleteGroupChat,
    leaveGroupChat,
    isGroupAdmin,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};