import { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Switch,
  FormControlLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import { api } from '../../services/api';

interface WhitelistEntry {
  id: string;
  name: string;
  notes?: string;
  allowed_name: string;
  is_domain: boolean;
  is_email: boolean;
}

const sanitizeText = (text: string = ''): string => {
  return DOMPurify.sanitize(text.trim());
};

export const WhitelistPage = () => {
  const [entries, setEntries] = useState<WhitelistEntry[]>([]);
  const [selectedEntry, setSelectedEntry] = useState<WhitelistEntry | null>(null);
  const [open, setOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [errors, setErrors] = useState<{
    name?: string;
    allowed_name?: string;
    submit?: string;
  }>({});

  const fetchEntries = async () => {
    try {
      const response = await api.get('/whitelist');
      setEntries(response.data);
    } catch (error) {
      console.error('Failed to fetch whitelist entries:', error);
    }
  };

  useEffect(() => {
    fetchEntries();
  }, []);

  const handleEdit = (entry: WhitelistEntry) => {
    setIsNew(false);
    setSelectedEntry(entry);
    setOpen(true);
  };

  const handleCreate = () => {
    setIsNew(true);
    setSelectedEntry({
      id: '',
      name: '',
      allowed_name: '',
      notes: '',
      is_domain: false,
      is_email: true
    });
    setOpen(true);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this entry?')) {
      try {
        const response = await api.delete(`/whitelist/${id}`);
        console.log('Delete response:', response);
        await fetchEntries();
      } catch (error) {
        console.error('Delete error details:', {
          error,
          response: error.response,
          status: error.response?.status,
          data: error.response?.data
        });
        alert('Failed to delete entry. Please try again.');
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedEntry(null);
    setIsNew(false);
    setErrors({});
  };

  const validateForm = (): boolean => {
    const newErrors: { name?: string; allowed_name?: string } = {};
    
    if (!selectedEntry?.name?.trim()) {
      newErrors.name = 'Name is required';
    }
    
    if (!selectedEntry?.allowed_name?.trim()) {
      newErrors.allowed_name = 'Allowed name is required';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!selectedEntry) return;
    setErrors({});

    if (!validateForm()) {
      return;
    }

    try {
      const updateData: Partial<WhitelistEntry> = {
        name: sanitizeText(selectedEntry.name),
        allowed_name: sanitizeText(selectedEntry.allowed_name),
        notes: selectedEntry.notes ? sanitizeText(selectedEntry.notes) : null,
        is_domain: selectedEntry.is_domain,
        is_email: !selectedEntry.is_domain
      };

      console.log('Sending data:', updateData);

      if (isNew) {
        const response = await api.post('/whitelist', updateData);
        console.log('Create response:', response);
      } else {
        const response = await api.patch(`/whitelist/${selectedEntry.id}`, updateData);
        console.log('Update response:', response);
      }
      
      await fetchEntries();
      handleClose();
    } catch (error) {
      console.error('Save error details:', {
        error,
        response: error.response,
        status: error.response?.status,
        data: error.response?.data
      });
      setErrors({ 
        submit: error.response?.data?.error || 'An unexpected error occurred. Please check the console for details.' 
      });
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h5">White List</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          Create New
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Allowed Name</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Is Domain</TableCell>
              <TableCell>Is Email</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map((entry) => (
              <TableRow key={entry.id}>
                <TableCell>{entry.name}</TableCell>
                <TableCell>{entry.allowed_name}</TableCell>
                <TableCell>{entry.notes}</TableCell>
                <TableCell>{entry.is_domain ? 'Yes' : 'No'}</TableCell>
                <TableCell>{entry.is_email ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Tooltip title="Edit">
                      <IconButton onClick={() => handleEdit(entry)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => handleDelete(entry.id)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          {isNew ? 'Create New Whitelist Entry' : 'Edit Whitelist Entry'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
            {errors.submit && (
              <Typography color="error" variant="body2">
                {errors.submit}
              </Typography>
            )}
            <TextField
              label="Name"
              value={selectedEntry?.name || ''}
              onChange={(e) => setSelectedEntry(prev => 
                prev ? { ...prev, name: e.target.value } : null
              )}
              error={!!errors.name}
              helperText={errors.name}
              required
            />
            <TextField
              label="Allowed Name"
              value={selectedEntry?.allowed_name || ''}
              onChange={(e) => setSelectedEntry(prev => 
                prev ? { ...prev, allowed_name: e.target.value } : null
              )}
              error={!!errors.allowed_name}
              helperText={errors.allowed_name}
              required
            />
            <TextField
              label="Notes"
              multiline
              rows={3}
              value={selectedEntry?.notes || ''}
              onChange={(e) => setSelectedEntry(prev => 
                prev ? { ...prev, notes: e.target.value } : null
              )}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={selectedEntry?.is_domain || false}
                  onChange={(e) => setSelectedEntry(prev => 
                    prev ? { 
                      ...prev, 
                      is_domain: e.target.checked,
                      is_email: !e.target.checked
                    } : null
                  )}
                />
              }
              label="Is Domain"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} variant="contained">Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}; 