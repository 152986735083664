import { Box } from '@mui/material';
import RegisterForm from '../components/auth/RegisterForm';
import { useLocation, Navigate } from 'react-router-dom';

const RegisterPage = () => {
  const location = useLocation();
  const { hasConsent, consentTimestamp } = location.state || {};

  // Redirect to consent page if no consent
  if (!hasConsent) {
    return <Navigate to="/consent" replace />;
  }

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center' }}>
      <RegisterForm />
    </Box>
  );
};

export default RegisterPage; 