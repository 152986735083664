import { useState } from 'react';
import { 
  Box, 
  ToggleButton, 
  ToggleButtonGroup, 
  TextField,
  Popover,
  IconButton,
  Tooltip,
  Button
} from '@mui/material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import CodeIcon from '@mui/icons-material/Code';
import LinkIcon from '@mui/icons-material/Link';

interface RichTextEditorProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  rows?: number;
}

export const RichTextEditor = ({ 
  value, 
  onChange, 
  placeholder = 'Write a comment...', 
  rows = 2 
}: RichTextEditorProps) => {
  const [formats, setFormats] = useState<string[]>([]);
  const [selection, setSelection] = useState({ start: 0, end: 0 });
  const [linkDialogAnchor, setLinkDialogAnchor] = useState<null | HTMLElement>(null);
  const [linkUrl, setLinkUrl] = useState('');

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: string[],
  ) => {
    setFormats(newFormats);
    const input = event.currentTarget.closest('.MuiTextField-root')
      ?.querySelector('textarea');
    
    if (input) {
      const start = input.selectionStart;
      const end = input.selectionEnd;
      const selectedText = value.substring(start, end);
      let newText = value;

      switch (newFormats[newFormats.length - 1]) {
        case 'bold':
          newText = value.substring(0, start) + `**${selectedText}**` + value.substring(end);
          break;
        case 'italic':
          newText = value.substring(0, start) + `_${selectedText}_` + value.substring(end);
          break;
        case 'quote':
          newText = value.substring(0, start) + `> ${selectedText}` + value.substring(end);
          break;
        case 'code':
          newText = value.substring(0, start) + '`' + selectedText + '`' + value.substring(end);
          break;
      }

      onChange(newText);
    }
  };

  const handleLinkAdd = () => {
    if (linkUrl && selection.start !== selection.end) {
      const newText = 
        value.substring(0, selection.start) + 
        `[${value.substring(selection.start, selection.end)}](${linkUrl})` + 
        value.substring(selection.end);
      
      onChange(newText);
      setLinkUrl('');
      setLinkDialogAnchor(null);
    }
  };

  return (
    <Box>
      <Box sx={{ mb: 1, borderBottom: 1, borderColor: 'divider' }}>
        <ToggleButtonGroup
          value={formats}
          onChange={handleFormat}
          aria-label="text formatting"
          size="small"
        >
          <ToggleButton value="bold" aria-label="bold">
            <Tooltip title="Bold">
              <FormatBoldIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="italic" aria-label="italic">
            <Tooltip title="Italic">
              <FormatItalicIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="quote" aria-label="quote">
            <Tooltip title="Quote">
              <FormatQuoteIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="code" aria-label="code">
            <Tooltip title="Code">
              <CodeIcon />
            </Tooltip>
          </ToggleButton>
          <IconButton 
            size="small" 
            onClick={(e) => {
              const input = e.currentTarget.closest('.MuiTextField-root')
                ?.querySelector('textarea');
              if (input) {
                setSelection({
                  start: input.selectionStart,
                  end: input.selectionEnd
                });
              }
              setLinkDialogAnchor(e.currentTarget);
            }}
          >
            <Tooltip title="Add Link">
              <LinkIcon />
            </Tooltip>
          </IconButton>
        </ToggleButtonGroup>
      </Box>

      <TextField
        fullWidth
        multiline
        rows={rows}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        variant="outlined"
        size="small"
      />

      <Popover
        open={Boolean(linkDialogAnchor)}
        anchorEl={linkDialogAnchor}
        onClose={() => setLinkDialogAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2 }}>
          <TextField
            size="small"
            label="URL"
            value={linkUrl}
            onChange={(e) => setLinkUrl(e.target.value)}
            sx={{ mb: 1 }}
          />
          <Button 
            variant="contained" 
            size="small" 
            onClick={handleLinkAdd}
            fullWidth
          >
            Add Link
          </Button>
        </Box>
      </Popover>
    </Box>
  );
}; 