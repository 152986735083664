import { useState, useRef, useCallback } from 'react';
import { TextField, Popper, Paper, List, ListItem, ListItemText, Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { api } from '../services/api';

interface User {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
}

interface MentionInputProps {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  placeholder?: string;
  helperText?: string;
  multiline?: boolean;
  rows?: number;
}

export const MentionInput = ({
  value,
  onChange,
  label,
  placeholder,
  helperText,
  multiline = false,
  rows = 1
}: MentionInputProps) => {
  const [mentionSearch, setMentionSearch] = useState<string | null>(null);
  const [cursorPosition, setCursorPosition] = useState<{ top: number; left: number } | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { data: suggestions = [] } = useQuery({
    queryKey: ['users', mentionSearch],
    queryFn: async () => {
      if (!mentionSearch) return [];
      const url = `/users/search?q=${encodeURIComponent(mentionSearch)}`;
      console.log('Making user search request to:', url);
      const response = await api.get(url);
      return response.data as User[];
    },
    enabled: !!mentionSearch
  });

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const cursorPos = e.target.selectionStart || 0;
    
    // Find the word being typed
    const textBeforeCursor = newValue.slice(0, cursorPos);
    const words = textBeforeCursor.split(/\s/);
    const currentWord = words[words.length - 1];

    if (currentWord.startsWith('@') && currentWord.length > 1) {
      setMentionSearch(currentWord.slice(1));
      // Get position for popup
      const inputRect = inputRef.current?.getBoundingClientRect();
      if (inputRect) {
        setCursorPosition({
          top: inputRect.bottom,
          left: inputRect.left
        });
      }
    } else {
      setMentionSearch(null);
      setCursorPosition(null);
    }

    onChange(newValue);
  }, [onChange]);

  const handleSelectMention = useCallback((user: User) => {
    const cursorPos = inputRef.current?.selectionStart || 0;
    const textBeforeCursor = value.slice(0, cursorPos);
    const textAfterCursor = value.slice(cursorPos);
    
    // Replace the @mention with the selected username or full name
    const words = textBeforeCursor.split(/\s/);
    const mention = user.username 
      ? `@${user.username}` 
      : `@${user.firstName.toLowerCase()}${user.lastName.toLowerCase()}`;
    words[words.length - 1] = mention;
    
    const newValue = [...words, textAfterCursor].join(' ');
    onChange(newValue);
    setMentionSearch(null);
    setCursorPosition(null);
  }, [value, onChange]);

  return (
    <Box position="relative">
      <TextField
        inputRef={inputRef}
        fullWidth
        multiline={multiline}
        rows={rows}
        label={label}
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        helperText={helperText}
      />
      
      <Popper
        open={!!mentionSearch && !!cursorPosition}
        anchorEl={inputRef.current}
        placement="bottom-start"
        style={{
          width: inputRef.current?.offsetWidth,
          zIndex: 1300
        }}
      >
        <Paper elevation={3}>
          <List>
            {suggestions.map((user) => (
              <ListItem
                key={user.id}
                button
                onClick={() => handleSelectMention(user)}
              >
                <ListItemText
                  primary={`${user.firstName} ${user.lastName}`}
                  secondary={user.username 
                    ? `@${user.username}` 
                    : `@${user.firstName.toLowerCase()}${user.lastName.toLowerCase()}`
                  }
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Popper>
    </Box>
  );
}; 