import React, { useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
} from '@mui/material';
import type { Interaction, InteractionType } from '../../types/contact';
import { createInteraction, updateInteraction } from '../../services/contacts';

interface InteractionFormProps {
  contactId: string;
  initialData?: Interaction;
  onSubmit: () => void;
}

const InteractionForm: React.FC<InteractionFormProps> = ({
  contactId,
  initialData,
  onSubmit,
}) => {
  const [formData, setFormData] = useState<Partial<Interaction>>({
    type: InteractionType.MEETING_IN_PERSON,
    subject: '',
    description: '',
    date: new Date(),
    location: '',
    outcome: '',
    follow_up_needed: false,
    follow_up_date: undefined,
    follow_up_notes: '',
    ...initialData,
  });

  const handleChange = (field: keyof Interaction) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const handleDateChange = (field: 'date' | 'follow_up_date') => (value: Date | null) => {
    setFormData(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleCheckboxChange = (field: keyof Interaction) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.checked,
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      if (initialData?.id) {
        await updateInteraction(initialData.id, {
          ...formData,
          contact_id: contactId,
        });
      } else {
        await createInteraction({
          ...formData,
          contact_id: contactId,
        });
      }
      onSubmit();
    } catch (error) {
      console.error('Error saving interaction:', error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              value={formData.type}
              label="Type"
              onChange={handleChange('type')}
            >
              {Object.values(InteractionType).map((type) => (
                <MenuItem key={type} value={type}>
                  {type.replace(/_/g, ' ')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimePicker
            label="Date and Time"
            value={formData.date}
            onChange={handleDateChange('date')}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label="Subject"
            value={formData.subject}
            onChange={handleChange('subject')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Location"
            value={formData.location}
            onChange={handleChange('location')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={3}
            label="Description"
            value={formData.description}
            onChange={handleChange('description')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={2}
            label="Outcome"
            value={formData.outcome}
            onChange={handleChange('outcome')}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.follow_up_needed}
                onChange={handleCheckboxChange('follow_up_needed')}
              />
            }
            label="Follow-up needed"
          />
        </Grid>

        {formData.follow_up_needed && (
          <>
            <Grid item xs={12}>
              <DateTimePicker
                label="Follow-up Date"
                value={formData.follow_up_date}
                onChange={handleDateChange('follow_up_date')}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={2}
                label="Follow-up Notes"
                value={formData.follow_up_notes}
                onChange={handleChange('follow_up_notes')}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              {initialData ? 'Update' : 'Create'} Interaction
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InteractionForm;
