import React from 'react';
import { Card, CardContent, Typography, Button, Stack, Chip, Avatar } from '@mui/material';
import { PersonAdd, Article, Check, Close } from '@mui/icons-material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateSuggestionStatus } from '../../services/suggestions';
import { useMatomo } from '../../hooks/useMatomo';

interface SuggestionCardProps {
  suggestion: {
    id: string;
    type: 'network' | 'content';
    target_id: string;
    target_type: string;
    score: number;
    reasons: string[];
    status: 'pending' | 'accepted' | 'rejected';
    target_data?: any; // This will contain user or post data
  };
}

export const SuggestionCard: React.FC<SuggestionCardProps> = ({ suggestion }) => {
  const queryClient = useQueryClient();
  const { trackEvent } = useMatomo();

  const updateStatusMutation = useMutation({
    mutationFn: updateSuggestionStatus,
    onSuccess: () => {
      queryClient.invalidateQueries(['suggestions']);
    },
  });

  const handleAction = (status: 'accepted' | 'rejected') => {
    updateStatusMutation.mutate({
      suggestionId: suggestion.id,
      status
    });
  };

  return (
    <Card sx={{ mb: 2, position: 'relative' }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="center" mb={2}>
          {suggestion.type === 'network' ? (
            <Avatar src={suggestion.target_data?.avatar_url} />
          ) : (
            <Article color="primary" />
          )}
          <Typography variant="h6" component="div">
            {suggestion.type === 'network'
              ? suggestion.target_data?.name || 'Connection Suggestion'
              : suggestion.target_data?.title || 'Content Suggestion'}
          </Typography>
        </Stack>

        <Typography variant="body2" color="text.secondary" mb={2}>
          {suggestion.target_data?.description || suggestion.target_data?.content?.slice(0, 150)}
        </Typography>

        <Stack direction="row" spacing={1} mb={2} flexWrap="wrap">
          {suggestion.reasons.map((reason, index) => (
            <Chip
              key={index}
              label={reason}
              size="small"
              variant="outlined"
              sx={{ mb: 1 }}
            />
          ))}
        </Stack>

        {suggestion.status === 'pending' && (
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              startIcon={<Close />}
              variant="outlined"
              color="error"
              onClick={() => handleAction('rejected')}
              disabled={updateStatusMutation.isLoading}
            >
              Not Interested
            </Button>
            <Button
              startIcon={suggestion.type === 'network' ? <PersonAdd /> : <Check />}
              variant="contained"
              color="primary"
              onClick={() => handleAction('accepted')}
              disabled={updateStatusMutation.isLoading}
            >
              {suggestion.type === 'network' ? 'Connect' : 'View Content'}
            </Button>
          </Stack>
        )}

        {suggestion.status !== 'pending' && (
          <Typography
            variant="body2"
            color={suggestion.status === 'accepted' ? 'success.main' : 'error.main'}
            sx={{ textAlign: 'right', fontStyle: 'italic' }}
          >
            {suggestion.status === 'accepted' ? 'Accepted' : 'Rejected'}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
