import React, { useState } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  TextField, 
  Button, 
  Grid, 
  Paper, 
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  Alert,
  FormHelperText,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Divider,
  Card,
  CardContent,
  Tooltip,
  Switch,
  FormGroup,
  List,
  ListItem,
  ListItemText,
  Link as MuiLink
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CalculateIcon from '@mui/icons-material/Calculate';
import PeopleIcon from '@mui/icons-material/People';
import InfoIcon from '@mui/icons-material/Info';
import HelpIcon from '@mui/icons-material/Help';
import StarIcon from '@mui/icons-material/Star';

const SpeedNetworkingSchedulerPage: React.FC = () => {
  const theme = useTheme();
  const [numberOfUsers, setNumberOfUsers] = useState<number>(30);
  const [totalTime, setTotalTime] = useState<number>(120);
  const [roundDuration, setRoundDuration] = useState<number>(15);
  const [peoplePerTable, setPeoplePerTable] = useState<number>(4);
  const [startTimeStr, setStartTimeStr] = useState<string>("8:00");
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [numberOfTables, setNumberOfTables] = useState<number>(8);
  
  // New states for participant names and groups
  const [useCustomNames, setUseCustomNames] = useState<boolean>(false);
  const [participantInput, setParticipantInput] = useState<string>("");
  
  // Event Calculator States
  const [calculationMode, setCalculationMode] = useState<string>("totalTime");
  const [calcNumberOfUsers, setCalcNumberOfUsers] = useState<number>(20);
  const [calcTotalTime, setCalcTotalTime] = useState<number>(120);
  const [calcRoundDuration, setCalcRoundDuration] = useState<number>(15);
  const [calcPeoplePerTable, setCalcPeoplePerTable] = useState<number>(4);
  const [calculationResult, setCalculationResult] = useState<number | null>(null);
  const [coveragePercentage, setCoveragePercentage] = useState<number | null>(null);
  const [totalConnections, setTotalConnections] = useState<number | null>(null);
  const [maxConnections, setMaxConnections] = useState<number | null>(null);
  
  // New state for optimal parameters
  const [optimalParameters, setOptimalParameters] = useState<{
    tableSize: number;
    roundDuration: number;
    rounds: number;
    coverage: number;
  } | null>(null);
  
  const [calcErrors, setCalcErrors] = useState<{
    general?: string;
  }>({});
  
  // Validation states
  const [errors, setErrors] = useState<{
    numberOfUsers?: string;
    totalTime?: string;
    roundDuration?: string;
    peoplePerTable?: string;
    startTimeStr?: string;
    participantInput?: string;
    numberOfTables?: string;
    general?: string;
  }>({});

  // Helper to pad numbers with leading zero if needed
  const pad = (num: number): string => String(num).padStart(2, "0");

  // Validate inputs before generating
  const validateInputs = (): boolean => {
    const newErrors: any = {};

    // Validate number of users
    if (!numberOfUsers || numberOfUsers < 2) {
      newErrors.numberOfUsers = "Must have at least 2 users";
    } else if (numberOfUsers > 500) {
      newErrors.numberOfUsers = "Maximum of 500 users allowed";
    }

    // Validate total time
    if (!totalTime || totalTime <= 0) {
      newErrors.totalTime = "Total time must be positive";
    } else if (totalTime > 1440) { // 24 hours max
      newErrors.totalTime = "Maximum duration is 24 hours (1440 minutes)";
    }

    // Validate round duration
    if (!roundDuration || roundDuration <= 0) {
      newErrors.roundDuration = "Round duration must be positive";
    } else if (roundDuration > totalTime) {
      newErrors.roundDuration = "Round duration cannot exceed total time";
    }

    // Validate people per table
    if (!peoplePerTable || peoplePerTable < 2) {
      newErrors.peoplePerTable = "Must have at least 2 people per table";
    } else if (peoplePerTable > 20) {
      newErrors.peoplePerTable = "Maximum of 20 people per table";
    } else if (peoplePerTable > numberOfUsers) {
      newErrors.peoplePerTable = "Cannot exceed total number of users";
    }

    // Validate start time format (HH:MM)
    const timeRegex = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/;
    if (!timeRegex.test(startTimeStr)) {
      newErrors.startTimeStr = "Use format HH:MM (e.g., 8:00, 14:30)";
    }

    // Validate participant input if custom names are enabled
    if (useCustomNames) {
      // Split the text into lines and filter out empty lines
      const names = participantInput.split('\n').filter(name => name.trim() !== '');
      
      if (names.length === 0) {
        newErrors.participantInput = "Please enter at least one name or disable custom names";
      } else if (names.length !== numberOfUsers) {
        newErrors.participantInput = `Number of names (${names.length}) does not match the number of participants (${numberOfUsers})`;
      }
    }

    // Check if we have enough time for at least one round
    if (roundDuration > totalTime) {
      newErrors.general = "Total time must be enough for at least one round";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const generateSchedule = () => {
    if (!validateInputs()) {
      return;
    }

    setIsGenerating(true);

    // Defer the heavy computation to the next tick
    setTimeout(() => {
      try {
        // Get participant names and groups (or create default ones if custom names not used)
        let participants: { name: string; group: string; id: number }[] = [];
        if (useCustomNames) {
          participants = participantInput
            .split('\n')
            .map((line, idx) => {
              // First try to split by tab, if no tab found, try comma
              const parts = line.includes('\t') 
                ? line.split('\t') 
                : line.split(',');
              
              // Get name and group, trimming whitespace
              const name = parts[0]?.trim() || '';
              const group = parts[1]?.trim() || 'default';
              
              return { name, group, id: idx };
            })
            .filter(p => p.name !== '');
        } else {
          // Generate default names "Person 1", "Person 2", etc.
          participants = Array.from({ length: numberOfUsers }, (_, i) => ({
            name: `Person ${i + 1}`,
            group: 'default',
            id: i
          }));
        }

        // Update the helper text to reflect both separator options
        const helperText = errors.participantInput || 
          "Enter one participant per line. To use groups or departments (optional), add a comma or tab followed by the group name. Examples:\n" +
          "Fraziska Hübner, HR\n" +
          "Fabiola Fajardo\t IT\n" +
          "Or simply copy-paste from Excel (tab-separated)";

        // Parse start time assuming HH:MM format
        const [startHour, startMinute] = startTimeStr.split(":").map(Number);
        let startTime = new Date();
        startTime.setHours(startHour, startMinute, 0, 0);

        // Calculate available rounds
        const availableRounds = Math.floor(totalTime / roundDuration);

        // Determine number of tables (T) using a balanced partition:
        const T = Math.ceil(numberOfUsers / peoplePerTable);

        // Partition persons into tables (balanced):
        // We'll assign each person an initial table and a seat position.
        const initialTable = new Array(numberOfUsers);
        const seatPosition = new Array(numberOfUsers);
        const baseCount = Math.floor(numberOfUsers / T);
        const extra = numberOfUsers % T;
        let currentTable = 1;
        let countInTable = 0;
        let tableCapacity = currentTable <= extra ? baseCount + 1 : baseCount;
        for (let i = 0; i < numberOfUsers; i++) {
          initialTable[i] = currentTable;
          seatPosition[i] = countInTable + 1; // positions are 1-indexed
          countInTable++;
          if (countInTable === tableCapacity) {
            currentTable++;
            countInTable = 0;
            if (currentTable <= T) {
              tableCapacity = currentTable <= extra ? baseCount + 1 : baseCount;
            }
          }
        }

        // Build schedules for each participant
        const schedules: string[] = [];

        for (let i = 0; i < numberOfUsers; i++) {
          let personSchedule = `Schedule for ${participants[i].name}\n`;
          personSchedule += "----------------------------------------\n\n";
          
          for (let r = 0; r < availableRounds; r++) {
            // Compute round start and end times:
            let roundStart = new Date(startTime.getTime() + r * roundDuration * 60000);
            let roundEnd = new Date(roundStart.getTime() + roundDuration * 60000);
            const timeStr =
              `${roundStart.getHours()}:${pad(roundStart.getMinutes())}` +
              " - " +
              `${roundEnd.getHours()}:${pad(roundEnd.getMinutes())}`;

            // Calculate new table assignment
            const newTable = (((initialTable[i] - 1) + (seatPosition[i] - 1) * r) % T) + 1;
            
            // Find all other persons at the same table
            const tablePartners = [];
            for (let j = 0; j < numberOfUsers; j++) {
              if (i !== j) {
                const otherPersonTable = (((initialTable[j] - 1) + (seatPosition[j] - 1) * r) % T) + 1;
                if (otherPersonTable === newTable) {
                  tablePartners.push(participants[j].name);
                }
              }
            }
            
            personSchedule += `${timeStr} : Table #${newTable} with ${tablePartners.join(', ')}\n`;
          }
          
          personSchedule += "\n\n";
          schedules.push(personSchedule);
        }

        // Create a combined document with all schedules
        let fullDocument = schedules.join('\n\n' + '='.repeat(50) + '\n\n');
        
        // Generate table schedules
        const tableSchedules: string[] = [];
        for (let table = 1; table <= T; table++) {
          let tableSchedule = `Schedule for Table ${table}\n`;
          tableSchedule += "----------------------------------------\n\n";
          
          for (let r = 0; r < availableRounds; r++) {
            // Compute round start and end times:
            let roundStart = new Date(startTime.getTime() + r * roundDuration * 60000);
            let roundEnd = new Date(roundStart.getTime() + roundDuration * 60000);
            const timeStr =
              `${roundStart.getHours()}:${pad(roundStart.getMinutes())}` +
              " - " +
              `${roundEnd.getHours()}:${pad(roundEnd.getMinutes())}`;

            // Find all people at this table in this round
            const peopleAtTable = [];
            for (let i = 0; i < numberOfUsers; i++) {
              const newTableNum = (((initialTable[i] - 1) + (seatPosition[i] - 1) * r) % T) + 1;
              if (newTableNum === table) {
                peopleAtTable.push(participants[i].name);
              }
            }
            
            tableSchedule += `${timeStr} : ${peopleAtTable.join(', ')}\n`;
          }
          
          tableSchedule += "\n\n";
          tableSchedules.push(tableSchedule);
        }

        // Add table schedules to the full document
        fullDocument += '\n\n' + tableSchedules.join('\n\n' + '='.repeat(50) + '\n\n');
        
        // Generate HTML content
        const htmlContent = `
<!DOCTYPE html>
<html>
<head>
  <title>Speed Networking Schedule</title>
  <style>
    body { font-family: Arial, sans-serif; margin: 20px; }
    h1 { color: #333; border-bottom: 1px solid #ccc; padding-bottom: 5px; }
    .schedule { page-break-after: always; margin-bottom: 30px; }
    .schedule:last-child { page-break-after: avoid; }
    .divider { border-top: 1px solid #ccc; margin: 5px 0 15px 0; }
    .meeting { margin: 10px 0; }
    .time { font-weight: bold; }
    .partners { color: #0066cc; }
    @media print {
      body { margin: 0.5cm; }
      h1 { font-size: 18pt; }
      p { font-size: 12pt; line-height: 1.3; }
      .page-break { page-break-after: always; }
    }
  </style>
</head>
<body>
${schedules.map((schedule, idx) => {
  const lines = schedule.split('\n');
  const title = lines[0];
  
  // Skip the header and separator, start from actual meetings
  const meetings = [];
  for (let i = 3; i < lines.length; i++) {
    const line = lines[i].trim();
    if (line) {
      // Parse time, table and partners
      const parts = line.split(' : ');
      if (parts.length >= 2) {
        const time = parts[0];
        const rest = parts[1].split(' with ');
        const table = rest[0];
        const partners = rest.length > 1 ? rest[1] : '';
        
        meetings.push(`
          <div class="meeting">
            <span class="time">${time}:</span> 
            ${table} with <span class="partners">${partners}</span>
          </div>
        `);
      }
    }
  }
  
  return `
    <div class="schedule">
      <h1>${title}</h1>
      <div class="divider"></div>
      ${meetings.join('')}
      ${idx < schedules.length - 1 ? '<div class="page-break"></div>' : ''}
    </div>
  `;
}).join('')}

${tableSchedules.map((schedule, idx) => {
  const lines = schedule.split('\n');
  const title = lines[0];
  
  // Skip the header and separator, start from actual meetings
  const meetings = [];
  for (let i = 3; i < lines.length; i++) {
    const line = lines[i].trim();
    if (line) {
      const [time, participants] = line.split(' : ');
      if (time && participants) {
        meetings.push(`
          <div class="meeting">
            <span class="time">${time}:</span> 
            <span class="partners">${participants}</span>
          </div>
        `);
      }
    }
  }
  
  return `
    <div class="schedule">
      <h1>${title}</h1>
      <div class="divider"></div>
      ${meetings.join('')}
      ${idx < tableSchedules.length - 1 ? '<div class="page-break"></div>' : ''}
    </div>
  `;
}).join('')}
</body>
</html>
`;

        // Create a Blob with the HTML content
        const blob = new Blob([htmlContent], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        
        // Create a link and trigger download
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Speed_Networking_Schedule.html';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        setIsGenerating(false);
      } catch (error) {
        console.error("Error generating schedule:", error);
        setErrors({
          ...errors,
          general: "Error generating schedule. Please check your inputs."
        });
        setIsGenerating(false);
      }
    }, 0);
  };

  // Calculate event planning metrics
  const calculateMetrics = () => {
    try {
      setCalcErrors({});
      setOptimalParameters(null);
      
      // Determine the values based on what's being calculated
      const n = calcNumberOfUsers;
      const p = calcPeoplePerTable;
      const t = calcRoundDuration;
      const totalT = calcTotalTime;
      
      // Validate inputs
      if (n < 2) {
        setCalcErrors({ general: "Number of participants must be at least 2" });
        return;
      }
      
      if (p < 2 && calculationMode !== "maxCoverage") {
        setCalcErrors({ general: "People per table must be at least 2" });
        return;
      }
      
      if (t <= 0 && calculationMode !== "maxCoverage") {
        setCalcErrors({ general: "Round duration must be positive" });
        return;
      }
      
      if (totalT <= 0) {
        setCalcErrors({ general: "Total time must be positive" });
        return;
      }
      
      // Ensure total time isn't smaller than round duration
      if (calculationMode !== "totalTime" && calculationMode !== "maxCoverage" && totalT < t) {
        setCalcErrors({ general: "Total time cannot be smaller than round duration" });
        return;
      }
      
      // Special case for Maximum Coverage calculation
      if (calculationMode === "maxCoverage") {
        // Find optimal parameters for maximum coverage
        const result = findOptimalParameters(n, totalT);
        setOptimalParameters(result);
        
        // Calculate connections with optimal parameters
        const connectionsPerRound = result.tableSize - 1;
        const uniquePeopleMet = Math.min(result.rounds * connectionsPerRound, n - 1);
        const maxPotentialConnections = (n * (n - 1)) / 2;
        const actualConnections = Math.min((uniquePeopleMet * n) / 2, maxPotentialConnections);
        
        setTotalConnections(Math.floor(actualConnections));
        setMaxConnections(maxPotentialConnections);
        setCoveragePercentage(result.coverage);
        return;
      }
      
      // Calculate number of tables
      const tables = Math.ceil(n / p);
      
      // Calculate maximum possible unique connections between all participants
      const maxPotentialConnections = (n * (n - 1)) / 2;
      
      // Calculate maximum connections per person (all possible partners)
      const maxConnectionsPerPerson = n - 1;
      
      // Calculate how many unique people each participant can meet per round
      const connectionsPerRound = p - 1;
      
      // Calculate total connections in the event given the parameters
      let actualConnections = 0;
      let rounds = 0;
      
      switch (calculationMode) {
        case "totalTime":
          // Calculate how many rounds are needed 
          rounds = Math.floor(n / tables);
          if (rounds === 0) rounds = 1; // At least one round
          
          // Calculate how many unique people each participant meets in total
          const uniquePeopleMet = Math.min(rounds * connectionsPerRound, maxConnectionsPerPerson);
          
          // Calculate total unique connections (divide by 2 because each connection is counted twice)
          actualConnections = Math.min((uniquePeopleMet * n) / 2, maxPotentialConnections);
          setTotalConnections(Math.floor(actualConnections));
          
          // Calculate required total time
          const requiredTime = rounds * t;
          setCalculationResult(requiredTime);
          break;
          
        case "roundDuration":
          // Calculate optimal round duration for the given total time
          rounds = Math.floor(n / tables);
          if (rounds === 0) rounds = 1; // At least one round
          
          const optimalRoundDuration = Math.floor(totalT / rounds);
          if (optimalRoundDuration <= 0) {
            setCalcErrors({ general: "Total time is too small for the number of participants" });
            return;
          }
          
          // Calculate how many unique people each participant meets in total
          const uniquePeopleMet2 = Math.min(rounds * connectionsPerRound, maxConnectionsPerPerson);
          
          // Calculate total unique connections (divide by 2 because each connection is counted twice)
          actualConnections = Math.min((uniquePeopleMet2 * n) / 2, maxPotentialConnections);
          setTotalConnections(Math.floor(actualConnections));
          
          setCalculationResult(optimalRoundDuration);
          break;
          
        case "tableSize":
          // Calculate optimal table size
          rounds = Math.floor(totalT / t);
          if (rounds === 0) {
            setCalcErrors({ general: "Total time must be greater than round duration" });
            return;
          }
          
          const optimalTableSize = Math.max(2, Math.min(20, Math.floor(n / Math.ceil(n / p))));
          
          // Calculate connections with the optimal table size
          const connectionsPerRoundOptimal = optimalTableSize - 1;
          const uniquePeopleMet3 = Math.min(rounds * connectionsPerRoundOptimal, maxConnectionsPerPerson);
          actualConnections = Math.min((uniquePeopleMet3 * n) / 2, maxPotentialConnections);
          
          setTotalConnections(Math.floor(actualConnections));
          setCalculationResult(optimalTableSize);
          break;
          
        case "coverage":
          // Calculate maximum rounds needed for complete coverage
          const maxRounds = Math.ceil(maxConnectionsPerPerson / connectionsPerRound);
          
          // Calculate actual rounds based on total time
          rounds = Math.floor(totalT / t);
          if (rounds === 0) {
            setCalcErrors({ general: "Total time must be greater than round duration" });
            return;
          }
          
          // Calculate how many unique people each participant meets in total
          const uniquePeopleMet4 = Math.min(rounds * connectionsPerRound, maxConnectionsPerPerson);
          
          // Calculate coverage percentage
          const coverage = Math.min(100, Math.floor((uniquePeopleMet4 / maxConnectionsPerPerson) * 100));
          
          // Calculate total unique connections
          actualConnections = Math.min((uniquePeopleMet4 * n) / 2, maxPotentialConnections);
          setTotalConnections(Math.floor(actualConnections));
          
          setCalculationResult(coverage);
          break;
      }
      
      // Calculate the percentage of total potential connections that will be made
      const coveragePct = Math.min(100, Math.round((actualConnections / maxPotentialConnections) * 100));
      setCoveragePercentage(coveragePct);
      
      // Set maximum possible connections
      setMaxConnections(maxPotentialConnections);
    } catch (error) {
      console.error("Error in calculation:", error);
      setCalculationResult(null);
      setCoveragePercentage(null);
      setCalcErrors({ general: "Error in calculation. Please check your inputs." });
    }
  };
  
  // Find optimal parameters for maximum coverage
  const findOptimalParameters = (participants: number, totalTimeAvailable: number) => {
    let bestCoverage = 0;
    let bestTableSize = 0;
    let bestRoundDuration = 0;
    let bestRounds = 0;
    
    // Try different table sizes from 3 to 8 (common optimal range)
    for (let tableSize = 3; tableSize <= Math.min(8, participants); tableSize++) {
      // Try different round durations from 5 to 30 minutes
      for (let roundDuration = 5; roundDuration <= 30; roundDuration += 5) {
        // Calculate number of possible rounds
        const rounds = Math.floor(totalTimeAvailable / roundDuration);
        if (rounds < 1) continue;
        
        // Calculate number of tables
        const tables = Math.ceil(participants / tableSize);
        
        // Calculate how many unique people each participant can meet
        const connectionsPerRound = tableSize - 1;
        const uniquePeopleMet = Math.min(rounds * connectionsPerRound, participants - 1);
        
        // Calculate coverage percentage
        const coveragePercentage = Math.round((uniquePeopleMet / (participants - 1)) * 100);
        
        // If this is better than our current best, update it
        if (coveragePercentage > bestCoverage) {
          bestCoverage = coveragePercentage;
          bestTableSize = tableSize;
          bestRoundDuration = roundDuration;
          bestRounds = rounds;
        }
        
        // If we already found 100% coverage, we can stop
        if (coveragePercentage >= 100) break;
      }
      
      // If we already found 100% coverage, we can stop
      if (bestCoverage >= 100) break;
    }
    
    return {
      tableSize: bestTableSize,
      roundDuration: bestRoundDuration,
      rounds: bestRounds,
      coverage: bestCoverage
    };
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h4" gutterBottom align="center">
            Speed Networking Scheduler
          </Typography>
          <Typography variant="body1" paragraph align="center" color="text.secondary">
            Create personalized schedules for real life, face to face business networking speed dating events with optimal participant interactions
          </Typography>
        </Box>

        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <ScheduleIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h5">Configure Your Event</Typography>
          </Box>

          {errors.general && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errors.general}
            </Alert>
          )}

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Number of Participants"
                type="number"
                value={numberOfUsers}
                onChange={(e) => setNumberOfUsers(parseInt(e.target.value) || 0)}
                error={!!errors.numberOfUsers}
                helperText={errors.numberOfUsers}
                inputProps={{ min: 2, max: 500 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Number of Tables"
                type="number"
                value={numberOfTables}
                onChange={(e) => setNumberOfTables(parseInt(e.target.value) || 0)}
                error={!!errors.numberOfTables}
                helperText={errors.numberOfTables || "Specify how many tables are available"}
                inputProps={{ min: 1, max: 100 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="People Per Table"
                type="number"
                value={peoplePerTable}
                onChange={(e) => setPeoplePerTable(parseInt(e.target.value) || 0)}
                error={!!errors.peoplePerTable}
                helperText={errors.peoplePerTable}
                inputProps={{ min: 2, max: 20 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Total Duration (minutes)"
                type="number"
                value={totalTime}
                onChange={(e) => setTotalTime(parseInt(e.target.value) || 0)}
                error={!!errors.totalTime}
                helperText={errors.totalTime}
                inputProps={{ min: 1, max: 1440 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Round Duration (minutes)"
                type="number"
                value={roundDuration}
                onChange={(e) => setRoundDuration(parseInt(e.target.value) || 0)}
                error={!!errors.roundDuration}
                helperText={errors.roundDuration}
                inputProps={{ min: 1 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Start Time (HH:MM)"
                value={startTimeStr}
                onChange={(e) => {
                  const value = e.target.value;
                  
                  // Prevent starting with colon
                  if (value.startsWith(':')) {
                    return;
                  }
                  
                  // Handle hour and minute validation
                  if (value.includes(':')) {
                    const [hours, minutes] = value.split(':');
                    
                    // Validate hours (0-23)
                    if (hours && parseInt(hours) > 23) {
                      return;
                    }
                    
                    // Validate minutes (0-59)
                    if (minutes && parseInt(minutes) > 59) {
                      return;
                    }
                    
                    // Ensure we don't get more than one digit after the colon until valid
                    if (minutes && minutes.length > 2) {
                      return;
                    }
                  } else {
                    // When no colon yet, limit to valid hour values
                    if (value && value.length > 0) {
                      const hourVal = parseInt(value);
                      if (hourVal > 23) {
                        return;
                      }
                    }
                  }
                  
                  // Allow empty string for clearing the field
                  if (value === '') {
                    setStartTimeStr(value);
                    return;
                  }
                  
                  // Only allow digits and one colon in the right format
                  if (/^([0-9]{1,2})(:[0-9]{0,2})?$/.test(value)) {
                    setStartTimeStr(value);
                  }
                }}
                error={!!errors.startTimeStr}
                helperText={errors.startTimeStr || "Format: HH:MM (e.g., 8:00, 14:30)"}
                inputProps={{ 
                  placeholder: "8:00",
                  inputMode: "numeric",
                  pattern: "^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$"
                }}
              />
            </Grid>
            
            {/* Add participant names input with optional groups */}
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel 
                  control={
                    <Switch 
                      checked={useCustomNames} 
                      onChange={(e) => setUseCustomNames(e.target.checked)} 
                    />
                  } 
                  label="Use custom participant names (groups are optional)" 
                />
              </FormGroup>
            </Grid>
            
            {useCustomNames && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={10}
                  label="Participant List"
                  value={participantInput}
                  onChange={(e) => setParticipantInput(e.target.value)}
                  error={!!errors.participantInput}
                  helperText={
                    errors.participantInput || 
                    "Enter one participant per line. To use groups (optional), add a comma or tab followed by the group name. Examples:\n" +
                    "Marjorie Gómez, IT\n" +
                    "Simone Kannapinn\t IT\n" +
                    "Or simply copy-paste from Excel (tab-separated)"
                  }
                  placeholder={`Rafael González, HR
Marjorie Gómez, IT
Fabiola Fajardo, HR
Simone Kannapinn, IT
Fraziska Hübner`}
                />
              </Grid>
            )}
            
            <Grid item xs={12}>
              <FormHelperText>
                This will generate a schedule with {Math.floor(totalTime / roundDuration)} rounds of networking.
                The file can be printed directly from your browser.
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={generateSchedule}
                disabled={isGenerating}
                fullWidth
                size="large"
                sx={{ mt: 2 }}
              >
                {isGenerating ? (
                  <>
                    <CircularProgress size={24} sx={{ mr: 1, color: 'white' }} />
                    Generating Schedule...
                  </>
                ) : (
                  "Generate Schedule and Download"
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      
      {/* Event Planning Calculator Section */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Box sx={{ mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <CalculateIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h5">Event Planning Calculator</Typography>
          </Box>
          <Typography variant="body1" paragraph color="text.secondary">
            Calculate optimal parameters for your networking event
          </Typography>
        </Box>
        
        {calcErrors.general && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {calcErrors.general}
          </Alert>
        )}
        
        <FormControl component="fieldset" sx={{ mb: 3 }}>
          <FormLabel component="legend">What would you like to calculate?</FormLabel>
          <RadioGroup
            row
            value={calculationMode}
            onChange={(e) => {
              setCalculationMode(e.target.value);
              setCalculationResult(null);
              setCoveragePercentage(null);
              setOptimalParameters(null);
              setCalcErrors({});
            }}
          >
            <FormControlLabel value="totalTime" control={<Radio />} label="Total Time Needed" />
            <FormControlLabel value="roundDuration" control={<Radio />} label="Round Duration" />
            <FormControlLabel value="tableSize" control={<Radio />} label="Optimal Table Size" />
            <FormControlLabel value="coverage" control={<Radio />} label="Connection Coverage" />
            <FormControlLabel 
              value="maxCoverage" 
              control={<Radio />} 
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <StarIcon sx={{ mr: 0.5, color: 'gold' }} fontSize="small" />
                  <span>Maximum Coverage</span>
                </Box>
              }
            />
          </RadioGroup>
        </FormControl>
        
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={calculationMode === "maxCoverage" ? 6 : 3}>
            <TextField
              fullWidth
              label="Number of Participants"
              type="number"
              value={calcNumberOfUsers}
              onChange={(e) => {
                setCalcNumberOfUsers(parseInt(e.target.value) || 0);
                setCalculationResult(null);
                setOptimalParameters(null);
              }}
              disabled={false}
              InputProps={{
                inputProps: { min: 2, max: 500 }
              }}
            />
          </Grid>
          
          {calculationMode !== "maxCoverage" && (
            <>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  label="People Per Table"
                  type="number"
                  value={calcPeoplePerTable}
                  onChange={(e) => {
                    setCalcPeoplePerTable(parseInt(e.target.value) || 0);
                    setCalculationResult(null);
                  }}
                  disabled={calculationMode === "tableSize"}
                  InputProps={{
                    inputProps: { min: 2, max: 20 }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  label="Total Time (minutes)"
                  type="number"
                  value={calcTotalTime}
                  onChange={(e) => {
                    setCalcTotalTime(parseInt(e.target.value) || 0);
                    setCalculationResult(null);
                  }}
                  disabled={calculationMode === "totalTime"}
                  InputProps={{
                    inputProps: { min: 1, max: 1440 }
                  }}
                  helperText={calculationMode !== "totalTime" ? "Must be ≥ round duration" : ""}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  label="Round Duration (minutes)"
                  type="number"
                  value={calcRoundDuration}
                  onChange={(e) => {
                    setCalcRoundDuration(parseInt(e.target.value) || 0);
                    setCalculationResult(null);
                  }}
                  disabled={calculationMode === "roundDuration"}
                  InputProps={{
                    inputProps: { min: 1, max: 60 }
                  }}
                  helperText={calculationMode !== "roundDuration" ? "Must be ≤ total time" : ""}
                />
              </Grid>
            </>
          )}
          
          {calculationMode === "maxCoverage" && (
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                fullWidth
                label="Total Available Time (minutes)"
                type="number"
                value={calcTotalTime}
                onChange={(e) => {
                  setCalcTotalTime(parseInt(e.target.value) || 0);
                  setCalculationResult(null);
                  setOptimalParameters(null);
                }}
                InputProps={{
                  inputProps: { min: 5, max: 1440 }
                }}
                helperText="The total duration of your event"
              />
            </Grid>
          )}
          
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={calculateMetrics}
              fullWidth
              sx={{ mt: 2 }}
            >
              Calculate
            </Button>
          </Grid>
        </Grid>
        
        {(calculationResult !== null || optimalParameters !== null) && (
          <Box sx={{ mt: 4 }}>
            <Divider sx={{ mb: 3 }} />
            <Typography variant="h6" gutterBottom>
              Calculation Results
            </Typography>
            
            {optimalParameters ? (
              // Results for Maximum Coverage mode
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Card variant="outlined">
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <InfoIcon sx={{ mr: 1, color: 'primary.main' }} />
                        <Typography variant="h6">
                          Optimal Parameters
                        </Typography>
                      </Box>
                      
                      <List dense>
                        <ListItem>
                          <ListItemText 
                            primary={
                              <Typography variant="body1">
                                <strong>Table Size:</strong> {optimalParameters.tableSize} people per table
                              </Typography>
                            } 
                            secondary="The optimal number of people to place at each table"
                          />
                        </ListItem>
                        
                        <ListItem>
                          <ListItemText 
                            primary={
                              <Typography variant="body1">
                                <strong>Round Duration:</strong> {optimalParameters.roundDuration} minutes
                              </Typography>
                            } 
                            secondary="The optimal time to spend in each round"
                          />
                        </ListItem>
                        
                        <ListItem>
                          <ListItemText 
                            primary={
                              <Typography variant="body1">
                                <strong>Number of Rounds:</strong> {optimalParameters.rounds}
                              </Typography>
                            } 
                            secondary="How many networking rounds will occur"
                          />
                        </ListItem>
                        
                        <ListItem>
                          <ListItemText 
                            primary={
                              <Typography variant="body1">
                                <strong>Network Completion:</strong> {optimalParameters.coverage}%
                              </Typography>
                            } 
                            secondary="Percentage of all possible connections that will be made"
                          />
                        </ListItem>
                      </List>
                      
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                        With these optimal parameters, each participant will meet the maximum number of other participants 
                        possible within your time constraints.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <Card variant="outlined">
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <PeopleIcon sx={{ mr: 1, color: 'primary.main' }} />
                        <Typography variant="h6">
                          Connection Statistics
                        </Typography>
                      </Box>
                      
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                        <Typography variant="body1" sx={{ mr: 1 }}>
                          Network Completion:
                        </Typography>
                        <Typography variant="h6" color="primary.main">
                          {coveragePercentage}%
                        </Typography>
                        <Tooltip title="This shows what percentage of all possible participant pairings will occur during the event. For example, with 20 people, there are 190 possible unique pairs (20 × 19 ÷ 2). If your event format allows for 95 of these connections to happen, that's a 50% network completion.">
                          <HelpIcon fontSize="small" sx={{ ml: 1, color: 'text.secondary' }} />
                        </Tooltip>
                      </Box>
                      
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                        <Typography variant="body1" sx={{ mr: 1 }}>
                          Total Connections Made:
                        </Typography>
                        <Typography variant="h6" color="primary.main">
                          {totalConnections}
                        </Typography>
                        <Tooltip title="The actual number of unique participant pairs that will interact during the event given the current parameters. Each unique pair of participants meeting counts as one connection.">
                          <HelpIcon fontSize="small" sx={{ ml: 1, color: 'text.secondary' }} />
                        </Tooltip>
                      </Box>
                      
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" sx={{ mr: 1 }}>
                          Maximum Possible Connections:
                        </Typography>
                        <Typography variant="h6" color="primary.main">
                          {maxConnections}
                        </Typography>
                        <Tooltip title="The theoretical maximum number of unique participant pairs possible with the given number of participants. This equals n × (n-1) ÷ 2, where n is the number of participants. For example, with 20 participants, there are 190 possible unique connections.">
                          <HelpIcon fontSize="small" sx={{ ml: 1, color: 'text.secondary' }} />
                        </Tooltip>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            ) : (
              // Results for other modes
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Card variant="outlined">
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <InfoIcon sx={{ mr: 1, color: 'primary.main' }} />
                        <Typography variant="h6">
                          {calculationMode === "totalTime" && "Recommended Total Time"}
                          {calculationMode === "roundDuration" && "Recommended Round Duration"}
                          {calculationMode === "tableSize" && "Recommended Table Size"}
                          {calculationMode === "coverage" && "Connection Coverage"}
                        </Typography>
                      </Box>
                      
                      <Typography variant="h4" color="primary.main" sx={{ mb: 1 }}>
                        {calculationMode === "totalTime" && `${calculationResult} minutes`}
                        {calculationMode === "roundDuration" && `${calculationResult} minutes per round`}
                        {calculationMode === "tableSize" && `${calculationResult} people per table`}
                        {calculationMode === "coverage" && `${calculationResult}%`}
                      </Typography>
                      
                      <Typography variant="body2" color="text.secondary">
                        {calculationMode === "totalTime" && `This is the minimum time needed for each participant to meet the maximum number of others given your constraints.`}
                        {calculationMode === "roundDuration" && `This is the optimal time per round to maximize connections within your total time budget.`}
                        {calculationMode === "tableSize" && `This table size optimizes the number of connections each participant can make.`}
                        {calculationMode === "coverage" && `With these parameters, participants will meet approximately ${calculationResult}% of all other participants.`}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <Card variant="outlined">
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <PeopleIcon sx={{ mr: 1, color: 'primary.main' }} />
                        <Typography variant="h6">
                          Connection Statistics
                        </Typography>
                      </Box>
                      
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                        <Typography variant="body1" sx={{ mr: 1 }}>
                          Network Completion:
                        </Typography>
                        <Typography variant="h6" color="primary.main">
                          {coveragePercentage}%
                        </Typography>
                        <Tooltip title="This shows what percentage of all possible participant pairings will occur during the event. For example, with 20 people, there are 190 possible unique pairs (20 × 19 ÷ 2). If your event format allows for 95 of these connections to happen, that's a 50% network completion.">
                          <HelpIcon fontSize="small" sx={{ ml: 1, color: 'text.secondary' }} />
                        </Tooltip>
                      </Box>
                      
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                        <Typography variant="body1" sx={{ mr: 1 }}>
                          Total Connections Made:
                        </Typography>
                        <Typography variant="h6" color="primary.main">
                          {totalConnections}
                        </Typography>
                        <Tooltip title="The actual number of unique participant pairs that will interact during the event given the current parameters. Each unique pair of participants meeting counts as one connection.">
                          <HelpIcon fontSize="small" sx={{ ml: 1, color: 'text.secondary' }} />
                        </Tooltip>
                      </Box>
                      
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" sx={{ mr: 1 }}>
                          Maximum Possible Connections:
                        </Typography>
                        <Typography variant="h6" color="primary.main">
                          {maxConnections}
                        </Typography>
                        <Tooltip title="The theoretical maximum number of unique participant pairs possible with the given number of participants. This equals n × (n-1) ÷ 2, where n is the number of participants. For example, with 20 participants, there are 190 possible unique connections.">
                          <HelpIcon fontSize="small" sx={{ ml: 1, color: 'text.secondary' }} />
                        </Tooltip>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            )}
          </Box>
        )}
      </Paper>
      
      <Box>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Context & Purpose</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" paragraph>
              <strong>What is Speed Networking?</strong> Speed networking is a structured meeting format designed to accelerate 
              business contacts and relationships. Similar to speed dating, it allows participants to interact with multiple 
              people in a single event, maximizing opportunities for meaningful connections.
            </Typography>
            
            <Typography variant="body2" paragraph>
              <strong>Event Format:</strong> Participants are organized into groups at tables. After each round (typically 10-20 minutes), 
              participants move to different tables according to their personalized schedules. This ensures everyone meets 
              the maximum number of new contacts during the event.
            </Typography>
            
            <Typography variant="body2" paragraph>
              <strong>Benefits:</strong> This format prevents clustering of the same people and ensures diverse interactions. 
              Each participant receives a personalized schedule showing exactly where to go for each round and who they'll meet, 
              eliminating confusion during transitions.
            </Typography>
          </AccordionDetails>
        </Accordion>
        
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Event Logistics & Checklist</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" paragraph>
              <strong>Room Setup:</strong>
            </Typography>
            <ul style={{ marginTop: '-8px' }}>
              <li>
                <Typography variant="body2">
                  Label each table with large, clearly visible numbers (1, 2, 3...)
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Arrange tables sequentially in the room to make them easy to find
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Provide name tags for each participant
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Position a visible timer or clock that all participants can see
                </Typography>
              </li>
            </ul>
            
            <Typography variant="body2" paragraph>
              <strong>Timing & Transitions:</strong>
            </Typography>
            <ul style={{ marginTop: '-8px' }}>
              <li>
                <Typography variant="body2">
                  Use a bell, whistle, or timer with sound (your phone?) to signal the end of each round
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Inform participants that each round is slightly shorter because 1–2 minutes are used for transitioning to the next table
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Give a 2-minute warning before each round ends
                </Typography>
              </li>
            </ul>
            
            <Typography variant="body2" paragraph>
              <strong>Refreshments & Comfort:</strong>
            </Typography>
            <ul style={{ marginTop: '-8px' }}>
              <li>
                <Typography variant="body2">
                  Provide water bottles at each table or a central water station
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Consider offering light snacks during a mid-event break
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Ensure good ventilation and comfortable room temperature
                </Typography>
              </li>
            </ul>
            
            <Typography variant="body2" paragraph>
              <strong>Materials & Communication:</strong>
            </Typography>
            <ul style={{ marginTop: '-8px' }}>
              <li>
                <Typography variant="body2">
                  Distribute personalized schedules to participants before the event
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Provide notepads and pens for taking notes during conversations
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Consider business card collection tools or digital alternatives
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Brief participants at the start about the process and expectations
                </Typography>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
        
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>How It Works</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" paragraph>
              <strong>Balanced Grouping:</strong> The algorithm first divides participants into balanced table groups. If the number 
              of participants isn't perfectly divisible by the table size, some tables may have one less person to ensure 
              fairness. Each person is assigned an initial table and a position within that table.
            </Typography>
            
            <Typography variant="body2" paragraph>
              <strong>Cyclic Rotation Algorithm:</strong> For each subsequent round, the system uses a mathematical formula to 
              determine new table assignments:
              <br />
              newTable = ((initialTable - 1) + (seatPosition - 1) × roundNumber) mod totalTables + 1
              <br />
              This ensures each participant meets a different set of people in each round, maximizing the number of unique connections.
            </Typography>
            
            <Typography variant="body2" paragraph>
              <strong>Time Slot Calculation:</strong> Based on the start time, round duration, and total event duration, 
              the system calculates precise time slots for each round (e.g., "8:00 - 8:15", "8:15 - 8:30").
            </Typography>
            
            <Typography variant="body2" paragraph>
              <strong>Output Generation:</strong> The system creates an HTML document with a separate page for each participant. 
              Each page shows that participant's complete schedule with time slots, table numbers, and the names of the people 
              they'll meet in each round. The document is designed to be easily printed or shared electronically.
            </Typography>
          </AccordionDetails>
        </Accordion>
        
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>How To Use This Tool</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" paragraph>
              <strong>Step 1:</strong> Enter the number of participants who will attend your event.
            </Typography>
            
            <Typography variant="body2" paragraph>
              <strong>Step 2:</strong> Specify how many people should be at each table (typically 3-6 works best).
            </Typography>
            
            <Typography variant="body2" paragraph>
              <strong>Step 3:</strong> Set the total duration of your networking event in minutes.
            </Typography>
            
            <Typography variant="body2" paragraph>
              <strong>Step 4:</strong> Define how long each networking round should last (typically 10-20 minutes).
            </Typography>
            
            <Typography variant="body2" paragraph>
              <strong>Step 5:</strong> Enter the start time of your event in 24-hour format (HH:MM).
            </Typography>
            
            <Typography variant="body2" paragraph>
              <strong>Step 6:</strong> Click "Generate Schedule and Download" to create and download the schedule document. The document will contain:
            </Typography>
            
            <Typography variant="body2" paragraph>
              • A personalized schedule for each participant (one per page)
            </Typography>
            
            <Typography variant="body2" paragraph>
              • A schedule for each table showing who will be there in each round (one per page)
            </Typography>
            
            <Typography variant="body2" paragraph>
              <strong>Step 7:</strong> Print the document and distribute each person's schedule, or send digitally in advance.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* Disclaimer and Feedback Section */}
      <Paper 
        elevation={2} 
        sx={{ 
          p: 3, 
          mt: 4,
          borderRadius: 2,
          background: theme => theme.palette.mode === 'dark' 
            ? 'rgba(66, 66, 66, 0.8)' 
            : 'rgba(255, 244, 229, 0.8)',
          borderLeft: theme => `4px solid ${theme.palette.warning.main}`
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ color: theme => theme.palette.warning.main }}>
          Disclaimer & Feedback
        </Typography>
        <Typography variant="body2" paragraph>
          While our scheduling algorithm aims to optimize participant interactions, it may not always produce perfect results 
          in all scenarios. The effectiveness depends on various factors such as the number of participants, tables, and time constraints.
        </Typography>
        <Typography variant="body2" paragraph>
          This tool operates entirely in your browser - no participant names, schedules, or other data are saved or transmitted 
          to any server. This ensures privacy but also means that generated schedules are temporary and should be saved locally 
          when generated.
        </Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>
          Please note the following limitations:
        </Typography>
        <ul>
          <li>The algorithm may not achieve 100% interaction coverage with all possible combinations of parameters</li>
          <li>Generated schedules are not persisted - download and save them immediately after generation</li>
          <li>For very large groups (100+ participants), the generation process may take several seconds</li>
          <li>The tool does not account for specific seating arrangements or special requirements</li>
          <li>Manual adjustments to the generated schedule may be needed for specific constraints</li>
        </ul>
        <Box sx={{ mt: 3, pt: 2, borderTop: 1, borderColor: 'divider' }}>
          <Typography variant="body2">
            Have suggestions for improvements? We'd love to hear from you! Send your feedback to:{' '}
            <MuiLink href="mailto:admin@yeysus.com" target="_blank" rel="noopener">
              admin@yeysus.com
            </MuiLink>
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default SpeedNetworkingSchedulerPage; 