import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

export const useAuth = () => {
  const context = useContext(AuthContext);
  
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  const isDPOorSuperuser = () => {
    if (!context.user) return false;
    return context.user.roles?.some(userRole => 
      userRole.role.name === 'Data Protection Officer' || 
      userRole.role.name === 'Superuser'
    ) ?? false;
  };

  return {
    ...context,
    isDPOorSuperuser,
  };
}; 