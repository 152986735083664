import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Alert,
  CircularProgress
} from '@mui/material';
import { api } from '../services/api';

const VerifyEmailPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const verifyEmail = async () => {
      if (!token) {
        setStatus('error');
        setMessage('Missing verification token');
        return;
      }

      try {
        const response = await api.get(`/auth/verify-email/${token}`);
        setStatus('success');
        setMessage(response.data.message);
        
        // Redirect after successful verification
        setTimeout(() => {
          if (response.data.redirectTo) {
            navigate(response.data.redirectTo);
          } else {
            navigate('/login');
          }
        }, 3000);
      } catch (error: any) {
        setStatus('error');
        setMessage(error.response?.data?.message || 'Failed to verify email');
      }
    };

    verifyEmail();
  }, [token, navigate]);

  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      minHeight: '100vh',
      p: 2 
    }}>
      <Paper sx={{ p: 4, maxWidth: 400, width: '100%' }}>
        <Typography variant="h5" gutterBottom>
          Email Verification
        </Typography>

        {status === 'loading' && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
            <CircularProgress />
          </Box>
        )}

        {status === 'success' && (
          <Alert severity="success">
            {message}
            <Typography variant="body2" sx={{ mt: 1 }}>
              You will be redirected shortly...
            </Typography>
          </Alert>
        )}

        {status === 'error' && (
          <Alert severity="error">
            {message}
          </Alert>
        )}
      </Paper>
    </Box>
  );
};

export default VerifyEmailPage; 