import { useState } from 'react';
import { Box, TextField, Button } from '@mui/material';
import { api } from '../services/api';
import { Comment } from '../types';

interface ReplyFormProps {
  commentId: string;
  onReplyAdded: (reply: Comment) => void;
  onCancel: () => void;
}

export const ReplyForm = ({ commentId, onReplyAdded, onCancel }: ReplyFormProps) => {
  const [content, setContent] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!content.trim()) return;

    setIsSubmitting(true);
    try {
      const response = await api.post(`/comments/${commentId}/reply`, { 
        content,
        notify: true
      });
      onReplyAdded(response.data);
      setContent('');
      onCancel();
    } catch (error) {
      console.error('Failed to add reply:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, ml: 4 }}>
      <TextField
        fullWidth
        multiline
        rows={2}
        placeholder="Write a reply..."
        value={content}
        onChange={(e) => setContent(e.target.value)}
        variant="outlined"
        size="small"
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, gap: 1 }}>
        <Button 
          size="small"
          onClick={onCancel}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button 
          type="submit" 
          variant="contained" 
          disabled={!content.trim() || isSubmitting}
          size="small"
        >
          Reply
        </Button>
      </Box>
    </Box>
  );
}; 