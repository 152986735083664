import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  IconButton,
  FormHelperText,
  Dialog,
  Alert,
  CircularProgress
} from '@mui/material';
import { PhotoCamera, Delete as DeleteIcon } from '@mui/icons-material';
import { ImageCropper } from './';
import { validateImage } from '../../utils/imageValidation';
import { ImageType, getImageConfigCached } from './constants';
import type { ImageConfig } from './types';

interface ImageUploaderProps {
  onImageSelect: (file: File) => void;
  onImageRemove: () => void;
  imagePreview?: string;
  imageType: ImageType;
  aspectRatio?: number;
  isLoading?: boolean;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  onImageSelect,
  onImageRemove,
  imagePreview,
  imageType,
  aspectRatio,
  isLoading = false
}) => {
  const [error, setError] = useState<string | null>(null);
  const [warning, setWarning] = useState<string | null>(null);
  const [showCropper, setShowCropper] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [config, setConfig] = useState<ImageConfig | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    getImageConfigCached(imageType).then(setConfig);
  }, [imageType]);

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !config) return;

    try {
      const validationResult = await validateImage(file, config);
      if (validationResult.isValid) {
        setSelectedFile(file);
        setShowCropper(true);
        setError(null);
        setWarning(validationResult.warning || null);
      } else {
        setError(validationResult.message || 'Invalid image file');
        setWarning(null);
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Invalid image file');
      setWarning(null);
    }
  };

  const handleCropComplete = async (croppedImageBlob: Blob) => {
    setShowCropper(false);
    
    if (!selectedFile) return;

    const croppedFile = new File([croppedImageBlob], selectedFile.name, {
      type: 'image/jpeg'
    });
    
    onImageSelect(croppedFile);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <input
          accept={config?.allowedTypes.join(',')}
          type="file"
          hidden
          ref={fileInputRef}
          onChange={handleFileSelect}
          disabled={isLoading}
        />
        <Button
          variant="outlined"
          component="span"
          startIcon={isLoading ? <CircularProgress size={18} /> : <PhotoCamera />}
          onClick={() => fileInputRef.current?.click()}
          disabled={isLoading}
        >
          {isLoading ? 'Uploading...' : 'Upload Image'}
        </Button>
        {imagePreview && (
          <Box sx={{ position: 'relative', width: 100, height: 100 }}>
            <img
              src={`${imagePreview}${imagePreview.includes('?') ? '&' : '?'}t=${Date.now()}`}
              alt="Preview"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: 4
              }}
            />
            <IconButton
              size="small"
              sx={{
                position: 'absolute',
                top: -8,
                right: -8,
                backgroundColor: 'background.paper',
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
              }}
              onClick={onImageRemove}
              disabled={isLoading}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      {error && <FormHelperText error>{error}</FormHelperText>}
      {warning && (
        <Alert severity="info" sx={{ mt: 1 }}>
          {warning}
        </Alert>
      )}
      {config && (
        <FormHelperText>
          Supported formats: {config.allowedTypes.map(t => t.split('/')[1].toUpperCase()).join(', ')}
          {' '}(max {config.maxSize / (1024 * 1024)}MB).
          {' '}Recommended dimensions: {config.recommendedDimensions || `${config.minWidth}x${config.minHeight}px`}
        </FormHelperText>
      )}

      <Dialog
        open={showCropper}
        onClose={() => setShowCropper(false)}
        maxWidth="md"
        fullWidth
      >
        {selectedFile && (
          <ImageCropper
            image={URL.createObjectURL(selectedFile)}
            onCropComplete={handleCropComplete}
            onCancel={() => {
              setShowCropper(false);
              setSelectedFile(null);
            }}
            aspectRatio={aspectRatio}
            config={config || undefined}
          />
        )}
      </Dialog>
    </>
  );
};

export default ImageUploader; 