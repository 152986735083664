import React, { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Paper,
  Link,
  CircularProgress
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { LoginCredentials } from '../../types';

const LoginForm: React.FC = () => {
  const { login, resendVerification } = useAuth();
  const navigate = useNavigate();
  const { register, handleSubmit: handleFormSubmit, formState: { errors } } = useForm<LoginCredentials>();
  const [error, setError] = useState<string | null>(null);
  const [showVerificationDialog, setShowVerificationDialog] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [resendSuccess, setResendSuccess] = useState(false);
  const [currentEmail, setCurrentEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: LoginCredentials) => {
    setError(null);
    setIsLoading(true);
    try {
      setCurrentEmail(data.email);
      const response = await login(data.email, data.password);
      
      // Check if user's email is verified
      if (response && response.user && !response.user.is_email_verified) {
        navigate('/verification-pending', { replace: true });
        return;
      }
      
      navigate('/', { replace: true });
    } catch (err: any) {
      if (err.message === 'UNVERIFIED_EMAIL') {
        setShowVerificationDialog(true);
      } else {
        setError(err.response?.data?.message || 'An error occurred during login');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendVerification = async () => {
    try {
      setIsResending(true);
      await resendVerification(currentEmail);
      setResendSuccess(true);
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to resend verification email');
    } finally {
      setIsResending(false);
    }
  };

  return (
    <Box
      component={Paper}
      sx={{
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        maxWidth: 400,
        mx: 'auto',
        position: 'relative'
      }}
    >
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'rgba(255, 255, 255, 0.7)',
            zIndex: 1
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Typography variant="h5" component="h1" gutterBottom>
        Login to TrueConn
      </Typography>

      {error && <Alert severity="error">{error}</Alert>}
      
      <form onSubmit={handleFormSubmit(onSubmit)}>
        <TextField
          fullWidth
          label="Email"
          margin="normal"
          {...register('email', { 
            required: 'Email is required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address'
            }
          })}
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        
        <TextField
          fullWidth
          type="password"
          label="Password"
          margin="normal"
          {...register('password', { 
            required: 'Password is required',
            minLength: {
              value: 6,
              message: 'Password must be at least 6 characters'
            }
          })}
          error={!!errors.password}
          helperText={errors.password?.message}
        />

        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={isLoading}
          sx={{ mt: 2 }}
        >
          {isLoading ? 'Logging in...' : 'Login'}
        </Button>
      </form>

      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <Link
          component={RouterLink}
          to="/forgot-password"
          sx={{
            color: 'text.primary',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline'
            }
          }}
        >
          Forgot your password?
        </Link>
      </Box>

      <Button
        color="secondary"
        onClick={() => navigate('/register')}
        disabled={isLoading}
      >
        Don't have an account? Register
      </Button>

      <Dialog open={showVerificationDialog} onClose={() => setShowVerificationDialog(false)}>
        <DialogTitle>Email Verification Required</DialogTitle>
        <DialogContent>
          <Typography>
            Your email address needs to be verified before you can log in.
            Would you like us to send you a new verification email?
          </Typography>
          {resendSuccess && (
            <Alert severity="success" sx={{ mt: 2 }}>
              Verification email has been resent. Please check your inbox.
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowVerificationDialog(false)}>
            Close
          </Button>
          <Button 
            onClick={handleResendVerification}
            variant="contained"
            color="primary"
            disabled={isResending || resendSuccess}
          >
            {isResending ? 'Sending...' : 'Resend Verification Email'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LoginForm;