import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Paper,
  Box,
  Chip,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import {
  Event as EventIcon,
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Notifications as NotificationsIcon,
} from '@mui/icons-material';
import { format } from 'date-fns';
import { Interaction, InteractionType } from '../../types/contact';
import { fetchContactInteractions, deleteInteraction } from '../../services/contacts';
import InteractionForm from './InteractionForm';

interface InteractionListProps {
  contactId: string;
  onInteractionAdded?: () => void;
}

const getInteractionIcon = (type: InteractionType) => {
  // You can customize this based on your interaction types
  return <EventIcon />;
};

const InteractionList: React.FC<InteractionListProps> = ({ contactId, onInteractionAdded }) => {
  const [interactions, setInteractions] = useState<Interaction[]>([]);
  const [loading, setLoading] = useState(true);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedInteraction, setSelectedInteraction] = useState<Interaction | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  useEffect(() => {
    loadInteractions();
  }, [contactId]);

  const loadInteractions = async () => {
    try {
      setLoading(true);
      const data = await fetchContactInteractions(contactId);
      setInteractions(data);
    } catch (error) {
      console.error('Error loading interactions:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, interaction: Interaction) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedInteraction(interaction);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedInteraction(null);
  };

  const handleEdit = () => {
    setIsEditDialogOpen(true);
    handleMenuClose();
  };

  const handleDelete = async () => {
    if (selectedInteraction && window.confirm('Are you sure you want to delete this interaction?')) {
      try {
        await deleteInteraction(selectedInteraction.id);
        await loadInteractions();
        if (onInteractionAdded) {
          onInteractionAdded();
        }
      } catch (error) {
        console.error('Error deleting interaction:', error);
      }
    }
    handleMenuClose();
  };

  const handleInteractionSubmit = async () => {
    setIsEditDialogOpen(false);
    await loadInteractions();
    if (onInteractionAdded) {
      onInteractionAdded();
    }
  };

  if (loading) {
    return <Typography>Loading interactions...</Typography>;
  }

  if (interactions.length === 0) {
    return (
      <Paper sx={{ p: 3, textAlign: 'center' }}>
        <Typography color="text.secondary">No interactions recorded yet.</Typography>
      </Paper>
    );
  }

  return (
    <>
      <List>
        {interactions.map((interaction) => (
          <ListItem
            key={interaction.id}
            sx={{
              mb: 2,
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
            }}
          >
            <ListItemIcon>
              {getInteractionIcon(interaction.type)}
            </ListItemIcon>
            <ListItemText
              primary={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="subtitle1">
                    {interaction.subject}
                  </Typography>
                  <Chip
                    size="small"
                    label={interaction.type.replace(/_/g, ' ')}
                    color="primary"
                    variant="outlined"
                  />
                  {interaction.follow_up_needed && (
                    <Chip
                      size="small"
                      icon={<NotificationsIcon />}
                      label={`Follow-up: ${format(new Date(interaction.follow_up_date!), 'MMM d, yyyy')}`}
                      color="warning"
                    />
                  )}
                </Box>
              }
              secondary={
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    {format(new Date(interaction.date), 'MMM d, yyyy h:mm a')}
                    {interaction.location && ` • ${interaction.location}`}
                  </Typography>
                  {interaction.description && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 1 }}
                    >
                      {interaction.description}
                    </Typography>
                  )}
                  {interaction.outcome && (
                    <Typography
                      variant="body2"
                      sx={{ mt: 1 }}
                    >
                      <strong>Outcome:</strong> {interaction.outcome}
                    </Typography>
                  )}
                </Box>
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                onClick={(event) => handleMenuOpen(event, interaction)}
              >
                <MoreVertIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>

      <Dialog
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Interaction</DialogTitle>
        <DialogContent>
          <InteractionForm
            contactId={contactId}
            initialData={selectedInteraction || undefined}
            onSubmit={handleInteractionSubmit}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InteractionList;
