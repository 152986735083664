import { Box, Typography, Container, Divider } from '@mui/material';

const TermsPage = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        {/* Terms of Use Section */}
        <Typography variant="h4" gutterBottom>
          Terms of Use
        </Typography>
        
        <Typography variant="body1" paragraph>
          Last updated: March 1, 2025
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          1. Be Respectful
        </Typography>
        <Typography variant="body1" paragraph>
          We are all equal. Regardless of race, religion, gender, sexual orientation, or anything else. We have a Zero Tolerance rule here. You must respect everybody. Treat others the way you want to be treated.
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          2. Be Careful
        </Typography>
        <Typography variant="body1" paragraph>
          This server is in the Internet, not in your company Intranet, nor in a controlled environment. You may receive Direct Messages, DM, from people that you do not know. Be very careful, and NEVER EVER click on a link from somebody you do not know. People may try to phish your information. Always remember, a friend of yours or a colleague may have been hacked. Therefore: Be always very careful before following any recommendation or clicking any link. And for the Crypto enthusiasts here: NEVER connect your wallet to a site you do not know. Even if you need something from a well-known website, disconnect your wallet as soon as you finished using it. Always Do Your Own Research, DYOR.
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          3. No Inappropriate Language
        </Typography>
        <Typography variant="body1" paragraph>
          The use of profanity should be kept to a minimum. Better avoid it. Any derogatory language towards any person is strictly prohibited.
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          4. No Spamming
        </Typography>
        <Typography variant="body1" paragraph>
          Do not send a lot of small messages right after each other. Do not disrupt posts or comments by spamming.
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          5. No Inappropriate Content
        </Typography>
        <Typography variant="body1" paragraph>
          This is a community server and not meant to share inappropriate material. The following content is strictly forbidden:
        </Typography>
        <Box component="ul" sx={{ pl: 4, mb: 2 }}>
          <li>Pornographic/adult/NSFW material</li>
          <li>Illegal or pirated content</li>
          <li>False or misleading information</li>
          <li>Hacks, cheats, or unauthorized tools</li>
          <li>Viruses or malicious software</li>
          <li>Counterfeit or fraudulent items</li>
          <li>Stolen financial information</li>
          <li>Unauthorized software keys</li>
          <li>Illegal substances</li>
          <li>Ponzi or pump-and-dump schemes</li>
        </Box>
        
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          6. Profile Standards
        </Typography>
        <Typography variant="body1" paragraph>
          You will be asked to change your name or profile picture (PFP) if the Administrators deem them inappropriate.
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          7. No Threats
        </Typography>
        <Typography variant="body1" paragraph>
          Threats to other users of DDoS, Death, DoX, abuse, and other malicious threats are absolutely prohibited and disallowed.
        </Typography>
        
        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          8. Positive Community
        </Typography>
        <Typography variant="body1" paragraph>
          This is the place to experience how to create a community, and at the same time to learn, experiment, network with brilliant innovative minds. Toxicity is not desired. Administrators will remove any such comments and ban from the server people that do not follow these rules.
        </Typography>

        <Divider sx={{ my: 6 }} />

        {/* Privacy Policy Section */}
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          1. Data Protection Overview
        </Typography>
        <Typography variant="body1" paragraph>
          We take the protection of your personal data very seriously. We process your data in accordance with the EU General Data Protection Regulation (GDPR) and the German Federal Data Protection Act (BDSG). This privacy policy informs you about how we collect and process your personal data when you use our platform.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          2. Data Controller
        </Typography>
        <Typography variant="body1" paragraph>
          Data Protection Officer: Dr. Jesus del Valle<br />
          Sonatenweg 10, 13158 Berlin, Germany<br />
          Email: admin@yeysus.com<br />
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          3. Analytics and Cookies
        </Typography>
        <Typography variant="body1" paragraph>
          We use Matomo (formerly Piwik) for web analytics, hosted on our own servers within the EU. This ensures that your data never leaves our control and is not shared with third parties. We collect and process the following analytics data:
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Analytics Data Collection:
        </Typography>
        <Box component="ul" sx={{ pl: 4, mb: 2 }}>
          <li>Page views (anonymized)</li>
          <li>Feature usage statistics</li>
          <li>Search and filter usage (without search terms)</li>
          <li>Error occurrences</li>
          <li>Content engagement metrics</li>
        </Box>

        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Analytics Configuration:
        </Typography>
        <Box component="ul" sx={{ pl: 4, mb: 2 }}>
          <li>Respect "Do Not Track" browser settings</li>
          <li>Anonymize IP addresses</li>
          <li>Store data only on EU-based servers</li>
          <li>Delete logs after 90 days</li>
          <li>Require explicit consent for non-essential cookies</li>
        </Box>

        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Use of Analytics Data:
        </Typography>
        <Box component="ul" sx={{ pl: 4, mb: 2 }}>
          <li>Improve user experience</li>
          <li>Fix errors and technical issues</li>
          <li>Optimize site performance</li>
          <li>Enhance feature functionality</li>
        </Box>

        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          4. Data We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect and process the following data:
        </Typography>
        <Box component="ul" sx={{ pl: 4, mb: 2 }}>
          <li>Account information (email, name)</li>
          <li>Profile data you choose to share</li>
          <li>Posts and interactions</li>
          <li>Usage data (anonymized)</li>
        </Box>

        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          5. Legal Basis for Processing
        </Typography>
        <Typography variant="body1" paragraph>
          We process your data based on:
        </Typography>
        <Box component="ul" sx={{ pl: 4, mb: 2 }}>
          <li>Your consent (Art. 6(1)(a) GDPR)</li>
          <li>Contract fulfillment (Art. 6(1)(b) GDPR)</li>
          <li>Legal obligations (Art. 6(1)(c) GDPR)</li>
          <li>Legitimate interests (Art. 6(1)(f) GDPR)</li>
        </Box>

        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          6. Your Rights
        </Typography>
        <Typography variant="body1" paragraph>
          Under GDPR/DSGVO, you have the following rights:
        </Typography>
        <Box component="ul" sx={{ pl: 4, mb: 2 }}>
          <li>Right to access your data</li>
          <li>Right to rectification</li>
          <li>Right to erasure ("right to be forgotten")</li>
          <li>Right to restrict processing</li>
          <li>Right to data portability</li>
          <li>Right to object</li>
          <li>Right to withdraw consent</li>
        </Box>

        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          7. Data Security
        </Typography>
        <Typography variant="body1" paragraph>
          We implement appropriate technical and organizational measures to protect your data, including:
        </Typography>
        <Box component="ul" sx={{ pl: 4, mb: 2 }}>
          <li>SSL/TLS encryption</li>
          <li>Regular security assessments</li>
          <li>Access controls and authentication</li>
          <li>Data minimization practices</li>
        </Box>

        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          8. Data Retention
        </Typography>
        <Typography variant="body1" paragraph>
          We retain your data only as long as necessary for the purposes described or as required by law. Analytics data is automatically deleted after 90 days.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          9. Third-Party Services
        </Typography>
        <Typography variant="body1" paragraph>
          We use the following self-hosted services:
        </Typography>
        <Box component="ul" sx={{ pl: 4, mb: 2 }}>
          <li>Matomo Analytics (self-hosted)</li>
          <li>MinIO for image storage (self-hosted)</li>
        </Box>

        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          10. GDPR Compliance
        </Typography>
        <Box component="ul" sx={{ pl: 4, mb: 2 }}>
          <li>All IPs are anonymized</li>
          <li>No personal data is collected without consent</li>
          <li>Data is stored within the EU</li>
          <li>You can opt-out at any time</li>
          <li>Data is automatically deleted after retention period</li>
          <li>Regular privacy impact assessments</li>
          <li>Data processing agreements in place</li>
          <li>Privacy by design and default</li>
        </Box>

        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          11. Contact for Privacy Concerns
        </Typography>
        <Typography variant="body1" paragraph>
          For any privacy-related concerns or to exercise your rights, please contact our Data Protection Officer at admin@yeysus.com.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsPage; 