 interface TrackEventParams {
  category: string;
  action: string;
  name?: string;
  value?: number;
}

export const useMatomo = () => {
  const trackEvent = ({ category, action, name, value }: TrackEventParams) => {
    if (window._paq) {
      window._paq.push(['trackEvent', category, action, name, value]);
    }
  };

  const trackPageView = (customTitle?: string) => {
    if (window._paq) {
      if (customTitle) {
        window._paq.push(['setDocumentTitle', customTitle]);
      }
      window._paq.push(['trackPageView']);
    }
  };

  return {
    trackEvent,
    trackPageView
  };
};