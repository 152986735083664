import { Card, CardContent, Box, Typography, Link, Avatar } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { User } from '../types';
import { getImageUrl } from '../utils/imageUtils';
import { useState, useEffect, useRef } from 'react';

interface SimpleHorizontalUserCardProps {
  user: User;
}

const SimpleHorizontalUserCard = ({ user }: SimpleHorizontalUserCardProps) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const [isInView, setIsInView] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsInView(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => observer.disconnect();
  }, []);

  // Load image URL only when component is in view
  useEffect(() => {
    if (isInView && user.profile_image_url) {
      setImageUrl(getImageUrl(user.profile_image_url));
    }
  }, [isInView, user.profile_image_url]);

  /*
  console.log('User data in card:', {
    id: user.id,
    profile_image_url: user.profile_image_url,
    first_name: user.firstName,
    last_name: user.lastName
  });
  */

  return (
    <Card ref={cardRef} sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
      <Avatar
        src={imageUrl}
        alt={`${user.firstName} ${user.lastName}`}
        imgProps={{ loading: 'lazy' }}
        sx={{ 
          width: 80, 
          height: 80, 
          m: 2,
          bgcolor: 'primary.main' 
        }}
      >
        {user.firstName?.[0]}{user.lastName?.[0]}
      </Avatar>
      <CardContent sx={{ flex: 1 }}>
        <Typography 
          component={RouterLink} 
          to={`/profile/${user.id}`}
          variant="h6" 
          sx={{ 
            color: 'text.primary',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline'
            }
          }}
        >
          {user.firstName} {user.lastName}
        </Typography>
        {/* Position and Company section */}
        {(user.position || user.company) && (
          <Typography color="text.secondary">
            {user.position && (
              <Link
                component={RouterLink}
                to={`/search?position=${encodeURIComponent(user.position)}`}
                sx={{ textDecoration: 'none' }}
              >
                {user.position}
              </Link>
            )}
            {user.position && user.company && ' at '}
            {user.company && (
              <Link
                component={RouterLink}
                to={`/search?company=${encodeURIComponent(user.company)}`}
              >
                {user.company}
              </Link>
            )}
          </Typography>
        )}
        <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
          {/* Location section */}
          {(user.city || user.country) && (
            <Typography>
              {user.city && (
                <>
                  <Link
                    component={RouterLink}
                    to={`/search?city=${encodeURIComponent(user.city)}`}
                  >
                    {user.city}
                  </Link>
                  {user.country && ', '}
                </>
              )}
              {user.country && (
                <Link
                  component={RouterLink}
                  to={`/search?country=${encodeURIComponent(user.country)}`}
                >
                  {user.country}
                </Link>
              )}
            </Typography>
          )}
        </Box>
        {user.short_description && (
          <Typography variant="body2" color="text.secondary">
            {user.short_description}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default SimpleHorizontalUserCard;