import { useState, useEffect } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { api } from '../services/api';
import { Organisation } from '../types';
import { useQuery } from '@tanstack/react-query';

interface OrganisationSelectorProps {
  selectedOrganisation: Organisation | null;
  onOrganisationChange: (org: Organisation | null) => void;
}

export const OrganisationSelector = ({ 
  selectedOrganisation, 
  onOrganisationChange 
}: OrganisationSelectorProps) => {
  const [inputValue, setInputValue] = useState('');

  const { data: options = [], isLoading } = useQuery({
    queryKey: ['organisations', inputValue],
    queryFn: async () => {
      const response = await api.get('/organisations/search', {
        params: { q: inputValue || '' }
      });
      return response.data;
    },
    enabled: true,
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  });

  return (
    <Autocomplete
      value={selectedOrganisation}
      onChange={(_, newValue) => {
        onOrganisationChange(newValue);
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Company"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}; 