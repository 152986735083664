import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../services/api';
import { ContentType, EngagementType } from '../types';

interface CreateEngagementParams {
  contentType: ContentType;
  contentId: string;
  type: EngagementType;
  reactionType?: string;
  isPrivate?: boolean;
}

export const useEngagement = () => {
  const queryClient = useQueryClient();

  const createEngagement = useMutation({
    mutationFn: async (params: CreateEngagementParams) => {
      const response = await api.post('/engagements', params);
      return response.data;
    },
    onSuccess: () => {
      // Invalidate relevant queries
      queryClient.invalidateQueries({ queryKey: ['posts'] });
      queryClient.invalidateQueries({ queryKey: ['comments'] });
      queryClient.invalidateQueries({ queryKey: ['announcements'] });
    },
  });

  const deleteEngagement = useMutation({
    mutationFn: async (engagementId: string) => {
      await api.delete(`/engagements/${engagementId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['posts'] });
      queryClient.invalidateQueries({ queryKey: ['comments'] });
      queryClient.invalidateQueries({ queryKey: ['announcements'] });
    },
  });

  return {
    createEngagement,
    deleteEngagement,
  };
}; 