import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "./ConnectedLunch.css";
import { useQuery, useMutation } from '@tanstack/react-query';
import { format } from 'date-fns';
import { connectedLunchService, PotentialMatch } from '../../services/connectedLunch.service';
import { Wheel } from './Wheel';
import { useAuth } from '../../contexts/AuthContext';
import { LocationSelector } from '../../components/LocationSelector';
import { City, Country } from '../../types';
import { api } from '../../services/api';
import { connectedLunchService as connectedLunchServiceAlias } from '@/services/connectedLunch.service';

interface Registration {
  date: string;
  hasOthers: boolean;
  userRegistered: boolean;
  lunchId: string;
  matchStatus?: 'PENDING' | 'ACCEPTED' | 'REJECTED';
}

const ConnectedLunchPage: React.FC = () => {
  const { user } = useAuth();
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [mealType, setMealType] = useState<'LUNCH' | 'DINNER'>('LUNCH');
  const [selectedCity, setSelectedCity] = useState<City | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [showWheel, setShowWheel] = useState(false);
  const [matches, setMatches] = useState<PotentialMatch[]>([]);
  const [selectedMatch, setSelectedMatch] = useState<PotentialMatch | null>(null);
  const [registrationId, setRegistrationId] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error' | 'info';
  }>({
    open: false,
    message: '',
    severity: 'info'
  });
  const [activeRegistration, setActiveRegistration] = useState<string | null>(null);
  const [registrations, setRegistrations] = useState<Registration[]>([]);

  // Fetch current user profile
  const { data: profileData } = useQuery({
    queryKey: ['userProfile', user?.id],
    queryFn: async () => {
      if (!user?.id) return null;
      const response = await api.get('/users/me');
      return response.data;
    },
    enabled: !!user?.id
  });

  // Set city and country when profile data is available
  useEffect(() => {
    if (profileData) {
      setSelectedCity(profileData.city || null);
      setSelectedCountry(profileData.country || null);
    }
  }, [profileData]);

  useEffect(() => {
    loadRegistrations();
  }, [selectedCity, mealType]);

  const loadRegistrations = async () => {
    if (!selectedCity) return [];

    try {
      setRegistrations([]);
      
      const data = await connectedLunchServiceAlias.getRegistrations(
        selectedCity.id,
        mealType
      );
      
      const formattedData = data.map(reg => ({
        ...reg,
        date: new Date(reg.date).toISOString(),
      }));
      
      setRegistrations(formattedData);
      return formattedData;
      
    } catch (error) {
      showMessage('Failed to load registrations', 'error');
      return [];
    }
  };

  const handleDayClick = async (day: Date) => {
    console.log('handleDayClick called with date:', day);
    const localDate = new Date(day.getTime() - day.getTimezoneOffset() * 60000);
    
    if (!selectedCity) {
      showMessage('Please select a city first', 'error');
      return;
    }

    // Clear any stale state
    setSelectedMatch(null);

    const registration = registrations.find(reg => {
      const regDate = new Date(reg.date);
      return regDate.toISOString().split('T')[0] === localDate.toISOString().split('T')[0];
    });

    try {
      if (!registration) {
        // No registrations - register new
        setSelectedDate(localDate);
        await connectedLunchServiceAlias.register(
          localDate,
          selectedCity.id,
          mealType
        );
        await loadRegistrations();
        showMessage('Successfully registered for connected lunch', 'success');
        setActiveRegistration(null);
      } else if (registration.userRegistered && !registration.hasOthers) {
        // Only user registered (red) - allow cancellation
        setSelectedDate(localDate);
        await connectedLunchServiceAlias.deregister(registration.lunchId);
        await loadRegistrations();
        showMessage('Successfully cancelled registration', 'success');
        setActiveRegistration(null);
      } else if (registration.userRegistered && registration.hasOthers) {
        // User and others registered (green) - just show match buttons
        setSelectedDate(localDate);
        setActiveRegistration(registration.lunchId);
      } else if (registration.hasOthers && !registration.userRegistered) {
        // Only others registered (yellow) - register and show match buttons
        setSelectedDate(localDate);
        
        const newRegistration = await connectedLunchServiceAlias.register(
          localDate,
          selectedCity.id,
          mealType
        );
        
        // Update local state to show green and show buttons immediately
        setRegistrations(prevRegistrations => 
          prevRegistrations.map(reg => 
            new Date(reg.date).toISOString().split('T')[0] === localDate.toISOString().split('T')[0]
              ? { ...reg, userRegistered: true, hasOthers: true, lunchId: registration.lunchId }
              : reg
          )
        );
        
        // Show buttons immediately by setting active registration
        setActiveRegistration(registration.lunchId);  
        showMessage('Successfully registered for connected lunch', 'success');
        
        // Refresh registrations in the background
        setTimeout(() => loadRegistrations(), 100);
      }
    } catch (error) {
      showMessage(error.message || 'Operation failed', 'error');
      await loadRegistrations();
      setActiveRegistration(null);
    }
  };

  const handleCancelRegistration = async () => {
    if (!selectedDate || !activeRegistration) return;

    try {
      await connectedLunchServiceAlias.deregister(activeRegistration);
      await new Promise(resolve => setTimeout(resolve, 100));
      const updatedRegistrations = await loadRegistrations();
      showMessage('Successfully cancelled registration', 'success');
      setActiveRegistration(null);
      setSelectedDate(selectedDate); // Keep the date selected after cancellation
    } catch (error) {
      showMessage(error.message || 'Failed to cancel registration', 'error');
    }
  };

  const handleFindMatch = async () => {
    if (!activeRegistration) return;

    try {
      const matches = await connectedLunchService.findMatch(activeRegistration);
      if (matches && matches.length > 0) {
        setMatches(matches);
        setShowWheel(true);
      } else {
        showMessage('No matches found yet', 'info');
      }
    } catch (error) {
      showMessage('Failed to find match', 'error');
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const showMessage = (message: string, severity: 'success' | 'error' | 'info') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  const registerMutation = useMutation({
    mutationFn: () => {
      if (!selectedDate) throw new Error('Please select a date');
      if (!selectedCity) throw new Error('Please select a city');
      return connectedLunchService.registerForLunch(
        format(selectedDate, 'yyyy-MM-dd'),
        selectedCity.id,
        mealType
      );
    },
    onSuccess: (data) => {
      if (data.alreadyRegistered) {
        showMessage('You were already registered for this date. Here are your potential matches!', 'info');
      }
      setRegistrationId(data.registration.id);
      if (data.matches.length > 0) {
        setMatches(data.matches);
        setShowWheel(true);
      } else {
        showMessage('No matches found yet. We\'ll notify you when someone registers for the same date!', 'info');
      }
    },
    onError: (error: any) => {
      showMessage(error.response?.data?.error || 'Failed to register', 'error');
    },
  });

  const acceptMatchMutation = useMutation({
    mutationFn: () => {
      if (!activeRegistration || !selectedMatch) throw new Error('No match selected');
      return connectedLunchService.acceptMatch(activeRegistration, selectedMatch.id);
    },
    onSuccess: () => {
      showMessage('Match accepted! Check your email for the calendar invite.', 'success');
      setShowWheel(false);
      setSelectedMatch(null);
    },
    onError: (error: any) => {
      console.error('Accept match error:', error);
      showMessage(error.response?.data?.error || 'Failed to accept match', 'error');
    },
  });

  const rejectMatchMutation = useMutation({
    mutationFn: () => {
      if (!activeRegistration || !selectedMatch) throw new Error('No match selected');
      return connectedLunchService.rejectMatch(activeRegistration, selectedMatch.id);
    },
    onSuccess: () => {
      // Just clear selected match and spin again
      setSelectedMatch(null);
      setTimeout(() => {
        const wheel = document.querySelector('canvas');
        if (wheel) {
          wheel.click();
        }
      }, 100);
    },
    onError: (error: any) => {
      console.error('Reject match error:', error);
      showMessage(error.response?.data?.error || 'Failed to reject match', 'error');
    },
  });

  const handleWheelStop = (match: PotentialMatch) => {
    setSelectedMatch(match);
  };

  useEffect(() => {
    return () => {
      // Clear all state when component unmounts
      setRegistrations([]);
      setActiveRegistration(null);
      setSelectedMatch(null);
      setSelectedDate(undefined);
    };
  }, []);

  // Add console log to track button rendering
  useEffect(() => {
    console.log('State updated:', {
      activeRegistration,
      selectedDate,
      shouldShowButtons: !!(activeRegistration && selectedDate)
    });
  }, [activeRegistration, selectedDate]);

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Connected Lunch
        </Typography>
        <Typography variant="h6" color="text.secondary" sx={{ mb: 4 }}>
          Register for a lunch or dinner appointment and get matched with another talent!
        </Typography>

        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 3, sm: 4 },
          alignItems: { xs: 'center', sm: 'flex-start' },
          justifyContent: 'center', 
          mb: 4 
        }}>
          <div className="connected-lunch-calendar">
            <DatePicker
              selected={selectedDate}
              onChange={(date: Date) => handleDayClick(date)}
              onMonthChange={(date) => {
                setSelectedDate(undefined);
                setActiveRegistration(null);
              }}
              inline
              dateFormat="MMMM d, yyyy"
              minDate={new Date()}
              className="w-full custom-datepicker"
              dayClassName={date => {
                const registration = registrations.find(reg => {
                  const regDate = new Date(reg.date);
                  const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
                  return regDate.toISOString().split('T')[0] === localDate.toISOString().split('T')[0];
                });

                const isSelected = selectedDate && 
                  date.toISOString().split('T')[0] === selectedDate.toISOString().split('T')[0];
                
                const classes = [];

                if (!registration) {
                  if (isSelected) {
                    classes.push('selected-date');
                  }
                  return classes.join(' ');
                }

                if (registration.userRegistered) {
                  if (registration.hasOthers) {
                    classes.push('bg-green-200');
                  } else {
                    classes.push('bg-red-200');
                  }
                } else if (registration.hasOthers) {
                  classes.push('bg-yellow-200');
                }

                if (isSelected) {
                  classes.push('selected-date');
                }

                return classes.join(' ');
              }}
            />
          </div>

          <Card sx={{ 
            p: 2, 
            minWidth: { xs: '100%', sm: '200px' },
            maxWidth: { xs: '100%', sm: 'auto' }
          }}>
            <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>
              Calendar Legend
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box className="bg-green-200" sx={{ width: 24, height: 24, borderRadius: 1 }} />
                <Typography variant="body2">You and others registered</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box className="bg-red-200" sx={{ width: 24, height: 24, borderRadius: 1 }} />
                <Typography variant="body2">Only you registered</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box className="bg-yellow-200" sx={{ width: 24, height: 24, borderRadius: 1 }} />
                <Typography variant="body2">Others registered</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box sx={{ 
                  width: 24, 
                  height: 24, 
                  borderRadius: 1,
                  border: '2px solid #3b82f6',
                  color: '#1a365d',
                  fontWeight: 700,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  {selectedDate?.getDate() || new Date().getDate()}
                </Box>
                <Typography variant="body2">Selected date</Typography>
              </Box>
            </Box>
            {activeRegistration && selectedDate && (
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                gap: 1,
                mt: 3,
                pt: 3,
                borderTop: '1px solid rgba(0, 0, 0, 0.12)'
              }}>
                <Button
                  onClick={handleCancelRegistration}
                  variant="contained"
                  color="error"
                  fullWidth
                >
                  Cancel Registration
                </Button>
                <Button
                  onClick={handleFindMatch}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Find Match
                </Button>
              </Box>
            )}
          </Card>
        </Box>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="meal-type-label">Meal Type</InputLabel>
          <Select
            labelId="meal-type-label"
            value={mealType}
            label="Meal Type"
            onChange={(e) => setMealType(e.target.value as 'LUNCH' | 'DINNER')}
          >
            <MenuItem value="LUNCH">Lunch</MenuItem>
            <MenuItem value="DINNER">Dinner</MenuItem>
          </Select>
        </FormControl>

        <Card sx={{ mt: 4 }}>
          <CardContent>
            <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
              <Box sx={{ '& .react-datepicker-wrapper': { width: '100%' } }}>
                <DatePicker
                  selected={selectedDate}
                  onChange={(date: Date) => setSelectedDate(date)}
                  onMonthChange={() => {
                    setSelectedDate(undefined);
                    setActiveRegistration(null);
                  }}
                  dateFormat="MMMM d, yyyy"
                  minDate={new Date()}
                  placeholderText="Select a date"
                  className="MuiInputBase-input MuiOutlinedInput-input"
                  wrapperClassName="datePicker"
                  customInput={
                    <TextField
                      fullWidth
                      placeholder="Select a date"
                      sx={{ '& input': { color: 'text.primary' } }}
                    />
                  }
                />
              </Box>

              <LocationSelector
                selectedCity={selectedCity}
                selectedCountry={selectedCountry}
                onCityChange={setSelectedCity}
                onCountryChange={setSelectedCountry}
              />

              <Button
                variant="contained"
                color="primary"
                disabled={!selectedDate || !selectedCity || registerMutation.isPending}
                onClick={() => registerMutation.mutate()}
                sx={{ mt: 2 }}
              >
                {registerMutation.isPending ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Register'
                )}
              </Button>
            </Box>
          </CardContent>
        </Card>

        <Dialog
          open={showWheel}
          maxWidth="md"
          fullWidth
          onClose={() => !selectedMatch && setShowWheel(false)}
        >
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            Find Your Lunch Partner
            <IconButton
              onClick={() => {
                setShowWheel(false);
                setSelectedMatch(null);
              }}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {matches.length > 0 && (
              <Wheel
                items={matches}
                onStop={handleWheelStop}
                selectedItem={selectedMatch}
              />
            )}
            {selectedMatch && (
              <Box sx={{ mt: 2, textAlign: 'center' }}>
                <Typography variant="h6">
                  {selectedMatch.user.firstName} {selectedMatch.user.lastName}
                </Typography>
                {selectedMatch.user.company && (
                  <Typography variant="body2" color="text.secondary">
                    {selectedMatch.user.company.name}
                  </Typography>
                )}
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => acceptMatchMutation.mutate()}
                    disabled={acceptMatchMutation.isPending}
                  >
                    Accept Match
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => rejectMatchMutation.mutate()}
                    disabled={rejectMatchMutation.isPending}
                  >
                    Spin Again
                  </Button>
                  <Button
                    variant="outlined"
                    color="inherit"
                    onClick={() => {
                      setShowWheel(false);
                      setSelectedMatch(null);
                    }}
                  >
                    Close
                  </Button>
                </Box>
              </Box>
            )}
          </DialogContent>
        </Dialog>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default ConnectedLunchPage;
