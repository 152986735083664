import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Alert,
  Container,
  Checkbox,
  FormControlLabel,
  Divider,
} from '@mui/material';
import { api } from '../services/api';

const ConsentPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [termsContent, setTermsContent] = useState<{
    version: string;
    content: string;
    effective_date: string;
  } | null>(null);
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
  const [consentChecked, setConsentChecked] = useState(false);
  const termsRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const response = await api.get('/consent/terms/current');
        setTermsContent(response.data);
        setIsLoading(false);
      } catch (error) {
        setError('Failed to load terms and conditions');
        setIsLoading(false);
      }
    };

    fetchTerms();
  }, []);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const element = e.target as HTMLDivElement;
    if (element.scrollHeight - element.scrollTop <= element.clientHeight + 100) {
      setHasScrolledToBottom(true);
    }
  };

  const handleConsent = async () => {
    try {
      // Record consent
      await api.post('/consent', {
        action: 'PRE_REGISTRATION_CONSENT'
      });
      
      // Navigate to registration with consent flag
      navigate('/register', { 
        state: { 
          hasConsent: true,
          consentTimestamp: new Date().toISOString()
        }
      });
    } catch (error) {
      setError('Failed to record consent. Please try again.');
    }
  };

  const renderContent = () => {
    if (!termsContent) return null;

    const content = JSON.parse(termsContent.content);
    return (
      <>
        {/* Terms of Use Section */}
        <Typography variant="h4" gutterBottom>
          Terms of Use
        </Typography>
        
        <Typography variant="body1" paragraph>
          Last updated: {new Date(termsContent.effective_date).toLocaleDateString()}
        </Typography>

        {content.termsOfUse.map((section: any, index: number) => (
          <Box key={index} sx={{ mb: 4 }}>
            <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
              {section.title}
            </Typography>
            <Typography variant="body1" paragraph>
              {section.content}
            </Typography>
            {section.list && (
              <Box component="ul" sx={{ pl: 4, mb: 2 }}>
                {section.list.map((item: string, i: number) => (
                  <li key={i}>{item}</li>
                ))}
              </Box>
            )}
          </Box>
        ))}

        <Divider sx={{ my: 6 }} />

        {/* Privacy Policy Section */}
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>

        {content.privacyPolicy.map((section: any, index: number) => (
          <Box key={index} sx={{ mb: 4 }}>
            <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
              {section.title}
            </Typography>
            <Typography variant="body1" paragraph>
              {section.content}
            </Typography>
            {section.subsections && section.subsections.map((subsection: any, i: number) => (
              <Box key={i} sx={{ mb: 2 }}>
                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                  {subsection.title}
                </Typography>
                {subsection.list && (
                  <Box component="ul" sx={{ pl: 4, mb: 2 }}>
                    {subsection.list.map((item: string, j: number) => (
                      <li key={j}>{item}</li>
                    ))}
                  </Box>
                )}
              </Box>
            ))}
            {section.list && (
              <Box component="ul" sx={{ pl: 4, mb: 2 }}>
                {section.list.map((item: string, i: number) => (
                  <li key={i}>{item}</li>
                ))}
              </Box>
            )}
          </Box>
        ))}
      </>
    );
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md">
      <Paper sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Terms and Privacy Policy
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Typography variant="body2" color="text.secondary" paragraph>
          Please read our terms and privacy policy carefully before proceeding with registration.
        </Typography>

        <Box
          ref={termsRef}
          onScroll={handleScroll}
          sx={{
            maxHeight: '400px',
            overflowY: 'auto',
            mb: 3,
            p: 2,
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
          }}
        >
          {renderContent()}
        </Box>

        <FormControlLabel
          control={
            <Checkbox
              checked={consentChecked}
              onChange={(e) => setConsentChecked(e.target.checked)}
              disabled={!hasScrolledToBottom}
            />
          }
          label="I have read and agree to the terms and privacy policy"
        />

        <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            disabled={!consentChecked || !hasScrolledToBottom}
            onClick={handleConsent}
          >
            Accept and Continue to Registration
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate('/')}
          >
            Cancel
          </Button>
        </Box>

        {!hasScrolledToBottom && (
          <Typography variant="body2" color="warning.main" sx={{ mt: 2 }}>
            Please scroll to the bottom to read the entire document
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default ConsentPage; 