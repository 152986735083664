import { useState, useEffect } from 'react';
import { City, Country } from '../types';
import { Autocomplete, TextField, Box, Typography } from '@mui/material';
import Fuse from 'fuse.js';
import { api } from '../services/api';

interface City {
  id: string;
  name: string;
  country_id: string;
  synonyms: string[];
  short_description?: string;
  city_region?: string;
  city_country_name?: string;
  is_fictional: boolean;
  is_historical: boolean;
  latitude?: number;
  longitude?: number;
}

interface Country {
  id: string;
  name: string;
  synonyms: string[];
  two_letter_code?: string;
  three_letter_code?: string;
  country_un_code?: string;
  country_flag?: string;
  country_continent_name?: string;
  is_fictional: boolean;
  is_historical: boolean;
  latitude?: number;
  longitude?: number;
}

interface LocationSelectorProps {
  selectedCity?: City | null;
  selectedCountry?: Country | null;
  onCityChange: (city: City | null) => void;
  onCountryChange: (country: Country | null) => void;
}

export function LocationSelector({
  selectedCity,
  selectedCountry,
  onCityChange,
  onCountryChange
}: LocationSelectorProps) {
  const [page, setPage] = useState(0);
  const [cityPage, setcityPage] = useState(0);
  const [cityQuery, setCityQuery] = useState('');
  const [cities, setCities] = useState<City[]>([]);
  const [isLoadingCities, setIsLoadingCities] = useState(false);
  const [isLoadingCountries, setIsLoadingCountries] = useState(false);
  const [countries, setCountries] = useState<Country[]>([]);
  const PAGE_SIZE = 20;

  // Configure Fuse.js options for countries
  const countryFuseOptions = {
    keys: ['name', 'synonyms'],
    threshold: 0.4,
    minMatchCharLength: 2
  };

  // Create Fuse instance for countries
  const countryFuse = new Fuse(countries, countryFuseOptions);

  // Fetch countries
  useEffect(() => {
    setIsLoadingCountries(true);
    api.get('/locations/countries')
      .then(response => {
        setCountries(response.data);
      })
      .finally(() => setIsLoadingCountries(false));
  }, []);

  // Fetch cities when country changes
  useEffect(() => {
    if (selectedCountry?.id) {
      setIsLoadingCities(true);
      api.get(`/locations/cities?countryId=${selectedCountry.id}`)
        .then(response => {
          setCities(response.data);
          // If we have a selected city but it's not in the list, clear it
          if (selectedCity && !response.data.find((c: City) => c.id === selectedCity.id)) {
            onCityChange(null);
          }
        })
        .finally(() => setIsLoadingCities(false));
    } else {
      setCities([]);
      onCityChange(null);
    }
  }, [selectedCountry, selectedCity]);

  const handleCountryChange = (country: Country | null) => {
    onCountryChange(country);
    // Clear cities and selected city immediately when country changes
    setCities([]);
    onCityChange(null);
  };

  const handleCityChange = (city: City | null) => {
    onCityChange(city || null);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Autocomplete
        value={selectedCountry}
        onChange={(_, newValue) => {
          handleCountryChange(newValue);
        }}
        options={countries}
        filterOptions={(options, { inputValue }) => {
          if (!inputValue) return options;
          return countryFuse.search(inputValue).map(result => result.item);
        }}
        getOptionLabel={(option) => option?.name || ''}
        isOptionEqualToValue={(option, value) => {
          if (!option || !value) return option === value;
          return option.id === value.id;
        }}
        selectOnFocus
        clearOnBlur={false}
        handleHomeEndKeys
        loading={isLoadingCountries}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Country"
            variant="outlined"
          />
        )}
      />

      <Autocomplete
        value={selectedCity}
        onChange={(_, newValue) => {
          handleCityChange(newValue);
        }}
        options={cities}
        disabled={!selectedCountry}
        filterOptions={(options, { inputValue }) => {
          if (!inputValue) return options;
          const fuse = new Fuse(options, {
            keys: ['name', 'synonyms'],
            threshold: 0.4,
            minMatchCharLength: 2
          });
          return fuse.search(inputValue).map(result => result.item);
        }}
        getOptionLabel={(option) => option?.name || ''}
        isOptionEqualToValue={(option, value) => {
          if (!option || !value) return option === value;
          return option.id === value.id;
        }}
        loading={isLoadingCities}
        renderInput={(params) => (
          <TextField
            {...params}
            label="City"
            variant="outlined"
            placeholder={selectedCountry ? "Start typing to search cities..." : "Select a country first"}
            disabled={!selectedCountry}
          />
        )}
      />
    </Box>
  );
}