import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import './index.css'
import App from './App'

// Debug environment variables
console.log('Frontend Environment Variables:', {
  VITE_API_URL: import.meta.env.VITE_API_URL,
  VITE_MATOMO_URL: import.meta.env.VITE_MATOMO_URL,
  VITE_MATOMO_SITE_ID: import.meta.env.VITE_MATOMO_SITE_ID,
  VITE_MINIO_PUBLIC_URL: import.meta.env.VITE_MINIO_PUBLIC_URL,
  MODE: import.meta.env.MODE,
  BASE_URL: import.meta.env.BASE_URL,
  PROD: import.meta.env.PROD,
  DEV: import.meta.env.DEV
});

console.log('Starting application...');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false
    }
  }
})

const rootElement = document.getElementById('root');
console.log('Root element:', rootElement);

if (rootElement) {
  try {
    ReactDOM.createRoot(rootElement).render(
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </React.StrictMode>
    );
    console.log('Application rendered successfully');
  } catch (error) {
    console.error('Error rendering application:', error);
  }
} else {
  console.error('Root element not found!');
}
