export interface User {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  position?: string;
  company?: {
    id: string;
    name: string;
  };
  city?: {
    id: string;
    name: string;
  };
  country?: {
    id: string;
    name: string;
  };
  profile_image_url?: string;
  short_description?: string;
  long_description?: string;
  is_managed?: boolean;
  role?: string;
  roles?: Array<{
    roleId: string;
    role: {
      name: string;
    };
  }>;
  skills?: Array<{
    skillId: string;
    proficiency: 'BASIC' | 'ADVANCED' | 'EXPERT';
    skill: {
      id: string;
      name: string;
    };
  }>;
  languages?: Array<{
    languageId: string;
    proficiency: string;
    language: {
      id: string;
      name: string;
    };
  }>;
  is_email_verified: boolean;
}

// Separate interface for sensitive user data that should only be used internally
export interface UserWithSensitiveData extends User {
  password?: string;
  notes?: string;
}

export interface UserRole {
  roleId: string;
  role: Role;
}

export interface Role {
  id: string;
  name: string;
}

export const ADMIN_ROLES = ['Superuser', 'Admin', 'Content Manager'];

// Add back other necessary types
export interface LoginCredentials {
  email: string;
  password: string;
}

export interface EditProfileInputs {
  first_name: string;
  last_name: string;
  position?: string;
  company_id?: string;
  city_id?: string;
  country_id?: string;
  short_description?: string;
  long_description?: string;
}

export interface Post {
  id: string;
  content: string;
  image_url?: string;
  created_at: string;
  updated_at: string;
  view_count: number;
  author: User;
  likes: PostLike[];
  mentioned_users: UserMention[];
}

export interface PostLike {
  userId: string;
  postId: string;
  user: User;
}

export interface UserMention {
  id: string;
  post_id: string;
  user_id: string;
  user: User;
}

// Add these interfaces alongside your existing types
export interface Comment {
  id: string;
  content: string;
  created_at: string;
  updated_at: string;
  post_id: string;
  author: User;
  engagements: Engagement[];
  replies?: Comment[];
  parent_id?: string;
  reactions: CommentReaction[];
}

export interface Engagement {
  id: string;
  user_id: string;
  content_id: string;
  content_type: string;
  type: string;
  reaction_type: string;
  user: User;
}

export interface CommentLike {
  userId: string;
  commentId: string;
  user: User;
}

export interface CommentReaction {
  emoji: string;
  count: number;
  users: User[];
}

export enum Duration {
  ONE_MINUTE = "ONE_MINUTE",
  FIVE_MINUTES = "FIVE_MINUTES",
  SEVEN_MINUTES = "SEVEN_MINUTES"
}

// Add these interfaces
export interface Country {
  id: string;
  name: string;
  two_letter_code?: string;
}

export interface City {
  id: string;
  name: string;
  country_id: string;
}

export interface Organisation {
  id: string;
  name: string;
}