import React from 'react';
import { useState } from 'react';
import DOMPurify from 'dompurify';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Switch,
  FormControlLabel,
  Typography,
  Alert,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon, HelpOutline as HelpOutlineIcon, Info as InfoIcon } from '@mui/icons-material';
import { api } from '../../services/api';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';

interface Language {
  id: string;
  name: string;
  wikipedia?: string;
  notes?: string;
  synonyms: string[];
  is_fictional: boolean;
  is_historical: boolean;
  short_description?: string;
  long_description?: string;
  two_letter_code?: string;
  three_letter_code?: string;
}

// Sanitization utilities (only used at save time)
const sanitizeText = (text?: string): string => {
  if (!text) return '';
  return DOMPurify.sanitize(text.trim());
};

const isValidUrl = (url?: string): boolean => {
  if (!url) return true; // Empty URL is valid
  try {
    new URL(url);
    return url.startsWith('https://') || url.startsWith('http://');
  } catch {
    return false;
  }
};

const sanitizeMultilineText = (text?: string): string => {
  if (!text) return '';
  return text
    .split('\n')
    .map(line => line.trim())
    .filter(line => line.length > 0)
    .join('\n');
};

const sanitizeLongDescription = (text?: string): string => {
  if (!text) return '';
  const lines = text.split('\n');
  
  // Trim array from both ends until we find non-empty lines
  let start = 0;
  let end = lines.length - 1;
  
  while (start < lines.length && !lines[start].trim()) start++;
  while (end > start && !lines[end].trim()) end--;
  
  return lines
    .slice(start, end + 1)
    .join('\n');
};

const sanitizeSynonyms = (synonyms: string[] = []): string[] => {
  return synonyms
    .map(syn => DOMPurify.sanitize(syn?.trim() || ''))
    .filter(syn => syn.length > 0)
    .filter((syn, index, self) => self.indexOf(syn) === index); // Remove duplicates
};

const sanitizeCode = (text?: string): string => {
  if (!text) return '';
  // Remove any non-alphanumeric characters
  return text.replace(/[^a-zA-Z0-9]/g, '');
};

const FieldLabel = ({ label, tooltip }: { label: string; tooltip: string }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    {label}
    <Tooltip title={tooltip}>
      <InfoIcon fontSize="small" sx={{ color: 'text.secondary' }} />
    </Tooltip>
  </Box>
);

export const LanguagesPage = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<Language | null>(null);
  const [open, setOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const { data: languages = [], isLoading } = useQuery({
    queryKey: ['languages'],
    queryFn: async () => {
      const response = await api.get('/languages');
      return response.data;
    }
  });

  const handleCreate = () => {
    setSelectedLanguage({
      id: '',
      name: '',
      synonyms: [],
      is_fictional: false,
      is_historical: false,
    });
    setIsCreating(true);
    setOpen(true);
  };

  const handleEdit = (language: Language) => {
    setSelectedLanguage(language);
    setIsCreating(false);
    setOpen(true);
  };

  const handleDelete = (language: Language) => {
    setSelectedLanguage(language);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = () => {
    if (selectedLanguage) {
      deleteMutation.mutate(selectedLanguage.id);
      setDeleteDialogOpen(false);
    }
  };

  const handleClose = () => {
    setSelectedLanguage(null);
    setOpen(false);
    setIsCreating(false);
  };

  const handleSave = async () => {
    if (!selectedLanguage) return;

    try {
      // Sanitize data only at save time
      const sanitizedLanguage = {
        ...selectedLanguage,
        name: sanitizeText(selectedLanguage.name),
        wikipedia: selectedLanguage.wikipedia?.trim() || '',
        short_description: sanitizeText(selectedLanguage.short_description),
        notes: sanitizeMultilineText(selectedLanguage.notes),
        long_description: sanitizeLongDescription(selectedLanguage.long_description),
        synonyms: sanitizeSynonyms(selectedLanguage.synonyms),
        two_letter_code: sanitizeCode(selectedLanguage.two_letter_code || ''),
        three_letter_code: sanitizeCode(selectedLanguage.three_letter_code || '')
      };

      // Validate required fields
      if (!sanitizedLanguage.name) {
        throw new Error('Name is required');
      }

      if (sanitizedLanguage.wikipedia && !isValidUrl(sanitizedLanguage.wikipedia)) {
        throw new Error('Please enter a valid Wikipedia URL');
      }

      if (sanitizedLanguage.short_description && sanitizedLanguage.short_description.length > 255) {
        throw new Error('Short description must be 255 characters or less');
      }

      if (isCreating) {
        await api.post('/languages', sanitizedLanguage);
      } else {
        await api.patch(`/languages/${selectedLanguage.id}`, sanitizedLanguage);
      }
      
      queryClient.invalidateQueries({ queryKey: ['languages'] });
      handleClose();
    } catch (error) {
      console.error('Failed to save language:', error);
      // TODO: Show error message to user
    }
  };

  const deleteMutation = useMutation({
    mutationFn: async (id: string) => {
      // First check how many users are using this language
      const usageResponse = await api.get(`/languages/${id}/usage`);
      const userCount = usageResponse.data.userCount;

      if (userCount > 0) {
        throw new Error(`This language is currently used by ${userCount} user${userCount === 1 ? '' : 's'}`);
      }

      return api.delete(`/languages/${id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['languages'] });
      setDeleteDialogOpen(false);
      setDeleteError(null);
    },
    onError: (error: any) => {
      setDeleteError(error.message || 'Failed to delete language');
    }
  });

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h5">Languages</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          Create New
        </Button>
      </Box>

      <TableContainer component={Paper}>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Short Description</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {languages.map((language) => (
                <TableRow key={language.id}>
                  <TableCell>{language.name}</TableCell>
                  <TableCell>{language.short_description}</TableCell>
                  <TableCell>{language.notes}</TableCell>
                  <TableCell>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton onClick={() => handleEdit(language)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton 
                      onClick={() => handleDelete(language)}
                      sx={{ 
                        color: 'error.main',
                        '&:hover': {
                          color: 'error.dark',
                        }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          {isCreating ? 'Create New Language' : 'Edit Language'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
            <TextField
              label="Name"
              value={selectedLanguage?.name || ''}
              onChange={(e) => setSelectedLanguage(prev => 
                prev ? { ...prev, name: sanitizeText(e.target.value) } : null
              )}
              required
              helperText="The primary name of the language"
            />
            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={selectedLanguage?.is_fictional || false}
                    onChange={(e) => setSelectedLanguage(prev => 
                      prev ? { ...prev, is_fictional: e.target.checked } : null
                    )}
                  />
                }
                label="Is Fictional"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={selectedLanguage?.is_historical || false}
                    onChange={(e) => setSelectedLanguage(prev => 
                      prev ? { ...prev, is_historical: e.target.checked } : null
                    )}
                  />
                }
                label="Is Historical"
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <TextField
                label="Two Letter Code"
                value={selectedLanguage?.two_letter_code || ''}
                onChange={(e) => setSelectedLanguage(prev => 
                  prev ? {
                    ...prev,
                    two_letter_code: sanitizeCode(e.target.value).slice(0, 2)
                  } : null
                )}
                inputProps={{ maxLength: 2 }}
                sx={{ width: '50%' }}
              />
              <TextField
                label="Three Letter Code"
                value={selectedLanguage?.three_letter_code || ''}
                onChange={(e) => setSelectedLanguage(prev => 
                  prev ? {
                    ...prev,
                    three_letter_code: sanitizeCode(e.target.value).slice(0, 3)
                  } : null
                )}
                inputProps={{ maxLength: 3 }}
                sx={{ width: '50%' }}
              />
            </Box>
            <TextField
              label={
                <FieldLabel 
                  label="Wikipedia URL" 
                  tooltip="The full URL to the Wikipedia article about this language"
                />
              }
              value={selectedLanguage?.wikipedia || ''}
              onChange={(e) => setSelectedLanguage(prev => 
                prev ? { ...prev, wikipedia: e.target.value.trim() } : null
              )}
              error={!!selectedLanguage?.wikipedia && !isValidUrl(selectedLanguage.wikipedia)}
              helperText={
                selectedLanguage?.wikipedia && !isValidUrl(selectedLanguage.wikipedia)
                  ? 'Please enter a valid URL starting with http:// or https://'
                  : 'Full URL to Wikipedia article'
              }
            />
            <TextField
              label={
                <FieldLabel 
                  label="Short Description" 
                  tooltip="A brief description of the language (max 255 characters)"
                />
              }
              value={selectedLanguage?.short_description || ''}
              onChange={(e) => setSelectedLanguage(prev => 
                prev ? { ...prev, short_description: sanitizeText(e.target.value) } : null
              )}
              multiline
              rows={2}
              inputProps={{ maxLength: 255 }}
              helperText={`${selectedLanguage?.short_description?.length || 0}/255`}
            />
            <TextField
              label={
                <FieldLabel 
                  label="Long Description" 
                  tooltip="A detailed description of the language, its history, and usage"
                />
              }
              value={selectedLanguage?.long_description || ''}
              onChange={(e) => setSelectedLanguage(prev => 
                prev ? { ...prev, long_description: sanitizeMultilineText(e.target.value) } : null
              )}
              multiline
              rows={4}
            />
            <TextField
              label="Notes"
              value={selectedLanguage?.notes || ''}
              onChange={(e) => setSelectedLanguage(prev => 
                prev ? { ...prev, notes: sanitizeMultilineText(e.target.value) } : null
              )}
              multiline
              rows={2}
            />
            <TextField
              label={
                <FieldLabel 
                  label="Synonyms" 
                  tooltip="Alternative names for this language, one per line"
                />
              }
              value={selectedLanguage?.synonyms?.join('\n') || ''}
              onChange={(e) => setSelectedLanguage(prev => 
                prev ? { 
                  ...prev, 
                  synonyms: e.target.value
                    .split('\n')
                    .map(s => s.trim())
                    .filter(s => s.length > 0)
                } : null
              )}
              multiline
              rows={4}
              helperText="Enter each synonym on a new line"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} variant="contained">
            {isCreating ? 'Create' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={deleteDialogOpen} 
        onClose={() => {
          setDeleteDialogOpen(false);
          setDeleteError(null);
        }}
      >
        <DialogTitle>Delete Language</DialogTitle>
        <DialogContent>
          {deleteError ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              {deleteError}
            </Alert>
          ) : (
            <Typography>
              Are you sure you want to delete this language? This action cannot be undone.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              setDeleteDialogOpen(false);
              setDeleteError(null);
            }}
            disabled={deleteMutation.isPending}
          >
            Cancel
          </Button>
          <Button 
            onClick={confirmDelete} 
            color="error" 
            variant="contained"
            disabled={deleteMutation.isPending || !!deleteError}
            startIcon={deleteMutation.isPending ? <CircularProgress size={20} /> : null}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}; 