import React from 'react';
import { useState, useEffect } from 'react';
import { 
  Autocomplete, 
  TextField, 
  Box,
  Chip,
  FormControl,
  Select,
  MenuItem 
} from '@mui/material';
import { debounce } from 'lodash';
import { api } from '../services/api';

interface Skill {
  id: string;
  name: string;
  short_description?: string;
}

interface UserSkill {
  skillId: string;
  proficiency: 'BASIC' | 'ADVANCED' | 'EXPERT';
  skill: Skill;
}

interface Props {
  userSkills: UserSkill[];
  onSkillAdd: (skillId: string, proficiency: 'BASIC' | 'ADVANCED' | 'EXPERT') => void;
  onSkillRemove: (skillId: string) => void;
  onSkillProficiencyChange: (skillId: string, proficiency: 'BASIC' | 'ADVANCED' | 'EXPERT') => void;
}

export const SkillAutocomplete = ({ userSkills, onSkillAdd, onSkillRemove, onSkillProficiencyChange }: Props) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<Skill[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchSkills = async (searchTerm: string) => {
    try {
      setLoading(true);
      const response = await api.get(`/skills/search?q=${searchTerm}`);
      const newOptions = response.data.filter(
        (skill: Skill) => !userSkills.some(us => us.skillId === skill.id)
      );
      setOptions(newOptions);
    } catch (error) {
      console.error('Failed to fetch skills:', error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchSkills = debounce(fetchSkills, 300);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Box>
      <Autocomplete<Skill, false, false, false>
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        inputValue={inputValue}
        value={null}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          if (newInputValue.length >= 2) {
            debouncedFetchSkills(newInputValue);
          }
        }}
        options={options}
        getOptionLabel={(option) => option.name}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search Skills"
            placeholder="Type to search..."
          />
        )}
        onChange={(event, skill) => {
          if (skill) {
            onSkillAdd(skill.id, 'BASIC');
            setInputValue('');
            setOptions([]);
          }
        }}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        filterOptions={(x) => x}
        noOptionsText={inputValue.length < 2 ? "Type at least 2 characters" : "No skills found"}
      />

      <Box sx={{ mt: 2 }}>
        {userSkills.map((userSkill) => (
          <Box key={userSkill.skillId} sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
            <Chip
              label={userSkill.skill.name}
              onDelete={() => onSkillRemove(userSkill.skillId)}
              sx={{ flexGrow: 1 }}
            />
            <FormControl size="small" sx={{ minWidth: 120 }}>
              <Select
                displayEmpty
                value={userSkill.proficiency}
                onChange={(e) => {
                  console.log('Changing proficiency:', {
                    skillId: userSkill.skillId,
                    oldValue: userSkill.proficiency,
                    newValue: e.target.value
                  });
                  onSkillProficiencyChange(userSkill.skillId, e.target.value as 'BASIC' | 'ADVANCED' | 'EXPERT');
                }}
                renderValue={(value) => value ? value.charAt(0) + value.slice(1).toLowerCase() : 'Select Level'}
                sx={{ height: 32 }}
              >
                <MenuItem value="BASIC">Basic</MenuItem>
                <MenuItem value="ADVANCED">Advanced</MenuItem>
                <MenuItem value="EXPERT">Expert</MenuItem>
              </Select>
            </FormControl>
          </Box>
        ))}
      </Box>
    </Box>
  );
}; 