import React from 'react';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Typography, TextField, InputAdornment } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { api } from '../services/api';
import { User } from '../types';
import SimpleHorizontalUserCard from '../components/SimpleHorizontalUserCard';
import LoadingSpinner from '../components/common/LoadingSpinner';

const SearchPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(searchParams.get('q') || '');
  const [refreshKey, setRefreshKey] = useState(Date.now());

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const params = Object.fromEntries(searchParams.entries());
      // console.log('Searching with params:', params);
      const url = '/users/search';
      //  console.log('Making request to:', import.meta.env.VITE_API_URL + url);
      const response = await api.get('/users/search', { params });
      // console.log('Search response:', response.data);
      setUsers(response.data);
      setRefreshKey(Date.now());
    } catch (error) {
      console.error('Failed to fetch users:', error);
      console.error('Search error details:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [searchParams]);

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      const newParams = new URLSearchParams();
      newParams.set('q', searchTerm.trim());
      setSearchParams(newParams);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Search Users
      </Typography>
      
      <Box component="form" onSubmit={handleSearch} sx={{ mb: 4 }}>
        <TextField
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search by name, position, company, or location..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <Box>
          {users.length > 0 ? (
            users.map(user => (
              <SimpleHorizontalUserCard 
                key={`${user.id}-${refreshKey}`} 
                user={user} 
              />
            ))
          ) : (
            <Typography>No users found</Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SearchPage; 