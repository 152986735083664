declare global {
  interface Window {
    _paq: any[];
  }
}

let matomoInitialized = false;

export const initMatomo = () => {
  if (matomoInitialized) {
    return;
  }

  window._paq = window._paq || [];

  // GDPR Compliance Settings
  window._paq.push(['requireConsent']); // Require consent before tracking
  window._paq.push(['setDoNotTrack', true]); // Respect Do Not Track setting
  window._paq.push(['disableCookies']); // Disable cookies by default
  
  // Anonymize data
  window._paq.push(['setCustomDimension', 1, 'anonymous']);
  window._paq.push(['setSecureCookie', false]); // Disable secure cookies for http
  window._paq.push(['setVisitorCookieTimeout', 0]); // Disable visitor cookies
  window._paq.push(['setSessionCookieTimeout', 0]); // Disable session cookies

  // Configure tracker
  const u = import.meta.env.VITE_MATOMO_URL + '/';
  window._paq.push(['setTrackerUrl', u + 'matomo.php']);
  window._paq.push(['setSiteId', import.meta.env.VITE_MATOMO_SITE_ID]);

  // Load Matomo script
  const d = document;
  const g = d.createElement('script');
  const s = d.getElementsByTagName('script')[0];
  g.async = true;
  g.src = u + 'matomo.js';
  if (s && s.parentNode) {
    s.parentNode.insertBefore(g, s);
  }

  matomoInitialized = true;
};

// Function to grant consent for specific tracking categories
export const grantConsentForCategories = (categories: string[]) => {
  if (window._paq) {
    window._paq.push(['rememberConsentGiven']);
    categories.forEach(category => {
      window._paq.push(['setConsentGiven', category]);
    });
  }
};

// Function to withdraw consent
export const withdrawConsent = () => {
  if (window._paq) {
    window._paq.push(['forgetConsentGiven']);
  }
};

// Track specific engagement events
export const trackEngagement = {
  view: (contentType: string, contentId: string) => {
    window._paq?.push(['trackEvent', 'Engagement', 'View', `${contentType}-${contentId}`]);
  },
  
  reaction: (contentType: string, contentId: string, reactionType: string) => {
    window._paq?.push(['trackEvent', 'Engagement', 'Reaction', `${contentType}-${contentId}-${reactionType}`]);
  },
  
  comment: (contentType: string, contentId: string) => {
    window._paq?.push(['trackEvent', 'Engagement', 'Comment', `${contentType}-${contentId}`]);
  },
  
  share: (contentType: string, contentId: string, platform: string) => {
    window._paq?.push(['trackEvent', 'Engagement', 'Share', `${contentType}-${contentId}-${platform}`]);
  },
  
  bookmark: (contentType: string, contentId: string) => {
    window._paq?.push(['trackEvent', 'Engagement', 'Bookmark', `${contentType}-${contentId}`]);
  },
  
  linkClick: (contentType: string, contentId: string, url: string) => {
    window._paq?.push([
      'trackEvent',
      'Engagement',
      'LinkClick',
      `${contentType}-${contentId}`,
      url
    ]);
  }
};

// Additional tracking events for suggestions
export const suggestionEvents = {
  view: (type: string) => {
    window._paq?.push(['trackEvent', 'Suggestions', 'View', type]);
  },
  
  interact: (type: string, action: string) => {
    window._paq?.push(['trackEvent', 'Suggestions', 'Interact', `${type}-${action}`]);
  },
  
  settings: (action: string, value?: string) => {
    window._paq?.push(['trackEvent', 'Suggestions', 'Settings', action, value]);
  },
  
  conversion: (type: string) => {
    window._paq?.push(['trackEvent', 'Suggestions', 'Conversion', type]);
  },
  
  error: (message: string) => {
    window._paq?.push(['trackEvent', 'Suggestions', 'Error', message]);
  }
};