import { api } from './api';

export interface ConnectedLunchRegistration {
  id: string;
  date: string;
  mealType: 'LUNCH' | 'DINNER';
  cityId: string;
  city: {
    id: string;
    name: string;
  };
  status: 'PENDING' | 'ACCEPTED' | 'REJECTED';
  matchedWith?: {
    id: string;
    user: {
      id: string;
      firstName: string;
      lastName: string;
      company?: {
        name: string;
      };
    };
  };
}

export interface PotentialMatch {
  id: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    company?: {
      name: string;
    };
  };
}

export const connectedLunchService = {
  registerForLunch: async (date: string, cityId?: string, mealType: 'LUNCH' | 'DINNER' = 'LUNCH') => {
    const response = await api.post('/connected-lunch/register', {
      date,
      cityId,
      mealType,
    });
    return response.data;
  },

  getPotentialMatches: async (registrationId: string) => {
    const response = await api.get(`/connected-lunch/matches/${registrationId}`);
    return response.data as PotentialMatch[];
  },

  acceptMatch: async (registrationId: string, matchedWithId: string) => {
    const response = await api.post('/connected-lunch/accept-match', {
      registrationId,
      matchedWithId,
    });
    return response.data;
  },

  rejectMatch: async (registrationId: string, matchedWithId: string) => {
    const response = await api.post('/connected-lunch/reject-match', {
      registrationId,
      matchedWithId,
    });
    return response.data;
  },

  async getRegistrations(cityId: string, mealType: string) {
    const response = await api.get('/connected-lunch/registrations', {
      params: { cityId, mealType }
    });
    return response.data;
  },

  async register(date: Date, cityId: string, mealType: string) {
    try {
      const response = await api.post('/connected-lunch/register', {
        date: date.toISOString(),
        cityId,
        mealType,
      });
      return response.data;
    } catch (error) {
      console.error('Registration failed:', error);
      throw new Error(error.response?.data?.error || 'Failed to register for lunch');
    }
  },

  async deregister(lunchId: string) {
    try {
      const response = await api.delete(`/connected-lunch/register/${lunchId}`);
      return response.data;
    } catch (error) {
      console.error('Deregistration failed:', error);
      throw new Error(error.response?.data?.error || 'Failed to cancel registration');
    }
  },

  async findMatch(lunchId: string) {
    const response = await api.post(`/connected-lunch/match/${lunchId}`);
    return response.data;
  },
};
