// src/api/chatApi.ts
import { apiClient } from './apiClient';
import { User } from '../types';

export interface ChatMessageReaction {
  id: string;
  message_id: string;
  user_id: string;
  emoji: string;
  created_at: Date;
  updated_at: Date;
  user?: User;
}

export interface ChatMessage {
  id: string;
  conversationId: string;
  senderId: string;
  content: string;
  contentType: string;
  status: string;
  readBy: string[];
  sentAt?: string | Date;
  createdAt: string | Date;
  updatedAt: string | Date;
  metadata?: Record<string, any>;
  mediaUrls?: string[];
  mediaTypes?: string[];
  reactions?: ChatMessageReaction[];
}

export interface ChatConversation {
  id: string;
  type: string;
  status: string;
  participants: string[];
  lastMessage?: ChatMessage;
  messages?: ChatMessage[];
  unreadCount?: number;
  createdAt: Date;
  updatedAt?: Date;
  is_group?: boolean;
  metadata?: {
    contactId?: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
    name?: string;
    creator_id?: string;
    contacts?: Array<{
      id: string;
      first_name: string;
      last_name: string;
      email?: string;
      profile_image_url?: string;
    }>;
  };
}

export const chatApi = {
  // Get all conversations for the current user
  getConversations: async (): Promise<ChatConversation[]> => {
    try {
      const response = await apiClient.get('/communication/chat/conversations');
      // console.log('Conversations response:', response);
      
      // Check if response.data has a data property (API returns { success: true, data: [...] })
      if (response.data && response.data.data && Array.isArray(response.data.data)) {
        return response.data.data;
      } else if (Array.isArray(response.data)) {
        return response.data;
      } else {
        console.error('Unexpected response format:', response.data);
        return []; // Return empty array to avoid errors
      }
    } catch (error) {
      console.error('[ChatAPI] Error fetching conversations:', error);
      return []; // Return empty array on error
    }
  },

  // Get a specific conversation
  getConversation: async (conversationId: string): Promise<ChatConversation> => {
    try {
      if (!conversationId) {
        console.error('getConversation called with undefined conversationId');
        throw new Error('Conversation ID is required');
      }
      
      const response = await apiClient.get(`/communication/chat/conversations/${conversationId}`);
      // console.log('Get conversation response:', response);
      return response.data && response.data.data ? response.data.data : response.data;
    } catch (error) {
      console.error(`[ChatAPI] Error fetching conversation ${conversationId}:`, error);
      throw error;
    }
  },

  // Create a new conversation
  createConversation: async (
    participantIds: string[], 
    isGroup: boolean,
    metadata?: Record<string, any>
  ): Promise<ChatConversation> => {
    try {
      const response = await apiClient.post('/communication/chat/conversations', {
        participantIds,
        isGroup,
        metadata
      });
      
      // console.log('Create conversation API response:', response);
      
      // Handle the API response structure
      if (response.data && response.data.data) {
        return response.data.data;
      } else if (response.data) {
        return response.data;
      } else {
        console.error('Invalid API response:', response);
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('[ChatAPI] Error creating conversation:', error);
      throw error;
    }
  },

  // Get messages for a conversation
  getMessages: async (
    conversationId: string,
    limit?: number,
    before?: Date
  ): Promise<ChatMessage[]> => {
    const params = new URLSearchParams();
    if (limit) params.append('limit', limit.toString());
    if (before) params.append('before', before.toISOString());

    const response = await apiClient.get(
      `/communication/chat/conversations/${conversationId}/messages`,
      { params }
    );
    
    // Handle the API response structure
    if (response.data && response.data.data) {
      return response.data.data;
    } else if (response.data) {
      return response.data;
    } else {
      console.error('Invalid API response:', response);
      throw new Error('Invalid response from server');
    }
  },

  // Send a message
  sendMessage: async (
    conversationId: string,
    content: string,
    mediaUrls?: string[],
    mediaTypes?: string[]
  ): Promise<ChatMessage> => {
    const response = await apiClient.post(
      `/communication/chat/conversations/${conversationId}/messages`,
      { content, mediaUrls, mediaTypes }
    );
    return response.data;
  },

  // Mark messages as read
  markAsRead: async (
    conversationId: string,
    messageIds?: string[]
  ): Promise<boolean> => {
    const response = await apiClient.post(
      `/communication/chat/conversations/${conversationId}/read`,
      { messageIds }
    );
    return response.data.success;
  },

  // Add a reaction to a message
  addReaction: async (
    conversationId: string,
    messageId: string,
    emoji: string
  ): Promise<{ success: boolean; data: ChatMessageReaction[] }> => {
    const response = await apiClient.post(
      `/communication/chat/conversations/${conversationId}/messages/${messageId}/reactions`,
      { emoji }
    );
    // Handle the API response structure that includes success and data properties
    if (response.data && response.data.data) {
      return response.data;
    } else if (response.data) {
      return { success: true, data: response.data };
    }
    throw new Error('Invalid response format');
  },

  // Remove a reaction from a message
  removeReaction: async (
    conversationId: string,
    messageId: string,
    emoji: string
  ): Promise<void> => {
    await apiClient.delete(
      `/communication/chat/conversations/${conversationId}/messages/${messageId}/reactions/${encodeURIComponent(emoji)}`
    );
  },

  // Get reactions for a message
  getReactions: async (
    conversationId: string,
    messageId: string
  ): Promise<ChatMessageReaction[]> => {
    const response = await apiClient.get(
      `/communication/chat/conversations/${conversationId}/messages/${messageId}/reactions`
    );
    return response.data;
  },

  deleteMessage: async (conversationId: string, messageId: string, deleteForAll: boolean = false): Promise<void> => {
    const response = await apiClient.delete(`/communication/chat/conversations/${conversationId}/messages/${messageId}`, {
      data: { deleteForAll }
    });
    return response.data;
  },

  // Mark a message as unread
  markAsUnread: async (
    conversationId: string,
    messageId: string
  ): Promise<void> => {
    const response = await apiClient.post(
      `/communication/chat/conversations/${conversationId}/messages/${messageId}/unread`
    );
    return response.data;
  },

  // Add a participant to a conversation
  addParticipant: async (conversationId: string, participantId: string): Promise<void> => {
    try {
      await apiClient.post(`/communication/chat/conversations/${conversationId}/participants`, {
        participantId
      });
    } catch (error) {
      console.error('[ChatAPI] Error adding participant:', error);
      throw error;
    }
  },

  // Remove a participant from a conversation
  removeParticipant: async (conversationId: string, participantId: string): Promise<void> => {
    try {
      await apiClient.delete(`/communication/chat/conversations/${conversationId}/participants/${participantId}`);
    } catch (error) {
      console.error('[ChatAPI] Error removing participant:', error);
      throw error;
    }
  },

  // Update conversation metadata (e.g., group name)
  updateConversation: async (
    conversationId: string,
    metadata: Partial<ChatConversation['metadata']>
  ): Promise<ChatConversation> => {
    try {
      const response = await apiClient.patch(`/communication/chat/conversations/${conversationId}`, {
        metadata
      });
      
      if (response.data && response.data.data) {
        return response.data.data;
      } else if (response.data) {
        return response.data;
      }
      throw new Error('Invalid response format');
    } catch (error) {
      console.error('[ChatAPI] Error updating conversation:', error);
      throw error;
    }
  },

  // Delete a group chat (admin/owner only)
  deleteGroupChat: async (conversationId: string): Promise<void> => {
    try {
      await apiClient.delete(`/communication/chat/conversations/${conversationId}`);
    } catch (error) {
      console.error('[ChatAPI] Error deleting group chat:', error);
      throw error;
    }
  },

  // Leave a group chat (non-admin participants)
  leaveGroupChat: async (conversationId: string): Promise<void> => {
    try {
      await apiClient.post(`/communication/chat/conversations/${conversationId}/leave`);
    } catch (error) {
      console.error('[ChatAPI] Error leaving group chat:', error);
      throw error;
    }
  },

  // Check if user is group admin/owner
  isGroupAdmin: async (conversationId: string): Promise<boolean> => {
    try {
      const response = await apiClient.get(`/communication/chat/conversations/${conversationId}/role`);
      return response.data.isAdmin || false;
    } catch (error) {
      console.error('[ChatAPI] Error checking admin status:', error);
      return false;
    }
  },
};