/**
 * Announcements Page
 * 
 * Display Rules:
 * 1. Announcements are sorted by event_start_date in ascending order (upcoming events first)
 * 2. Only active announcements (is_active = true) are displayed
 * 3. Announcements are only visible if the current date is between their 
 *    announcement_start_date and announcement_end_date
 * 
 * Date Defaults:
 * 1. When creating a new announcement:
 *    - event_end_date defaults to 24 hours after event_start_date
 *    - announcement_end_date defaults to 24 hours after announcement_start_date
 * 
 * Validation Rules:
 * 1. Date Sequence Validation:
 *    - event_end_date must be at least one minute after event_start_date
 *    - announcement_end_date cannot be after event_end_date
 *    - announcement_start_date must be before or equal to announcement_end_date
 * 
 * These rules are enforced by the backend API but are documented here for clarity
 * and to ensure consistent behavior across the application.
 */

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { fetchAnnouncements, deleteAnnouncement } from '../services/announcements';
import type { Announcement } from '../services/announcements';
import { useAuth } from '../contexts/AuthContext';

const AnnouncementsPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [announcementToDelete, setAnnouncementToDelete] = useState<Announcement | null>(null);

  const loadAnnouncements = async () => {
    try {
      setLoading(true);
      const response = await fetchAnnouncements();
      setAnnouncements(response.items);
    } catch (err) {
      console.error('Error loading announcements:', err);
      setError('Failed to load announcements. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAnnouncements();
  }, []);

  const handleEdit = (announcement: Announcement) => {
    navigate(`/announcement/${announcement.id}`, { state: { announcement } });
  };

  const handleDeleteClick = (announcement: Announcement) => {
    setAnnouncementToDelete(announcement);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!announcementToDelete) return;

    try {
      await deleteAnnouncement(announcementToDelete.id);
      await loadAnnouncements(); // Reload the list
      setDeleteDialogOpen(false);
      setAnnouncementToDelete(null);
    } catch (err) {
      console.error('Error deleting announcement:', err);
      setError('Failed to delete announcement. Please try again later.');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={3}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Announcements</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/announcement/create')}
        >
          CREATE
        </Button>
      </Box>

      {announcements.length === 0 ? (
        <Typography>No announcements available.</Typography>
      ) : (
        <Grid container spacing={3}>
          {announcements.map((announcement) => (
            <Grid item xs={12} md={6} key={announcement.id}>
              <Card>
                <CardContent>
                  <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                    <Typography variant="h6" gutterBottom>
                      {announcement.name}
                    </Typography>
                    {user && announcement.author_id === user.id && (
                      <Box>
                        <IconButton 
                          size="small" 
                          onClick={() => handleEdit(announcement)}
                          aria-label="edit"
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton 
                          size="small" 
                          onClick={() => handleDeleteClick(announcement)}
                          aria-label="delete"
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                  <Typography variant="body2" color="textSecondary" paragraph>
                    {announcement.short_description}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    Type: {announcement.announcement_type} | Area: {announcement.announcement_area}
                  </Typography>
                  {announcement.venue && (
                    <Typography variant="body2">
                      Venue: {announcement.venue}
                    </Typography>
                  )}
                  {(announcement.city || announcement.country) && (
                    <Typography variant="body2">
                      Location: {[
                        announcement.city?.name,
                        announcement.country?.name
                      ].filter(Boolean).join(', ')}
                    </Typography>
                  )}
                  <Box mt={2}>
                    <Typography variant="caption" display="block">
                      Event: {new Date(announcement.event_start_date).toLocaleDateString()} - {new Date(announcement.event_end_date).toLocaleDateString()}
                    </Typography>
                    <Typography variant="caption" display="block">
                      Display: {new Date(announcement.announcement_start_date).toLocaleDateString()} - {new Date(announcement.announcement_end_date).toLocaleDateString()}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Announcement</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete "{announcementToDelete?.name}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AnnouncementsPage;