// Quiz configuration constants
export const QUIZ_CONSTANTS = {
    // Number of random questions to show from the full question pool
    QUESTIONS_PER_QUIZ: 8,
    
    // Time limit in seconds per question
    QUESTION_TIME_LIMIT: 15,
    
    // When to show warning (seconds remaining)
    TIMER_WARNING_THRESHOLD: 10,
  };