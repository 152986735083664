import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  TextField,
  Typography,
  Alert,
  CircularProgress,
  Paper,
  Link as MuiLink,
} from '@mui/material';
import { RegisterData, RegisterFormFields, EmailCheckResult } from '../../types';
import { useAuth } from '../../contexts/AuthContext';
import { api } from '../../services/api';

const RegisterForm = () => {
  const [error, setError] = useState<string>('');
  const [emailStatus, setEmailStatus] = useState<EmailCheckResult | null>(null);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const navigate = useNavigate();
  const { register: registerUser, isLoading } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    trigger,
  } = useForm<RegisterFormFields>({
    mode: 'onChange'
  });

  const password = watch('password');
  const confirmPassword = watch('confirmPassword');
  const passwordsMatch = password === confirmPassword;

  const checkEmailStatus = async (email: string) => {
    try {
      setIsCheckingEmail(true);
      const response = await api.get(`/auth/check-email/${encodeURIComponent(email)}`);
      setEmailStatus(response.data);
    } catch (error) {
      console.error('Email check failed:', error);
      setEmailStatus(null);
    } finally {
      setIsCheckingEmail(false);
    }
  };

  const handleEmailBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    const email = event.target.value;
    if (email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      await checkEmailStatus(email);
    }
  };

  const onSubmit = async (data: RegisterFormFields) => {
    try {
      setError('');

      if (!passwordsMatch) {
        setError('Passwords do not match');
        return;
      }

      if (emailStatus?.status !== 'AVAILABLE') {
        setError(emailStatus?.message || 'Email is not available for registration');
        return;
      }

      const { confirmPassword: _, ...registerData } = data;
      const response = await registerUser(registerData);
      
      if (response.status === 'WAITLIST') {
        navigate('/waiting-list-confirmation');
      } else if (response.status === 'SUCCESS') {
        navigate('/profile/me');
      }
    } catch (err: any) {
      console.error('Registration failed:', err);
      const errorMessage = err.response?.data?.message || err.message || 'Registration failed. Please try again.';
      setError(errorMessage);
    }
  };

  const getEmailHelperText = () => {
    if (errors.email) return errors.email.message;
    if (isCheckingEmail) return 'Checking email availability...';
    if (emailStatus) {
      if (emailStatus.status === 'EXISTING_USER') {
        return (
          <>
            {emailStatus.message}{' '}
            <MuiLink component={Link} to="/login" color="primary">
              Login here
            </MuiLink>
          </>
        );
      }
      if (emailStatus.status === 'INVALID_DOMAIN') {
        return emailStatus.message;
      }
      if (emailStatus.status === 'IN_WAITLIST') {
        return emailStatus.message;
      }
      return emailStatus.message;
    }
    return '';
  };

  return (
    <Box
      component={Paper}
      sx={{
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        maxWidth: 400,
        mx: 'auto',
      }}
    >
      <Typography variant="h5" component="h1" gutterBottom>
        Register for TrueConn
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <TextField
          margin="normal"
          required
          fullWidth
          label="First Name"
          autoFocus
          {...register('first_name', { 
            required: 'First name is required',
            minLength: {
              value: 2,
              message: 'First name must be at least 2 characters'
            }
          })}
          error={!!errors.first_name}
          helperText={errors.first_name?.message}
        />
        
        <TextField
          margin="normal"
          required
          fullWidth
          label="Last Name"
          {...register('last_name', { 
            required: 'Last name is required',
            minLength: {
              value: 2,
              message: 'Last name must be at least 2 characters'
            }
          })}
          error={!!errors.last_name}
          helperText={errors.last_name?.message}
        />
        
        <TextField
          margin="normal"
          required
          fullWidth
          label="Email Address"
          type="email"
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address',
            },
          })}
          error={!!errors.email || (emailStatus && emailStatus.status !== 'AVAILABLE')}
          helperText={getEmailHelperText()}
          onBlur={handleEmailBlur}
          InputProps={{
            endAdornment: isCheckingEmail && <CircularProgress size={20} />,
          }}
        />
        
        <TextField
          margin="normal"
          required
          fullWidth
          label="Password"
          type="password"
          {...register('password', {
            required: 'Password is required',
            minLength: {
              value: 8,
              message: 'Password must be at least 8 characters',
            },
            pattern: {
              value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?]{8,}$/,
              message: 'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character (!@#$%^&*()_+-=[]{};\'"\\|,.<>/?)',
            },
          })}
          error={!!errors.password}
          helperText={errors.password?.message}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          label="Confirm Password"
          type="password"
          {...register('confirmPassword', {
            required: 'Please confirm your password',
            validate: value => value === password || 'Passwords do not match'
          })}
          error={!!errors.confirmPassword || (password && confirmPassword && !passwordsMatch)}
          helperText={errors.confirmPassword?.message || (!passwordsMatch && password && confirmPassword ? 'Passwords do not match' : '')}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          label="Reason for Joining"
          multiline
          rows={3}
          {...register('reason', {
            required: 'Please provide a reason for joining',
            minLength: {
              value: 10,
              message: 'Please provide a more detailed reason (minimum 10 characters)',
            },
          })}
          error={!!errors.reason}
          helperText={errors.reason?.message}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={
            isLoading || 
            !isValid || 
            !passwordsMatch || 
            !password || 
            !confirmPassword || 
            Object.keys(errors).length > 0 ||
            (emailStatus && emailStatus.status !== 'AVAILABLE')
          }
        >
          {isLoading ? <CircularProgress size={24} /> : 'Register'}
        </Button>
      </form>
    </Box>
  );
};

export default RegisterForm;