export enum ContactStatus {
  POTENTIAL = 'POTENTIAL',
  LEAD = 'LEAD',
  ACTIVE = 'ACTIVE',
  ENGAGED = 'ENGAGED',
  VIP = 'VIP',
  INACTIVE = 'INACTIVE',
  FORMER = 'FORMER',
  DO_NOT_CONTACT = 'DO_NOT_CONTACT',
  ARCHIVED = 'ARCHIVED'
}

export enum PriorityLevel {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  VIP = 'VIP'
}

export enum ContactMethodType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  VIDEO_CALL = 'VIDEO_CALL',
  IN_PERSON = 'IN_PERSON',
  MESSAGE = 'MESSAGE',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA'
}

export enum InteractionType {
  CALL = 'CALL',
  EMAIL = 'EMAIL',
  MEETING = 'MEETING',
  NOTE = 'NOTE',
  TASK = 'TASK',
  OTHER = 'OTHER'
}

export enum AnnouncementType {
  GENERAL = 'GENERAL',
  EVENT = 'EVENT',
  NEWS = 'NEWS',
  MAINTENANCE = 'MAINTENANCE',
  ALERT = 'ALERT',
  PROMOTION = 'PROMOTION'
}

export enum AnnouncementArea {
  BUSINESS = 'BUSINESS',
  SCIENCE = 'SCIENCE',
  TECHNOLOGY = 'TECHNOLOGY',
  EDUCATION = 'EDUCATION',
  HEALTH = 'HEALTH',
  ENVIRONMENT = 'ENVIRONMENT',
  POLITICS = 'POLITICS',
  CULTURE = 'CULTURE',
  SPORTS = 'SPORTS',
  ENTERTAINMENT = 'ENTERTAINMENT',
  OTHER = 'OTHER'
} 