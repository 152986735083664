import { useState } from 'react';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  CircularProgress,
  TablePagination,
  Alert
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../../services/api';
import { OrganisationDialog } from '../../components/admin/OrganisationDialog';
import { debounce } from 'lodash';

interface Organisation {
  id: string;
  name: string;
  short_description: string;
  notes: string;
}

export const OrganisationsPage = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedOrganisation, setSelectedOrganisation] = useState<Organisation | null>(null);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: ['organisations', page, rowsPerPage, searchTerm],
    queryFn: async () => {
      const response = await api.get(`/organisations`, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          search: searchTerm
        }
      });
      return response.data;
    }
  });

  // Ensure we have default values if data is undefined
  const organisations = data?.organisations || [];
  const totalCount = data?.total || 0;

  const deleteMutation = useMutation({
    mutationFn: async (id: string) => {
      const checkResponse = await api.get(`/organisations/${id}/check-delete`);
      if (!checkResponse.data.canDelete) {
        throw new Error(checkResponse.data.reason || 'Cannot delete this organisation');
      }
      return api.delete(`/organisations/${id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organisations'] });
      setDeleteDialogOpen(false);
      setDeleteError(null);
    },
    onError: (error: any) => {
      setDeleteError(error.message || 'Failed to delete organisation');
    }
  });

  const handleSearchChange = debounce((value: string) => {
    setSearchTerm(value);
    setPage(0);
  }, 300);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (organisation: Organisation) => {
    setSelectedOrganisation(organisation);
    setDialogOpen(true);
  };

  const handleDelete = (organisation: Organisation) => {
    setSelectedOrganisation(organisation);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = () => {
    if (selectedOrganisation) {
      deleteMutation.mutate(selectedOrganisation.id);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h5">Organisations</Typography>
        <Button
          variant="contained"
          onClick={() => {
            setSelectedOrganisation(null);
            setDialogOpen(true);
          }}
        >
          + CREATE NEW
        </Button>
      </Box>

      <Box sx={{ mb: 3 }}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => handleSearchChange(e.target.value)}
        />
      </Box>

      <TableContainer component={Paper}>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Short Description</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organisations.map((organisation) => (
                <TableRow key={organisation.id}>
                  <TableCell>{organisation.name}</TableCell>
                  <TableCell>{organisation.short_description}</TableCell>
                  <TableCell>{organisation.notes}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEdit(organisation)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton 
                      onClick={() => handleDelete(organisation)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <OrganisationDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        organisation={selectedOrganisation}
      />

      <Dialog 
        open={deleteDialogOpen} 
        onClose={() => {
          setDeleteDialogOpen(false);
          setDeleteError(null);
        }}
      >
        <DialogTitle>Delete Organisation</DialogTitle>
        <DialogContent>
          {deleteError ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              {deleteError}
            </Alert>
          ) : (
            <Typography>
              Are you sure you want to delete this organisation? This action cannot be undone.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              setDeleteDialogOpen(false);
              setDeleteError(null);
            }}
            disabled={deleteMutation.isPending}
          >
            Cancel
          </Button>
          <Button 
            onClick={confirmDelete} 
            color="error" 
            variant="contained"
            disabled={deleteMutation.isPending || !!deleteError}
            startIcon={deleteMutation.isPending ? <CircularProgress size={20} /> : null}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}; 