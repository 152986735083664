import React from 'react';
import { Box, Typography, Paper, CircularProgress } from '@mui/material';

interface ScoreDisplayProps {
  score: number;
  passingScore?: number;
  loading?: boolean;
  showLabel?: boolean;
}

/**
 * A floating score display component that shows quiz score in the top right corner
 */
const ScoreDisplay: React.FC<ScoreDisplayProps> = ({
  score,
  passingScore = 70,
  loading = false,
  showLabel = true
}) => {
  // Determine if the score is passing
  const isPassing = score >= passingScore;
  
  // Determine color based on score
  const getColor = () => {
    if (score >= 90) return 'success.main';
    if (score >= 70) return 'primary.main';
    if (score >= 60) return 'warning.main';
    return 'error.main';
  };

  if (loading) {
    return (
      <Paper
        elevation={3}
        sx={{
          position: 'fixed',
          top: 70,
          right: 24,
          zIndex: 100,
          borderRadius: '50%',
          width: 80,
          height: 80,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={40} />
      </Paper>
    );
  }

  return (
    <Paper
      elevation={3}
      sx={{
        position: 'fixed',
        top: 70,
        right: 24,
        zIndex: 100,
        borderRadius: '50%',
        width: 80,
        height: 80,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'background.paper',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: 6,
        }
      }}
    >
      <Typography variant="h4" color={getColor()}>
        {Math.round(score)}%
      </Typography>
      
      {showLabel && (
        <Typography variant="caption" color="text.secondary">
          Score
        </Typography>
      )}
    </Paper>
  );
};

export default ScoreDisplay; 