import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { Layout } from './components/layout/Layout';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import ProfilePage from './pages/ProfilePage';
import PostsPage from './pages/PostsPage';
import PostPage from './pages/PostPage';
import CreatePostPage from './pages/CreatePostPage';
import AnnouncementsPage from './pages/AnnouncementsPage';
import CreateAnnouncementPage from './pages/CreateAnnouncementPage';
import EditAnnouncementPage from './pages/EditAnnouncementPage';
import UsersPage from './pages/UsersPage';
import SettingsPage from './pages/SettingsPage';
import NotFoundPage from './pages/NotFoundPage';
import { ProtectedRoute } from './components/guards/ProtectedRoute';
import { AdminRouteGuard } from './components/guards/AdminRouteGuard';
import { AdminOrSuperuserRoute } from './components/guards/AdminOrSuperuserRoute';
import { LanguagesPage } from './pages/admin/LanguagesPage';
import { WhitelistPage } from './pages/admin/WhitelistPage';
import { WaitinglistPage } from './pages/admin/WaitinglistPage';
import { OrganisationsPage } from './pages/admin/OrganisationsPage';
import SkillPage from './pages/SkillPage';
import EditProfilePage from './pages/EditProfilePage';
import SearchPage from './pages/SearchPage';
import { OneOnOneMeetupsPage } from './pages/OneOnOneMeetupsPage';
import { OneOnOneMeetupsStatsPage } from './pages/admin/OneOnOneMeetupsStatsPage';
import FAQPage from './pages/FAQPage';
import AboutPage from './pages/AboutPage';
import TermsPage from './pages/TermsPage';
import { AnalyticsPage } from './pages/Analytics';
import ContactsPage from './pages/ContactsPage';
import ContactDetailPage from './pages/ContactDetailPage';
import SuggestionsPage from './pages/SuggestionsPage';
import SuggestionSettingsPage from './pages/SuggestionSettingsPage';
import ConnectedLunchPage from './pages/ConnectedLunch/ConnectedLunchPage';
import WaitingListConfirmation from './pages/WaitingListConfirmation';
import ConsentPage from './pages/ConsentPage';
import ConsentManagementPage from './pages/admin/ConsentManagementPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import VerifyEmailPage from './pages/VerifyEmailPage';
import VerificationPendingPage from './pages/VerificationPendingPage';
import ToolsPage from './pages/ToolsPage';
import UuidGeneratorPage from './pages/tools/UuidGeneratorPage';
import SpeedNetworkingSchedulerPage from './pages/tools/SpeedNetworkingSchedulerPage';
import ScanContactPage from './pages/ScanContactPage';
import QuizzesPage from './pages/tools/QuizzesPage';
import ChatPage from './pages/Chat';

const Router = () => {
  // Not using isAuthenticated since it's handled by the route guards
  useAuth();

  return (
    <Routes>
      <Route element={<Layout />}>
        {/* Public routes */}
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/consent" element={<ConsentPage />} />
        <Route path="/waiting-list-confirmation" element={<WaitingListConfirmation />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/verify-email" element={<VerifyEmailPage />} />
        <Route path="/verification-pending" element={<VerificationPendingPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/terms" element={<TermsPage />} />
        
        {/* Tools routes - publicly accessible */}
        <Route path="/tools" element={<ToolsPage />} />
        <Route path="/tools/generateuuid4" element={<UuidGeneratorPage />} />
        <Route path="/tools/speed-networking-scheduler" element={<SpeedNetworkingSchedulerPage />} />
        <Route path="/tools/scan-contact" element={<ScanContactPage />} />
        <Route path="/tools/quizzes" element={<QuizzesPage />} />
        <Route path="/tools/quizzes/:quizId" element={<QuizzesPage />} />
        
        {/* Redirect legacy /scan-contact to /tools/scan-contact */}
        <Route path="/scan-contact" element={
          <Navigate replace to={`/tools/scan-contact${window.location.search}`} />
        } />

        {/* Protected routes */}
        <Route element={<ProtectedRoute />}>
          <Route path="/posts" element={<PostsPage />} />
          <Route path="/post/create" element={<CreatePostPage />} />
          <Route path="/post/:postId" element={<PostPage />} />
          
          <Route path="/announcements" element={<AnnouncementsPage />} />
          <Route path="/announcement/create" element={<CreateAnnouncementPage />} />
          <Route path="/announcement/:id" element={<EditAnnouncementPage />} />
          
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/profile/me" element={<ProfilePage />} />
          <Route path="/profile/:userId" element={<ProfilePage />} />
          <Route path="/profile/:userId/edit" element={<EditProfilePage />} />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/oneononemeetups" element={<OneOnOneMeetupsPage />} />
          <Route path="/contacts" element={<ContactsPage />} />
          <Route path="/contact/:id" element={<ContactDetailPage />} />
          <Route path="/suggestions" element={<SuggestionsPage />} />
          <Route path="/settings/suggestions" element={<SuggestionSettingsPage />} />
          <Route path="/connected-lunch" element={<ConnectedLunchPage />} />
          <Route path="/chat" element={<ChatPage />} />
          <Route path="/chat/:userId" element={<ChatPage />} />
          
          {/* Admin routes */}
          <Route element={<AdminRouteGuard />}>
            <Route path="/admin/languages" element={<LanguagesPage />} />
            <Route path="/admin/organisations" element={<OrganisationsPage />} />
            <Route path="/admin/whitelist" element={<WhitelistPage />} />
            <Route path="/admin/waitinglist" element={<WaitinglistPage />} />
            <Route path="/admin/oneononemeetups" element={<OneOnOneMeetupsStatsPage />} />
            <Route path="/admin/consent" element={<ConsentManagementPage />} />
          </Route>

          {/* Admin/Superuser routes */}
          <Route element={<AdminOrSuperuserRoute />}>
            <Route path="/admin/analytics" element={<AnalyticsPage />} />
          </Route>
        </Route>

        {/* Fallback route */}
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default Router;