import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Box, CircularProgress } from '@mui/material';

export const AdminRouteGuard = () => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  const isAdmin = user?.roles?.some(role => 
    ['Admin', 'Superuser', 'Content Manager'].includes(role.role.name)
  );

  if (!isAdmin) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};