import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Paper,
  TextField,
  Button,
  Typography,
  Grid,
  Alert,
  Avatar,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useAuth } from '../contexts/AuthContext';
import { api } from '../services/api';
import { User, Country, City, Organisation, Role } from '../types';
import { InputValidation } from '../utils/validation';
import { LanguageSelector } from '../components/LanguageSelector';
import { LocationSelector } from '../components/LocationSelector';
import { OrganisationSelector } from '../components/OrganisationSelector';
import { SkillAutocomplete } from '../components/SkillAutocomplete';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import { getImageUrl } from '../utils/imageUtils';
import CloudUpload from '@mui/icons-material/CloudUpload';
import ImageUploader from '../components/images/ImageUploader';
import { ImageType } from '../components/images/constants';
import { useTemporaryImage, EntityType } from '../hooks/useTemporaryImage';

type EditProfileInputs = Pick<User, 
  'first_name' | 
  'last_name' | 
  'position' | 
  'company' | 
  'short_description' |
  'long_description' |
  'notes' |
  'do_not_track'
>;

const EditProfilePage = () => {
  const { userId } = useParams();
  const { user: currentUser, updateUser } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const [imageError, setImageError] = useState<string>('');
  const [imageSuccess, setImageSuccess] = useState<string>('');
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);
  const [selectedOrganisation, setSelectedOrganisation] = useState<Organisation | null>(null);
  const [companyId, setCompanyId] = useState<string | null>(null);
  const [userSkills, setUserSkills] = useState<any[]>([]);
  const queryClient = useQueryClient();

  // Add state for profile data with proper typing
  const [localProfileData, setLocalProfileData] = useState<User | null>(null);
  const [success, setSuccess] = useState<string>('');

  const isOwnProfile = userId === currentUser?.id;
  const targetUserId = isOwnProfile ? currentUser?.id : userId;

  // Force refresh query cache on mount to ensure latest profile image
  useEffect(() => {
    if (targetUserId) {
      // Invalidate any cached profile data to ensure fresh image
      queryClient.invalidateQueries({ queryKey: ['userProfile', targetUserId] });
      queryClient.invalidateQueries({ queryKey: ['profile', targetUserId] });
      
      // Add a unique timestamp to force component re-render
      const timestamp = Date.now();
      console.log(`Forcing profile data refresh at ${timestamp}`);
    }
  }, [targetUserId, queryClient]);

  // Initialize temporary image hook
  const {
    imagePreview,
    imageKey,
    hasTemporaryImage,
    isLoading: imageLoading,
    isImageRemoved,
    uploadTemporaryImage,
    commitTemporaryImage,
    discardTemporaryImage,
    removeImage,
    handleCancel: handleImageCancel,
    updateInitialImage
  } = useTemporaryImage({
    entityType: EntityType.PROFILE,
    entityId: targetUserId || '',
    initialImageUrl: null, // Will be updated from profileData
    onError: setImageError,
    onSuccess: setImageSuccess
  });

  const { register, handleSubmit: formHandleSubmit, watch, formState: { errors, dirtyFields }, setValue, control, reset } = useForm<EditProfileInputs>({
    defaultValues: {
      do_not_track: false
    }
  });

  const hasManagementRoles = (roles?: Role[]) => {
    const managementRoles = ['Superuser', 'Admin', 'Content Manager'];
    return roles?.some(role => managementRoles.includes(role.role.name)) ?? false;
  };

  const { data: profileData, isLoading } = useQuery({
    queryKey: ['userProfile', targetUserId],
    queryFn: async () => {
      if (!targetUserId) return null;
      const response = await api.get(`/users/${targetUserId}`);
      return response.data;
    },
    enabled: !!targetUserId,
    onSuccess: (data) => {
      setLocalProfileData(data);
      updateInitialImage(data.profile_image_url);
      reset({
        first_name: data.first_name,
        last_name: data.last_name,
        position: data.position,
        short_description: data.short_description,
        long_description: data.long_description,
        notes: data.notes,
        do_not_track: data.do_not_track
      });
      setSelectedCountry(data.country || null);
      setSelectedCity(data.city || null);
      setSelectedOrganisation(data.company || null);
      setCompanyId(data.company?.id || null);
      setUserSkills(data.skills || []);
    }
  });

  useEffect(() => {
    if (!isOwnProfile && (!currentUser || !hasManagementRoles(currentUser.roles))) {
      console.log('Redirecting: Not own profile and no management roles');
      navigate('/profile');
      return;
    }

    // If it's own profile but no currentUser, redirect to login
    if (isOwnProfile && !currentUser) {
      console.log('Redirecting: Own profile but no current user');
      navigate('/login');
      return;
    }
  }, [isOwnProfile, currentUser, navigate]);

  useEffect(() => {
    if (profileData) {
      console.log('Setting form data from profile:', profileData);
      
      // Update the initial image in the hook
      // Only update if it has changed to prevent unnecessary re-renders
      updateInitialImage(profileData.profile_image_url);
      
      reset({
        first_name: profileData.first_name,
        last_name: profileData.last_name,
        position: profileData.position,
        short_description: profileData.short_description,
        long_description: profileData.long_description,
        notes: profileData.notes,
        do_not_track: profileData.do_not_track
      });
      setSelectedCountry(profileData.country || null);
      setSelectedCity(profileData.city || null);
      setSelectedOrganisation(profileData.company || null);
      setCompanyId(profileData.company?.id || null);
      setUserSkills(profileData.skills || []);
    }
  }, [profileData, reset]);

  useEffect(() => {
    const fetchUserSkills = async () => {
      try {
        const response = await api.get('/user-skills/me/skills');
        setUserSkills(response.data);
      } catch (error) {
        console.error('Failed to fetch user skills:', error);
        setError('Failed to load skills');
      }
    };

    if (targetUserId) {
      fetchUserSkills();
    }
  }, [targetUserId]);

  const handleSkillAdd = async (skillId: string, proficiency: 'BASIC' | 'ADVANCED' | 'EXPERT') => {
    try {
      const response = await api.post('/user-skills/me/skills', { skillId, proficiency });
      setUserSkills([...userSkills, response.data]);
    } catch (error) {
      console.error('Failed to add skill:', error);
      setError('Failed to add skill');
    }
  };

  const handleSkillRemove = async (skillId: string) => {
    try {
      await api.delete(`/user-skills/me/skills/${skillId}`);
      setUserSkills(userSkills.filter(us => us.skillId !== skillId));
    } catch (error) {
      console.error('Failed to remove skill:', error);
      setError('Failed to remove skill');
    }
  };

  const handleSkillProficiencyChange = async (skillId: string, proficiency: 'BASIC' | 'ADVANCED' | 'EXPERT') => {
    try {
      const response = await api.patch(`/user-skills/me/skills/${skillId}`, { proficiency });
      setUserSkills(userSkills.map(us => us.skillId === skillId ? response.data : us));
    } catch (error) {
      console.error('Failed to update skill proficiency:', error);
      setError('Failed to update skill proficiency');
    }
  };

  // Helper function to get only changed form values
  const dirtyValues = (dirtyFields: any, allValues: any) => {
    if (dirtyFields === true || !dirtyFields) {
      return allValues;
    }
    
    return Object.entries(dirtyFields).reduce((acc, [key, value]) => {
      if (value === true) {
        acc[key] = allValues[key];
      }
      
      if (typeof value === 'object') {
        acc[key] = dirtyValues(value, allValues[key]);
      }
      
      return acc;
    }, {} as any);
  };

  // Update onSubmit to use the new image hook
  const onSubmit = async (data: EditProfileInputs) => {
    try {
      const updateUserId = isOwnProfile ? currentUser?.id : targetUserId;
      console.log('Form data:', data);
      console.log('Dirty fields:', dirtyFields);
      
      // Get only the changed form values - stick to the original pattern
      const changedFormValues = dirtyValues(dirtyFields, data);
      console.log('Changed form values:', changedFormValues);

      // Create the update object with ONLY changed form values
      const changes: any = { ...changedFormValues };

      // Add non-form fields that changed
      if (companyId !== profileData?.company_id) {
        changes.company_id = companyId;
      }
      if (selectedCity?.id !== profileData?.city_id) {
        changes.city_id = selectedCity?.id || null;
      }
      if (selectedCountry?.id !== profileData?.country_id) {
        changes.country_id = selectedCountry?.id || null;
      }

      // Handle image changes
      let finalImageUrl: string | null = null;
      let hasImageChange = false;

      if (isImageRemoved) {
        // User wants to remove their profile image - ONLY set the profile_image_url field
        console.log('Removing profile image, isImageRemoved=true');
        hasImageChange = true;
        finalImageUrl = null;
        
        // This is the key part - make a separate, focused API call JUST for removing the image
        console.log('Making a dedicated API call to remove profile image');
        try {
          const response = await api.patch(`/users/${updateUserId}`, { profile_image_url: null });
          console.log('Image removal response:', response.data);
          
          // Update profile data with removal
          const updatedUser = {
            ...response.data,
            profile_image_url: null
          };
          
          if (isOwnProfile && updateUser) {
            updateUser(updatedUser);
          }
          
          // If there are other fields to update, continue with them after image removal
          if (Object.keys(changes).length > 0) {
            console.log('Continuing with other field updates after image removal');
          } else {
            // If no other changes, we're done
            console.log('No other changes, just image removal - complete');
            updateCachesAndNavigate(updatedUser, updateUserId);
            return;
          }
        } catch (error) {
          console.error('Failed to remove profile image:', error);
          setError('Failed to remove profile image');
          return;
        }
      } else if (hasTemporaryImage) {
        // User has uploaded a new image
        console.log('Committing temporary profile image');
        hasImageChange = true;
        finalImageUrl = await commitTemporaryImage();
        
        // If only changing the image with no other changes, don't make another request
        // commitTemporaryImage already updates the DB
        if (Object.keys(changes).length === 0) {
          console.log('Only changing profile image, no other changes');
          
          // Get the updated user to ensure we have the right data
          const userResponse = await api.get(`/users/${updateUserId}`);
          const updatedUser = {
            ...userResponse.data,
            profile_image_url: finalImageUrl
          };
          
          if (isOwnProfile && updateUser) {
            updateUser(updatedUser);
          }
          
          // Update caches and navigate
          updateCachesAndNavigate(updatedUser, updateUserId);
          return;
        }
      } else {
        // Keep existing image - don't include in changes
        finalImageUrl = profileData?.profile_image_url || null;
      }

      // Only make the API call if there are changes to form fields
      if (Object.keys(changes).length > 0) {
        console.log('Sending changed fields only:', changes);
        
        const response = await api.patch(`/users/${updateUserId}`, changes);
        console.log('Update response:', response.data);
        
        // Create a complete user object with the updated image URL
        const updatedUser = {
          ...response.data,
          profile_image_url: finalImageUrl
        };
        
        console.log('Updated user with final image URL:', updatedUser);
        
        if (isOwnProfile && updateUser) {
          // Update the current user with the new image URL
          updateUser(updatedUser);
        }

        // Update caches and navigate
        updateCachesAndNavigate(updatedUser, updateUserId);
      } else if (hasImageChange) {
        console.log('No form field changes, only image');
        
        // If we reach here, we already handled the image changes separately
        
        // Get the latest user data after image changes
        const userResponse = await api.get(`/users/${updateUserId}`);
        const updatedUser = {
          ...userResponse.data,
          profile_image_url: finalImageUrl
        };
        
        if (isOwnProfile && updateUser) {
          updateUser(updatedUser);
        }
        
        // Update caches and navigate
        updateCachesAndNavigate(updatedUser, updateUserId);
      } else {
        console.log('No changes detected');
        navigate(`/profile/${updateUserId}`);
      }
    } catch (error: any) {
      console.error('Failed to update profile:', error);
      setError(error.response?.data?.message || 'Failed to update profile');
    }
  };
  
  // Helper to update caches and navigate after a successful update
  const updateCachesAndNavigate = (updatedUser: any, userId: string | undefined) => {
    // Show success message
    setSuccess('Profile updated successfully');
    
    // Update ALL caches that might be using the old data
    if (userId) {
      queryClient.setQueryData(['userProfile', userId], updatedUser);
      
      // Update any searchResults that might include this user
      queryClient.setQueriesData({ queryKey: ['searchResults'] }, (oldData: any) => {
        if (!oldData) return oldData;
        // If the data is an array of users, update the matching user
        if (Array.isArray(oldData)) {
          return oldData.map(user => 
            user.id === userId 
              ? {...user, profile_image_url: updatedUser.profile_image_url}
              : user
          );
        }
        return oldData;
      });
      
      if (isOwnProfile) {
        queryClient.setQueryData(['currentUser'], updatedUser);
      }
      
      // Force invalidate all profile-related queries to ensure fresh data
      queryClient.invalidateQueries({ queryKey: ['userProfile'] });
      queryClient.invalidateQueries({ queryKey: ['currentUser'] });
      queryClient.invalidateQueries({ queryKey: ['searchResults'] });
      queryClient.invalidateQueries({ queryKey: ['profile'] });
      
      // Force an update to any cached avatar images by dispatching a custom event
      window.dispatchEvent(new CustomEvent('profile-image-updated', { 
        detail: { userId: userId }
      }));
      
      // Wait for cache updates to complete and navigate
      setTimeout(() => {
        navigate(`/profile/${userId}`);
      }, 300);
    }
  };

  // Handle cancel - discards temp images and navigates away
  const handleCancel = async () => {
    // Use the hook's cancel handler
    await handleImageCancel();
    
    // Navigate back to profile
    navigate(`/profile/${targetUserId}`);
  };

  return (
    <Box sx={{ py: 4 }}>
      <Paper sx={{ p: 3, maxWidth: 600, mx: 'auto' }}>
        <Typography variant="h5" gutterBottom>
          Edit Profile{!isOwnProfile && `: ${profileData?.first_name} ${profileData?.last_name}`}
        </Typography>

        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

        <form onSubmit={formHandleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={!profileData?.first_name ? "First Name" : ""}
                {...register('first_name', { required: 'First name is required' })}
                error={!!errors.first_name}
                helperText={errors.first_name?.message}
                inputProps={{ maxLength: 255 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={!profileData?.last_name ? "Last Name" : ""}
                {...register('last_name', { required: 'Last name is required' })}
                error={!!errors.last_name}
                helperText={errors.last_name?.message}
                inputProps={{ maxLength: 255 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={!profileData?.position ? "Position" : ""}
                {...register('position')}
                inputProps={{ maxLength: 255 }}
              />
            </Grid>
            <Grid item xs={12}>
              <OrganisationSelector
                selectedOrganisation={selectedOrganisation}
                onOrganisationChange={(org) => {
                  setSelectedOrganisation(org);
                  setCompanyId(org?.id || null);
                  setValue('company', org || null);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={!profileData?.short_description ? "Short Description" : ""}
                {...register('short_description')}
                multiline
                rows={4}
                inputProps={{ maxLength: 255 }}
                helperText={`${(watch('short_description')?.length || 0)}/255`}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={!profileData?.long_description ? "Long Description" : ""}
                {...register('long_description')}
                multiline
                rows={4}
                inputProps={{ maxLength: 1535 }}
                helperText={`${(watch('long_description')?.length || 0)}/1535`}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Profile Image
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {!imagePreview && !isImageRemoved && (
                  <Avatar
                    src={`${getImageUrl(profileData?.profile_image_url)}?t=${Date.now()}`}
                    alt={`${profileData?.first_name || ''} ${profileData?.last_name || ''}`}
                    sx={{ 
                      width: 100, 
                      height: 100,
                      bgcolor: 'primary.main' 
                    }}
                    key={imageKey}
                  >
                    {profileData?.first_name?.[0]}{profileData?.last_name?.[0]}
                  </Avatar>
                )}
                
                {/* Show default avatar when image is removed */}
                {isImageRemoved && (
                  <Avatar
                    sx={{ 
                      width: 100, 
                      height: 100,
                      bgcolor: 'primary.main' 
                    }}
                  >
                    {profileData?.first_name?.[0]}{profileData?.last_name?.[0]}
                  </Avatar>
                )}
                
                <ImageUploader
                  onImageSelect={uploadTemporaryImage}
                  onImageRemove={removeImage}
                  imagePreview={imagePreview}
                  imageType={ImageType.PROFILE}
                  aspectRatio={1}
                  isLoading={imageLoading}
                />
                
                {imageError && (
                  <Alert severity="error" sx={{ mt: 1 }}>
                    {imageError}
                  </Alert>
                )}
                {imageSuccess && (
                  <Alert severity="success" sx={{ mt: 1 }}>
                    {imageSuccess}
                  </Alert>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Skills
              </Typography>
              <SkillAutocomplete
                userSkills={userSkills}
                onSkillAdd={handleSkillAdd}
                onSkillRemove={handleSkillRemove}
                onSkillProficiencyChange={handleSkillProficiencyChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Privacy Settings
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={watch('do_not_track')}
                    onChange={(e) => setValue('do_not_track', e.target.checked)}
                  />
                }
                label="Do not track my activity"
              />
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                When enabled, this will limit the data we collect about your activity on the platform.
              </Typography>
            </Grid>
          </Grid>

          <Box sx={{ mt: 4 }}>
            <LanguageSelector userId={isOwnProfile ? undefined : targetUserId} />
          </Box>

          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>
              Location
            </Typography>
            <LocationSelector
              selectedCity={selectedCity}
              selectedCountry={selectedCountry}
              onCityChange={setSelectedCity}
              onCountryChange={setSelectedCountry}
            />
          </Box>

          <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Save Changes
            </Button>
            <Button
              variant="outlined"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default EditProfilePage;