import { api } from './api';

export interface Post {
  id: string;
  content: string;
  author_id: string;
  created_at: string;
  updated_at: string;
  author: {
    id: string;
    first_name: string;
    last_name: string;
    profile_image_url?: string;
  };
  likes: Array<{
    id: string;
    user: {
      id: string;
      first_name: string;
      last_name: string;
    };
  }>;
}

export const postService = {
  getPosts: async (page = 1, limit = 10): Promise<{ posts: Post[], pagination: any }> => {
    const response = await api.get('/posts', {
      params: { page, limit }
    });
    return response.data;
  },

  getPost: async (id: string): Promise<Post> => {
    const response = await api.get(`/posts/${id}`);
    return response.data;
  },

  createPost: async (data: { content: string, image?: File }) => {
    const formData = new FormData();
    formData.append('content', data.content);
    if (data.image) {
      formData.append('image', data.image);
    }
    const response = await api.post('/posts', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  },

  deletePost: async (id: string) => {
    await api.delete(`/posts/${id}`);
  },

  likePost: async (id: string) => {
    const response = await api.post(`/posts/${id}/like`);
    const token = localStorage.getItem('token');
    if (token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    return response.data;
  },

  unlikePost: async (id: string) => {
    const response = await api.delete(`/posts/${id}/like`);
    return response.data;
  },

  createComment: async (postId: string, content: string) => {
    const token = localStorage.getItem('token');
    if (token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    const response = await api.post(`/posts/${postId}/comments`, { content });
    return response.data;
  }
}; 