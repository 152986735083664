import React from 'react';
import { Box, Paper, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EmailIcon from '@mui/icons-material/Email';

const WaitingListConfirmation = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
        p: 2
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
          maxWidth: 600,
          width: '100%',
          textAlign: 'center'
        }}
      >
        <CheckCircleOutlineIcon
          sx={{
            fontSize: 64,
            color: 'success.main',
            mb: 2
          }}
        />
        
        <Typography variant="h4" component="h1" gutterBottom>
          Welcome to TrueConn!
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          You've Been Added to Our Waiting List
        </Typography>

        <Box sx={{ my: 4 }}>
          <Typography paragraph>
            Thank you for your interest in joining TrueConn. Your registration has been received and you have been added to our waiting list.
          </Typography>
          
          <Typography paragraph>
            Our administrators will review your application and get back to you soon.
          </Typography>

          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            gap: 1,
            my: 3,
            p: 2,
            bgcolor: 'info.light',
            borderRadius: 1,
            color: 'info.contrastText'
          }}>
            <EmailIcon />
            <Typography>
              We've sent you a confirmation email for your reference
            </Typography>
          </Box>

          <Typography paragraph color="text.secondary">
            If you believe this is an error or have any questions, please don't hesitate to contact our administrators.
          </Typography>
        </Box>

        <Button
          component={Link}
          to="/"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Return to Homepage
        </Button>
      </Paper>
    </Box>
  );
};

export default WaitingListConfirmation; 