import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  CircularProgress,
  Alert,
  Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../services/api';
import { Post } from '../types';
import ImageUploader from './images/ImageUploader';
import { ImageType } from './images/constants';
import { useAuth } from '../contexts/AuthContext';
import { getImageUrl } from '../utils/imageUtils';

interface UserRole {
  roleId: string;
  role: {
    name: string;
  };
}

interface PostDialogProps {
  post: Post | null;
  open: boolean;
  onClose: () => void;
  onSave?: () => void;
  authorId?: string;
}

export const PostDialog = ({ post, open, onClose, onSave, authorId }: PostDialogProps) => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({
    content: '',
  });
  const [error, setError] = useState<string | null>(null);
  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string>('');

  // Check if user can edit/delete the post
  const canModifyPost = post ? (
    auth?.user?.id === post.author.id || 
    auth?.user?.roles?.some((userRole: UserRole) => ['Content Manager', 'Admin', 'Superuser'].includes(userRole.role.name))
  ) : true;

  useEffect(() => {
    if (post) {
      setFormData({
        content: post.content,
      });
      setImage(null);
      if (post.image_url) {
        setImagePreview(getImageUrl(`/uploads/posts/${post.id}/${post.image_url}`));
      } else {
        setImagePreview('');
      }
    } else {
      setFormData({
        content: '',
      });
      setImage(null);
      setImagePreview('');
    }
  }, [post]);

  const mutation = useMutation({
    mutationFn: async (data: typeof formData) => {
      if (post && !canModifyPost) {
        throw new Error('You do not have permission to modify this post');
      }

      const formDataToSend = new FormData();
      formDataToSend.append('content', data.content.trim());
      if (image) {
        formDataToSend.append('image', image);
      }
      if (authorId) {
        formDataToSend.append('authorId', authorId);
      }

      if (post) {
        const response = await api.put(`/posts/${post.id}`, formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        return response.data;
      }
      const response = await api.post('/posts', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['posts'] });
      queryClient.invalidateQueries({ queryKey: ['userPosts'] });
      setImage(null);
      if (onSave) onSave();
      onClose();
    },
    onError: (error: any) => {
      console.error('API Error:', error.response || error);
      setError(error.response?.data?.message || 'Failed to save post');
    },
  });

  const handleImageSelect = (file: File) => {
    setImage(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const handleImageRemove = () => {
    setImage(null);
    setImagePreview('');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    
    if (!formData.content.trim()) {
      setError('Content is required');
      return;
    }

    mutation.mutate(formData);
  };

  if (!canModifyPost) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <Alert severity="error">
            You do not have permission to modify this post
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{post ? 'Edit Post' : 'Create Post'}</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                label="Content"
                value={formData.content}
                onChange={(e) => setFormData({ ...formData, content: e.target.value })}
                multiline
                rows={4}
                fullWidth
                required
                error={!!error && !formData.content.trim()}
                helperText={`${formData.content.length}/1535`}
              />
              
              <Box sx={{ mt: 2 }}>
                <ImageUploader
                  onImageSelect={handleImageSelect}
                  onImageRemove={handleImageRemove}
                  imagePreview={imagePreview}
                  imageType={ImageType.POST}
                  aspectRatio={16/9}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary"
              disabled={mutation.isPending || !formData.content.trim()}
            >
              {mutation.isPending ? <CircularProgress size={24} /> : (post ? 'Update' : 'Create')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}; 