/**
 * MessageInput.tsx
 * 
 * A React component that provides the message composition interface in the chat.
 * Handles text input, file attachments, and message sending.
 * 
 * Key features:
 * - Text message composition
 * - File attachments (images, documents)
 * - Send button with loading state
 * - Media preview
 * - Input validation
 * 
 * Related files:
 * - ChatContext.tsx: Provides message sending functionality
 * - ChatController.mjs: Backend API for message operations
 * - MessageList.tsx: Displays sent messages
 * - ChatService.mjs: Backend service for message processing
 */

// src/components/communication/MessageInput.tsx
import React, { useState, useRef } from 'react';
import { Box, TextField, IconButton, Tooltip, Badge } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ImageIcon from '@mui/icons-material/Image';
import MicIcon from '@mui/icons-material/Mic';
import { useChatContext } from '../../contexts/ChatContext';

interface MessageInputProps {
  onSendMessage?: (content: string) => Promise<void>;
}

const MessageInput: React.FC<MessageInputProps> = ({ onSendMessage }) => {
  const { sendMessage, activeConversation, selectConversation } = useChatContext();
  const [message, setMessage] = useState('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const [isSending, setIsSending] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSend = async () => {
    if (!message.trim() && attachments.length === 0) return;
    if (!activeConversation) return;
    if (isSending) return; // Prevent double-sending

    // Handle file uploads first if any
    let mediaUrls: string[] = [];
    let mediaTypes: string[] = [];

    try {
      setIsSending(true);

      if (attachments.length > 0) {
        // This would be replaced with your actual file upload logic
        // For now, we'll just simulate it
        mediaUrls = attachments.map(file => URL.createObjectURL(file));
        mediaTypes = attachments.map(file => file.type);
      }

      const messageContent = message.trim();

      // Use the provided onSendMessage if available, otherwise use the context's sendMessage
      if (onSendMessage) {
        await onSendMessage(messageContent);
      } else {
        await sendMessage(messageContent, mediaUrls, mediaTypes);
      }
      
      // Clear the input only after successful send
      setMessage('');
      setAttachments([]);

      // Refresh the conversation data
      if (activeConversation.id) {
        await selectConversation(activeConversation.id);
      }
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsSending(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      setAttachments(prev => [...prev, ...newFiles]);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileSelect}
          multiple
        />
        
        <Tooltip title="Attach files">
          <IconButton onClick={triggerFileInput} disabled={isSending}>
            <Badge badgeContent={attachments.length} color="primary" invisible={attachments.length === 0}>
              <AttachFileIcon />
            </Badge>
          </IconButton>
        </Tooltip>
        
        <Tooltip title="Send image">
          <IconButton 
            onClick={() => {
              if (fileInputRef.current) {
                fileInputRef.current.accept = 'image/*';
                fileInputRef.current.click();
              }
            }}
            disabled={isSending}
          >
            <ImageIcon />
          </IconButton>
        </Tooltip>
        
        <TextField
          fullWidth
          placeholder="Type a message..."
          variant="outlined"
          size="small"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          sx={{ mx: 1 }}
          multiline
          maxRows={4}
          disabled={isSending}
        />
        
        {(message.trim() || attachments.length > 0) && !isSending ? (
          <Tooltip title="Send message">
            <IconButton 
              color="primary" 
              onClick={handleSend}
            >
              <SendIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton 
            color="primary" 
            disabled={true}
          >
            <SendIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default MessageInput;