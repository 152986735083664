import React, { useState, useEffect } from 'react';
import {
  Typography,
  Paper,
  Button,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox,
  CircularProgress,
  Alert,
  TextField,
  FormHelperText,
  LinearProgress,
  Grid,
  Card,
  CardContent,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  List,
  Chip
} from '@mui/material';
import {
  CheckCircleOutline,
  HighlightOff,
  EmojiEvents,
  TrendingUp,
  School,
  Psychology
} from '@mui/icons-material';
import { api } from '../../services/api';
import { Quiz, QuizQuestion, QuizOption } from '../../types/quiz';
import ScoreDisplay from './ScoreDisplay';
import { QUIZ_CONSTANTS } from './quizConstants';

interface QuizComponentProps {
  quiz: Quiz;
  onComplete?: (submissionId: string) => void;
  onCancel?: () => void;
}

const QuizComponent: React.FC<QuizComponentProps> = ({ 
  quiz, 
  onComplete, 
  onCancel 
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<Record<string, string[]>>({});
  const [textAnswers, setTextAnswers] = useState<Record<string, string>>({});
  const [currentAnswer, setCurrentAnswer] = useState<string>('');
  const [currentTextAnswer, setCurrentTextAnswer] = useState<string>('');
  const [isCompleted, setIsCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [submissionId, setSubmissionId] = useState<string | null>(null);
  const [submissionData, setSubmissionData] = useState<any | null>(null);
  const [shuffledOptionsMap, setShuffledOptionsMap] = useState<Record<string, QuizOption[]>>({});
  const [currentFeedback, setCurrentFeedback] = useState<{
    isCorrect: boolean | null;
    explanation: string | null;
  } | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<Record<string, string[]>>({});
  const [randomizedQuestions, setRandomizedQuestions] = useState<QuizQuestion[]>([]);
  const [timeRemaining, setTimeRemaining] = useState(QUIZ_CONSTANTS.QUESTION_TIME_LIMIT);
  const [timerActive, setTimerActive] = useState(true);
  const [timeExpiredQuestions, setTimeExpiredQuestions] = useState(new Set());

  // Function to shuffle array using Fisher-Yates algorithm
  const shuffleArray = <T,>(array: T[]): T[] => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  // Initialize quiz with randomized questions
  useEffect(() => {
    if (quiz && quiz.questions) {
      // Reset all state variables when a new quiz is loaded
      setCurrentQuestionIndex(0);
      setAnswers({});
      setTextAnswers({});
      setCurrentAnswer('');
      setCurrentTextAnswer('');
      setIsCompleted(false);
      setLoading(false);
      setError(null);
      setSubmissionId(null);
      setSubmissionData(null);
      setShuffledOptionsMap({});
      setCurrentFeedback(null);
      setSelectedOptions({});
      setTimeRemaining(QUIZ_CONSTANTS.QUESTION_TIME_LIMIT);
      setTimerActive(true);
      setTimeExpiredQuestions(new Set());
      setRandomizedQuestions([]); // Clear previous questions before setting new ones

      // Filter out questions with invalid IDs
      const validQuestions = quiz.questions.filter(question => {
        return question.id && 
               typeof question.id === 'string' && 
               /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(question.id);
      });
      
      // Select random questions from valid ones
      const shuffledQuestions = shuffleArray([...validQuestions]);
      
      // Take all questions or limit to QUESTIONS_PER_QUIZ
      const selectedQuestions = shuffledQuestions.slice(0, Math.min(shuffledQuestions.length, QUIZ_CONSTANTS.QUESTIONS_PER_QUIZ));
      setRandomizedQuestions(selectedQuestions);
      console.log(`Selected ${selectedQuestions.length} random questions out of ${validQuestions.length} valid questions`);
      
      // Shuffle options for each question
      const shuffledMap: Record<string, QuizOption[]> = {};
      
      selectedQuestions.forEach(question => {
        if (question.question_type !== 'TEXT') {
          // Parse options if they're stored as a string
          let options = typeof question.options === 'string' 
            ? JSON.parse(question.options) 
            : question.options;
          
          // Convert string array to option objects if needed
          if (Array.isArray(options) && typeof options[0] === 'string') {
            options = options.map((text, index) => ({
              id: `option-${question.id}-${index}`,
              option_text: text,
              is_correct: Array.isArray(question.is_correct) 
                ? question.is_correct.includes(index + 1)
                : question.is_correct === index + 1
            }));
          } else if (Array.isArray(options)) {
            // Ensure all options have proper IDs
            options = options.map((option, index) => {
              if (typeof option === 'string') {
                return {
                  id: `option-${question.id}-${index}`,
                  option_text: option
                };
              } else if (!option.id) {
                return {
                  ...option,
                  id: `option-${question.id}-${index}`
                };
              }
              return option;
            });
          }
          
          // Only shuffle if it's not a true/false question
          if (question.question_type !== 'TRUE_FALSE') {
            shuffledMap[question.id] = shuffleArray([...options]);
          } else {
            shuffledMap[question.id] = options;
          }
        }
      });
      
      setShuffledOptionsMap(shuffledMap);
      
      // Start the timer for the first question
      setTimeRemaining(QUIZ_CONSTANTS.QUESTION_TIME_LIMIT);
    }
  }, [quiz]);

  // Timer functionality
  useEffect(() => {
    console.log('Timer state:', { timerActive, timeRemaining });
    
    let timer: NodeJS.Timeout | null = null;
    
    if (timerActive && timeRemaining > 0) {
      console.log('Starting timer interval');
      timer = setInterval(() => {
        setTimeRemaining(prev => {
          console.log('Updating time remaining:', prev - 1);
          return prev - 1;
        });
      }, 1000);
    } else if (timeRemaining === 0 && timerActive) {
      // Time expired, mark as incorrect
      console.log('Time expired, marking as incorrect');
      handleTimeExpired();
    }
    
    return () => {
      if (timer) {
        console.log('Clearing timer interval');
        clearInterval(timer);
      }
    };
  }, [timerActive, timeRemaining]);

  // Handle time expiration
  const handleTimeExpired = () => {
    const question = getCurrentQuestion();
    if (!question) return;
    
    // Add to time expired set
    setTimeExpiredQuestions(prev => {
      const updated = new Set(prev);
      updated.add(question.id);
      return updated;
    });
    
    // Determine the correct answer text to show
    let correctAnswerText = '';
    
    if (question.question_type === 'MULTIPLE_CHOICE') {
      // Handle both array and non-array formats for is_correct
      const correctOptions = [];
      
      if (Array.isArray(question.is_correct)) {
        // Handle 1-based indexing in is_correct
        for (const idx of question.is_correct) {
          const optionIndex = idx - 1; // Convert 1-based to 0-based
          if (optionIndex >= 0 && optionIndex < question.options.length) {
            const option = question.options[optionIndex];
            correctOptions.push(typeof option === 'string' ? option : option.option_text);
          }
        }
      } else if (typeof question.is_correct === 'number') {
        // Single number format (1-based)
        const optionIndex = question.is_correct - 1; // Convert 1-based to 0-based
        if (optionIndex >= 0 && optionIndex < question.options.length) {
          const option = question.options[optionIndex];
          correctOptions.push(typeof option === 'string' ? option : option.option_text);
        }
      }
      
      correctAnswerText = correctOptions.length > 0 
        ? `The correct answer was: ${correctOptions.join(', ')}. ` 
        : '';
      
      // Record a dummy answer for multiple choice questions
      if (question.question_type === 'MULTIPLE_CHOICE') {
        // For multiple choice, use selectedOptions state
        setSelectedOptions(prev => ({
          ...prev,
          [question.id]: ['timeout-placeholder']
        }));
      } else {
        // For single choice, use answers state
        setAnswers(prev => ({
          ...prev,
          [question.id]: ['timeout-placeholder']
        }));
      }
    } else if (question.question_type === 'SINGLE_CHOICE') {
      // Handle both array and non-array formats
      let correctIndex = -1;
      
      if (Array.isArray(question.is_correct) && question.is_correct.length > 0) {
        correctIndex = question.is_correct[0] - 1; // Convert 1-based to 0-based
      } else if (typeof question.is_correct === 'number') {
        correctIndex = question.is_correct - 1; // Convert 1-based to 0-based
      }
      
      if (correctIndex >= 0 && correctIndex < question.options.length) {
        const correctOption = question.options[correctIndex];
        correctAnswerText = `The correct answer was: ${typeof correctOption === 'string' ? correctOption : correctOption.option_text}. `;
      }
      
      // Record a dummy answer for single choice questions
      setAnswers(prev => ({
        ...prev,
        [question.id]: ['timeout-placeholder']
      }));
    } else if (question.question_type === 'TRUE_FALSE') {
      // Handle both array and non-array formats
      const correctValue = Array.isArray(question.is_correct) 
        ? question.is_correct[0] === 1 
        : question.is_correct === 1;
      correctAnswerText = `The correct answer was: ${correctValue ? 'True' : 'False'}. `;
      
      // Record a dummy answer for true/false questions
      setAnswers(prev => ({
        ...prev,
        [question.id]: ['timeout-placeholder']
      }));
    } else if (question.question_type === 'TEXT') {
      // Record a placeholder text answer
      setTextAnswers(prev => ({
        ...prev,
        [question.id]: 'No answer provided (time expired)'
      }));
    }
    
    // Mark as incorrect and show explanation
    const explanationText = typeof question.explanation === 'string' && question.explanation.trim() !== '' 
      ? question.explanation.trim() 
      : '';
      
    setCurrentFeedback({
      isCorrect: false,
      explanation: `Time expired! ${explanationText ? ' ' + explanationText : ''}`
    });
  };

  // Calculate progress as a percentage
  const calculateProgress = () => {
    const answeredQuestions = Object.keys(answers).length + Object.keys(textAnswers).length;
    const totalQuestions = randomizedQuestions.length;
    return (answeredQuestions / totalQuestions) * 100;
  };

  // Get current question from randomized set
  const getCurrentQuestion = (): QuizQuestion => {
    return randomizedQuestions[currentQuestionIndex] || {} as QuizQuestion;
  };

  const isTextQuestion = (): boolean => {
    return getCurrentQuestion().question_type === 'TEXT';
  };

  const isTrueFalseQuestion = (): boolean => {
    return getCurrentQuestion().question_type === 'TRUE_FALSE';
  };

  // Handle checkbox selection for multiple choice questions
  const handleCheckboxChange = (optionId: string) => {
    const question = getCurrentQuestion();
    if (!question) return;
    
    setSelectedOptions(prev => {
      const currentSelected = prev[question.id] || [];
      
      // If already selected, remove it, otherwise add it
      const newSelected = currentSelected.includes(optionId)
        ? currentSelected.filter(id => id !== optionId)
        : [...currentSelected, optionId];
      
      return {
        ...prev,
        [question.id]: newSelected
      };
    });
  };

  // Submit multiple choice answer
  const submitMultipleChoiceAnswer = async () => {
    const question = getCurrentQuestion();
    if (!question) return;
    
    const selected = selectedOptions[question.id] || [];
    if (selected.length === 0) return;
    
    // Save the answer
    setAnswers(prev => ({
      ...prev,
      [question.id]: selected
    }));
    
    try {
      // Validate the answer with the backend
      const response = await api.post(`/quizzes/${quiz.id}/validate-answer`, {
        question_id: question.id,
        selected_option_ids: selected
      });
      
      // Set feedback based on backend response
      setCurrentFeedback({
        isCorrect: response.data.is_correct,
        explanation: response.data.explanation || null
      });
    } catch (err) {
      console.error('Error validating answer:', err);
      // If there's an error, still show the answer was submitted
      setCurrentFeedback({
        isCorrect: null,
        explanation: question.explanation || null
      });
    }
  };

  // Handle selecting an answer for single choice questions
  const handleAnswer = async (optionId: string) => {
    const question = getCurrentQuestion();
    if (!question) return;
    
    setCurrentAnswer(optionId);
    
    setAnswers(prev => ({
      ...prev,
      [question.id]: [optionId]
    }));
    
    try {
      // Get shuffled options from our map, or parse from original if not shuffled yet
      const options = shuffledOptionsMap[question.id] || 
        (typeof question.options === 'string' ? JSON.parse(question.options) : question.options);
      
      // Debug info
      console.log('Submitting single choice answer:', {
        questionId: question.id,
        questionText: question.question_text,
        selectedOptionId: optionId,
        options: options,
        is_correct: question.is_correct,
        questionType: question.question_type
      });
      
      // Validate the answer with the backend
      const response = await api.post(`/quizzes/${quiz.id}/validate-answer`, {
        question_id: question.id,
        selected_option_ids: [optionId]
      });
      
      console.log('Backend response:', response.data);
      
      // Set feedback based on backend response
      setCurrentFeedback({
        isCorrect: response.data.is_correct,
        explanation: response.data.explanation || null
      });
      
      // Stop the timer when feedback is shown
      setTimerActive(false);
    } catch (err) {
      console.error('Error validating answer:', err);
      // If there's an error, still show the answer was submitted
      setCurrentFeedback({
        isCorrect: null,
        explanation: question.explanation || null
      });
      
      // Stop the timer when feedback is shown
      setTimerActive(false);
    }
  };

  // Handle text answers
  const handleTextAnswer = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const question = getCurrentQuestion();
    if (!question) return;
    
    const text = e.target.value;
    setCurrentTextAnswer(text);
    
    setTextAnswers(prev => ({
      ...prev,
      [question.id]: text
    }));
  };

  const submitTextAnswer = async () => {
    const question = getCurrentQuestion();
    if (!question) return;
    
    try {
      // Validate the answer with the backend
      const response = await api.post(`/quizzes/${quiz.id}/validate-answer`, {
        question_id: question.id,
        text_answer: currentTextAnswer
      });
      
      // Set feedback based on backend response
      setCurrentFeedback({
        isCorrect: response.data.is_correct,
        explanation: response.data.explanation || null
      });
      
      // Stop the timer when feedback is shown
      setTimerActive(false);
    } catch (err) {
      console.error('Error validating answer:', err);
      // If there's an error, still show the answer was submitted
      setCurrentFeedback({
        isCorrect: null,
        explanation: question.explanation || null
      });
      
      // Stop the timer when feedback is shown
      setTimerActive(false);
    }
  };

  // Handle moving to the next question
  const handleNextQuestion = () => {
    // Reset timer for next question
    setTimeRemaining(QUIZ_CONSTANTS.QUESTION_TIME_LIMIT);
    
    // If we're at the last question, submit the quiz
    if (currentQuestionIndex >= randomizedQuestions.length - 1) {
      handleSubmitQuiz();
      return;
    }
    
    // Move to the next question
    setCurrentQuestionIndex(prev => prev + 1);
    
    // Clear the current feedback
    setCurrentFeedback(null);
    setCurrentAnswer('');
    setCurrentTextAnswer('');
    
    // Start the timer for the next question
    setTimerActive(true);
  };

  // Determine if the next button should be enabled
  const isNextButtonEnabled = () => {
    // Always enable the next button if feedback is shown
    if (currentFeedback) return true;
    
    return false;
  };

  // Submit the quiz
  const handleSubmitQuiz = async () => {
    setLoading(true);
    
    try {
      // Create submission data that exactly matches the backend schema
      const submissionData = {
        answers: randomizedQuestions
          .map(question => {
            const timeExpired = timeExpiredQuestions.has(question.id);
            
            // For text questions
            if (question.question_type === 'TEXT') {
              return {
                question_id: question.id,
                text_answer: textAnswers[question.id] || (timeExpired ? 'No answer provided (time expired)' : ''),
                time_expired: timeExpired
              };
            }
            
            // For multiple choice questions
            if (question.question_type === 'MULTIPLE_CHOICE') {
              const selectedIds = selectedOptions[question.id] || [];
              
              // If time expired and no selection, use a placeholder
              const finalSelectedIds = selectedIds.length === 0 && timeExpired 
                ? ['timeout-placeholder'] 
                : selectedIds;
                
              return {
                question_id: question.id,
                selected_option_ids: finalSelectedIds,
                time_expired: timeExpired
              };
            }
            
            // For single choice questions (including TRUE_FALSE)
            const selectedIds = answers[question.id] || [];
            
            // If time expired and no selection, use a placeholder
            const finalSelectedIds = selectedIds.length === 0 && timeExpired 
              ? ['timeout-placeholder'] 
              : selectedIds;
              
            return {
              question_id: question.id,
              selected_option_ids: finalSelectedIds,
              time_expired: timeExpired
            };
          })
          .filter(answer => {
            // Only include answers that have either text or selected options
            if (answer.time_expired) return true;
            if (answer.text_answer) return true;
            if (answer.selected_option_ids && answer.selected_option_ids.length > 0) return true;
            return false;
          })
      };
      
      // Debug the submission data
      console.log('Submitting quiz data:', JSON.stringify(submissionData, null, 2));
      
      // Submit to backend
      const response = await api.post(`/quizzes/${quiz.id}/submit`, submissionData);
      
      // The backend returns submission_id, not id
      setSubmissionId(response.data.submission_id);
      setSubmissionData(response.data);
      setIsCompleted(true);
      
      // Call the onComplete callback if provided
      if (onComplete) {
        onComplete(response.data.submission_id);
      }
    } catch (err) {
      console.error('Error submitting quiz:', err);
      // Log more detailed error information
      if (err.response) {
        console.error('Error response data:', err.response.data);
        console.error('Error response status:', err.response.status);
        // Extract the actual error message from the response if available
        const errorMessage = err.response.data?.error || 
                            err.response.data?.message || 
                            (typeof err.response.data === 'string' ? err.response.data : 'Unknown error');
        setError(`Failed to submit quiz: ${errorMessage}`);
      } else if (err.request) {
        console.error('Error request:', err.request);
        setError('Failed to submit quiz: No response received from server');
      } else {
        console.error('Error message:', err.message);
        setError(`Failed to submit quiz: ${err.message || 'Unknown error'}`);
      }
    } finally {
      setLoading(false);
    }
  };

  // Handle canceling the quiz
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  // Render a quiz question
  const renderQuestion = () => {
    const question = getCurrentQuestion();
    if (!question) return null;
    
    // Get shuffled options from our map, or parse from original if not shuffled yet
    let options = shuffledOptionsMap[question.id] || [];
    
    // If options is empty but we have options in the question, try to process them
    if (options.length === 0 && question.options) {
      let rawOptions = typeof question.options === 'string' 
        ? JSON.parse(question.options) 
        : question.options;
        
      // Convert string array to option objects if needed
      if (Array.isArray(rawOptions) && typeof rawOptions[0] === 'string') {
        options = rawOptions.map((text, index) => ({
          id: `option-${question.id}-${index}`,
          option_text: text,
          is_correct: Array.isArray(question.is_correct) 
            ? question.is_correct.includes(index + 1)
            : question.is_correct === index + 1
        }));
      } else {
        // Ensure all options have IDs
        options = rawOptions.map((option, index) => {
          if (typeof option === 'string') {
            return {
              id: `option-${question.id}-${index}`,
              option_text: option
            };
          } else if (!option.id) {
            return {
              ...option,
              id: `option-${question.id}-${index}`
            };
          }
          return option;
        });
      }
      
      // Store in the shuffled map for future reference
      shuffledOptionsMap[question.id] = options;
    }

    return (
      <Box>
        
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          {/* Timer UI */}
          <Box sx={{ mb: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <Typography variant="body2">
                Time remaining: {timeRemaining} seconds
              </Typography>
              <Typography 
                variant="body2" 
                color={timeRemaining <= QUIZ_CONSTANTS.TIMER_WARNING_THRESHOLD ? 'error' : 'textPrimary'}
              >
                {timeRemaining <= QUIZ_CONSTANTS.TIMER_WARNING_THRESHOLD ? 'Hurry up!' : ''}
              </Typography>
            </Box>
            <LinearProgress 
              variant="determinate" 
              value={(timeRemaining / QUIZ_CONSTANTS.QUESTION_TIME_LIMIT) * 100}
              color={timeRemaining <= QUIZ_CONSTANTS.TIMER_WARNING_THRESHOLD ? 'error' : 'primary'}
            />
          </Box>
          
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="body2">
              Question {currentQuestionIndex + 1} of {randomizedQuestions.length}
            </Typography>
          </Box>
          
          <Typography variant="h6" gutterBottom>
            {question.question_text}
          </Typography>
          
          {isTextQuestion() ? (
            // Text question input
            <>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={currentTextAnswer}
                onChange={handleTextAnswer}
                placeholder="Type your answer here..."
                variant="outlined"
                sx={{ mt: 2 }}
                inputProps={{ maxLength: 1535 }}
                helperText={`${currentTextAnswer.length}/1535 characters`}
                disabled={!!currentFeedback}
              />
              {!currentFeedback && (
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={submitTextAnswer}
                  disabled={!currentTextAnswer.trim()}
                  sx={{ mt: 2 }}
                >
                  Submit Answer
                </Button>
              )}
            </>
          ) : question.question_type === 'MULTIPLE_CHOICE' ? (
            // Multiple choice question with checkboxes
            <>
              <FormControl component="fieldset" sx={{ width: '100%', mt: 2 }}>
                <FormGroup>
                  {options.map((option, index) => {
                    // Ensure option has an ID
                    const optionId = option.id || `option-${question.id}-${index}`;
                    const optionText = option.option_text || option;
                    const selected = (selectedOptions[question.id] || []).includes(optionId);
                    
                    return (
                      <FormControlLabel
                        key={optionId}
                        control={
                          <Checkbox 
                            checked={selected}
                            onChange={() => handleCheckboxChange(optionId)}
                            disabled={!!currentFeedback}
                          />
                        }
                        label={optionText}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
              {!currentFeedback && (
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={submitMultipleChoiceAnswer}
                  disabled={(selectedOptions[question.id] || []).length === 0}
                  sx={{ mt: 2 }}
                >
                  Submit Answer
                </Button>
              )}
            </>
          ) : (
            // Single choice question with radio buttons
            <FormControl component="fieldset" sx={{ width: '100%', mt: 2 }}>
              <RadioGroup 
                value={currentAnswer}
                onChange={(e) => handleAnswer(e.target.value)}
              >
                {options.map((option, index) => {
                  // Ensure option has an ID
                  const optionId = option.id || `option-${question.id}-${index}`;
                  const optionText = option.option_text || option;
                  
                  return (
                    <FormControlLabel
                      key={optionId}
                      value={optionId}
                      control={<Radio disabled={!!currentFeedback} />}
                      label={optionText}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          )}
          
          {/* Feedback section */}
          {currentFeedback && (
            <Box 
              mt={3} 
              p={2} 
              bgcolor={currentFeedback.isCorrect === true ? 'rgba(46, 125, 50, 0.15)' : 
                      currentFeedback.isCorrect === false ? 'rgba(211, 47, 47, 0.15)' : 'rgba(25, 118, 210, 0.15)'} 
              borderRadius={1}
              border={currentFeedback.isCorrect === true ? '1px solid rgba(46, 125, 50, 0.5)' : 
                     currentFeedback.isCorrect === false ? '1px solid rgba(211, 47, 47, 0.5)' : '1px solid rgba(25, 118, 210, 0.5)'}
            >
              <Typography 
                variant="subtitle1" 
                fontWeight="bold" 
                gutterBottom
                color={currentFeedback.isCorrect === true ? 'success.dark' : 
                      currentFeedback.isCorrect === false ? 'error.dark' : 'primary.dark'}
              >
                {currentFeedback.isCorrect === true ? '✓ Correct!' : 
                 currentFeedback.isCorrect === false ? '✗ Incorrect' : 'Answer Submitted'}
              </Typography>
              {currentFeedback.explanation && (
                <Typography variant="body2" color="text.primary" sx={{ fontWeight: 500 }}>
                  {currentFeedback.explanation}
                </Typography>
              )}
            </Box>
          )}
        </Paper>
        
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        
        <Box display="flex" justifyContent="space-between">
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleNextQuestion}
            disabled={!isNextButtonEnabled()}
          >
            {currentQuestionIndex < randomizedQuestions.length - 1 ? 'Next' : 'Complete'}
          </Button>
        </Box>
      </Box>
    );
  };

  // Render quiz results
  const renderResults = () => {
    if (!submissionData) return null;
    
    // Calculate score based only on the randomized questions
    const totalPoints = randomizedQuestions.reduce((sum, q) => sum + q.points, 0);
    const earnedPoints = submissionData.answers.reduce((sum, a) => sum + (a.points_earned || 0), 0);
    const scorePercentage = totalPoints > 0 ? Math.round((earnedPoints / totalPoints) * 100) : 0;
    const passingScore = quiz.passing_score || 70;
    const passed = scorePercentage >= passingScore;
    
    // Generate feedback based on score
    const getFeedback = () => {
      if (scorePercentage >= 90) {
        return "Excellent! You have an outstanding grasp of this subject.";
      } else if (scorePercentage >= 80) {
        return "Great job! You have a strong understanding of this material.";
      } else if (scorePercentage >= 70) {
        return "Good work! You've demonstrated a solid knowledge base.";
      } else if (scorePercentage >= 60) {
        return "Not bad! You've got the basics down, but there's room for improvement.";
      } else {
        return "This topic seems challenging for you. We recommend reviewing the material again.";
      }
    };
    
    // Map answers to questions for easy lookup
    const answersMap = new Map();
    submissionData.answers.forEach(answer => {
      answersMap.set(answer.question_id, answer);
    });
    
    return (
      <Box>
        <Typography variant="h4" component="h1" gutterBottom>
          {quiz.title} - Results
        </Typography>
        
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h5">Your Performance</Typography>
            <Chip 
              icon={passed ? <CheckCircleOutline /> : <HighlightOff />}
              label={passed ? "PASSED" : "FAILED"}
              color={passed ? "success" : "error"}
              variant="outlined"
            />
          </Box>
          
          <Grid container spacing={3} mb={3}>
            <Grid item xs={12} md={4}>
              <Card variant="outlined">
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <EmojiEvents sx={{ color: 'primary.main', mr: 1 }} />
                    <Typography variant="h6">Score</Typography>
                  </Box>
                  <Typography variant="h4" align="center" mt={2}>
                    {earnedPoints} / {totalPoints}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" align="center">
                    points earned
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <Card variant="outlined">
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <TrendingUp sx={{ color: 'primary.main', mr: 1 }} />
                    <Typography variant="h6">Percentage</Typography>
                  </Box>
                  <Typography variant="h4" align="center" mt={2}>
                    {scorePercentage}%
                  </Typography>
                  <Typography variant="body2" color="text.secondary" align="center">
                    of total points
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <Card variant="outlined">
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <School sx={{ color: 'primary.main', mr: 1 }} />
                    <Typography variant="h6">Required</Typography>
                  </Box>
                  <Typography variant="h4" align="center" mt={2}>
                    {passingScore}%
                  </Typography>
                  <Typography variant="body2" color="text.secondary" align="center">
                    passing score
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          
          <Box mb={3} p={2} bgcolor="background.default" borderRadius={1}>
            <Box display="flex" alignItems="center" mb={1}>
              <Psychology sx={{ color: 'primary.main', mr: 1 }} />
              <Typography variant="h6">Personalized Feedback</Typography>
            </Box>
            <Typography variant="body1">{getFeedback()}</Typography>
          </Box>
          
          <Divider sx={{ my: 3 }} />
          
          <Typography variant="h6" gutterBottom>Question Results</Typography>
          
          <List>
            {/* Only show the randomized questions that were actually presented to the user */}
            {randomizedQuestions.map((question, index) => {
              const answer = answersMap.get(question.id);
              const isCorrect = answer?.is_correct;
              
              // Get the correct answer for display
              let correctAnswerText = '';
              if (question.question_type === 'SINGLE_CHOICE' || question.question_type === 'MULTIPLE_CHOICE') {
                const correctIndices = question.is_correct.map(index => index - 1); // Convert 1-based to 0-based
                const correctOptions = correctIndices.map(index => question.options[index]).filter(Boolean);
                correctAnswerText = correctOptions.length > 0 
                  ? `Correct answer: ${correctOptions.map(opt => opt.option_text).join(', ')}` 
                  : '';
              } else if (question.question_type === 'TRUE_FALSE') {
                correctAnswerText = `Correct answer: ${question.is_correct[0] === 1 ? 'True' : 'False'}`;
              }
              
              return (
                <React.Fragment key={question.id}>
                  <ListItem alignItems="flex-start">
                    <ListItemIcon>
                      {isCorrect === true ? (
                        <CheckCircleOutline color="success" />
                      ) : isCorrect === false ? (
                        <HighlightOff color="error" />
                      ) : (
                        <Box ml={1}>{index + 1}.</Box>
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={question.question_text}
                      secondary={
                        <>
                          {answer?.text_answer && (
                            <Typography component="span" variant="body2" color="text.primary" display="block">
                              Your answer: {answer.text_answer}
                            </Typography>
                          )}
                          {answer?.selected_option_ids && answer.selected_option_ids.length > 0 && (
                            <Typography component="span" variant="body2" color="text.primary" display="block">
                              Your choice: {
                                answer.selected_option_ids.map(optionId => {
                                  const option = question.options.find(opt => opt.id === optionId);
                                  return option?.option_text || 'Unknown option';
                                }).join(', ')
                              }
                            </Typography>
                          )}
                          {!isCorrect && (
                            <Typography component="span" variant="body2" color="error" display="block">
                              {correctAnswerText}
                            </Typography>
                          )}
                          {answer?.points_earned !== undefined && (
                            <Typography component="span" variant="body2" color="text.secondary" display="block">
                              Points: {answer.points_earned} / {question.points}
                            </Typography>
                          )}
                          {question.explanation && (
                            <Typography component="span" variant="body2" color="text.secondary" display="block">
                              Explanation: {question.explanation}
                            </Typography>
                          )}
                        </>
                      }
                    />
                  </ListItem>
                  {index < randomizedQuestions.length - 1 && <Divider variant="inset" component="li" />}
                </React.Fragment>
              );
            })}
          </List>
        </Paper>
      </Box>
    );
  };

  // Render loading state
  const renderLoading = () => (
    <Box display="flex" justifyContent="center" alignItems="center" height="300px" flexDirection="column">
      <CircularProgress size={60} sx={{ mb: 3 }} />
      <Typography variant="h6">Analyzing your responses...</Typography>
    </Box>
  );

  if (loading) {
    return renderLoading();
  }

  if (isCompleted) {
    return renderResults();
  }

  return renderQuestion();
};

export default QuizComponent;