import { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Pagination, Button } from '@mui/material';
import { Comment } from '../types';
import { CommentCard } from './CommentCard';
import { AddCommentForm } from './AddCommentForm';
import { api } from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

interface CommentSectionProps {
  postId: string;
  onUpdate?: () => void;
}

type SortOption = 'newest' | 'oldest' | 'most_liked';

interface CommentResponse {
  comments: Comment[];
  pagination: {
    pages: number;
  };
}

export const CommentSection = ({ postId, onUpdate }: CommentSectionProps) => {
  const { user } = useAuth();
  const [page, setPage] = useState(1);
  const [error, setError] = useState<string | null>(null);
  const [sortBy, setSortBy] = useState<SortOption>('newest');

  const { data: commentsData, isLoading } = useQuery<CommentResponse, Error>({
    queryKey: ['comments', postId, page, sortBy],
    queryFn: async () => {
      const response = await api.get<CommentResponse>(
        `/posts/${postId}/comments?page=${page}&sort=${sortBy}`
      );
      return response.data;
    }
  });

  useEffect(() => {
    if (commentsData && onUpdate) {
      onUpdate();
    }
  }, [commentsData, onUpdate]);

  const comments = commentsData?.comments || [];
  const totalPages = commentsData?.pagination?.pages || 1;

  const handleCommentAdded = (newComment: Comment) => {
    // React Query will handle the updates
  };

  const handleCommentUpdated = ({ comment, deletedId }: { comment?: Comment; deletedId?: string }) => {
    if (deletedId) {
      // Remove comment and its replies
      comments.filter((c: Comment) => {
        if (c.id === deletedId) return false;
        if (c.replies) {
          c.replies = c.replies.filter((r: Comment) => r.id !== deletedId);
        }
        return true;
      });
    } else if (comment) {
      comments.map((c: Comment) => {
        if (c.id === comment.id) return comment;
        if (c.replies) {
          const replyIndex = c.replies.findIndex((r: Comment) => r.id === comment.id);
          if (replyIndex !== -1) {
            return {
              ...c,
              replies: [
                ...c.replies.slice(0, replyIndex),
                comment,
                ...c.replies.slice(replyIndex + 1)
              ]
            };
          }
        }
        return c;
      });
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
        <CircularProgress size={24} />
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">
          Comments
        </Typography>
        <Box>
          <Button
            size="small"
            color={sortBy === 'newest' ? 'primary' : 'inherit'}
            onClick={() => setSortBy('newest')}
          >
            Newest
          </Button>
          <Button
            size="small"
            color={sortBy === 'oldest' ? 'primary' : 'inherit'}
            onClick={() => setSortBy('oldest')}
          >
            Oldest
          </Button>
          <Button
            size="small"
            color={sortBy === 'most_liked' ? 'primary' : 'inherit'}
            onClick={() => setSortBy('most_liked')}
          >
            Most Liked
          </Button>
        </Box>
      </Box>
      
      {error && (
        <Typography color="error" sx={{ textAlign: 'center', py: 2 }}>
          {error}
        </Typography>
      )}
      
      {user && (
        <AddCommentForm 
          postId={postId} 
          onCommentAdded={(comment) => {
            handleCommentAdded(comment);
          }} 
        />
      )}

      {comments.map(comment => (
        <CommentCard
          key={comment.id}
          comment={comment}
          onCommentUpdated={handleCommentUpdated}
        />
      ))}

      {comments.length === 0 && (
        <Typography color="text.secondary" sx={{ textAlign: 'center', py: 2 }}>
          No comments yet
        </Typography>
      )}

      {totalPages > 1 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Pagination 
            count={totalPages} 
            page={page} 
            onChange={(_, value) => setPage(value)}
            size="small"
          />
        </Box>
      )}
    </Box>
  );
}; 