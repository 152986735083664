import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  IconButton,
  Chip,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Tooltip,
  Snackbar,
  Alert,
  Pagination,
  Stack,
} from '@mui/material';
import {
  Search as SearchIcon,
  Add as AddIcon,
  FilterList as FilterIcon,
  Sort as SortIcon,
  CloudUpload as ImportIcon,
  CloudDownload as ExportIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import ContactCard from '../components/contacts/ContactCard';
import ContactForm from '../components/contacts/ContactForm';
import { Contact, ContactStatus, PriorityLevel } from '../types/contact';
import { fetchContacts, createContact } from '../services/contacts';
import { api } from '../services/api';
import { Link } from 'react-router-dom';
import ImportProgress from '../components/contacts/ImportProgress';

const ContactsPage: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState<ContactStatus | ''>('');
  const [priorityFilter, setPriorityFilter] = useState<PriorityLevel | ''>('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isImporting, setIsImporting] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [importResults, setImportResults] = useState<null | {
    total: number;
    created: number;
    updated: number;
    failed: number;
    errors: Array<{ data: any; error: string }>;
  }>(null);
  const [isResultsDialogOpen, setIsResultsDialogOpen] = useState(false);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({ open: false, message: '', severity: 'success' });
  const [message, setMessage] = useState<string | null>(null);
  const [messageType, setMessageType] = useState<'success' | 'error'>('success');
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    loadContacts();
  }, [page, searchQuery, statusFilter, priorityFilter]);

  const loadContacts = async () => {
    try {
      setLoading(true);
      const response = await fetchContacts({
        page,
        search: searchQuery,
        status: statusFilter,
        priority: priorityFilter,
      });
      setContacts(response.contacts);
      setTotalPages(response.pagination.pages);
      setTotal(response.pagination.total);
    } catch (error) {
      console.error('Error loading contacts:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateContact = async (contactData: Partial<Contact>) => {
    try {
      const newContact = await createContact(contactData);
      setContacts(prev => [newContact, ...prev]);
      setIsCreateDialogOpen(false);
    } catch (error) {
      console.error('Error creating contact:', error);
    }
  };

  const handleStatusFilterChange = (event: SelectChangeEvent<ContactStatus | ''>) => {
    setStatusFilter(event.target.value as ContactStatus | '');
    setPage(1);
  };

  const handlePriorityFilterChange = (event: SelectChangeEvent<PriorityLevel | ''>) => {
    setPriorityFilter(event.target.value as PriorityLevel | '');
    setPage(1);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setPage(1);
  };

  const handleContactClick = (contactId: string) => {
    navigate(`/contact/${contactId}`);
  };

  const handleImportClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setShowProgress(true);
    setProgress(0);
    setIsImporting(true);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await api.post('/contacts/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });

      if (response.data.results) {
        const { created, updated, total, progress } = response.data.results;
        setTotal(total);
        setProgress(progress);
        
        setTimeout(() => {
          setShowProgress(false);
          setIsImporting(false);
          showMessage(`Import completed: ${created} created, ${updated} updated`, 'success');
          loadContacts();
        }, 1000);
      }
    } catch (error) {
      console.error('Import error:', error);
      setShowProgress(false);
      setIsImporting(false);
      showMessage('Failed to import contacts', 'error');
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleExport = async (format: 'csv' | 'vcf') => {
    setIsExporting(true);
    setIsExportDialogOpen(false);
    try {
      const response = await api.get(`/contacts/export?format=${format}`, {
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `contacts_${new Date().toISOString().split('T')[0]}.${format}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Export error:', error);
      setSnackbar({
        open: true,
        message: 'Failed to export contacts',
        severity: 'error'
      });
    } finally {
      setIsExporting(false);
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const showMessage = (text: string, type: 'success' | 'error') => {
    setMessage(text);
    setMessageType(type);
    setTimeout(() => setMessage(null), 5000);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 2 }}>
          <Typography variant="h4" component="h1">
            Contacts
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            ({total} total)
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept=".csv,.vcf"
            onChange={handleFileUpload}
          />
          
          <Tooltip title="Import Contacts">
            <Button
              variant="outlined"
              startIcon={<ImportIcon />}
              onClick={handleImportClick}
              disabled={isImporting}
            >
              {isImporting ? 'Importing...' : 'Import'}
            </Button>
          </Tooltip>

          <Tooltip title="Export Contacts">
            <Button
              variant="outlined"
              startIcon={<ExportIcon />}
              onClick={() => setIsExportDialogOpen(true)}
              disabled={isExporting}
            >
              {isExporting ? 'Exporting...' : 'Export'}
            </Button>
          </Tooltip>

          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setIsCreateDialogOpen(true)}
          >
            Add Contact
          </Button>
        </Box>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search contacts..."
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                value={statusFilter}
                label="Status"
                onChange={handleStatusFilterChange}
              >
                <MenuItem value="">All</MenuItem>
                {Object.values(ContactStatus).map((status: ContactStatus) => (
                  <MenuItem key={status} value={status}>
                    {status.replace(/_/g, ' ')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>Priority</InputLabel>
              <Select
                value={priorityFilter}
                label="Priority"
                onChange={handlePriorityFilterChange}
              >
                <MenuItem value="">All</MenuItem>
                {Object.values(PriorityLevel).map((priority: PriorityLevel) => (
                  <MenuItem key={priority} value={priority}>
                    {priority}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={3}>
        {loading ? (
          <Grid item xs={12}>
            <Typography>Loading...</Typography>
          </Grid>
        ) : contacts.length === 0 ? (
          <Grid item xs={12}>
            <Typography>No contacts found</Typography>
          </Grid>
        ) : (
          <>
            {contacts.map((contact) => (
              <Grid item xs={12} sm={6} md={4} key={contact.id}>
                <ContactCard
                  contact={contact}
                  onClick={() => handleContactClick(contact.id)}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                <Pagination 
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                  size="large"
                  showFirstButton
                  showLastButton
                />
              </Box>
            </Grid>
          </>
        )}
      </Grid>

      <Dialog
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Add New Contact</DialogTitle>
        <DialogContent>
          <ContactForm onSubmit={handleCreateContact} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isResultsDialogOpen}
        onClose={() => setIsResultsDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Import Results</DialogTitle>
        <DialogContent>
          {importResults && (
            <Box sx={{ py: 2 }}>
              <Typography variant="h6" gutterBottom>Summary</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography>Total Processed: {importResults.total}</Typography>
                  <Typography>Successfully Created: {importResults.created}</Typography>
                  <Typography>Successfully Updated: {importResults.updated}</Typography>
                  <Typography color="error">Failed: {importResults.failed}</Typography>
                </Grid>
              </Grid>
              
              {importResults.errors.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6" color="error" gutterBottom>
                    Errors
                  </Typography>
                  {importResults.errors.map((error, index) => (
                    <Typography key={index} color="error" variant="body2">
                      {error.error}
                    </Typography>
                  ))}
                </Box>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsResultsDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isExportDialogOpen}
        onClose={() => setIsExportDialogOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Export Contacts</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Choose export format:
          </Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => handleExport('csv')}
                disabled={isExporting}
              >
                CSV Format
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => handleExport('vcf')}
                disabled={isExporting}
              >
                vCard Format
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <ImportProgress 
        open={showProgress} 
        progress={progress} 
        total={total} 
      />

      <Snackbar 
        open={!!message} 
        autoHideDuration={5000} 
        onClose={() => setMessage(null)}
      >
        <Alert severity={messageType} onClose={() => setMessage(null)}>
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ContactsPage;
