import { Box, Container, Typography, Grid, Paper } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import SecurityIcon from '@mui/icons-material/Security';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';

const AboutPage = () => {
  const features = [
    {
      icon: <GroupIcon sx={{ fontSize: 40 }} />,
      title: "Professional Community",
      description: "You will be able to join a community of professionals sharing knowledge and opportunities."
    },
    {
      icon: <SecurityIcon sx={{ fontSize: 40 }} />,
      title: "Secure Platform",
      description: "Your data and interactions are protected with state of the art security."
    },
    {
      icon: <ConnectWithoutContactIcon sx={{ fontSize: 40 }} />,
      title: "Meaningful Connections",
      description: "Build valuable professional relationships through one-on-one meetups and discussions."
    }
  ];

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 6 }}>
        <Typography 
          variant="h1" 
          sx={{ 
            fontSize: { xs: '2.5rem', md: '3.5rem' },
            fontWeight: 700,
            textAlign: 'center',
            mb: 2
          }}
        >
          About Yeysus.com
        </Typography>
        
        <Typography 
          variant="h5" 
          sx={{ 
            textAlign: 'center', 
            mb: 6,
            opacity: 0.8,
            maxWidth: '800px',
            mx: 'auto'
          }}
        >
          My name is Jesus del Valle. I'm from Berlin, Germany. This is a personal project. I'm building an app for professional networking designed to spark meaningful connections and knowledge sharing—fueling career growth, innovation, and plenty of fun along the way.
        </Typography>

        <Grid container spacing={4} sx={{ mb: 6 }}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 4,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  backgroundColor: theme => 
                    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
                  '&:hover': {
                    backgroundColor: theme => 
                      theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                <Box sx={{ color: 'primary.main', mb: 2 }}>
                  {feature.icon}
                </Box>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                  {feature.title}
                </Typography>
                <Typography sx={{ opacity: 0.8 }}>
                  {feature.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ maxWidth: '800px', mx: 'auto', px: 2 }}>
          <Typography variant="body1" sx={{ mb: 3, opacity: 0.9 }}>
            I am originally from Havana, Cuba. I graduated as a Physicist from the Technical University of Dresden,
            obtained my PhD in Biology at the Max Planck Institute / Free University in Berlin, and have worked
            in Life Sciences / Pharmaceutical Industry for some 30 years. I am currently at Bayer 
            leading the Rapid Innovation Team at Bayer Pharmaceuticals IT.
          </Typography>

          <Typography variant="body1" sx={{ mb: 3, opacity: 0.9 }}>
            I founded the Bayer Grants4Apps G4A open innovation initiative in 2013, which evolved into a 
            digital health for life sciences accelerator and coworking program with locations in many countries. 
            I founded the global STEM4Health community, https://www.meetup.com/pro/stem4health/, to foster networking 
            between all stakeholders in the healthcare industry. One of my passions is co-organizing and participating in 
            Hackathons and have been Participant, Mentor, Judge, Key note speaker, Co-organizer in many, Universities of Yale, 
            MIT, Cambridge (UK), Duke, Princeton, Stanford, Harvard, and others. I love bringing together students and startups' 
            culture and technological knowledge with experienced professionals.
          </Typography>

          <Typography variant="body1" sx={{ mb: 3, opacity: 0.9 }}>
            Twitter/X: https://x.com/yeysus. LinkedIn: https://www.linkedin.com/in/yeysus/.
          </Typography>

          <Typography variant="body1" sx={{ mb: 3, opacity: 0.9 }}>
            This Professional Network was founded with a simple mission: to make professional 
            networking more meaningful, accessible, and fun. I believe that every student and professional 
            has unique knowledge to share and experiences to learn from. The platform facilitates genuine connections 
            through one-on-one meetups, skill-based matching, and focused discussions. 
            I am committed to create an environment where we all can grow together.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default AboutPage; 