import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Alert
} from '@mui/material';
import { api } from '../../services/api';

interface MeetupStats {
  totalMeetups: number;
  matchedMeetups: number;
  plannedMeetups: number;
  totalMatches: number;
}

export const OneOnOneMeetupsStatsPage = () => {
  const { data: stats, error, isLoading } = useQuery<MeetupStats>({
    queryKey: ['oneononemeetups-stats'],
    queryFn: async () => {
      console.log('Fetching stats...');
      const response = await api.get('/oneononemeetups/stats');
      console.log('Stats response:', response.data);
      return response.data;
    },
    onError: (error) => {
      console.error('Error fetching stats:', error);
    }
  });

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" p={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={4}>
        <Alert severity="error">
          Failed to load meetup statistics
        </Alert>
      </Box>
    );
  }

  return (
    <Box p={4}>
      <Typography variant="h4" gutterBottom>
        1:1 Meetups Statistics
      </Typography>
      
      <Grid container spacing={3} mt={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Total Meetups
              </Typography>
              <Typography variant="h3">
                {stats?.totalMeetups || 0}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Meetups with Matches
              </Typography>
              <Typography variant="h3">
                {stats?.matchedMeetups || 0}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Planned/Ongoing
              </Typography>
              <Typography variant="h3">
                {stats?.plannedMeetups || 0}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Total Matches
              </Typography>
              <Typography variant="h3">
                {stats?.totalMatches || 0}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}; 