import { api } from './api';

export interface AnalyticsResponse<T> {
  data: T;
  matomoEnabled?: boolean;
}

export const analyticsService = {
  checkHealth: async () => {
    try {
      const response = await api.get('/analytics/health');
      return response.data;
    } catch (error) {
      console.error('Error checking analytics health:', error);
      return { status: 'error', message: 'Could not connect to analytics service' };
    }
  },

  getEngagementStats: async (contentId: string, contentType: string) => {
    const response = await api.get(`/analytics/content/${contentType}/${contentId}/stats`);
    return response.data;
  },

  getContentPerformance: async (userId: string, period: 'day' | 'week' | 'month' = 'week') => {
    try {
      const response = await api.get(`/analytics/users/${userId}/performance`, {
        params: { period }
      });
      return {
        ...response.data,
        engagement: response.data.engagement || [],
        matomo: response.data.matomo || []
      };
    } catch (error) {
      console.error('Error fetching content performance:', error);
      return {
        engagement: [],
        matomo: []
      };
    }
  },

  getSiteOverview: async () => {
    try {
      const response = await api.get('/analytics/site-analytics');
      return {
        totalUsers: response.data.totalUsers || 0,
        activeUsers: response.data.activeUsers || 0,
        userAnalytics: response.data.userAnalytics || [],
        popularContent: response.data.popularContent || [],
        matomo: response.data.matomo || {
          visits: 0,
          uniqueVisitors: 0,
          pageviews: 0,
          avgTimeOnSite: 0,
          bounceRate: 0,
          actions: {
            pageviews: 0,
            downloads: 0,
            outlinks: 0,
            searches: 0
          }
        }
      };
    } catch (error) {
      console.error('Error fetching site overview:', error);
      return {
        totalUsers: 0,
        activeUsers: 0,
        userAnalytics: [],
        popularContent: [],
        matomo: null
      };
    }
  },

  getUserAnalytics: async (userId: string) => {
    try {
      const response = await api.get(`/analytics/users/${userId}/analytics`);
      return response.data;
    } catch (error) {
      console.error('Error fetching user analytics:', error);
      return null;
    }
  },

  getEngagementTrends: async (userId: string) => {
    try {
      const response = await api.get(`/analytics/users/${userId}/trends`);
      return {
        ...response.data,
        matomo: response.data.matomo || {
          dailyVisits: {},
          actionTypes: {},
          timeOnSite: []
        }
      };
    } catch (error) {
      console.error('Error fetching engagement trends:', error);
      return {
        matomo: {
          dailyVisits: {},
          actionTypes: {},
          timeOnSite: []
        }
      };
    }
  },

  getContentRecommendations: async (userId: string) => {
    try {
      const response = await api.get(`/analytics/users/${userId}/recommendations`);
      return {
        fromMatomo: response.data.fromMatomo || [],
        fromDatabase: response.data.fromDatabase || []
      };
    } catch (error) {
      console.error('Error fetching content recommendations:', error);
      return {
        fromMatomo: [],
        fromDatabase: []
      };
    }
  },

  getHourlyActivity: async (userId: string) => {
    try {
      const response = await api.get(`/analytics/users/${userId}/activity/hourly`);
      return response.data;
    } catch (error) {
      console.error('Error fetching hourly activity:', error);
      return [];
    }
  },

  getTopContent: async (userId: string, period: string = 'week') => {
    try {
      const response = await api.get(`/analytics/users/${userId}/content/top`, {
        params: { period }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching top content:', error);
      return [];
    }
  },

  getEngagementRate: async (contentId: string, contentType: string) => {
    try {
      const response = await api.get(`/analytics/content/${contentType}/${contentId}/engagement-rate`);
      return response.data;
    } catch (error) {
      console.error('Error fetching engagement rate:', error);
      return {
        rate: 0,
        total: 0,
        breakdown: {}
      };
    }
  }
};