import { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { EngagementDashboard } from '../components/analytics/EngagementDashboard';
import { Box, Container, Typography, Alert } from '@mui/material';

export const AnalyticsPage = () => {
  const { user } = useAuth();
  const [error, setError] = useState<string | null>(null);

  if (!user) {
    return <Alert severity="error">Please login to view analytics</Alert>;
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" gutterBottom>
          Your Analytics Dashboard
        </Typography>
        <EngagementDashboard userId={user.id} />
      </Box>
    </Container>
  );
}; 