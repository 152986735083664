import { useState } from 'react';
import { Box, TextField, Button } from '@mui/material';
import { api } from '../services/api';
import { Comment, User } from '../types';
import { MentionInput } from './MentionInput';
import { useMutation, useQueryClient } from '@tanstack/react-query';

interface AddCommentFormProps {
  postId: string;
  onCommentAdded: (comment: Comment) => void;
}

export const AddCommentForm = ({ postId, onCommentAdded }: AddCommentFormProps) => {
  const [content, setContent] = useState('');
  const [mentions, setMentions] = useState<User[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();

  const addCommentMutation = useMutation({
    mutationFn: async (content: string) => {
      const response = await api.post(`/posts/${postId}/comments`, { content });
      return response.data;
    },
    onSuccess: () => {
      setContent('');
      if (onCommentAdded) {
        onCommentAdded();
      }
      queryClient.invalidateQueries({ queryKey: ['comments', postId] });
      queryClient.invalidateQueries({ queryKey: ['commentCount', postId] });
    },
    onError: (error) => {
      console.error('Failed to add comment:', error);
    }
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!content.trim()) return;

    addCommentMutation.mutate(content);
    setMentions([]);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, mb: 3 }}>
      <MentionInput
        value={content}
        onChange={setContent}
        onMentionsChange={setMentions}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
        <Button 
          type="submit" 
          variant="contained" 
          disabled={!content.trim() || isSubmitting}
          size="small"
        >
          Comment
        </Button>
      </Box>
    </Box>
  );
}; 