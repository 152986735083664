import React from 'react';
import { useAuth } from '../hooks/useAuth';
import { Box, Button, Typography, Paper } from '@mui/material';

const CookieConsent: React.FC = () => {
  const { cookiesAccepted, acceptCookies } = useAuth();

  if (cookiesAccepted) {
    return null;
  }

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        p: 2,
        backgroundColor: (theme) => 
          theme.palette.mode === 'dark' 
            ? 'black' 
            : 'rgba(255, 255, 255, 0.95)',
        color: (theme) => 
          theme.palette.mode === 'dark' 
            ? 'white' 
            : 'black',
        zIndex: 9999,
        boxShadow: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ flex: 1, mr: 2 }}>
        <Typography variant="body1">
          We use cookies to enable secure login sessions and improve your experience.
          These cookies are necessary for the website to function properly.
          By continuing to use this site, you accept our use of cookies.
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={acceptCookies}
        >
          Accept
        </Button>
      </Box>
    </Paper>
  );
};

export default CookieConsent;