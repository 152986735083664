import { useState } from 'react';
import { Comment } from '../types';
import { Box, Typography, IconButton, Avatar, Menu, MenuItem, Collapse, Fade, TextField, Button, Grow, Slide } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAuth } from '../contexts/AuthContext';
import { api } from '../services/api';
import { Link } from 'react-router-dom';
import { ReplyForm } from './ReplyForm';
import ReplyIcon from '@mui/icons-material/Reply';
import { RichTextEditor } from './RichTextEditor';
import { EmojiReactions } from './EmojiReactions';
import createDOMPurifier from 'isomorphic-dompurify';
import { marked } from 'marked';
import { getImageUrl } from '../utils/imageUtils';

interface CommentCardProps {
  comment: Comment;
  onCommentUpdated: (update: { comment?: Comment; deletedId?: string }) => void;
  onReply?: (parentComment: Comment) => void;
  isReply?: boolean;
}

const DOMPurifier = createDOMPurifier();

// Configure marked with all needed options
marked.setOptions({
  headerIds: false,
  mangle: false,
  breaks: true,      // Convert \n to <br>
  gfm: true,         // GitHub Flavored Markdown
  sanitize: false,   // Let DOMPurify handle sanitization
  smartLists: true,  // Smarter list behavior
});

// Configure DOMPurify to allow certain HTML tags
const purifyConfig = {
  ALLOWED_TAGS: [
    'p', 'br', 'b', 'i', 'em', 'strong', 'a', 'ul', 'ol', 'li',
    'code', 'pre', 'blockquote', 'hr', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'
  ],
  ALLOWED_ATTR: ['href', 'target', 'rel']
};

export const CommentCard = ({ comment, onCommentUpdated, onReply, isReply }: CommentCardProps) => {
  const { user } = useAuth();
  const [isLiked, setIsLiked] = useState(
    user ? comment.engagements?.some(eng => 
      eng.user_id === user.id && 
      eng.type === 'REACTION' && 
      eng.reaction_type === 'HEART'
    ) : false
  );
  const [likeCount, setLikeCount] = useState(
    comment.engagements?.filter(eng => 
      eng.type === 'REACTION' && 
      eng.reaction_type === 'HEART'
    ).length || 0
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editContent, setEditContent] = useState(comment.content);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [replyFormOpen, setReplyFormOpen] = useState(false);
  const [reactions, setReactions] = useState(comment.reactions || []);

  const canModify = user && (
    comment.author.id === user.id ||
    (user.roles?.some(role => 
      ['Superuser', 'Admin', 'Content Manager'].includes(role.role.name)
    ) && comment.author.is_managed)
  );

  const handleLike = async () => {
    try {
      if (isLiked) {
        await api.delete(`/comments/${comment.id}/like`);
        setLikeCount(prev => prev - 1);
      } else {
        await api.post(`/comments/${comment.id}/like`);
        setLikeCount(prev => prev + 1);
      }
      setIsLiked(!isLiked);
      
      onCommentUpdated({
        comment: {
          ...comment,
          engagements: isLiked 
            ? comment.engagements.filter(eng => eng.user_id !== user?.id)
            : [...comment.engagements, {
                id: 'temp-id',
                user_id: user?.id!,
                content_id: comment.id,
                content_type: 'COMMENT',
                type: 'REACTION',
                reaction_type: 'HEART',
                user: user!
              }]
        }
      });
    } catch (error) {
      console.error('Failed to toggle like:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await api.delete(`/comments/${comment.id}`);
      onCommentUpdated({ deletedId: comment.id });
      setAnchorEl(null);
    } catch (error) {
      console.error('Failed to delete comment:', error);
    }
  };

  const handleEdit = async () => {
    if (!editContent.trim()) return;
    setIsSubmitting(true);
    try {
      console.log('Sending comment update:', {
        commentId: comment.id,
        content: editContent
      });
      
      const response = await api.put(`/comments/${comment.id}`, {
        content: editContent
      });
      console.log('Response:', response.data);
      
      const updatedComment = {
        ...comment,
        content: editContent,
        updated_at: new Date().toISOString()
      };
      onCommentUpdated({ comment: updatedComment });
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to update comment:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReplyAdded = (reply: Comment) => {
    onCommentUpdated({
      comment: {
        ...comment,
        replies: [...(comment.replies || []), reply]
      }
    });
  };

  return (
    <Fade in={true}>
      <Box sx={{ mb: isReply ? 1 : 2, pl: isReply ? 4 : 0 }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
          <Link 
            to={`/profile/${comment.author.id}`}
            style={{ textDecoration: 'none', marginRight: '12px' }}
          >
            <Avatar
              src={getImageUrl(comment.author.profile_image_url)}
              alt={comment.author.first_name}
              sx={{
                width: 32,
                height: 32,
                mr: 1.5,
              }}
            >
              {!comment.author.profile_image_url && `${comment.author.first_name[0]}${comment.author.last_name[0]}`}
            </Avatar>
          </Link>
          <Collapse in={true} timeout={500}>
            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Link 
                  to={`/profile/${comment.author.id}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <Typography variant="subtitle2">
                    {comment.author.first_name} {comment.author.last_name}
                  </Typography>
                </Link>
                {canModify && (
                  <>
                    <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={() => setAnchorEl(null)}
                    >
                      <MenuItem onClick={() => setIsEditing(true)}>Edit</MenuItem>
                      <MenuItem onClick={handleDelete}>Delete</MenuItem>
                    </Menu>
                  </>
                )}
              </Box>
              {isEditing ? (
                <Box sx={{ mt: 1 }}>
                  <RichTextEditor
                    value={editContent}
                    onChange={setEditContent}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, gap: 1 }}>
                    <Button 
                      size="small" 
                      onClick={() => setIsEditing(false)}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={handleEdit}
                      disabled={!editContent.trim() || isSubmitting}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              ) : (
                <>
                  <Typography 
                    variant="body2" 
                    sx={{ mt: 0.5 }}
                    dangerouslySetInnerHTML={{ 
                      __html: DOMPurifier.sanitize(
                        marked.parse(comment.content),
                        purifyConfig
                      )
                    }}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                    <IconButton 
                      size="small" 
                      onClick={handleLike}
                      color={isLiked ? "primary" : "default"}
                    >
                      <FavoriteIcon fontSize="small" />
                    </IconButton>
                    <Typography variant="caption" sx={{ ml: 0.5 }}>
                      {likeCount}
                    </Typography>
                    <Typography variant="caption" sx={{ ml: 2, color: 'text.secondary' }}>
                      {new Date(comment.created_at).toLocaleString()}
                    </Typography>
                    {!isReply && comment.replies && comment.replies.length > 0 && (
                      <Typography 
                        variant="caption" 
                        sx={{ ml: 2, color: 'text.secondary' }}
                      >
                        {comment.replies.length} {comment.replies.length === 1 ? 'reply' : 'replies'}
                      </Typography>
                    )}
                    {!isReply && (
                      <Button
                        size="small"
                        onClick={() => setReplyFormOpen(true)}
                        sx={{ ml: 2 }}
                        startIcon={<ReplyIcon />}
                      >
                        Reply
                      </Button>
                    )}
                    <EmojiReactions
                      commentId={comment.id}
                      reactions={reactions}
                      onReactionAdded={(reaction) => {
                        setReactions(prev => [...prev, reaction]);
                      }}
                      onReactionRemoved={(emoji) => {
                        setReactions(prev => prev.filter(r => r.emoji !== emoji));
                      }}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Collapse>
        </Box>

        {replyFormOpen && (
          <Slide direction="right" in={replyFormOpen} mountOnEnter unmountOnExit>
            <Box>
              <ReplyForm
                commentId={comment.id}
                onReplyAdded={handleReplyAdded}
                onCancel={() => setReplyFormOpen(false)}
              />
            </Box>
          </Slide>
        )}

        {/* Show replies */}
        {comment.replies?.map(reply => (
          <Grow in={true} key={reply.id}>
            <Box>
              <CommentCard
                comment={reply}
                onCommentUpdated={onCommentUpdated}
                isReply={true}
              />
            </Box>
          </Grow>
        ))}
      </Box>
    </Fade>
  );
}; 