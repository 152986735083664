import React, { createContext, useContext, useState, ReactNode } from 'react';

interface LayoutContextType {
  showSidebar: boolean;
  setShowSidebar: (show: boolean) => void;
}

const LayoutContext = createContext<LayoutContextType | null>(null);

export const useLayoutContext = () => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayoutContext must be used within a LayoutProvider');
  }
  return context;
};

interface LayoutProviderProps {
  children: ReactNode;
}

export const LayoutProvider: React.FC<LayoutProviderProps> = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <LayoutContext.Provider value={{ showSidebar, setShowSidebar }}>
      {children}
    </LayoutContext.Provider>
  );
}; 