import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Container, Typography, CircularProgress, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Post } from '../types';
import { PostCard } from '../components/PostCard';
import { PostDialog } from '../components/PostDialog';
import { api } from '../services/api';
import { useQueryClient } from '@tanstack/react-query';

const PostPage = () => {
  const { postId } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState<Post | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const initialLoadDone = useRef(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!initialLoadDone.current) {
      initialLoadDone.current = true;
      if (postId && postId !== 'new') {
        fetchPost();
      } else {
        setLoading(false);
      }
    }
  }, [postId]);

  const fetchPost = async () => {
    if (!postId || postId === 'new') return;
    
    try {
      const response = await api.get(`/posts/${postId}`);
      setPost(response.data);
    } catch (error) {
      console.error('Failed to fetch post:', error);
      setError('Failed to load post');
    } finally {
      setLoading(false);
    }
  };

  if (postId === 'new') {
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Box sx={{ mb: 2 }}>
          <IconButton onClick={() => navigate('/posts')}>
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <PostDialog
          post={null}
          open={true}
          onClose={() => navigate('/posts')}
          onSave={() => {
            queryClient.invalidateQueries({ queryKey: ['posts'] });
            navigate('/posts');
          }}
        />
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Box sx={{ mb: 2 }}>
        <IconButton onClick={() => navigate('/posts')}>
          <ArrowBackIcon />
        </IconButton>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : post ? (
        <PostCard 
          post={post} 
          onEdit={fetchPost}
        />
      ) : (
        <Typography>Post not found</Typography>
      )}
    </Container>
  );
};

export default PostPage; 