/**
 * Chat.tsx
 * 
 * The main chat page component that orchestrates all chat functionality.
 * It provides the layout and manages the chat interface components.
 * 
 * Key features:
 * - Chat layout with conversation list and message area
 * - New conversation creation
 * - Contact selection and group chat creation
 * - Responsive design (mobile/desktop layouts)
 * - Real-time updates through ChatContext
 * - URL-based chat navigation
 * 
 * Components:
 * - ChatList: Displays conversation list
 * - MessageList: Shows conversation messages
 * - MessageInput: Message composition
 * - NewChatDialog: New conversation creation
 * 
 * Related files:
 * - ChatContext.tsx: Global chat state and operations
 * - ChatController.mjs: Backend API endpoints
 * - ChatService.mjs: Backend business logic
 * - contacts.ts: Contact management service
 */

// src/pages/Chat.tsx
import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, Typography, Divider, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, List, ListItem, ListItemText, ListItemAvatar, Avatar, Checkbox, CircularProgress, Alert, IconButton, Menu, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ChatProvider, useChatContext } from '../contexts/ChatContext';
import ChatList from '../components/communication/ChatList';
import MessageList from '../components/communication/MessageList';
import MessageInput from '../components/communication/MessageInput';
import { fetchContacts } from '../services/contacts';
import { Contact } from '../types/contact';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useTheme, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const NewChatDialog: React.FC<{ open: boolean, onClose: () => void }> = ({ open, onClose }) => {
  const { createConversation } = useChatContext();
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
  const [groupName, setGroupName] = useState('');
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (open) {
      loadContacts();
    }
  }, [open, search]);

  // Reset group name when switching back to direct chat
  useEffect(() => {
    if (selectedContacts.length <= 1) {
      setGroupName('');
    }
  }, [selectedContacts]);

  const loadContacts = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await fetchContacts({
        search: search,
      });
      
      // Filter contacts to only include those with linked user accounts
      const contactsWithUsers = response.contacts.filter(contact => 
        contact.linked_user_id && contact.linked_user_id.trim() !== ''
      );
      
      if (contactsWithUsers.length === 0 && response.contacts.length > 0) {
        // If we have contacts but none with user accounts, show a helpful message
        setError('No contacts with user accounts found. Only contacts with user accounts can participate in chats.');
      }
      
      setContacts(contactsWithUsers);
      setIsLoading(false);
    } catch (err) {
      setError('Failed to load contacts');
      setIsLoading(false);
      console.error('Error loading contacts:', err);
    }
  };

  const handleToggleContact = (contactId: string) => {
    setSelectedContacts(prev => 
      prev.includes(contactId)
        ? prev.filter(id => id !== contactId)
        : [...prev, contactId]
    );
  };

  const handleCreateChat = async () => {
    try {
      if (selectedContacts.length === 0) {
        setError('Please select at least one contact');
        return;
      }

      setIsLoading(true);
      setError(null);

      // Get the selected contact data for metadata
      const selectedContactsData = contacts.filter(contact => 
        selectedContacts.includes(contact.id)
      );

      // Check if contacts have linked user IDs
      const contactsWithUsers = selectedContactsData.filter(contact => 
        contact.linked_user_id && contact.linked_user_id.trim() !== ''
      );

      if (contactsWithUsers.length === 0) {
        setError('Selected contacts do not have linked user accounts');
        setIsLoading(false);
        return;
      }

      // Automatically determine if it's a group chat based on number of participants
      const isGroupChat = selectedContacts.length > 1;

      // Create metadata with proper contact information
      const metadata = isGroupChat 
        ? { 
            name: groupName || `Group (${selectedContactsData.length})`,
            contacts: selectedContactsData.map(c => ({
              id: c.id,
              first_name: c.first_name,
              last_name: c.last_name,
              email: c.email_personal || c.email_work
            }))
          } 
        : { 
            contactId: selectedContactsData[0].id,
            first_name: selectedContactsData[0].first_name,
            last_name: selectedContactsData[0].last_name,
            email: selectedContactsData[0].email_personal || selectedContactsData[0].email_work
          };

      // Extract user IDs from contacts and filter out any undefined values
      const userIds = contactsWithUsers
        .map(contact => contact.linked_user_id)
        .filter((id): id is string => typeof id === 'string');

      await createConversation(userIds, isGroupChat, metadata);
      
      setIsLoading(false);
      onClose();
    } catch (err) {
      setError('Failed to create chat');
      setIsLoading(false);
      console.error('Error creating chat:', err);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>New Conversation</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Search Contacts"
          type="text"
          fullWidth
          variant="outlined"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{ mb: 2 }}
        />
        
        {error && (
          <Typography color="error" variant="body2" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}
        
        {/* Show group name field when more than 1 contact is selected */}
        {selectedContacts.length > 1 && (
          <TextField
            margin="dense"
            label="Group Name (optional)"
            type="text"
            fullWidth
            variant="outlined"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            sx={{ mb: 2 }}
            placeholder="Enter a name for the group"
          />
        )}
        
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Select contacts to chat with {selectedContacts.length > 1 ? '(Group Chat)' : ''}
          </Typography>
          
          {/* Contact selection list */}
          <List sx={{ maxHeight: 300, overflow: 'auto' }}>
            {isLoading ? (
              <CircularProgress />
            ) : contacts.length === 0 ? (
              <Typography variant="body2" color="text.secondary">
                No contacts found
              </Typography>
            ) : (
              contacts.map((contact) => (
                <ListItem
                  key={contact.id}
                  secondaryAction={
                    <Checkbox
                      edge="end"
                      onChange={() => handleToggleContact(contact.id)}
                      checked={selectedContacts.includes(contact.id)}
                    />
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      {contact.first_name?.[0]?.toUpperCase() || 
                       contact.last_name?.[0]?.toUpperCase() || 
                       'U'}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${contact.first_name} ${contact.last_name}`}
                    secondary={contact.email_work || contact.email_personal}
                  />
                </ListItem>
              ))
            )}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleCreateChat} 
          color="primary" 
          disabled={isLoading || selectedContacts.length === 0}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface ParticipantInfo {
  name: string;
  email?: string;
  initial: string;
  profileImageUrl?: string;
}

const ChatContent: React.FC<{ userId?: string }> = ({ userId: propsUserId }) => {
  const { conversations, activeConversation, loading, error, createConversation, selectConversation, sendMessage, deleteGroupChat, leaveGroupChat, isGroupAdmin } = useChatContext();
  const auth = useAuth();
  const [isNewChatDialogOpen, setIsNewChatDialogOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isAdmin, setIsAdmin] = useState(false);

  // Get userId from either props (path parameter) or query parameter
  const queryParams = new URLSearchParams(location.search);
  const queryUserId = queryParams.get('userId');
  const contactId = queryParams.get('contactId');
  const userId = propsUserId || queryUserId;

  // Handle URL parameters and initialize chat
  useEffect(() => {
    // Only redirect if we have query parameters but are not already on the path parameter URL
    // This prevents infinite loops by ensuring we don't redirect if we're already at the right URL
    if (queryUserId && !propsUserId && location.pathname === '/chat') {
      navigate(`/chat/${queryUserId}`, { replace: true });
      return;
    }
    
    // If we have a userId (from either path or query), initialize the chat
    if (userId) {
      const initializeChat = async () => {
        try {
          // Check if we already have a conversation with this user
          const existingConversation = conversations.find(conv => 
            !conv.is_group && conv.participants.includes(userId)
          );
          
          if (existingConversation) {
            await selectConversation(existingConversation.id);
          } else if (contactId) {
            const metadata = { 
              contactId,
              createdAt: new Date().toISOString()
            };
            
            const newConversation = await createConversation([userId], false, metadata);
            
            // Select the newly created conversation
            if (newConversation && newConversation.id) {
              await selectConversation(newConversation.id);
            }
          } else if (propsUserId) {
            // If we have a userId in the path but no existing conversation and no contactId,
            // we need to fetch the contact information
            // console.log('Fetching contact info for user:', propsUserId);
            const contactsResponse = await fetchContacts({});
            
            const validContact = contactsResponse.contacts.find(
              contact => contact.linked_user_id === propsUserId
            );
            
            if (validContact) {
              // console.log('Found valid contact:', validContact);
              const metadata = {
                contactId: validContact.id,
                first_name: validContact.first_name,
                last_name: validContact.last_name,
                email: validContact.email_personal || validContact.email_work,
                phone: validContact.phone_mobile || validContact.phone_work,
                createdAt: new Date().toISOString()
              };
              
              const newConversation = await createConversation([propsUserId], false, metadata);
              // console.log('New conversation created from path userId:', newConversation);
              
              if (newConversation && newConversation.id) {
                await selectConversation(newConversation.id);
              }
            }
          }
        } catch (error) {
          console.error('Error initializing chat:', error);
        }
      };
      
      initializeChat();
    }
  }, [userId, contactId, propsUserId]);

  // Check admin status when conversation changes
  useEffect(() => {
    const checkAdminStatus = async () => {
      if (activeConversation?.is_group && activeConversation?.id) {
        const adminStatus = await isGroupAdmin(activeConversation.id);
        setIsAdmin(adminStatus);
      }
    };
    checkAdminStatus();
  }, [activeConversation?.id]);

  const handleSendMessage = async (content: string) => {
    if (!activeConversation) {
      console.error('Cannot send message: No active conversation selected');
      alert('Error: No active conversation selected');
      return;
    }
    
    if (!activeConversation.id) {
      console.error('Cannot send message: Conversation ID is undefined', activeConversation);
      alert('Error: Conversation ID is undefined');
      return;
    }
    
    try {
      // Track message send attempt
      window._paq?.push(['trackEvent', 'Chat', 'Send Message', activeConversation.is_group ? 'Group' : 'Direct']);
      
      await sendMessage(content);
      
      // Track successful message send
      window._paq?.push(['trackEvent', 'Chat', 'Send Success', activeConversation.is_group ? 'Group' : 'Direct']);
    } catch (error) {
      // Track message send error
      window._paq?.push(['trackEvent', 'Chat', 'Send Error', error.message]);
      console.error('Error sending message:', error);
      const errorMessage = error instanceof Error ? error.message : 'Failed to send message';
      alert(`Error: ${errorMessage}`);
    }
  };

  const handleMessagesClick = () => {
    // First navigate to base chat URL
    navigate('/chat', { replace: true });
    // Then clear active conversation
    selectConversation(null);
  };

  // Get the other participant's info from the conversation
  const getOtherParticipantInfo = (conversation: any): ParticipantInfo | null => {
    if (!conversation || !auth?.user) {
      return null;
    }

    // For group chats, show the group name
    if (conversation.is_group || conversation.type === 'GROUP_CHAT') {
      console.log('This is a group chat');
      return {
        name: conversation.metadata?.name || 'Group Chat',
        email: `${conversation.participants?.length || 0} participants`,
        initial: conversation.metadata?.name?.[0] || 'G'
      };
    }

    // For direct chats, find the other participant
    console.log('Direct chat participants:', conversation.participants);
    console.log('Current user ID:', auth.user.id);
    
    // Get valid participants array
    const validParticipants = (conversation.participants || [])
      .filter((id: string | undefined): id is string => typeof id === 'string');
    
    const otherParticipantId = validParticipants.find((id: string) => id !== auth.user?.id);
    
    if (!otherParticipantId) {
      return null;
    }

    // Get metadata for the other participant
    const metadata = conversation.metadata;
    
    if (!metadata) {
      return null;
    }

    // First try to find the other participant in the contacts array
    if (metadata.contacts && Array.isArray(metadata.contacts)) {
      const otherParticipant = metadata.contacts.find(
        (contact: any) => contact.id === otherParticipantId
      );

      if (otherParticipant) {
        const name = `${otherParticipant.first_name} ${otherParticipant.last_name}`.trim();
        return {
          name: name || 'Unknown User',
          email: otherParticipant.email,
          initial: otherParticipant.first_name?.[0] || otherParticipant.last_name?.[0] || 'U',
          profileImageUrl: otherParticipant.profile_image_url
        };
      }
    }

    // Fallback to direct metadata if available
    const name = metadata.first_name && metadata.last_name
      ? `${metadata.first_name} ${metadata.last_name}`.trim()
      : metadata.name || 'Unknown User';

    return {
      name,
      email: metadata.email,
      initial: metadata.first_name?.[0] || metadata.last_name?.[0] || 'U',
      profileImageUrl: metadata.profile_image_url
    };
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteGroup = async () => {
    if (!activeConversation?.id) return;
    
    if (window.confirm('Are you sure you want to delete this group chat? This action cannot be undone.')) {
      try {
        await deleteGroupChat(activeConversation.id);
        handleMenuClose();
      } catch (error) {
        console.error('Failed to delete group:', error);
      }
    }
  };

  const handleLeaveGroup = async () => {
    if (!activeConversation?.id) return;
    
    if (window.confirm('Are you sure you want to leave this group chat?')) {
      try {
        await leaveGroupChat(activeConversation.id);
        handleMenuClose();
      } catch (error) {
        console.error('Failed to leave group:', error);
      }
    }
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography 
          variant="h6" 
          onClick={handleMessagesClick}
          sx={{ 
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            '&:hover': {
              color: 'primary.main'
            }
          }}
        >
          {isMobile && activeConversation && (
            <IconButton 
              edge="start"
              onClick={(e) => {
                e.stopPropagation();
                handleMessagesClick();
              }}
              sx={{ mr: 1 }}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          Messages
        </Typography>
        <Button 
          variant="contained" 
          startIcon={<AddIcon />}
          onClick={() => setIsNewChatDialogOpen(true)}
        >
          New Chat
        </Button>
      </Box>
      
      <Grid container sx={{ flexGrow: 1, overflow: 'hidden' }}>
        {/* Chat list sidebar - Hide on mobile when conversation is selected */}
        <Grid 
          item 
          xs={12} 
          md={4} 
          sx={{ 
            borderRight: { md: 1 }, 
            borderColor: 'divider', 
            height: '100%', 
            overflow: 'auto',
            display: {
              xs: activeConversation ? 'none' : 'block',
              md: 'block'
            }
          }}
        >
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : conversations.length === 0 ? (
            <Box sx={{ p: 3, textAlign: 'center' }}>
              <Typography color="text.secondary" gutterBottom>
                No conversations yet
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph>
                Start a new conversation to connect with your contacts
              </Typography>
              <Button 
                variant="outlined" 
                startIcon={<AddIcon />} 
                onClick={() => setIsNewChatDialogOpen(true)}
                size="small"
              >
                Start Chat
              </Button>
            </Box>
          ) : (
            <ChatList />
          )}
        </Grid>
        
        {/* Message area - Show on mobile only when conversation is selected */}
        <Grid 
          item 
          xs={12} 
          md={8} 
          sx={{ 
            height: '100%', 
            display: {
              xs: activeConversation ? 'flex' : 'none',
              md: 'flex'
            }, 
            flexDirection: 'column' 
          }}
        >
          {activeConversation ? (
            <>
              {/* Conversation Header */}
              {(() => {
                const otherParticipant = getOtherParticipantInfo(activeConversation);
                return (
                  <Box sx={{ 
                    p: 2, 
                    borderBottom: 1, 
                    borderColor: 'divider',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    {isMobile && (
                      <IconButton 
                        edge="start"
                        onClick={handleMessagesClick}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    )}
                    <Avatar 
                      sx={{ bgcolor: 'primary.main' }}
                      src={otherParticipant?.profileImageUrl}
                    >
                      {otherParticipant?.initial || 'U'}
                    </Avatar>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="h6">
                        {otherParticipant?.name || 'Unknown User'}
                      </Typography>
                      {otherParticipant?.email && (
                        <Typography variant="body2" color="text.secondary">
                          {otherParticipant.email}
                        </Typography>
                      )}
                    </Box>
                    {activeConversation?.is_group && (
                      <>
                        <IconButton
                          aria-label="group options"
                          aria-controls="group-menu"
                          aria-haspopup="true"
                          onClick={handleMenuClick}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="group-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                        >
                          {isAdmin ? (
                            <MenuItem onClick={handleDeleteGroup} sx={{ color: 'error.main' }}>
                              Delete Group
                            </MenuItem>
                          ) : (
                            <MenuItem onClick={handleLeaveGroup} sx={{ color: 'error.main' }}>
                              Leave Group
                            </MenuItem>
                          )}
                        </Menu>
                      </>
                    )}
                  </Box>
                );
              })()}
              
              <Box sx={{ 
                flexGrow: 1, 
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                minHeight: 0
              }}>
                <MessageList />
              </Box>
              <Box sx={{ 
                borderTop: 1, 
                borderColor: 'divider',
                bgcolor: 'background.paper',
                position: 'sticky',
                bottom: 0,
                zIndex: 1
              }}>
                <MessageInput onSendMessage={handleSendMessage} />
              </Box>
            </>
          ) : (
            <Box sx={{ 
              display: { xs: 'none', md: 'flex' }, 
              justifyContent: 'center', 
              alignItems: 'center', 
              height: '100%' 
            }}>
              <Typography color="text.secondary">
                Select a conversation to start messaging
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      
      <NewChatDialog 
        open={isNewChatDialogOpen} 
        onClose={() => setIsNewChatDialogOpen(false)} 
      />
    </Box>
  );
};

const ChatPage: React.FC = () => {
  // Get userId from URL params here at the top level
  const { userId } = useParams<{ userId?: string }>();
  
  // Add debug logging
  useEffect(() => {
    console.log('ChatPage received userId from URL:', userId);
  }, [userId]);
  
  return (
    <ChatProvider>
      <Box sx={{ flexGrow: 1, height: 'calc(100vh - 64px)' }}>
        <ChatContent userId={userId} />
      </Box>
    </ChatProvider>
  );
};

export default ChatPage;