import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Switch,
  FormControlLabel,
  Alert,
  Container
} from '@mui/material';
import { api } from '../../services/api';
import { useAuth } from '../../hooks/useAuth';

interface ConsentVersion {
  id: string;
  content: string;
  version_number: string;
  is_active: boolean;
  created_at: string;
  created_by: string;
}

interface AuditLogEntry {
  id: string;
  version_id: string;
  user_id: string;
  action: string;
  details: string;
  created_at: string;
  version: ConsentVersion;
  user: {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
  };
}

export default function ConsentManagementPage() {
  const { user } = useAuth();
  const [versions, setVersions] = useState<ConsentVersion[]>([]);
  const [auditLog, setAuditLog] = useState<AuditLogEntry[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [newVersion, setNewVersion] = useState({ content: '', version_number: '' });

  // Check if user has required role
  const isDPOorSuperuser = user?.roles?.some(role => 
    role.role.name === 'Data Protection Officer' || 
    role.role.name === 'Superuser'
  );

  if (!isDPOorSuperuser) {
    return (
      <Container>
        <Alert severity="error" sx={{ mt: 2 }}>
          Access restricted to Data Protection Officers and Superusers
        </Alert>
      </Container>
    );
  }

  const fetchData = async () => {
    try {
      setLoading(true);
      const [versionsResponse, auditResponse] = await Promise.all([
        api.get<ConsentVersion[]>('/admin/consent/versions'),
        api.get<AuditLogEntry[]>('/admin/consent/audit')
      ]);
      setVersions(versionsResponse.data);
      setAuditLog(auditResponse.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load consent management data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCreateVersion = async () => {
    try {
      const response = await api.post<ConsentVersion>('/admin/consent/versions', newVersion);
      setVersions([response.data, ...versions]);
      setOpenDialog(false);
      setNewVersion({ content: '', version_number: '' });
      await fetchData(); // Refresh data to get updated audit log
    } catch (error) {
      console.error('Error creating version:', error);
      setError('Failed to create new version');
    }
  };

  const handleToggleActive = async (id: string, isActive: boolean) => {
    try {
      await api.patch(`/admin/consent/versions/${id}/status`, { is_active: isActive });
      await fetchData(); // Refresh data to get updated versions and audit log
    } catch (error) {
      console.error('Error updating version status:', error);
      setError('Failed to update version status');
    }
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4">Consent Management</Typography>
        <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
          Create New Version
        </Button>
      </Box>

      <Typography variant="h6" sx={{ mb: 2 }}>Consent Versions</Typography>
      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Version</TableCell>
              <TableCell>Content</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {versions.map((version) => (
              <TableRow key={version.id}>
                <TableCell>{version.version_number}</TableCell>
                <TableCell>{version.content.substring(0, 100)}...</TableCell>
                <TableCell>{new Date(version.created_at).toLocaleString()}</TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={version.is_active}
                        onChange={(e) => handleToggleActive(version.id, e.target.checked)}
                      />
                    }
                    label={version.is_active ? 'Active' : 'Inactive'}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => window.open(`/consent/${version.id}`, '_blank')}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h6" sx={{ mb: 2 }}>Audit Log</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Details</TableCell>
              <TableCell>Version</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {auditLog.map((entry) => (
              <TableRow key={entry.id}>
                <TableCell>{new Date(entry.created_at).toLocaleString()}</TableCell>
                <TableCell>{`${entry.user.first_name} ${entry.user.last_name}`}</TableCell>
                <TableCell>{entry.action}</TableCell>
                <TableCell>{entry.details}</TableCell>
                <TableCell>{entry.version.version_number}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>Create New Consent Version</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Version Number"
            fullWidth
            value={newVersion.version_number}
            onChange={(e) => setNewVersion({ ...newVersion, version_number: e.target.value })}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Content"
            multiline
            rows={10}
            fullWidth
            value={newVersion.content}
            onChange={(e) => setNewVersion({ ...newVersion, content: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleCreateVersion} variant="contained" color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
} 