import React from 'react';
import {
  Card,
  CardContent,
  CardActionArea,
  Typography,
  Box,
  Chip,
  Avatar,
  Stack,
  Link,
  IconButton,
} from '@mui/material';
import {
  BusinessCenter as BusinessIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  Home as HomeIcon,
  LinkedIn as LinkedInIcon,
  Chat as ChatIcon,
} from '@mui/icons-material';
import { Contact } from '../../types/contact';
import { useNavigate } from 'react-router-dom';

interface ContactCardProps {
  contact: Contact;
  onClick: () => void;
}

const getStatusColor = (status: string) => {
  const statusColors: { [key: string]: string } = {
    POTENTIAL: '#9e9e9e',
    LEAD: '#2196f3',
    ACTIVE: '#4caf50',
    ENGAGED: '#ff9800',
    VIP: '#f44336',
    INACTIVE: '#757575',
    FORMER: '#607d8b',
    DO_NOT_CONTACT: '#d32f2f',
    ARCHIVED: '#455a64',
  };
  return statusColors[status] || '#9e9e9e';
};

const getPriorityColor = (priority: string) => {
  const priorityColors: { [key: string]: string } = {
    LOW: '#4caf50',
    MEDIUM: '#ff9800',
    HIGH: '#f44336',
    VIP: '#d32f2f',
  };
  return priorityColors[priority] || '#9e9e9e';
};

const ContactCard: React.FC<ContactCardProps> = ({ contact, onClick }) => {
  const currentPosition = contact.positions?.find(p => p.is_current);
  const navigate = useNavigate();
  
  const handleClick = () => {
    navigate(`/contact/${contact.id}`);
  };

  const handleChatClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent card click
    
    if (contact.linked_user_id) {
      // Navigate to chat page with contact info as query params
      navigate(`/chat?contactId=${contact.id}&userId=${contact.linked_user_id}`);
    }
  };

  return (
    <Card sx={{ height: '100%', position: 'relative' }}>
      {contact.linked_user_id && (
        <IconButton 
          onClick={handleChatClick}
          color="primary"
          size="small"
          sx={{ 
            position: 'absolute', 
            top: 8, 
            right: 8, 
            zIndex: 10,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
            }
          }}
          title="Chat with contact"
        >
          <ChatIcon fontSize="small" />
        </IconButton>
      )}
      <CardActionArea onClick={handleClick} sx={{ height: '100%' }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Avatar
              src={contact.linked_user?.profile_image_url}
              sx={{ width: 56, height: 56 }}
            >
              {contact.first_name?.[0]}{contact.last_name?.[0]}
            </Avatar>
            <Box>
              <Typography variant="h6">
                {contact.first_name} {contact.last_name}
              </Typography>
              {contact.positions?.map((position, index) => (
                <Typography 
                  key={position.id}
                  variant="body2" 
                  color="text.secondary" 
                  sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                >
                  <BusinessIcon fontSize="small" />
                  {position.title}
                  {(position.organisation?.name || position.organisation_other) && (
                    <> at {position.organisation?.name || position.organisation_other}</>
                  )}
                  {position.is_current && " (Current)"}
                </Typography>
              ))}
              {contact.linkedin_url && (
                <Link 
                  href={contact.linkedin_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                >
                  <LinkedInIcon fontSize="small" />
                  LinkedIn Profile
                </Link>
              )}
              {contact.email_work && (
                <Typography variant="body2" color="text.secondary">
                  {contact.email_work}
                </Typography>
              )}
            </Box>
          </Box>

          <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
            <Chip
              label={contact.status.replace(/_/g, ' ')}
              size="small"
              sx={{
                bgcolor: getStatusColor(contact.status),
                color: 'white',
              }}
            />
            {contact.priority_level && (
              <Chip
                label={contact.priority_level}
                size="small"
                sx={{
                  bgcolor: getPriorityColor(contact.priority_level),
                  color: 'white',
                }}
              />
            )}
          </Stack>

          {(contact.email_work || contact.email_personal) && (
            <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 1 }}>
              <EmailIcon fontSize="small" />
              {contact.email_work || contact.email_personal}
            </Typography>
          )}

          {(contact.phone_mobile || contact.phone_work) && (
            <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <PhoneIcon fontSize="small" />
              {contact.phone_mobile || contact.phone_work}
            </Typography>
          )}

          {/* Location - Show work address if available, otherwise show home address */}
          {((contact.work_street || contact.work_city || contact.work_state) || 
            (contact.home_street || contact.home_city || contact.home_state)) && (
            <Box sx={{ mt: 1 }}>
              <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                {contact.work_city || contact.work_country ? 
                  <BusinessIcon fontSize="small" /> : 
                  <HomeIcon fontSize="small" />}
                {[
                  // Try work address first
                  contact.work_street || contact.home_street,
                  (contact.work_city?.name || contact.home_city?.name),
                  contact.work_state || contact.home_state,
                  (contact.work_country?.name || contact.home_country?.name)
                ].filter(Boolean).join(', ')}
              </Typography>
            </Box>
          )}

          {contact.contact_tags && contact.contact_tags.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Stack direction="row" spacing={0.5} flexWrap="wrap" gap={0.5}>
                {contact.contact_tags.map(({ tag }) => (
                  <Chip
                    key={tag.id}
                    label={tag.name}
                    size="small"
                    sx={{
                      bgcolor: tag.color || '#e0e0e0',
                      color: tag.color ? 'white' : 'inherit',
                    }}
                  />
                ))}
              </Stack>
            </Box>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ContactCard;
