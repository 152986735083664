import { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip
} from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  RadarChart,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Legend
} from 'recharts';
import { useQuery } from '@tanstack/react-query';
import { analyticsService } from '../../services/analytics';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

export const EngagementDashboard = ({ userId }: { userId: string }) => {
  const [period, setPeriod] = useState<'day' | 'week' | 'month'>('week');

  const { data: performance } = useQuery({
    queryKey: ['performance', userId, period],
    queryFn: () => analyticsService.getContentPerformance(userId, period)
  });

  const { data: trends } = useQuery({
    queryKey: ['trends', userId],
    queryFn: () => analyticsService.getEngagementTrends(userId)
  });

  const { data: recommendations } = useQuery({
    queryKey: ['recommendations', userId],
    queryFn: () => analyticsService.getContentRecommendations(userId)
  });

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5">Engagement Analytics</Typography>
        <ToggleButtonGroup
          value={period}
          exclusive
          onChange={(_, newPeriod) => newPeriod && setPeriod(newPeriod)}
        >
          <ToggleButton value="day">Day</ToggleButton>
          <ToggleButton value="week">Week</ToggleButton>
          <ToggleButton value="month">Month</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Grid container spacing={3}>
        {/* Engagement Over Time */}
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Engagement Over Time
              </Typography>
              <Box sx={{ height: 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={trends?.timeData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="views" stroke="#8884d8" />
                    <Line type="monotone" dataKey="reactions" stroke="#82ca9d" />
                    <Line type="monotone" dataKey="comments" stroke="#ffc658" />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Engagement Distribution */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Engagement Distribution
              </Typography>
              <Box sx={{ height: 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={performance?.byType || []}
                      dataKey="_count"
                      nameKey="type"
                      cx="50%"
                      cy="50%"
                      outerRadius={80}
                      label
                    >
                      {(performance?.byType || []).map((_, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Content Performance */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Content Performance
              </Typography>
              <Box sx={{ height: 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={performance?.byContent}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="content_type" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="_count" stroke="#8884d8" />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* New: Engagement Radar Chart */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Engagement Pattern
              </Typography>
              <Box sx={{ height: 300 }}>
                <ResponsiveContainer>
                  <RadarChart data={performance?.byType}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="type" />
                    <PolarRadiusAxis />
                    <Radar
                      name="Engagement"
                      dataKey="_count"
                      stroke="#8884d8"
                      fill="#8884d8"
                      fillOpacity={0.6}
                    />
                  </RadarChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* New: Hourly Activity Heatmap */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Peak Activity Hours
              </Typography>
              <Box sx={{ height: 300 }}>
                <ResponsiveContainer>
                  <BarChart data={trends?.hourlyActivity || []}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="hour" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill="#8884d8">
                      {(trends?.hourlyActivity || []).map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={`rgb(136, 132, 216, ${entry.count / Math.max(...(trends?.hourlyActivity || []).map(e => e.count))})`}
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* New: Content Recommendations */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Recommended Actions
              </Typography>
              <Grid container spacing={2}>
                {recommendations?.map((rec, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography variant="subtitle1" gutterBottom>
                          {rec.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {rec.description}
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                          <Chip
                            label={`Impact: ${rec.impact}`}
                            color={rec.impact === 'High' ? 'error' : rec.impact === 'Medium' ? 'warning' : 'info'}
                            size="small"
                            sx={{ mr: 1 }}
                          />
                          <Chip
                            label={rec.category}
                            variant="outlined"
                            size="small"
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* New: Top Performing Content */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Top Performing Content
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Content</TableCell>
                    <TableCell align="right">Views</TableCell>
                    <TableCell align="right">Reactions</TableCell>
                    <TableCell align="right">Comments</TableCell>
                    <TableCell align="right">Engagement Rate</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {performance?.topContent?.map((content) => (
                    <TableRow key={content.id}>
                      <TableCell component="th" scope="row">
                        {content.title}
                      </TableCell>
                      <TableCell align="right">{content.views}</TableCell>
                      <TableCell align="right">{content.reactions}</TableCell>
                      <TableCell align="right">{content.comments}</TableCell>
                      <TableCell align="right">
                        <Chip
                          label={`${content.engagementRate}%`}
                          color={
                            content.engagementRate > 15 ? 'success' :
                            content.engagementRate > 5 ? 'warning' : 'error'
                          }
                          size="small"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}; 