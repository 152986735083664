import axios from 'axios';

// In development, always use the Vite proxy
// In production, use the environment variable

/**
 * API Configuration Logic:
 * 
 * Development (isDevelopment = true):
 * - Frontend runs on http://localhost:5173
 * - Backend runs on http://localhost:3000
 * - We use '/api' which gets proxied by Vite to http://localhost:3000/api
 * - This avoids CORS issues in development
 * 
 * Production (isDevelopment = false):
 * - Frontend and Backend both run under https://yeysus.com
 * - We use either:
 *   1. VITE_API_URL from environment if set
 *   2. Or fallback to window.location.origin + '/api'
 * - All requests go through Nginx which handles routing
 */

const isDevelopment = import.meta.env.DEV;
const currentOrigin = window.location.origin;

// In development: use '/api' which gets proxied by Vite
// In production: use environment variable or current origin + '/api'
const API_URL = isDevelopment
  ? '/api'  // This gets proxied by Vite to backend:3000/api
  : (import.meta.env.VITE_API_URL || `${currentOrigin}/api`);

console.log('[API] Configuration:', { 
  isDevelopment,
  API_URL,
  viteApiUrl: import.meta.env.VITE_API_URL,
  currentOrigin
});

export const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add request interceptor
api.interceptors.request.use(
  (config) => {
    console.log(`[API] Request:`, {
      method: config.method?.toUpperCase(),
      url: `${config.baseURL}${config.url}`,
      headers: config.headers,
      withCredentials: config.withCredentials,
      data: config.data
    });
    return config;
  },
  (error) => {
    console.error('[API] Request error:', error);
    return Promise.reject(error);
  }
);

// Add response interceptor
api.interceptors.response.use(
  (response) => {
    console.log(`[API] Response:`, {
      url: response.config.url,
      status: response.status,
      headers: response.headers,
      data: response.data
    });
    return response;
  },
  async (error) => {
    if (axios.isAxiosError(error)) {
      const status = error.response?.status;
      const url = error.config?.url;
      const responseData = error.response?.data;
      
      // Special handling for auth check endpoint
      if (url === '/auth/check') {
        console.log('[API] Auth check response:', { status, data: responseData });
        
        // For 401 responses, this is expected when not authenticated
        if (status === 401 || (responseData?.error?.statusCode === 401)) {
          console.log('[API] User not authenticated (expected)');
          // Return a structured response with null data to indicate no user
          return Promise.resolve({ data: null });
        }
        
        // For 500 responses, log but don't show as error
        if (status === 500) {
          console.log('[API] Server error on auth check:', responseData);
          // Return a structured response with null data
          return Promise.resolve({ data: null });
        }
      }
      
      console.error(`[API] Error:`, {
        status,
        url,
        data: error.response?.data,
        headers: error.response?.headers,
        config: {
          withCredentials: error.config?.withCredentials,
          headers: error.config?.headers
        }
      });
    }
    return Promise.reject(error);
  }
);

// Add resend verification email function
export const resendVerificationEmail = async (email: string) => {
  return api.post('/auth/resend-verification', { email });
};

// Test function to check if proxy is working
export const testProxy = () => {
  console.log('[API] Testing proxy connection...');
  console.log('[API] Current configuration:', { 
    isDevelopment,
    API_URL,
    viteApiUrl: import.meta.env.VITE_API_URL,
    currentOrigin: window.location.origin
  });
  
  // Test proxy connection
  const proxyTest = api.get('/auth/check')
    .then(response => {
      console.log('[API] Proxy test successful:', response);
      return response;
    })
    .catch(err => {
      console.log('[API] Proxy test error:', {
        message: err.message,
        status: err.response?.status,
        data: err.response?.data,
        headers: err.response?.headers
      });
      return { data: null };
    });
  
  return proxyTest; // Only test the proxy for now
};