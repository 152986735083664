import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  CircularProgress,
  Alert,
  Tooltip,
  FormControlLabel,
  Switch
} from '@mui/material';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { api } from '../../services/api';
import DOMPurify from 'dompurify';
import { Info as InfoIcon } from '@mui/icons-material';

interface Organisation {
  id: string;
  name: string;
  short_description: string;
  notes: string;
  wikipedia?: string;
  synonyms: string[];
  is_fictional: boolean;
  is_historical: boolean;
  long_description?: string;
}

interface OrganisationDialogProps {
  open: boolean;
  onClose: () => void;
  organisation: Organisation | null;
}

const LIMITS = {
  name: 255,
  shortDescription: 255,
  longDescription: 1535,
  notes: 255
};

// Sanitization utilities
const sanitizeText = (text: string): string => {
  return DOMPurify.sanitize(text.trim());
};

const sanitizeMultilineText = (text: string): string => {
  return text
    .split('\n')
    .map(line => DOMPurify.sanitize(line.trim()))
    .filter(line => line.length > 0)
    .join('\n');
};

const FieldLabel = ({ label, tooltip }: { label: string; tooltip: string }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    {label}
    <Tooltip title={tooltip}>
      <InfoIcon fontSize="small" sx={{ color: 'text.secondary' }} />
    </Tooltip>
  </Box>
);

const isValidUrl = (url?: string): boolean => {
  if (!url) return true; // Empty URL is valid
  try {
    new URL(url);
    return url.startsWith('https://') || url.startsWith('http://');
  } catch {
    return false;
  }
};

export const OrganisationDialog = ({ open, onClose, organisation }: OrganisationDialogProps) => {
  const [formData, setFormData] = useState({
    name: '',
    short_description: '',
    notes: '',
    wikipedia: '',
    synonyms: [] as string[],
    is_fictional: false,
    is_historical: false,
    long_description: ''
  });
  const [error, setError] = useState<string | null>(null);
  const queryClient = useQueryClient();

  // Check name uniqueness
  const { data: nameExists, isLoading: checkingName } = useQuery({
    queryKey: ['checkOrganisationName', formData.name, organisation?.id],
    queryFn: async () => {
      if (!formData.name || formData.name === organisation?.name) return false;
      const response = await api.get(`/organisations/check-name/${encodeURIComponent(formData.name)}`);
      return response.data.exists;
    },
    enabled: !!formData.name && formData.name !== organisation?.name
  });

  useEffect(() => {
    if (organisation) {
      setFormData({
        name: organisation.name,
        short_description: organisation.short_description || '',
        notes: organisation.notes || '',
        wikipedia: organisation.wikipedia || '',
        synonyms: organisation.synonyms || [],
        is_fictional: organisation.is_fictional || false,
        is_historical: organisation.is_historical || false,
        long_description: organisation.long_description || ''
      });
    } else {
      setFormData({
        name: '',
        short_description: '',
        notes: '',
        wikipedia: '',
        synonyms: [],
        is_fictional: false,
        is_historical: false,
        long_description: ''
      });
    }
    setError(null);
  }, [organisation]);

  const mutation = useMutation({
    mutationFn: (data: typeof formData) => {
      const sanitizedData = {
        name: sanitizeText(data.name),
        short_description: sanitizeText(data.short_description),
        notes: sanitizeMultilineText(data.notes),
        wikipedia: data.wikipedia
      };

      if (organisation) {
        return api.put(`/organisations/${organisation.id}`, sanitizedData);
      }
      return api.post('/organisations', sanitizedData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organisations'] });
      onClose();
    },
    onError: (error: any) => {
      setError(error.response?.data?.error || 'Failed to save organisation');
    }
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!formData.name.trim()) {
      setError('Name is required');
      return;
    }

    if (formData.name.length > LIMITS.name) {
      setError(`Name must be ${LIMITS.name} characters or less`);
      return;
    }

    if (formData.short_description.length > LIMITS.shortDescription) {
      setError(`Short description must be ${LIMITS.shortDescription} characters or less`);
      return;
    }

    if (formData.long_description.length > LIMITS.longDescription) {
      setError(`Long description must be ${LIMITS.longDescription} characters or less`);
      return;
    }

    if (formData.notes.length > LIMITS.notes) {
      setError(`Notes must be ${LIMITS.notes} characters or less`);
      return;
    }

    if (nameExists) {
      setError('An organisation with this name already exists');
      return;
    }

    mutation.mutate(formData);
  };

  const isSubmitDisabled = 
    !formData.name.trim() || 
    nameExists || 
    mutation.isPending || 
    checkingName;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {organisation ? 'Edit Organisation' : 'Create New Organisation'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            
            <TextField
              fullWidth
              label="Name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              required
              error={!!nameExists || formData.name.length > LIMITS.name}
              helperText={
                nameExists ? 'This name is already taken' : 
                formData.name.length > LIMITS.name ? `Maximum ${LIMITS.name} characters` :
                `${formData.name.length}/${LIMITS.name}`
              }
              disabled={mutation.isPending}
              inputProps={{ maxLength: LIMITS.name }}
            />
            <TextField
              label={
                <FieldLabel 
                  label="Wikipedia URL" 
                  tooltip="The full URL to the Wikipedia article about this organisation"
                />
              }
              value={formData.wikipedia || ''}
              onChange={(e) => setFormData({ ...formData, wikipedia: e.target.value.trim() })}
              error={!!formData.wikipedia && !isValidUrl(formData.wikipedia)}
              helperText={
                formData.wikipedia && !isValidUrl(formData.wikipedia)
                  ? 'Please enter a valid URL starting with http:// or https://'
                  : 'Full URL to Wikipedia article'
              }
            />
            <TextField
              label={
                <FieldLabel 
                  label="Short Description" 
                  tooltip="A brief description of the organisation (max 255 characters)"
                />
              }
              value={formData.short_description}
              onChange={(e) => setFormData({ ...formData, short_description: e.target.value })}
              multiline
              rows={2}
              inputProps={{ maxLength: LIMITS.shortDescription }}
              error={formData.short_description.length > LIMITS.shortDescription}
              helperText={`${formData.short_description?.length || 0}/${LIMITS.shortDescription}`}
            />
            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.is_fictional}
                    onChange={(e) => setFormData(prev => ({ 
                      ...prev, 
                      is_fictional: e.target.checked 
                    }))}
                  />
                }
                label="Is Fictional"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.is_historical}
                    onChange={(e) => setFormData(prev => ({ 
                      ...prev, 
                      is_historical: e.target.checked 
                    }))}
                  />
                }
                label="Is Historical"
              />
            </Box>
            <TextField
              label={
                <FieldLabel 
                  label="Long Description" 
                  tooltip="A detailed description of the organisation, its history, and purpose"
                />
              }
              value={formData.long_description}
              onChange={(e) => setFormData({ ...formData, long_description: e.target.value })}
              multiline
              rows={4}
              inputProps={{ maxLength: LIMITS.longDescription }}
              error={formData.long_description.length > LIMITS.longDescription}
              helperText={`${formData.long_description?.length || 0}/${LIMITS.longDescription}`}
            />
            <TextField
              label={
                <FieldLabel 
                  label="Synonyms" 
                  tooltip="Alternative names for this organisation, one per line"
                />
              }
              value={formData.synonyms.join('\n')}
              onChange={(e) => setFormData({ 
                ...formData, 
                synonyms: e.target.value
                  .split('\n')
                  .map(s => s.trim())
                  .filter(s => s.length > 0)
              })}
              multiline
              rows={4}
              helperText="Enter each synonym on a new line"
            />
            <TextField
              label={
                <FieldLabel 
                  label="Notes" 
                  tooltip="Additional information about the organisation"
                />
              }
              value={formData.notes}
              onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
              multiline
              rows={4}
              inputProps={{ maxLength: LIMITS.notes }}
              error={formData.notes.length > LIMITS.notes}
              helperText={`${formData.notes?.length || 0}/${LIMITS.notes}`}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={mutation.isPending}>
            Cancel
          </Button>
          <Button 
            type="submit" 
            variant="contained" 
            disabled={isSubmitDisabled}
            startIcon={mutation.isPending ? <CircularProgress size={20} /> : null}
          >
            {organisation ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}; 