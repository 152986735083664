import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Switch,
  FormControlLabel,
  FormGroup,
  Autocomplete,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Stack,
  Chip
} from '@mui/material';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  fetchSuggestionSettings,
  updateSuggestionSettings,
  fetchAvailableIndustries,
  fetchAvailableModules
} from '../../services/suggestions';
import { useMatomo } from '../../hooks/useMatomo';

export const SuggestionSettings: React.FC = () => {
  const queryClient = useQueryClient();
  const { trackEvent } = useMatomo();

  const { data: settings, isLoading: isLoadingSettings } = useQuery({
    queryKey: ['suggestionSettings'],
    queryFn: fetchSuggestionSettings
  });

  const { data: industries } = useQuery({
    queryKey: ['industries'],
    queryFn: fetchAvailableIndustries
  });

  const { data: modules } = useQuery({
    queryKey: ['suggestionModules'],
    queryFn: fetchAvailableModules
  });

  const updateSettingsMutation = useMutation({
    mutationFn: updateSuggestionSettings,
    onSuccess: () => {
      queryClient.invalidateQueries(['suggestionSettings']);
      trackEvent({
        category: 'Suggestions',
        action: 'UpdateSettings'
      });
    }
  });

  const handleSettingChange = (field: string, value: any) => {
    if (!settings) return;

    const newSettings = {
      ...settings,
      [field]: value
    };

    updateSettingsMutation.mutate(newSettings);
  };

  if (isLoadingSettings) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!settings) {
    return (
      <Alert severity="error">
        Error loading suggestion settings. Please try again later.
      </Alert>
    );
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Suggestion Engine Settings
        </Typography>

        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={settings.engine_enabled}
                onChange={(e) => handleSettingChange('engine_enabled', e.target.checked)}
              />
            }
            label="Enable Suggestion Engine"
          />

          {settings.engine_enabled && (
            <Stack spacing={3} sx={{ mt: 2 }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={settings.network_suggestions}
                      onChange={(e) =>
                        handleSettingChange('network_suggestions', e.target.checked)
                      }
                    />
                  }
                  label="Enable Network Suggestions"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={settings.content_suggestions}
                      onChange={(e) =>
                        handleSettingChange('content_suggestions', e.target.checked)
                      }
                    />
                  }
                  label="Enable Content Suggestions"
                />
              </FormGroup>

              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Industry
                </Typography>
                <Autocomplete
                  options={industries || []}
                  getOptionLabel={(option) => option.name}
                  value={industries?.find((i) => i.id === settings.industry_id) || null}
                  onChange={(_, newValue) =>
                    handleSettingChange('industry_id', newValue?.id)
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" fullWidth />
                  )}
                />
              </Box>

              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Enabled Modules
                </Typography>
                <Stack direction="row" spacing={1} flexWrap="wrap">
                  {modules?.map((module) => (
                    <Chip
                      key={module.id}
                      label={module.name}
                      color={
                        settings.enabled_modules.some((m) => m.module_id === module.id)
                          ? 'primary'
                          : 'default'
                      }
                      onClick={() => {
                        const isEnabled = settings.enabled_modules.some(
                          (m) => m.module_id === module.id
                        );
                        const newModules = isEnabled
                          ? settings.enabled_modules.filter(
                              (m) => m.module_id !== module.id
                            )
                          : [...settings.enabled_modules, { module_id: module.id }];
                        handleSettingChange('enabled_modules', newModules);
                      }}
                      sx={{ mb: 1 }}
                    />
                  ))}
                </Stack>
              </Box>
            </Stack>
          )}
        </FormGroup>

        {updateSettingsMutation.isError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            Error updating settings. Please try again.
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};
