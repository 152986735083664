import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  Button, 
  useTheme, 
  IconButton, 
  Tooltip, 
  CircularProgress 
} from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import { api } from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';

interface QrCodeGeneratorProps {
  isProfileOwner: boolean;
  isManagedUser: boolean;
  size?: number;
  isVisible?: boolean;
}

const QrCodeGenerator: React.FC<QrCodeGeneratorProps> = ({ 
  isProfileOwner, 
  isManagedUser,
  size = 150,
  isVisible = true
}) => {
  const theme = useTheme();
  const auth = useAuth();
  const [qrData, setQrData] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [tokenExpiration, setTokenExpiration] = useState<Date | null>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  
  // Generate a QR code token
  const generateQrCode = async () => {
    if (!isProfileOwner || isManagedUser || !isVisible || !auth?.isAuthenticated) return;
    
    setIsLoading(true);
    setError(null);
    
    try {
      // Use the app's API service which has authentication built-in
      const response = await api.post('/contacts/qr/generate', {});
      
      if (response.data.success) {
        const { token, qrUrl, expiresAt } = response.data.data;
        setQrData(qrUrl);
        setTokenExpiration(new Date(expiresAt));
        
        // Schedule QR code refresh
        startQrRefreshTimer(new Date(expiresAt));
      } else {
        setError(response.data.message || 'Failed to generate QR code');
        setQrData(null);
      }
    } catch (err: any) {
      console.error('Error generating QR code:', err);
      // Display more specific error information
      const errorMessage = err.response?.data?.message || 
                         err.message || 
                         'Could not generate QR code. Please try again.';
      setError(errorMessage);
      setQrData(null);
    } finally {
      setIsLoading(false);
    }
  };
  
  // Start timer for QR code refresh
  const startQrRefreshTimer = (expirationTime: Date) => {
    // Clear any existing timer
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    
    // Calculate time until expiration
    const now = new Date();
    const timeUntilExpiration = expirationTime.getTime() - now.getTime();
    
    // Set a timer to regenerate the QR code when it expires
    if (timeUntilExpiration > 0) {
      timerRef.current = setTimeout(() => {
        generateQrCode();
      }, timeUntilExpiration);
    } else {
      // If already expired, generate a new one immediately
      generateQrCode();
    }
  };
  
  // Clean up timer on unmount
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);
  
  // Generate QR code on initial render
  useEffect(() => {
    if (isProfileOwner && !isManagedUser && isVisible) {
      generateQrCode();
    }
  }, [isProfileOwner, isManagedUser, isVisible]);
  
  // If not visible or not the profile owner, don't render anything
  if (!isVisible || !isProfileOwner) {
    return null;
  }
  
  // If managed user, show a message
  if (isManagedUser) {
    return (
      <Box sx={{ textAlign: 'center', p: 1 }}>
        <Typography variant="caption" color="text.secondary">
          QR code not available for managed users
        </Typography>
      </Box>
    );
  }
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
      <Paper
        elevation={3}
        sx={{
          p: 2,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
          background: theme.palette.background.paper,
          width: size + 40, // Add padding for the Paper
          height: size + 80 // Add extra space for text and buttons
        }}
      >
        {/* Move help icon to top of the Paper, above QR code */}
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end', 
            width: '100%',
            mb: 1
          }}
        >
          <Tooltip title="Share this QR code to quickly connect with others. It expires and refreshes after 60 seconds.">
            <IconButton 
              size="small" 
              sx={{ 
                bgcolor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  bgcolor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.2)',
                }
              }}
            >
              <HelpOutlineIcon 
                fontSize="small" 
                sx={{ 
                  color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
                  fontWeight: 'bold'
                }} 
              />
            </IconButton>
          </Tooltip>
        </Box>
        
        {isLoading ? (
          <Box sx={{ height: size, width: size, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress size={size / 3} />
          </Box>
        ) : error ? (
          <Box sx={{ height: size, width: size, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <Typography variant="caption" color="error" align="center" gutterBottom>
              {error}
            </Typography>
            <Button 
              variant="outlined" 
              size="small" 
              onClick={generateQrCode}
              startIcon={<RefreshIcon />}
            >
              Try Again
            </Button>
          </Box>
        ) : qrData ? (
          <Box sx={{ 
            height: size, 
            width: size, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor: 'white', // QR code needs white background
            borderRadius: 1,
            p: 0.5 // Reduce padding to make QR code larger
          }}>
            <QRCodeSVG 
              value={qrData} 
              size={size - 8} // Less padding subtracted to make QR code larger
              bgColor={"#ffffff"}
              fgColor={theme.palette.mode === 'dark' ? '#000000' : '#000000'}
              level={"H"}
              includeMargin={false}
            />
          </Box>
        ) : (
          <Box sx={{ height: size, width: size, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button 
              variant="contained" 
              size="small" 
              onClick={generateQrCode}
            >
              Generate QR Code
            </Button>
          </Box>
        )}
        
      </Paper>
    </Box>
  );
};

export default QrCodeGenerator; 