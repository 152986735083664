/**
 * MessageList.tsx
 * 
 * A React component that displays the messages in a chat conversation.
 * It handles message rendering, reactions, read receipts, and message actions.
 * 
 * Key features:
 * - Message display with sender info and timestamps
 * - Message actions (copy, forward, delete, mark as unread)
 * - Emoji reactions
 * - Read receipts
 * - Media attachments (images, videos, audio)
 * - Message grouping by date
 * - Infinite scroll for message history
 * 
 * Related files:
 * - ChatContext.tsx: Provides chat state and message operations
 * - ChatController.mjs: Backend API for message operations
 * - MessageInput.tsx: Handles message composition and sending
 * - EmojiReactions.tsx: Manages message reactions UI
 */

// src/components/communication/MessageList.tsx
import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography, Paper, Avatar, Grid, Divider, IconButton, Tooltip, Snackbar, Menu, MenuItem, ListItemIcon, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions, Button, Alert } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { useChatContext } from '../../contexts/ChatContext';
import { useAuth } from '../../hooks/useAuth';
import { ChatMessage, chatApi, ChatMessageReaction } from '../../api/chatApi';
import { EmojiReactions } from '../EmojiReactions';
import { User } from '../../types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ForwardIcon from '@mui/icons-material/Forward';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';

const MessageItem: React.FC<{ message: ChatMessage, isCurrentUser: boolean }> = ({ message, isCurrentUser }) => {
  const { user } = useAuth();
  const { updateMessage, deleteMessage, activeConversation } = useChatContext();
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const open = Boolean(anchorEl);

  // Check if message is unread by current user
  const isUnread = !message.readBy?.includes(user?.id || '');
  // Check if message is unread by anyone
  const isUnreadByAnyone = message.readBy?.length === 0;
  // Check if user is group creator (chat admin)
  const isChatAdmin = activeConversation?.is_group && activeConversation?.metadata?.creator_id === user?.id;

  if (!message || !message.senderId) {
    console.error('Invalid message:', message);
    return null;
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleReactionAdded = async (reaction: { emoji: string; count: number; users: User[] }) => {
    try {
      if (!user?.id) {
        console.error('Cannot add reaction: user not authenticated');
        return;
      }

      // Ensure message.reactions is an array
      const messageReactions = Array.isArray(message.reactions) ? message.reactions : [];
      
      // Check if the user has already added this reaction
      const existingReaction = messageReactions.find(r => 
        r.emoji === reaction.emoji && r.user_id === user.id
      );

      if (existingReaction) {
        // If the user has already added this reaction, remove it (toggle off)
        console.log('User has already added this reaction, removing it');
        await handleReactionRemoved(reaction.emoji);
        return;
      }

      // Check if this reaction is from another user
      const otherUserReaction = messageReactions.find(r => 
        r.emoji === reaction.emoji && r.user_id !== user.id
      );

      if (otherUserReaction) {
        // If another user has already added this reaction, just add our reaction
        console.log('Another user has already added this reaction, adding our reaction');
      }

      // Create a temporary reaction for immediate UI update
      const tempReaction = {
        id: `temp-${Date.now()}`,
        message_id: message.id,
        user_id: user.id,
        emoji: reaction.emoji,
        created_at: new Date(),
        updated_at: new Date(),
        user: user
      };

      // Update the message with the temporary reaction immediately
      const updatedMessage: ChatMessage = {
        ...message,
        reactions: [...messageReactions, tempReaction]
      };
      
      // Update the message in the context immediately
      if (updateMessage) {
        updateMessage(updatedMessage);
      }

      console.log('Adding reaction:', reaction.emoji, 'to message:', message.id, 'in conversation:', message.conversationId);
      const response = await chatApi.addReaction(message.conversationId, message.id, reaction.emoji);
      console.log('Reaction added response:', response);

      // Update the message with the real reactions from the server
      const finalMessage: ChatMessage = {
        ...message,
        reactions: response.data || [] // Ensure we have an array even if data is undefined
      };
      
      // Update the message in the context with the final version
      if (updateMessage) {
        updateMessage(finalMessage);
      }
    } catch (error: any) {
      console.error('Failed to add reaction:', error);
      // Remove the temporary reaction if the server request failed
      const updatedMessage: ChatMessage = {
        ...message,
        reactions: message.reactions?.filter(r => !r.id.startsWith('temp-')) || []
      };
      if (updateMessage) {
        updateMessage(updatedMessage);
      }
      if (error.response?.status === 400 && error.response?.data?.message) {
        console.log('Server message:', error.response.data.message);
      }
    }
  };

  const handleReactionRemoved = async (emoji: string) => {
    try {
      if (!user?.id) {
        console.error('Cannot remove reaction: user not authenticated');
        return;
      }

      // Ensure message.reactions is an array
      const messageReactions = Array.isArray(message.reactions) ? message.reactions : [];
      
      // Check if the user has added this reaction
      const existingReaction = messageReactions.find(r => 
        r.emoji === emoji && r.user_id === user.id
      );

      if (!existingReaction) {
        console.log('User has not added this reaction');
        return;
      }

      // Store the existing reaction in case we need to restore it
      const reactionToRemove = { ...existingReaction };

      // Remove the reaction immediately for instant UI feedback
      const updatedMessage: ChatMessage = {
        ...message,
        reactions: messageReactions.filter(r => 
          !(r.emoji === emoji && r.user_id === user.id)
        )
      };
      
      // Update the message in the context immediately
      if (updateMessage) {
        updateMessage(updatedMessage);
      }

      console.log('Removing reaction:', emoji, 'from message:', message.id, 'in conversation:', message.conversationId);
      await chatApi.removeReaction(message.conversationId, message.id, emoji);
      console.log('Reaction removed successfully');
    } catch (error: any) {
      console.error('Failed to remove reaction:', error);
      // Restore the reaction if the server request failed
      if (updateMessage) {
        updateMessage(message);
      }
      if (error.response?.status === 400 && error.response?.data?.message) {
        console.log('Server message:', error.response.data.message);
      }
    }
  };

  const handleCopyMessage = async () => {
    try {
      await navigator.clipboard.writeText(message.content);
      setShowCopySuccess(true);
    } catch (err) {
      console.error('Failed to copy message:', err);
      // Fallback for mobile devices
      const textArea = document.createElement('textarea');
      textArea.value = message.content;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        setShowCopySuccess(true);
      } catch (err) {
        console.error('Fallback copy failed:', err);
      }
      document.body.removeChild(textArea);
    }
    handleMenuClose();
  };

  const handleDeleteMessage = () => {
    setShowDeleteDialog(true);
    handleMenuClose();
  };

  const handleConfirmDelete = async (deleteForAll: boolean) => {
    try {
      setIsDeleting(true);
      
      // Check message deletion permissions
      // - Chat admin (group creator) can delete any message in their group
      // - Sender can delete their message for everyone if it hasn't been read by anyone
      // - All users can delete any message for themselves
      const isSender = message.senderId === user?.id;
      
      // Determine if we can delete for everyone based on permissions
      // - Chat admin can always delete for everyone in their group
      // - Sender can delete for everyone only if message is unread by anyone
      const canDeleteForAll = isChatAdmin || (isSender && isUnreadByAnyone);
      
      // If deleteForAll is requested but user doesn't have permission,
      // fall back to deleting only for the current user
      const actualDeleteForAll = deleteForAll && canDeleteForAll;
      
      // Call the API to delete the message
      await chatApi.deleteMessage(message.conversationId, message.id, actualDeleteForAll);
      
      // Update the UI by removing the message from the context
      if (deleteMessage) {
        deleteMessage(message.id);
      }

      setShowCopySuccess(true);
      setShowDeleteDialog(false);
    } catch (error) {
      console.error('Error deleting message:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
  };

  const handleForwardMessage = () => {
    // TODO: Implement forward functionality
    console.log('Forward message:', message.id);
    handleMenuClose();
  };

  const handleMarkUnread = async () => {
    try {
      if (!message.conversationId || !message.id) return;
      
      // Call the API to mark the message as unread
      await chatApi.markAsUnread(message.conversationId, message.id);
      
      // Update the message in the UI
      if (updateMessage) {
        const updatedMessage = {
          ...message,
          readBy: message.readBy?.filter(id => id !== user?.id) || []
        };
        updateMessage(updatedMessage);
      }
      
      handleMenuClose();
    } catch (error) {
      console.error('Failed to mark message as unread:', error);
    }
  };

  // Convert ChatMessageReaction[] to Reaction[]
  const convertToReactions = (reactions: ChatMessageReaction[] | undefined | null = []): { emoji: string; count: number; users: User[] }[] => {
    const reactionMap = new Map<string, { count: number; users: User[] }>();
    
    // Process the reactions array
    if (Array.isArray(reactions)) {
      reactions.forEach(reaction => {
        if (!reaction || !reaction.emoji) return;
        
        const key = reaction.emoji;
        const existing = reactionMap.get(key) || { count: 0, users: [] };
        existing.count++;
        // Use the user data from the reaction if available
        if (reaction.user) {
          existing.users.push(reaction.user);
        } else if (reaction.user_id) {
          existing.users.push({ id: reaction.user_id } as User);
        }
        reactionMap.set(key, existing);
      });
    }

    return Array.from(reactionMap.entries()).map(([emoji, data]) => ({
      emoji,
      count: data.count,
      users: data.users
    }));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: isCurrentUser ? 'flex-end' : 'flex-start',
        mb: 2,
        width: '100%',
      }}
    >
      <Box sx={{ maxWidth: '70%' }}>
        <Paper
          elevation={1}
          sx={{
            p: 2,
            bgcolor: isCurrentUser ? 'primary.main' : 'background.paper',
            color: isCurrentUser ? 'primary.contrastText' : 'text.primary',
            borderRadius: 2,
            wordBreak: 'break-word',
            position: 'relative',
            '&:hover .message-menu': {
              opacity: 1,
            },
          }}
        >
          <Typography variant="body1" sx={{ 
            whiteSpace: 'pre-wrap',
            color: 'inherit'
          }}>{message.content}</Typography>
          
          <Tooltip title="Message options">
            <IconButton
              className="message-menu"
              size="small"
              onClick={handleMenuClick}
              sx={{
                position: 'absolute',
                top: 4,
                right: 4,
                opacity: 0.5,
                transition: 'opacity 0.2s',
                color: isCurrentUser ? 'primary.contrastText' : 'text.secondary',
                '&:hover': {
                  opacity: 1,
                  backgroundColor: isCurrentUser ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.04)',
                },
                '@media (max-width: 600px)': {
                  opacity: 1,
                  backgroundColor: isCurrentUser ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.04)',
                  padding: '8px',
                  '&:active': {
                    backgroundColor: isCurrentUser ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.08)',
                  }
                }
              }}
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            PaperProps={{
              sx: {
                mt: 1,
                minWidth: 180,
                boxShadow: 3,
              }
            }}
          >
            <MenuItem onClick={handleCopyMessage}>
              <ListItemIcon>
                <ContentCopyIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Copy message</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleForwardMessage}>
              <ListItemIcon>
                <ForwardIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Forward message</ListItemText>
            </MenuItem>
            {!isUnread && (
              <MenuItem onClick={handleMarkUnread}>
                <ListItemIcon>
                  <MarkEmailUnreadIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Mark as unread</ListItemText>
              </MenuItem>
            )}
            <MenuItem onClick={handleDeleteMessage} sx={{ color: 'error.main' }}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" color="error" />
              </ListItemIcon>
              <ListItemText>Delete message</ListItemText>
            </MenuItem>
          </Menu>
          
          {message.mediaUrls && message.mediaUrls.length > 0 && (
            <Box sx={{ mt: 1 }}>
              {message.mediaUrls.map((url, index) => {
                const mediaType = message.mediaTypes?.[index] || '';
                
                if (mediaType.startsWith('image/')) {
                  return (
                    <Box
                      key={`${message.id}-media-${index}`}
                      component="img"
                      src={url}
                      alt="Message attachment"
                      sx={{
                        maxWidth: '100%',
                        height: 'auto',
                        borderRadius: 1,
                        mt: 1
                      }}
                    />
                  );
                } else if (mediaType.startsWith('video/')) {
                  return (
                    <Box
                      key={`${message.id}-media-${index}`}
                      component="video"
                      controls
                      sx={{
                        maxWidth: '100%',
                        borderRadius: 1,
                        mt: 1
                      }}
                    >
                      <source src={url} type={mediaType} />
                      Your browser does not support the video tag.
                    </Box>
                  );
                } else if (mediaType.startsWith('audio/')) {
                  return (
                    <Box 
                      component="audio"
                      key={`${message.id}-media-${index}`}
                      controls
                      sx={{ 
                        width: '100%',
                        mt: 1
                      }}
                    >
                      <source src={url} type={mediaType} />
                      Your browser does not support the audio tag.
                    </Box>
                  );
                } else {
                  return (
                    <Box 
                      key={`${message.id}-media-${index}`}
                      sx={{ 
                        mt: 1,
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Typography variant="body2">
                        <a href={url} target="_blank" rel="noopener noreferrer">
                          Attachment
                        </a>
                      </Typography>
                    </Box>
                  );
                }
              })}
            </Box>
          )}
          <Typography variant="caption" sx={{ 
            mt: 0.5, 
            display: 'block',
            textAlign: isCurrentUser ? 'right' : 'left',
            color: isCurrentUser ? 'primary.contrastText' : 'text.secondary',
            opacity: 0.8
          }}>
            {formatDistanceToNow(new Date(message.createdAt), { addSuffix: true })}
          </Typography>
        </Paper>
        
        {/* Emoji reactions moved outside the message bubble */}
        <Box sx={{ 
          mt: 0.5, 
          display: 'flex', 
          justifyContent: isCurrentUser ? 'flex-end' : 'flex-start',
          pl: 1,
          pr: 1
        }}>
          <EmojiReactions
            contentId={message.id}
            contentType="chat_message"
            conversationId={message.conversationId}
            reactions={convertToReactions(message.reactions)}
            onReactionAdded={handleReactionAdded}
            onReactionRemoved={handleReactionRemoved}
            user={user || undefined}
          />
        </Box>
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={showDeleteDialog}
        onClose={handleCancelDelete}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Message</DialogTitle>
        <DialogContent>
          <Typography id="delete-dialog-description">
            {isChatAdmin ? (
              'As the group creator, you can delete this message for everyone.'
            ) : isCurrentUser && isUnreadByAnyone ? (
              'This message has not been read yet. Deleting it will remove it for everyone.'
            ) : (
              'Are you sure you want to delete this message? This will only remove it from your view.'
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} disabled={isDeleting}>
            Cancel
          </Button>
          {(isChatAdmin || (isCurrentUser && isUnreadByAnyone)) ? (
            <Button 
              onClick={() => handleConfirmDelete(true)} 
              color="error" 
              variant="contained"
              disabled={isDeleting}
            >
              Delete for everyone
            </Button>
          ) : (
            <Button 
              onClick={() => handleConfirmDelete(false)} 
              color="error" 
              variant="outlined"
              disabled={isDeleting}
            >
              Delete for me
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Success/Error Snackbar */}
      <Snackbar
        open={showCopySuccess}
        autoHideDuration={6000}
        onClose={() => setShowCopySuccess(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setShowCopySuccess(false)} 
          severity={isDeleting ? "error" : "success"}
          sx={{ width: '100%' }}
        >
          {isDeleting ? "Message deleted successfully" : "Message copied to clipboard"}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const MessageList: React.FC = () => {
  const { messages, activeConversation, loadMoreMessages } = useChatContext();
  const { user } = useAuth();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  // Scroll to bottom on initial load and when new messages are added
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // Handle scroll to load more messages
  const handleScroll = () => {
    const container = messagesContainerRef.current;
    if (container && container.scrollTop === 0) {
      loadMoreMessages();
    }
  };

  if (!activeConversation) {
    console.log('No active conversation');
    return (
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography color="text.secondary">Select a conversation to view messages</Typography>
      </Box>
    );
  }

  if (!messages || messages.length === 0) {
    console.log('No messages in conversation');
    return (
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography color="text.secondary">No messages yet</Typography>
      </Box>
    );
  }

  // Group messages by date
  const groupedMessages: { [key: string]: ChatMessage[] } = {};
  messages.forEach(message => {
    if (!message || !message.createdAt) return;
    const date = new Date(message.createdAt).toDateString();
    if (!groupedMessages[date]) {
      groupedMessages[date] = [];
    }
    groupedMessages[date].push(message);
  });

  return (
    <Box
      ref={messagesContainerRef}
      onScroll={handleScroll}
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: 0,
      }}
    >
      {Object.entries(groupedMessages).map(([date, dateMessages]) => (
        <Box key={date}>
          <Divider>
            <Typography variant="caption" color="text.secondary">
              {date === new Date().toDateString() ? 'Today' : date}
            </Typography>
          </Divider>
          <Box sx={{ mt: 2, mb: 2 }}>
            {dateMessages.map((message) => (
              <MessageItem
                key={message.id}
                message={message}
                isCurrentUser={user?.id === message.senderId}
              />
            ))}
          </Box>
        </Box>
      ))}
      <div ref={messagesEndRef} style={{ float: 'left', clear: 'both' }} />
    </Box>
  );
};

export default MessageList;