import React from 'react';
import { 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardActionArea, 
  CardContent, 
  CardMedia,
  Box
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Tool interface
interface Tool {
  id: string;
  name: string;
  description: string;
  imagePath: string;
  path: string;
}

// Available tools
const tools: Tool[] = [
  {
    id: 'uuid4',
    name: 'UUID4 Generator',
    description: 'Generate random universally unique identifiers (UUID version 4)',
    imagePath: '/assets/illustrations/tools/uuid-generator.webp',
    path: '/tools/generateuuid4'
  },
  {
    id: 'speed-networking',
    name: 'Speed Networking Scheduler',
    description: 'Generate personalized schedules for networking events with optimal participant interactions',
    imagePath: '/assets/illustrations/tools/speed-networking-scheduler.webp',
    path: '/tools/speed-networking-scheduler'
  },
  {
    id: 'qrscanner',
    name: 'QR Code Scanner',
    description: 'Scan a QR code to connect with another user',
    imagePath: '/assets/illustrations/tools/qr-scanner.webp',
    path: '/scan-contact'
  },
  {
    id: 'quizzes',
    name: 'Quizzes',
    description: 'Take fun and interactive quizzes to connect with people sharing similar interests',
    imagePath: '/assets/illustrations/tools/quizzes.webp',
    path: '/tools/quizzes'
  }
  // Add more tools here as they become available
];

const ToolsPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 8 }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4 }}>
        Tools
      </Typography>
      
      <Typography variant="body1" paragraph sx={{ mb: 4 }}>
        Useful utilities and tools to help with various tasks. These tools are available to all users.
      </Typography>
      
      <Grid container spacing={3}>
        {tools.map((tool) => (
          <Grid item xs={12} sm={6} md={4} key={tool.id}>
            <Card 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 6,
                }
              }}
            >
              <CardActionArea 
                sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
                onClick={() => navigate(tool.path)}
              >
                <Box sx={{ position: 'relative', pt: '56.25%' /* 16:9 aspect ratio */ }}>
                  <CardMedia
                    component="img"
                    image={tool.imagePath}
                    alt={tool.name}
                    sx={{ 
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                  />
                </Box>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="div">
                    {tool.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {tool.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ToolsPage; 