import { useState, useEffect, useMemo } from 'react';
import Fuse from 'fuse.js';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  Chip,
  Stack,
  Autocomplete,
  TextField,
} from '@mui/material';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../services/api';

const PROFICIENCY_LEVELS = ['BASIC', 'ADVANCED', 'EXPERT'] as const;

interface Language {
  id: string;
  name: string;
  two_letter_code: string | null;
  three_letter_code: string | null;
  is_fictional: boolean;
  is_historical: boolean;
  synonyms: string[];
}

interface UserLanguage {
  language_id: string;
  proficiency: typeof PROFICIENCY_LEVELS[number];
  language: Language;
}

interface LanguageOption {
  id: string;
  label: string;
  originalName: string;
  isSynonym: boolean;
  key: string;
}

interface LanguageSelectorProps {
  userId?: string;
}

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({ userId }) => {
  const queryClient = useQueryClient();
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageOption | null>(null);
  const [selectedProficiency, setSelectedProficiency] = useState<typeof PROFICIENCY_LEVELS[number]>('BASIC');
  const [userLanguages, setUserLanguages] = useState<UserLanguage[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<LanguageOption[]>([]);

  // Fetch languages query
  const { data: languages } = useQuery<Language[]>({
    queryKey: ['languages'],
    queryFn: () => api.get('/languages').then(res => res.data)
  });

  // Fetch user languages query
  const { data: fetchedUserLanguages, refetch: refetchUserLanguages } = useQuery({
    queryKey: ['userLanguages', userId],
    queryFn: async () => {
      const endpoint = userId ? `/users/${userId}/languages` : '/users/me/languages';
      const response = await api.get(endpoint);
      return response.data;
    }
  });

  // Update languages mutation
  const updateLanguagesMutation = useMutation({
    mutationFn: async (languages: { language_id: string; proficiency: string }[]) => {
      const endpoint = userId ? `/users/${userId}/languages` : '/users/me/languages';
      return api.post(endpoint, { languages });
    },
    onSuccess: () => {
      refetchUserLanguages();
      queryClient.invalidateQueries(['userLanguages', userId]);
    }
  });

  useEffect(() => {
    if (fetchedUserLanguages) {
      setUserLanguages(fetchedUserLanguages);
    }
  }, [fetchedUserLanguages]);

  // Create language options including synonyms - memoized
  const languageOptions = useMemo(() => 
    languages?.flatMap(lang => [
      {
        id: lang.id,
        label: lang.name,
        originalName: lang.name,
        isSynonym: false,
        key: `${lang.id}-original`
      },
      ...(lang.synonyms?.map(synonym => ({
        id: lang.id,
        label: synonym,
        originalName: lang.name,
        isSynonym: true,
        key: `${lang.id}-${synonym}`
      })) || [])
    ]) || []
  , [languages]);

  // Configure Fuse.js options and create instance - memoized
  const fuse = useMemo(() => {
    const fuseOptions = {
      keys: ['label', 'originalName'],
      threshold: 0.3,
      minMatchCharLength: 2
    };
    return new Fuse(languageOptions, fuseOptions);
  }, [languageOptions]);

  // Update filtered options when input changes
  useEffect(() => {
    if (!inputValue) {
      setFilteredOptions(languageOptions);
      return;
    }

    const results = fuse.search(inputValue);
    setFilteredOptions(results.map(result => result.item));
  }, [inputValue, languageOptions, fuse]);

  const handleAddLanguage = () => {
    if (!selectedLanguage) return;

    setError(null); // Clear any previous error

    // Check if language already exists
    const languageExists = userLanguages.some(
      ul => ul.language.id === selectedLanguage.id
    );

    if (languageExists) {
      setError('This language is already added. Please remove it first if you want to change the proficiency level.');
      return;
    }

    // Create new language entry
    const newLanguage = {
      language_id: selectedLanguage.id,
      proficiency: selectedProficiency,
      language: languages?.find(l => l.id === selectedLanguage.id)!
    };

    // Combine existing languages with new one, ensuring proper format
    const newLanguages = [
      ...userLanguages.map(lang => ({
        language_id: lang.language.id, // Use the language.id from the existing language
        proficiency: lang.proficiency
      })),
      {
        language_id: newLanguage.language_id,
        proficiency: newLanguage.proficiency
      }
    ];

    console.log('Updating languages:', newLanguages);
    updateLanguagesMutation.mutate(newLanguages);
    setSelectedLanguage(null);
    setSelectedProficiency('BASIC');
  };

  const handleRemoveLanguage = (languageId: string, proficiency: typeof PROFICIENCY_LEVELS[number]) => {
    const updatedLanguages = userLanguages
      .filter(ul => !(ul.language.id === languageId && ul.proficiency === proficiency))
      .map(lang => ({
        language_id: lang.language.id,
        proficiency: lang.proficiency
      }));

    updateLanguagesMutation.mutate(updatedLanguages);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Languages
      </Typography>
      
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <FormControl sx={{ minWidth: 200 }}>
          <Autocomplete
            value={selectedLanguage}
            onChange={(_, newValue) => setSelectedLanguage(newValue)}
            onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
            options={filteredOptions}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <li {...props} key={option.key}>
                {option.label}
                {option.isSynonym && (
                  <Typography variant="caption" sx={{ ml: 1, color: 'text.secondary' }}>
                    → {option.originalName}
                  </Typography>
                )}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Language" />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterOptions={(x) => x} // Disable built-in filtering since we're using Fuse.js
          />
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>Proficiency</InputLabel>
          <Select
            value={selectedProficiency}
            onChange={(e) => setSelectedProficiency(e.target.value as typeof PROFICIENCY_LEVELS[number])}
            label="Proficiency"
          >
            {PROFICIENCY_LEVELS.map((level) => (
              <MenuItem key={level} value={level}>
                {level.charAt(0) + level.slice(1).toLowerCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button 
          variant="contained" 
          onClick={handleAddLanguage}
          disabled={!selectedLanguage || updateLanguagesMutation.isPending}
        >
          Add Language
        </Button>
      </Stack>

      <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
        {userLanguages?.map((userLanguage) => (
          <Chip
            key={`${userLanguage.language.id}-${userLanguage.proficiency}`}
            label={`${userLanguage.language.name} (${userLanguage.proficiency})`}
            onDelete={() => handleRemoveLanguage(userLanguage.language.id, userLanguage.proficiency)}
            sx={{ mb: 1 }}
          />
        ))}
      </Stack>
    </Box>
  );
}; 