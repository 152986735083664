import { Contact, ContactStatus, PriorityLevel } from '../types/contact';
import { api } from './api';

interface FetchContactsParams {
  page?: number;
  limit?: number;
  search?: string;
  status?: ContactStatus | '';
  priority?: PriorityLevel | '';
  tags?: string[];
}

interface ContactsResponse {
  contacts: Contact[];
  pagination: {
    total: number;
    page: number;
    pages: number;
  };
}

export const getContacts = async (page: number = 1) => {
  const response = await api.get(`/contacts?page=${page}`);
  return response.data;
};

export const fetchContacts = async (params: FetchContactsParams): Promise<ContactsResponse> => {
  const queryParams = new URLSearchParams();
  
  if (params.page) queryParams.append('page', params.page.toString());
  if (params.limit) queryParams.append('limit', params.limit.toString());
  if (params.search) queryParams.append('search', params.search);
  if (params.status) queryParams.append('status', params.status);
  if (params.priority) queryParams.append('priority', params.priority);
  if (params.tags?.length) queryParams.append('tags', params.tags.join(','));

  const response = await api.get(`/contacts?${queryParams.toString()}`);
  return response.data;
};

export const fetchContact = async (id: string): Promise<Contact> => {
  const response = await api.get(`/contacts/${id}`);
  return response.data;
};

export const createContact = async (contactData: Partial<Contact>): Promise<Contact> => {
  const response = await api.post('/contacts', contactData);
  return response.data;
};

export const updateContact = async (id: string, contactData: Partial<Contact>): Promise<Contact> => {
  console.log('Updating contact with data:', contactData);
  try {
    const response = await api.put(`/contacts/${id}`, contactData);
    return response.data;
  } catch (error) {
    console.error('Error updating contact:', error);
    throw error;
  }
};

export const deleteContact = async (id: string): Promise<void> => {
  await api.delete(`/contacts/${id}`);
};

// Tag related functions
export const fetchTagCategories = async () => {
  const response = await api.get('/contact-tags/categories');
  return response.data;
};

export const createTagCategory = async (name: string) => {
  const response = await api.post('/contact-tags/categories', { name });
  return response.data;
};

export const fetchTags = async () => {
  const response = await api.get('/contact-tags');
  return response.data;
};

export const createTag = async (data: { name: string; category_id: string; color?: string }) => {
  const response = await api.post('/contact-tags', data);
  return response.data;
};

export const addTagsToContact = async (contactId: string, tagIds: string[]) => {
  const response = await api.post(`/contact-tags/contact/${contactId}`, { tags: tagIds });
  return response.data;
};

export const removeTagFromContact = async (contactId: string, tagId: string) => {
  await api.delete(`/contact-tags/contact/${contactId}/tag/${tagId}`);
};

// Interaction related functions
export const fetchContactInteractions = async (contactId: string) => {
  try {
    const response = await api.get(`/interactions/contact/${contactId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching contact interactions:', error);
    return [];
  }
};

export const createInteraction = async (data: any) => {
  const response = await api.post('/interactions', data);
  return response.data;
};

export const updateInteraction = async (id: string, data: any) => {
  const response = await api.put(`/interactions/${id}`, data);
  return response.data;
};

export const deleteInteraction = async (id: string) => {
  await api.delete(`/interactions/${id}`);
};

export const fetchUpcomingFollowUps = async () => {
  const response = await api.get('/interactions/follow-ups');
  return response.data;
};

export const importContacts = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await api.post('/contacts/import', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });

  return response.data;
};

export const exportContacts = async (format: 'csv' | 'vcf'): Promise<string> => {
  const response = await api.get(`/contacts/export?format=${format}`, {
    responseType: 'blob'
  });
  return response.data;
};
