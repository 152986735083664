import React, { createContext, useContext, useState, useEffect } from 'react';
import { User, LoginCredentials } from '../types';
import { RegisterData } from '../types/index';
import { api } from '../services/api';
import axios from 'axios';
import { useQueryClient } from '@tanstack/react-query';

interface RegistrationResult {
  status: 'SUCCESS' | 'EXISTING_USER' | 'WAITLIST';
  message?: string;
  action?: string;
  user?: User;
}

interface AuthCheckResult {
  data: { user?: User } | null;
}

interface AuthContextType {
  user: User | null;
  loading: boolean;
  error: string | null;
  login: (email: string, password: string) => Promise<{ user: User }>;
  logout: () => Promise<void>;
  checkAuth: () => Promise<AuthCheckResult>;
  register: (data: RegisterData) => Promise<RegistrationResult>;
  updateUser: (userData: Partial<User>) => void;
  resendVerification: (email: string) => Promise<void>;
  isAdmin: boolean;
  isSuperuser: boolean;
  isContentManager: boolean;
  hasRole: (role: string) => boolean;
  cookiesAccepted: boolean;
  acceptCookies: () => void;
  isAuthenticated: boolean;
}

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [lastActivity, setLastActivity] = useState<number>(Date.now());
  const [sessionTimeout] = useState<number>(24 * 60 * 60 * 1000); // Default 24 hours, managed by backend session
  const [cookiesAccepted, setCookiesAccepted] = useState<boolean>(() => {
    return localStorage.getItem('cookiesAccepted') === 'true';
  });
  const queryClient = useQueryClient();

  // Function to check session timeout
  const checkSessionTimeout = () => {
    if (user && Date.now() - lastActivity > sessionTimeout) {
      console.log('[Auth] Session timeout detected');
      logout();
    }
  };

  // Update last activity timestamp
  const updateLastActivity = () => {
    setLastActivity(Date.now());
  };

  // Add activity listeners
  useEffect(() => {
    if (user) {
      const events = ['mousedown', 'keydown', 'scroll', 'touchstart'];
      const handleActivity = () => {
        updateLastActivity();
        checkSessionTimeout();
      };

      events.forEach(event => {
        window.addEventListener(event, handleActivity);
      });

      // Check timeout every minute
      const timeoutInterval = setInterval(checkSessionTimeout, 60000);

      return () => {
        events.forEach(event => {
          window.removeEventListener(event, handleActivity);
        });
        clearInterval(timeoutInterval);
      };
    }
  }, [user]);

  useEffect(() => {
    console.log('[Auth] Initial auth check starting...');
    let mounted = true;
    
    const initAuth = async () => {
      try {
        console.log('[Auth] Checking authentication...');
        const response = await checkAuth();
        
        if (!mounted) return;

        if (response.data?.user) {
          console.log('[Auth] User authenticated:', response.data.user.email);
          setUser(response.data.user);
        } else {
          console.log('[Auth] No authenticated user found');
          setUser(null);
        }
      } catch (error) {
        console.error('[Auth] Initial auth check failed:', error);
        if (mounted) {
          setUser(null);
        }
      } finally {
        // Only set loading to false after we've handled the user state
        if (mounted) {
          console.log('[Auth] Setting loading to false');
          setLoading(false);
        }
      }
    };

    initAuth();

    return () => {
      mounted = false;
    };
  }, []);

  const checkAuth = async () => {
    console.log('[Auth] Starting authentication check...');
    try {
      // Check session timeout first
      if (user && Date.now() - lastActivity > sessionTimeout) {
        console.log('[Auth] Session timeout detected during auth check');
        await logout();
        return { data: null };
      }

      const response = await api.get('/auth/check');
      console.log('[Auth] Auth check response:', response.data);
      
      if (response.data?.user) {
        updateLastActivity();
      }
      
      return response;
    } catch (error: any) {
      console.error('[Auth] Error in checkAuth:', error);
      if (!cookiesAccepted) {
        setError('Please accept cookies to enable login functionality');
      }
      return { data: null };
    }
  };

  const login = async (email: string, password: string) => {
    if (!cookiesAccepted) {
      throw new Error('Please accept cookies to enable login functionality');
    }

    try {
      setLoading(true);
      console.log('Attempting login...');
      
      const response = await api.post('/auth/login', { email, password });
      
      console.log('Login successful');
      setUser(response.data.user);
      updateLastActivity();
      return response.data;
    } catch (error: any) {
      console.error('Login error:', error.response || error);
      console.error('[Auth] Login error details:', {
        status: error.response?.status,
        statusText: error.response?.statusText,
        data: error.response?.data,
        message: error.message
      });
      if (error.response?.data?.message?.includes('verify your email')) {
        throw new Error('UNVERIFIED_EMAIL');
      }
      setError(error.response?.data?.error || 'An error occurred during login');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      // First, try to call the logout endpoint
      try {
        await api.post('/auth/logout');
      } catch (error) {
        console.warn('Server logout failed, proceeding with client-side cleanup:', error);
        // Continue with cleanup even if server request fails
      }

      // Always clear local state regardless of server response
      setUser(null);
      queryClient.clear(); // Clear all queries from cache
      
      // Force clear the cookie on the client side
      document.cookie = 'trueconn_session=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      
      // Clear any stored user data
      localStorage.removeItem('user');
    } catch (error: any) {
      console.error('Logout error:', error);
      // Still clear the user state even if something fails
      setUser(null);
      queryClient.clear();
      localStorage.removeItem('user');
      throw error;
    }
  };

  const register = async (data: RegisterData): Promise<RegistrationResult> => {
    try {
      setLoading(true);
      console.log('Starting registration process...');
      
      const response = await api.post('/auth/register', data);
      console.log('Registration response received:', response.data);

      // Handle successful registration
      if (response.data.user) {
        setUser(response.data.user);
        queryClient.setQueryData(['currentUser'], response.data.user);
        return { 
          status: 'SUCCESS',
          user: response.data.user 
        };
      }

      // Handle waitlist case
      if (response.status === 202) {
        return {
          status: 'WAITLIST',
          message: response.data.message
        };
      }

      return {
        status: 'SUCCESS',
        user: response.data.user
      };

    } catch (error: any) {
      console.error('Registration error:', error);
      
      // Handle specific error cases
      if (error.response?.data?.status) {
        return {
          status: error.response.data.status,
          message: error.response.data.message,
          action: error.response.data.action
        };
      }
      
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateUser = (userData: Partial<User>) => {
    if (user) {
      const updatedUser = { ...user, ...userData };
      setUser(updatedUser);
      queryClient.setQueryData(['currentUser'], updatedUser);
    }
  };

  const resendVerification = async (email: string) => {
    try {
      setLoading(true);
      await api.post('/auth/resend-verification', { email });
    } catch (error: any) {
      console.error('Resend verification error:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const hasRole = (role: string): boolean => {
    return user?.roles?.some(userRole => userRole.role.name === role) || false;
  };

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setCookiesAccepted(true);
    setError(null);
  };

  // Memoize the context value to prevent unnecessary re-renders
  const value = React.useMemo(() => ({
    user,
    loading,
    error,
    login,
    logout,
    checkAuth,
    register,
    updateUser,
    resendVerification,
    isAdmin: hasRole('Admin'),
    isSuperuser: hasRole('Superuser'),
    isContentManager: hasRole('Content Manager'),
    hasRole,
    cookiesAccepted,
    acceptCookies,
    isAuthenticated: user !== null
  }), [user, loading, error, cookiesAccepted]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;