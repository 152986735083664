import { ImageConfig } from './types';

/**
 * Image Types Enum
 * Used to identify different image upload types throughout the application
 */
export enum ImageType {
  POST = 'POST',
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  PROFILE = 'PROFILE'
}

/**
 * Common image types supported across all entities
 * These MIME types are accepted for all image uploads
 */
export const COMMON_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/webp'];

/**
 * Image Upload Paths
 * Standard paths for different upload types - used for constructing URLs
 */
export const IMAGE_UPLOAD_PATHS = {
  [ImageType.POST]: '/uploads/posts',
  [ImageType.ANNOUNCEMENT]: '/uploads/announcements',
  [ImageType.PROFILE]: '/profile-images'
};

/**
 * Default Image Configuration
 * Contains standardized settings for each image type
 */
export const IMAGE_CONFIGS: Record<ImageType, ImageConfig> = {
  [ImageType.POST]: {
    entityType: 'POST',
    maxSize: 5 * 1024 * 1024, // 5MB
    allowedTypes: COMMON_IMAGE_TYPES,
    minWidth: 800,
    minHeight: 450,
    aspectRatio: 16/9,
    recommendedDimensions: '1200x675',
    uploadPath: IMAGE_UPLOAD_PATHS[ImageType.POST]
  },
  [ImageType.ANNOUNCEMENT]: {
    entityType: 'ANNOUNCEMENT',
    maxSize: 5 * 1024 * 1024, // 5MB
    allowedTypes: COMMON_IMAGE_TYPES,
    minWidth: 800,
    minHeight: 450,
    aspectRatio: 16/9,
    recommendedDimensions: '1200x675',
    uploadPath: IMAGE_UPLOAD_PATHS[ImageType.ANNOUNCEMENT]
  },
  [ImageType.PROFILE]: {
    entityType: 'PROFILE',
    maxSize: 5 * 1024 * 1024, // 5MB
    allowedTypes: COMMON_IMAGE_TYPES,
    minWidth: 200,
    minHeight: 200,
    aspectRatio: 1/1,
    recommendedDimensions: '400x400',
    uploadPath: IMAGE_UPLOAD_PATHS[ImageType.PROFILE]
  }
};

/**
 * Get image configuration for a specific type
 * @param type Image type (POST, ANNOUNCEMENT, PROFILE)
 * @returns Configuration for the specified image type
 */
export const getImageConfig = async (type: ImageType): Promise<ImageConfig> => {
  return IMAGE_CONFIGS[type];
};

// Cache the configs for better performance
const configCache = new Map<ImageType, Promise<ImageConfig>>();

/**
 * Get cached image configuration
 * Uses in-memory cache to avoid repeated lookups
 * @param type Image type
 * @returns Cached configuration promise
 */
export const getImageConfigCached = (type: ImageType): Promise<ImageConfig> => {
  if (!configCache.has(type)) {
    configCache.set(type, getImageConfig(type));
  }
  return configCache.get(type)!;
}; 