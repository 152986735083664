import { Outlet } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { TopBar } from '../TopBar';
import { useAuth } from '../../contexts/AuthContext';
import { useThemeContext } from '../../contexts/ThemeContext';
import { useLayoutContext } from '../../contexts/LayoutContext';
import React from 'react';

const TOPBAR_HEIGHT = 72;
const MAX_CONTENT_WIDTH = 1200;

export const Layout = () => {
  const { user, isLoading, isAuthenticated } = useAuth();
  const { mode } = useThemeContext();
  const { setShowSidebar } = useLayoutContext();

  // Always keep sidebar hidden but update context for menu items
  React.useEffect(() => {
    setShowSidebar(false);
  }, [setShowSidebar]);

  return (
    <Box sx={{
      display: 'flex',
      minHeight: '100vh',
      width: '100vw',
      overflow: 'hidden',
      bgcolor: theme => theme.palette.mode === 'dark' ? '#1A1A1A' : '#F5F5F5',
      transition: 'background-color 0.3s ease'
    }}>
      <TopBar />
      
      {/* Main content wrapper */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: `calc(100vh - ${TOPBAR_HEIGHT}px)`,
          marginTop: `${TOPBAR_HEIGHT}px`,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          '& > *': {
            maxWidth: MAX_CONTENT_WIDTH,
            width: '100%',
            px: {
              xs: 2,
              sm: 3,
              md: 4
            }
          }
        }}
      >
        {isLoading ? (
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            minHeight: '100vh' 
          }}>
            <CircularProgress />
          </Box>
        ) : (
          <Outlet />
        )}
      </Box>
    </Box>
  );
};