import { Box, Typography } from '@mui/material';

const UsersPage = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4">Users</Typography>
    </Box>
  );
};

export default UsersPage; 