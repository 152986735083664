import { api } from './api';

export type UploadType = 'post' | 'announcement' | 'profile';

interface UploadResponse {
  imageUrl: string;
  message?: string;
}

export const uploadImage = async (file: File, type: UploadType, id?: string): Promise<string> => {
  const formData = new FormData();
  formData.append('image', file);
  formData.append('type', type.toUpperCase());

  try {
    let url;
    if (type === 'profile') {
      // Profile images always go to /profile-images/{userId}
      if (!id) throw new Error('User ID is required for profile image uploads');
      url = `/users/${id}/profile-image`;
      // The backend will handle storing this in /profile-images/userId.extension
    } else {
      // For posts and announcements, use the standard upload path
      url = id 
        ? `/${type}s/${id}/image`
        : '/upload/image';
    }

    const response = await api.post<UploadResponse>(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (!response.data.imageUrl) {
      throw new Error('No image URL returned from server');
    }

    // For profile images, ensure we return the correct URL format
    if (type === 'profile') {
      const extension = file.name.split('.').pop()?.toLowerCase() || 'jpg';
      return `/profile-images/${id}.${extension}`;
    }

    return response.data.imageUrl;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Failed to upload image: ${error.message}`);
    }
    throw new Error('Failed to upload image');
  }
}; 