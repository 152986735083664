/**
 * ChatList.tsx
 * 
 * A React component that renders the list of chat conversations in the sidebar.
 * It displays both direct messages and group chats, showing the most recent
 * conversations first.
 * 
 * Features:
 * - Displays conversation previews with last message
 * - Shows unread message count badges
 * - Handles both direct and group chats
 * - Displays user avatars and profile images
 * - Responsive design (collapsible on mobile)
 * - Real-time updates for new messages
 * - Sorts conversations by most recent activity
 * 
 * The component uses the ChatContext for state management and real-time updates,
 * and integrates with the Material-UI component library for styling.
 */

// src/components/communication/ChatList.tsx
import React, { useMemo } from 'react';
import { Box, List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, Divider, Badge } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { useChatContext } from '../../contexts/ChatContext';
import { useAuth } from '../../hooks/useAuth';
import { ChatConversation } from '../../api/chatApi';
import { useNavigate } from 'react-router-dom';

const ChatList: React.FC = () => {
  const { conversations, activeConversation, selectConversation } = useChatContext();
  const { user } = useAuth();
  const navigate = useNavigate();
  
  // Process and organize conversations
  const organizedConversations = useMemo(() => {
    if (!conversations) return [];
    
    // First deduplicate conversations by ID
    const uniqueConversations = conversations.reduce((acc, current) => {
      const existingConv = acc.find(conv => conv.id === current.id);
      if (!existingConv) {
        acc.push(current);
      }
      return acc;
    }, [] as ChatConversation[]);
    
    // Then filter and sort the unique conversations
    const validConversations = uniqueConversations.filter(conv => 
      conv.id === activeConversation?.id || 
      Boolean(conv.lastMessage)
    );

    return validConversations.sort((a, b) => {
      const aTime = a.lastMessage?.sentAt || a.lastMessage?.createdAt || a.updatedAt || new Date(0);
      const bTime = b.lastMessage?.sentAt || b.lastMessage?.createdAt || b.updatedAt || new Date(0);
      return new Date(bTime).getTime() - new Date(aTime).getTime();
    });
  }, [conversations, activeConversation]);

  const getOtherParticipantInfo = (conversation: ChatConversation) => {
    if (!user?.id) return { displayName: 'Unknown User', initial: 'U' };

    // For group chats
    if (conversation.is_group) {
      // If there's a custom group name, use it
      if (conversation.metadata?.name) {
        return {
          displayName: conversation.metadata.name,
          initial: conversation.metadata.name[0] || 'G',
          profileImageUrl: null
        };
      }

      // Otherwise, create a name from participants (up to 4)
      if (conversation.metadata?.contacts) {
        const otherParticipants = conversation.metadata.contacts
          .filter(contact => contact.id !== user.id)
          .slice(0, 4);

        if (otherParticipants.length > 0) {
          const participantNames = otherParticipants
            .map(p => p.first_name)
            .join(', ');
          
          const displayName = otherParticipants.length > 4 
            ? `${participantNames} and ${conversation.participants.length - 4} others`
            : participantNames;
          
          return {
            displayName,
            initial: 'G',
            profileImageUrl: null
          };
        }
      }

      // Fallback for group chats
      return {
        displayName: `Group (${conversation.participants?.length || 0} participants)`,
        initial: 'G',
        profileImageUrl: null
      };
    }

    // For direct chats
    const otherParticipantId = conversation.participants.find(id => id !== user.id);
    
    // Get the metadata for the other participant
    if (conversation.metadata?.contacts) {
      const otherParticipant = conversation.metadata.contacts.find(
        contact => contact.id === otherParticipantId
      );
      
      if (otherParticipant) {
        const displayName = `${otherParticipant.first_name} ${otherParticipant.last_name}`.trim();
        return {
          displayName: displayName || 'Unknown User',
          initial: otherParticipant.first_name?.[0] || otherParticipant.last_name?.[0] || 'U',
          profileImageUrl: otherParticipant.profile_image_url
        };
      }
    }

    // If we couldn't find the other participant's info, return Unknown User
    return {
      displayName: 'Unknown User',
      initial: 'U',
      profileImageUrl: null
    };
  };

  const handleConversationSelect = async (conversationId: string) => {
    const conversation = conversations.find(c => c.id === conversationId);
    if (!conversation) return;

    // Update URL to reflect the selected conversation
    navigate(`/chat/${conversationId}`, { replace: true });
    
    // Select the conversation
    await selectConversation(conversationId);
  };

  if (!organizedConversations || organizedConversations.length === 0) {
    return (
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography color="text.secondary">No conversations yet</Typography>
      </Box>
    );
  }

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {organizedConversations.map(conversation => {
        const isActive = activeConversation?.id === conversation.id;
        const { displayName, initial, profileImageUrl } = getOtherParticipantInfo(conversation);
        const hasUnread = (conversation.unreadCount || 0) > 0;

        // Don't show conversations with no messages unless they're active
        if (!conversation.lastMessage && !isActive) {
          return null;
        }

        return (
          <Box
            key={conversation.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              p: 2,
              borderBottom: 1,
              borderColor: 'divider',
              bgcolor: isActive ? 'action.selected' : hasUnread ? 'action.hover' : 'background.paper',
              '&:hover': {
                bgcolor: 'action.hover',
                cursor: 'pointer'
              }
            }}
            onClick={() => handleConversationSelect(conversation.id)}
          >
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
              color="primary"
              invisible={!hasUnread}
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: hasUnread ? 'primary.main' : 'transparent'
                }
              }}
            >
              <Avatar 
                sx={{ mr: 2 }}
                src={(() => {
                  if (conversation.is_group) return undefined;
                  if (profileImageUrl) {
                    let imageUrl = profileImageUrl;
                    if (!imageUrl.startsWith('http')) {
                      const cleanUrl = imageUrl.startsWith('/') ? imageUrl.slice(1) : imageUrl;
                      imageUrl = `${import.meta.env.VITE_MINIO_PUBLIC_URL || ''}/${cleanUrl}`;
                    }
                    return imageUrl;
                  }
                  return undefined;
                })()}
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src = '';
                }}
              >
                {initial}
              </Avatar>
            </Badge>
            <Box sx={{ flexGrow: 1, minWidth: 0 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography 
                  variant="subtitle1" 
                  noWrap 
                  sx={{ 
                    fontWeight: hasUnread ? 700 : 400,
                    color: hasUnread ? 'text.primary' : 'inherit'
                  }}
                >
                  {displayName}
                </Typography>
                {hasUnread && (
                  <Box
                    sx={{
                      minWidth: 20,
                      height: 20,
                      borderRadius: '50%',
                      bgcolor: 'primary.main',
                      color: 'primary.contrastText',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '0.75rem',
                      fontWeight: 'bold'
                    }}
                  >
                    {conversation.unreadCount}
                  </Box>
                )}
              </Box>
              {conversation.lastMessage && (
                <Typography 
                  variant="body2" 
                  color="text.secondary" 
                  noWrap
                  sx={{ 
                    fontWeight: hasUnread ? 600 : 400
                  }}
                >
                  {conversation.lastMessage.content}
                </Typography>
              )}
            </Box>
            {conversation.lastMessage && (
              <Typography 
                variant="caption" 
                color="text.secondary" 
                sx={{ 
                  ml: 2,
                  fontWeight: hasUnread ? 600 : 400
                }}
              >
                {(() => {
                  try {
                    const date = new Date(
                      conversation.lastMessage.sentAt || 
                      conversation.lastMessage.createdAt || 
                      conversation.lastMessage.updatedAt || 
                      new Date(0)
                    );
                    if (isNaN(date.getTime())) {
                      return '';
                    }
                    return formatDistanceToNow(date, { addSuffix: true });
                  } catch (error) {
                    return '';
                  }
                })()}
              </Typography>
            )}
          </Box>
        );
      })}
    </List>
  );
};

export default ChatList;