import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Container } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQPage = () => {
  const faqs = [
    {
      question: "What is this Professional Network?",
      answer: "This Professional Network is a platform designed to connect professionals and students across various industries, enabling knowledge sharing, networking, and collaborative opportunities."
    },
    {
      question: "How do I create an account?",
      answer: "The App is currently in an exclusive beta stage, offering early access only to select individuals. At this time, you can join only by contacting me directly or if you work at a select group of pioneering corporates and organisations. Click the 'Register' button in the top right corner to get started. You'll need to provide your email address and create a password. If you're whitelisted, you'll then be able to log in. Otherwise, your provided information will be deleted during routine database cleanups, which occur several times per week."
    },
    {
      question: "What are One-on-One Meetups?",
      answer: "One-on-One Meetups are scheduled video conversations between professionals. They provide opportunities for mentoring, knowledge exchange, and networking in a focused, one-to-one setting."
    },
    {
      question: "How can I find other professionals?",
      answer: "Use our Search feature to find professionals by name, skills, industry, or location. You can also discover people through posts and meetup discussions."
    },
    {
      question: "Is the platform free to use?",
      answer: "Yes, the core features of Professional Network are free to use. I believe in making professional networking accessible to everyone."
    }
  ];

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        <Typography 
          variant="h2" 
          sx={{ 
            mb: 4, 
            fontSize: { xs: '2rem', md: '3rem' },
            fontWeight: 600,
            textAlign: 'center'
          }}
        >
          Frequently Asked Questions
        </Typography>

        {faqs.map((faq, index) => (
          <Accordion 
            key={index}
            sx={{ 
              mb: 2,
              backgroundColor: theme => 
                theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
              '&:hover': {
                backgroundColor: theme => 
                  theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" sx={{ fontWeight: 500 }}>
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ opacity: 0.8 }}>
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
};

export default FAQPage; 