import { ImageConfig } from '../components/images/types';

/**
 * Result of image validation
 */
export interface ValidationResult {
  isValid: boolean;
  message?: string;
  warning?: string;
}

/**
 * Validates an image file against configuration requirements
 * @param file The image file to validate
 * @param config Validation configuration
 * @returns Validation result with status and messages
 */
export const validateImage = async (
  file: File,
  config: ImageConfig
): Promise<ValidationResult> => {
  // Check file type
  if (!config.allowedTypes.includes(file.type)) {
    return {
      isValid: false,
      message: `Invalid file type. Allowed types: ${config.allowedTypes.map(t => t.split('/')[1].toUpperCase()).join(', ')}`,
    };
  }

  // Check file size
  if (file.size > config.maxSize) {
    return {
      isValid: false,
      message: `File size exceeds ${Math.round(config.maxSize / (1024 * 1024))}MB limit`,
    };
  }

  // Load image to check dimensions
  return new Promise((resolve) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);

    img.onload = () => {
      URL.revokeObjectURL(objectUrl);
      let warning = '';

      // Check minimum dimensions (as recommendations, not requirements)
      if (config.minWidth && img.width < config.minWidth) {
        warning = `For best quality, minimum recommended width is ${config.minWidth}px`;
      }
      if (config.minHeight && img.height < config.minHeight) {
        warning = warning ? `${warning}, height is ${config.minHeight}px` : 
          `For best quality, minimum recommended height is ${config.minHeight}px`;
      }
      
      // Check maximum dimensions (as recommendations, not requirements)
      if (config.maxWidth && img.width > config.maxWidth) {
        warning = `Image width (${img.width}px) exceeds recommended maximum of ${config.maxWidth}px`;
      }
      if (config.maxHeight && img.height > config.maxHeight) {
        warning = warning ? `${warning}, height (${img.height}px) exceeds ${config.maxHeight}px` : 
          `Image height (${img.height}px) exceeds recommended maximum of ${config.maxHeight}px`;
      }

      // If we have recommended dimensions, include in warning
      if (!warning && config.recommendedDimensions) {
        warning = `For best quality, use recommended dimensions of ${config.recommendedDimensions}`;
      }

      resolve({
        isValid: true,
        warning: warning || undefined
      });
    };

    img.onerror = () => {
      URL.revokeObjectURL(objectUrl);
      resolve({
        isValid: false,
        message: 'Failed to load image. Please try another file.',
      });
    };

    img.src = objectUrl;
  });
}; 