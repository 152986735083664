import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Paper, 
  Box, 
  Button, 
  Avatar, 
  CircularProgress, 
  Divider,
  Alert,
  Grid,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
  Link,
  Chip
} from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import EmailIcon from '@mui/icons-material/Email';
import { getImageUrl } from '../utils/imageUtils';

interface TokenOwner {
  id: string;
  first_name: string;
  last_name: string;
  email?: string;
  position?: string;
  profile_image_url?: string;
  company_id?: string;
  city_id?: string;
  country_id?: string;
  linkedin_url?: string;
  twitter_url?: string;
  facebook_url?: string;
  instagram_url?: string;
  github_url?: string;
  website_url?: string;
  company?: {
    id: string;
    name: string;
  };
  city?: {
    id: string;
    name: string;
    country?: {
      id: string;
      name: string;
    };
  };
}

const ScanContactPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();
  const auth = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [tokenOwner, setTokenOwner] = useState<TokenOwner | null>(null);
  const [connectionStatus, setConnectionStatus] = useState<'pending' | 'loading' | 'success' | 'error'>('pending');
  const [connectionError, setConnectionError] = useState<string | null>(null);
  
  useEffect(() => {
    // Validate the token
    const validateToken = async () => {
      if (!token) {
        setError('No QR code token provided');
        setIsLoading(false);
        return;
      }
      
      try {
        // Make public request without authentication header to get token owner info
        const response = await axios.get(`/api/contacts/qr/validate/${token}`);
        
        if (response.data.success) {
          setTokenOwner(response.data.data.tokenOwner);
          console.log('Token owner loaded:', response.data.data.tokenOwner);
        } else {
          setError(response.data.message || 'Invalid QR code');
        }
      } catch (err: any) {
        setError(err.response?.data?.message || 'Failed to validate QR code');
      } finally {
        setIsLoading(false);
      }
    };
    
    validateToken();
  }, [token]);
  
  const handleConnect = async () => {
    if (!token || !auth?.isAuthenticated) {
      // If not authenticated, redirect to login
      handleLogin();
      return;
    }
    
    setConnectionStatus('loading');
    setConnectionError(null);
    
    try {
      const response = await axios.post(
        `/api/contacts/qr/connect/${token}`, 
        {}
      );
      
      if (response.data.success) {
        setConnectionStatus('success');
      } else {
        setConnectionStatus('error');
        setConnectionError(response.data.message || 'Failed to connect');
      }
    } catch (err: any) {
      setConnectionStatus('error');
      setConnectionError(err.response?.data?.message || 'An error occurred while trying to connect');
    }
  };
  
  const handleLogin = () => {
    // Redirect to login page with a returnTo parameter
    navigate(`/login?returnTo=${encodeURIComponent(`/tools/scan-contact?token=${token}`)}`);
  };
  
  const handleRegister = () => {
    // Redirect to register page with a qrToken parameter
    navigate(`/register?qrToken=${encodeURIComponent(token || '')}`);
  };
  
  const renderContactCard = () => {
    if (!tokenOwner) return null;
    
    return (
      <Card variant="outlined" sx={{ width: '100%', mb: 3 }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Avatar
              src={tokenOwner.profile_image_url ? getImageUrl(tokenOwner.profile_image_url) : undefined}
              alt={`${tokenOwner.first_name} ${tokenOwner.last_name}`}
              sx={{ width: 60, height: 60, mr: 2 }}
            />
            <Box>
              <Typography variant="h6" component="h2">
                {tokenOwner.first_name} {tokenOwner.last_name}
              </Typography>
              {tokenOwner.position && (
                <Typography variant="body2" color="text.secondary">
                  {tokenOwner.position}
                </Typography>
              )}
            </Box>
          </Box>
          
          <Divider sx={{ my: 2 }} />
          
          {tokenOwner.company?.name && (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
              <BusinessIcon sx={{ color: 'text.secondary', mr: 1, fontSize: 20 }} />
              <Typography variant="body2">{tokenOwner.company.name}</Typography>
            </Box>
          )}
          
          {(tokenOwner.city?.name || tokenOwner.city?.country?.name) && (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
              <LocationOnIcon sx={{ color: 'text.secondary', mr: 1, fontSize: 20 }} />
              <Typography variant="body2">
                {tokenOwner.city?.name}
                {tokenOwner.city?.name && tokenOwner.city?.country?.name && ', '}
                {tokenOwner.city?.country?.name}
              </Typography>
            </Box>
          )}
          
          {tokenOwner.email && (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
              <EmailIcon sx={{ color: 'text.secondary', mr: 1, fontSize: 20 }} />
              <Typography variant="body2">{tokenOwner.email}</Typography>
            </Box>
          )}
          
          <Box sx={{ mt: 2 }}>
            {tokenOwner.linkedin_url && (
              <Chip 
                label="LinkedIn" 
                size="small" 
                component={Link} 
                href={tokenOwner.linkedin_url} 
                target="_blank"
                clickable
                sx={{ mr: 1, mb: 1 }}
              />
            )}
            {tokenOwner.twitter_url && (
              <Chip 
                label="Twitter" 
                size="small" 
                component={Link} 
                href={tokenOwner.twitter_url} 
                target="_blank"
                clickable
                sx={{ mr: 1, mb: 1 }}
              />
            )}
            {tokenOwner.github_url && (
              <Chip 
                label="GitHub" 
                size="small" 
                component={Link} 
                href={tokenOwner.github_url} 
                target="_blank"
                clickable
                sx={{ mr: 1, mb: 1 }}
              />
            )}
            {tokenOwner.website_url && (
              <Chip 
                label="Website" 
                size="small" 
                component={Link} 
                href={tokenOwner.website_url} 
                target="_blank"
                clickable
                sx={{ mr: 1, mb: 1 }}
              />
            )}
          </Box>
        </CardContent>
      </Card>
    );
  };
  
  const renderConnectionButtons = () => {
    if (!auth?.isAuthenticated) {
      return (
        <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleLogin}
            startIcon={<PersonAddIcon />}
            size="large"
          >
            Add to My Contacts
          </Button>
          <Typography variant="caption" color="text.secondary" align="center">
            You'll need to log in or create an account to add this contact
          </Typography>
        </Box>
      );
    }
    
    if (connectionStatus === 'pending') {
      return (
        <Box sx={{ mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConnect}
            startIcon={<PersonAddIcon />}
            fullWidth
            size="large"
          >
            Add to My Contacts
          </Button>
        </Box>
      );
    }
    
    if (connectionStatus === 'loading') {
      return (
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={36} />
        </Box>
      );
    }
    
    if (connectionStatus === 'success') {
      return (
        <Box sx={{ mt: 3 }}>
          <Alert 
            icon={<CheckCircleIcon fontSize="inherit" />} 
            severity="success"
            sx={{ mb: 2 }}
          >
            Contact added successfully!
          </Alert>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/contacts')}
            fullWidth
          >
            View My Contacts
          </Button>
        </Box>
      );
    }
    
    if (connectionStatus === 'error') {
      return (
        <Box sx={{ mt: 3 }}>
          <Alert 
            icon={<ErrorIcon fontSize="inherit" />} 
            severity="error"
            sx={{ mb: 2 }}
          >
            {connectionError || 'Failed to add contact'}
          </Alert>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setConnectionStatus('pending')}
            fullWidth
          >
            Try Again
          </Button>
        </Box>
      );
    }
    
    return null;
  };
  
  return (
    <Container maxWidth="sm" sx={{ py: 4 }}>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography variant="h5" component="h1" gutterBottom align="center">
          Contact Details
        </Typography>
        
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert 
            severity="error" 
            sx={{ width: '100%', mt: 2 }}
          >
            {error}
          </Alert>
        ) : tokenOwner ? (
          <Box sx={{ width: '100%' }}>
            {renderContactCard()}
            {renderConnectionButtons()}
          </Box>
        ) : (
          <Alert 
            severity="error" 
            sx={{ width: '100%', mt: 2 }}
          >
            Could not load contact information
          </Alert>
        )}
      </Paper>
    </Container>
  );
};

export default ScanContactPage; 