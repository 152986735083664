import { useState, useEffect } from 'react';
import { Box, Card, CardContent, CardMedia, Typography, Avatar, CardHeader, IconButton, CardActions, Button } from '@mui/material';
import { Post } from '../types';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { api } from '../services/api';
import EditIcon from '@mui/icons-material/Edit';
import { PostDialog } from './PostDialog';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { CommentSection } from './CommentSection';
import CommentIcon from '@mui/icons-material/Comment';
import { Collapse, Grow } from '@mui/material';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEngagementWithPrivacy } from '../hooks/useEngagementWithPrivacy';
import LockIcon from '@mui/icons-material/Lock';
import { trackEngagement } from '../utils/analytics';
import { getImageUrl } from '../utils/imageUtils';

interface PostCardProps {
  post: Post;
  onEdit?: () => void;
}

export const PostCard = ({ post, onEdit }: PostCardProps) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const { data: commentCount = 0 } = useQuery({
    queryKey: ['commentCount', post.id],
    queryFn: async () => {
      const response = await api.get(`/posts/${post.id}/comments/count`);
      return response.data.count;
    }
  });

  const isLiked = user && post.likes?.some(like => like.userId === user.id);
  const likeCount = post.likes?.length || 0;

  const deleteMutation = useMutation({
    mutationFn: async () => {
      await api.delete(`/posts/${post.id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['posts'] });
      queryClient.invalidateQueries({ queryKey: ['userPosts'] });
      queryClient.invalidateQueries({ queryKey: ['userPosts', post.author.id] });
      queryClient.invalidateQueries({ queryKey: ['comments'] });
      queryClient.invalidateQueries({ queryKey: ['comments', post.id] });
      queryClient.invalidateQueries({ queryKey: ['commentCount', post.id] });
      setDeleteDialogOpen(false);
    },
    onError: (error) => {
      console.error('Failed to delete post:', error);
      alert('Failed to delete post. Please try again.');
    }
  });

  const { handleEngagement } = useEngagementWithPrivacy('POST', post.id);

  useEffect(() => {
    // Track post view
    trackEngagement.view('post', post.id);
  }, [post.id]);

  const handleLike = async () => {
    trackEngagement.reaction('post', post.id, 'like');
    await handleEngagement('react', { reaction_type: 'LIKE' });
  };

  const handleCommentClick = (e: React.MouseEvent) => {
    trackEngagement.comment('post', post.id);
    e.preventDefault();  // Prevent navigation
    e.stopPropagation(); // Stop event bubbling
    setShowComments(!showComments);
  };

  const handleDelete = async () => {
    deleteMutation.mutate();
  };

  const theme = useTheme();

  const handleCardClick = (e: React.MouseEvent) => {
    // Only navigate to post if not clicking a link or button
    if (!(e.target as HTMLElement).closest('a, button')) {
      navigate(`/post/${post.id}`);
    }
  };

  return (
    <>
      <Card 
        onClick={handleCardClick}
        sx={{ mb: 2, cursor: 'pointer' }}
      >
        <CardHeader
          avatar={
            <Link 
              to={`/profile/${post.author.id}`}
              onClick={(e) => e.stopPropagation()}
            >
              <Avatar
                src={getImageUrl(post.author.profile_image_url)}
                alt={post.author.first_name}
                sx={{ width: 36, height: 36 }}
              >
                {!post.author.profile_image_url && `${post.author.first_name[0]}${post.author.last_name[0]}`}
              </Avatar>
            </Link>
          }
          title={
            <Link 
              to={`/profile/${post.author.id}`}
              onClick={(e) => e.stopPropagation()}
              style={{ 
                textDecoration: 'none', 
                color: 'inherit'
              }}
            >
              {`${post.author.first_name} ${post.author.last_name}`}
            </Link>
          }
          subheader={new Date(post.created_at).toLocaleDateString()}
          action={
            <Box>
              {(user && (
                <>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setEditDialogOpen(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDeleteDialogOpen(true);
                    }}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              ))}
            </Box>
          }
        />
        <CardContent>
          <Typography variant="body1" paragraph>
            {post.content}
          </Typography>
          {post.image_url && (
            <CardMedia
              component="img"
              image={post.image_url.startsWith('http') 
                ? post.image_url 
                : `${import.meta.env.VITE_MINIO_PUBLIC_URL}/uploads/posts/${post.id}/${post.image_url}`}
              alt="Post image"
              sx={{ 
                borderRadius: 1,
                maxHeight: 500,
                objectFit: 'contain',
                backgroundColor: 'background.default'
              }}
            />
          )}
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton 
                onClick={(e) => {
                  e.preventDefault();
                  handleLike();
                }}
                color={isLiked ? "primary" : "default"}
                sx={{
                  '&:hover': {
                    backgroundColor: isLiked ? 'primary.light' : 'action.hover'
                  }
                }}
              >
                <FavoriteIcon />
                <Typography sx={{ ml: 1 }}>{likeCount}</Typography>
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <VisibilityIcon sx={{ mr: 1, fontSize: 'small' }} />
              <Typography variant="body2" color="text.secondary">
                {post.view_count}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton 
                onClick={handleCommentClick}
                color={showComments ? "primary" : "default"}
                sx={{
                  transition: 'transform 0.2s',
                  transform: showComments ? 'scale(1.1)' : 'scale(1)',
                  '&:hover': {
                    transform: 'scale(1.1)'
                  }
                }}
              >
                <CommentIcon />
                <Typography sx={{ ml: 1 }}>{commentCount}</Typography>
              </IconButton>
            </Box>
          </Box>
          <Collapse 
            in={showComments} 
            timeout={300}
            sx={{
              transformOrigin: '0 0 0',
              '& .MuiCollapse-wrapperInner': {
                overflow: 'visible'
              }
            }}
          >
            <Grow in={showComments} timeout={500}>
              <Box onClick={(e) => e.stopPropagation()}>
                <CommentSection 
                  postId={post.id} 
                  onUpdate={() => {
                    queryClient.invalidateQueries({ queryKey: ['commentCount', post.id] });
                  }}
                />
              </Box>
            </Grow>
          </Collapse>
        </CardContent>
      </Card>

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Delete Post</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this post? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleDelete} 
            color="error" 
            variant="contained"
            disabled={deleteMutation.isPending}
            startIcon={deleteMutation.isPending ? <CircularProgress size={20} /> : null}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <PostDialog
        post={post}
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
      />
    </>
  );
}; 