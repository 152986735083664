import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Box,
  Button,
  CircularProgress,
  Alert,
  Chip,
  Stack,
  Tabs,
  Tab,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Paper,
  Link as MuiLink
} from '@mui/material';
import { api } from '../../services/api';
import QuizComponent from '../../components/tools/QuizComponent';
import QuizResultsComponent from '../../components/tools/QuizResultsComponent';
import { Quiz, QuizDifficulty, QuizSubmission } from '../../types/quiz';
import { useParams, useNavigate } from 'react-router-dom';
import ShareIcon from '@mui/icons-material/Share';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkIcon from '@mui/icons-material/Link';
import EmailIcon from '@mui/icons-material/Email';

// Tab panel component for tab content
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`quiz-tabpanel-${index}`}
      aria-labelledby={`quiz-tab-${index}`}
      {...other}
    >
      {value === index && <Box py={3}>{children}</Box>}
    </div>
  );
};

// Tab a11y props
const a11yProps = (index: number) => {
  return {
    id: `quiz-tab-${index}`,
    'aria-controls': `quiz-tabpanel-${index}`,
  };
};

const QuizzesPage: React.FC = () => {
  const [quizzes, setQuizzes] = useState<Quiz[]>([]);
  const [selectedQuiz, setSelectedQuiz] = useState<Quiz | null>(null);
  const [fullQuiz, setFullQuiz] = useState<Quiz | null>(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [submissionId, setSubmissionId] = useState<string | null>(null);
  const [submission, setSubmission] = useState<QuizSubmission | null>(null);
  const [pastSubmissions, setPastSubmissions] = useState<QuizSubmission[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingResults, setLoadingResults] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [shareMenuAnchor, setShareMenuAnchor] = useState<null | HTMLElement>(null);

  const { quizId } = useParams<{ quizId: string }>();
  const navigate = useNavigate();

  // Fetch quizzes from API
  useEffect(() => {
    const fetchQuizzes = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await api.get('/quizzes');
        if (response.data && response.data.quizzes && response.data.quizzes.length > 0) {
          setQuizzes(response.data.quizzes);

          // If quizId is provided in URL, select that quiz
          if (quizId) {
            const matchingQuiz = response.data.quizzes.find((q: Quiz) => q.id === quizId);
            if (matchingQuiz) {
              handleSelectQuiz(matchingQuiz);
            } else {
              setError(`Quiz with ID ${quizId} not found.`);
            }
          }
        } else {
          setError('No quizzes available at the moment. Please try again later.');
        }
      } catch (err) {
        console.error('Error fetching quizzes:', err);
        setError('Failed to load quizzes. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchQuizzes();
  }, [quizId]);

  // Fetch past submissions when page loads
  useEffect(() => {
    const fetchPastSubmissions = async () => {
      try {
        const response = await api.get('/quizzes/results/me');
        if (response.data && response.data.submissions) {
          setPastSubmissions(response.data.submissions);
        }
      } catch (err) {
        console.error('Error fetching past quiz submissions:', err);
      }
    };

    fetchPastSubmissions();
  }, []);

  // Fetch full quiz with questions when selected
  const fetchFullQuiz = async (quizId: string) => {
    setLoading(true);
    try {
      console.log(`Fetching full quiz with ID: ${quizId}`);
      const response = await api.get(`/quizzes/${quizId}`);
      if (response.data && response.data.quiz) {
        // Verify that the quiz ID matches what we requested
        if (response.data.quiz.id === quizId) {
          console.log(`Successfully loaded quiz: ${response.data.quiz.title} with ${response.data.quiz.questions?.length || 0} questions`);
          setFullQuiz(response.data.quiz);
        } else {
          console.error(`Quiz ID mismatch: requested ${quizId} but got ${response.data.quiz.id}`);
          throw new Error('Quiz ID mismatch in response');
        }
      } else {
        throw new Error('Invalid quiz data received');
      }
    } catch (err) {
      console.error('Error fetching quiz details:', err);
      setError('Failed to load quiz details.');
      setSelectedQuiz(null);
    } finally {
      setLoading(false);
    }
  };

  // Fetch specific submission details
  const fetchSubmission = async (submissionId: string) => {
    setLoadingResults(true);
    try {
      const response = await api.get(`/quizzes/submissions/${submissionId}`);
      if (response.data && response.data.submission) {
        setSubmission(response.data.submission);

        // If we don't have the full quiz, fetch it
        if (!fullQuiz || fullQuiz.id !== response.data.submission.quiz_id) {
          await fetchFullQuiz(response.data.submission.quiz_id);
        }
      }
    } catch (err) {
      console.error('Error fetching submission details:', err);
      setError('Failed to load submission details.');
    } finally {
      setLoadingResults(false);
    }
  };

  // Handle selecting a quiz
  const handleSelectQuiz = async (quiz: Quiz) => {
    // Clear previous quiz data first
    setFullQuiz(null);
    setSelectedQuiz(quiz);
    setActiveTab(0);
    setLoading(true);
    setError(null);
    setSubmission(null);
    setIsCompleted(false);

    // Update URL if it doesn't already contain the quiz ID
    if (!window.location.pathname.includes(quiz.id)) {
      navigate(`/tools/quizzes/${quiz.id}`);
    }
    
    try {
      // Fetch full quiz details with questions
      await fetchFullQuiz(quiz.id);
    } catch (err) {
      console.error('Error fetching quiz details:', err);
      setError('Failed to load quiz details.');
      setSelectedQuiz(null);
    } finally {
      setLoading(false);
    }
  };

  // Handle quiz completion
  const handleQuizComplete = async (submissionId: string) => {
    setSubmissionId(submissionId);
    setIsCompleted(true);
    console.log('Quiz completed with submission ID:', submissionId);

    // Fetch the submission details
    await fetchSubmission(submissionId);

    // Refresh past submissions
    try {
      const response = await api.get('/quizzes/results/me');
      if (response.data && response.data.submissions) {
        setPastSubmissions(response.data.submissions);
      }
    } catch (err) {
      console.error('Error refreshing past submissions:', err);
    }

    // Switch to results tab
    setActiveTab(1);
  };

  // Handle viewing a past submission
  const handleViewSubmission = async (submission: QuizSubmission) => {
    setSelectedQuiz(submission.quiz as Quiz);
    setSubmissionId(submission.id);
    setIsCompleted(true);
    setActiveTab(1); // Switch to results tab

    // Fetch full submission details if needed
    if (!submission.answers || submission.answers.length === 0) {
      await fetchSubmission(submission.id);
    } else {
      setSubmission(submission);
    }

    // Fetch full quiz if needed
    if (!fullQuiz || fullQuiz.id !== submission.quiz_id) {
      await fetchFullQuiz(submission.quiz_id);
    }
  };

  // Handle restarting
  const handleRestart = () => {
    setSelectedQuiz(null);
    setFullQuiz(null);
    setIsCompleted(false);
    setSubmissionId(null);
    setSubmission(null);
    setActiveTab(0);
    navigate('/tools/quizzes');
  };

  // Handle tab change
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  // Helper to get a color for difficulty level
  const getDifficultyColor = (difficulty: QuizDifficulty): string => {
    switch (difficulty) {
      case 'EASY': return 'success';
      case 'MEDIUM': return 'info';
      case 'HARD': return 'warning';
      case 'EXPERT': return 'error';
      default: return 'default';
    }
  };

  // Format quiz time limit from seconds to minutes
  const formatTimeLimit = (seconds?: number): string => {
    if (!seconds) return 'No time limit';
    const minutes = Math.floor(seconds / 60);
    return minutes === 1 ? '1 minute' : `${minutes} minutes`;
  };

  // Format date for display
  const formatDate = (dateString?: string): string => {
    if (!dateString) return 'Unknown date';
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  // Render previous quiz results
  const renderPastResults = () => {
    if (pastSubmissions.length === 0) {
      return (
        <Box textAlign="center" py={4}>
          <Typography variant="body1" color="text.secondary">
            You haven't completed any quizzes yet.
          </Typography>
        </Box>
      );
    }

    return (
      <Box mt={2}>
        <Typography variant="h6" gutterBottom>Your Previous Quiz Results</Typography>
        <Grid container spacing={3}>
          {pastSubmissions.map((submission) => (
            <Grid item xs={12} md={6} key={submission.id}>
              <Card variant="outlined">
                <CardActionArea onClick={() => handleViewSubmission(submission)}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {submission.quiz?.title || 'Quiz'}
                    </Typography>

                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      Completed: {formatDate(submission.completed_at)}
                    </Typography>

                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                      <Chip 
                        size="small"
                        label={submission.percentage ? `${Math.round(submission.percentage)}%` : 'No score'}
                        color={submission.passed ? 'success' : 'error'}
                      />

                      <Typography variant="body2">
                        View details
                      </Typography>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  // Render the quiz selection screen
  const renderQuizSelection = () => (
    <>
      <Typography variant="h4" component="h1" gutterBottom>
        Quizzes
      </Typography>

      <Typography variant="body1" paragraph sx={{ mb: 4 }}>
        Take fun and interactive quizzes to discover connections with others who share similar interests and working styles.
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {loading ? (
        <Box display="flex" justifyContent="center" my={6}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography variant="h5" gutterBottom>Available Quizzes</Typography>
          <Grid container spacing={3}>
            {quizzes.map((quiz) => (
              <Grid item xs={12} sm={6} key={quiz.id}>
                <Card 
                  sx={{ 
                    height: '100%',
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: 6,
                    }
                  }}
                >
                  <CardActionArea 
                    sx={{ height: '100%' }}
                    onClick={() => handleSelectQuiz(quiz)}
                  >
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {quiz.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        {quiz.short_description || quiz.description}
                      </Typography>
                      <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                        <Chip 
                          size="small" 
                          label={quiz.difficulty} 
                          color={getDifficultyColor(quiz.difficulty) as any}
                        />
                        {quiz.time_limit && (
                          <Chip 
                            size="small" 
                            label={formatTimeLimit(quiz.time_limit)} 
                            variant="outlined" 
                          />
                        )}
                        {quiz.is_featured && (
                          <Chip 
                            size="small" 
                            label="Featured" 
                            color="secondary"
                          />
                        )}
                      </Stack>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Divider sx={{ my: 6 }} />

          {renderPastResults()}
        </>
      )}
    </>
  );

  // Share menu handlers
  const handleShareClick = (event: React.MouseEvent<HTMLElement>) => {
    setShareMenuAnchor(event.currentTarget);
  };

  const handleShareClose = () => {
    setShareMenuAnchor(null);
  };

  const handleShare = (platform: string) => {
    if (!selectedQuiz) return;

    const quizUrl = `${window.location.origin}/tools/quizzes/${selectedQuiz.id}`;
    const quizTitle = selectedQuiz.title;
    const quizDescription = selectedQuiz.short_description || 'Take this quiz on TrueConn!';

    let shareUrl = '';

    switch (platform) {
      case 'whatsapp':
        shareUrl = `https://wa.me/?text=${encodeURIComponent(`${quizTitle}: ${quizUrl}`)}`;
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(quizTitle)}&url=${encodeURIComponent(quizUrl)}`;
        break;
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(quizUrl)}`;
        break;
      case 'email':
        shareUrl = `mailto:?subject=${encodeURIComponent(quizTitle)}&body=${encodeURIComponent(`Check out this quiz: ${quizUrl}`)}`;
        break;
      case 'copy':
        navigator.clipboard.writeText(quizUrl)
          .then(() => {
            alert('Quiz link copied to clipboard!');
          })
          .catch(err => {
            console.error('Failed to copy link:', err);
          });
        break;
    }

    if (shareUrl && platform !== 'copy') {
      window.open(shareUrl, '_blank');
    }

    handleShareClose();
  };

  // Render quiz detail view with tabs
  const renderQuizDetail = () => {
    if (!selectedQuiz) return null;

    return (
      <>
        <Box mb={3} display="flex" alignItems="center">
          <Button variant="outlined" onClick={handleRestart}>
            Back to All Quizzes
          </Button>

          <Tooltip title="Share this quiz">
            <IconButton 
              color="primary" 
              onClick={handleShareClick}
              sx={{ ml: 2 }}
              aria-label="share quiz"
            >
              <ShareIcon />
            </IconButton>
          </Tooltip>

          <Menu
            anchorEl={shareMenuAnchor}
            open={Boolean(shareMenuAnchor)}
            onClose={handleShareClose}
          >
            <MenuItem onClick={() => handleShare('whatsapp')}>
              <ListItemIcon>
                <WhatsAppIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>WhatsApp</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleShare('twitter')}>
              <ListItemIcon>
                <TwitterIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Twitter</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleShare('facebook')}>
              <ListItemIcon>
                <FacebookIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Facebook</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleShare('email')}>
              <ListItemIcon>
                <EmailIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Email</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleShare('copy')}>
              <ListItemIcon>
                <LinkIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Copy Link</ListItemText>
            </MenuItem>
          </Menu>
        </Box>

        <Typography variant="h4" component="h1" gutterBottom>
          {selectedQuiz.title}
        </Typography>

        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="quiz tabs">
              <Tab label="Take Quiz" disabled={isCompleted} {...a11yProps(0)} />
              <Tab label="Results" disabled={!isCompleted} {...a11yProps(1)} />
            </Tabs>
          </Box>

          <TabPanel value={activeTab} index={0}>
            {loading ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              selectedQuiz && fullQuiz && !loading && !isCompleted && (
                fullQuiz.id === selectedQuiz.id ? (
                  <QuizComponent 
                    quiz={fullQuiz} 
                    onComplete={handleQuizComplete}
                    onCancel={handleRestart}
                  />
                ) : (
                  <Box display="flex" justifyContent="center" my={6}>
                    <Alert severity="error">
                      Quiz data mismatch. Please try refreshing the page.
                    </Alert>
                  </Box>
                )
              )
            )}
          </TabPanel>

          <TabPanel value={activeTab} index={1}>
            {loadingResults ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              isCompleted && fullQuiz && submission && (
                <QuizResultsComponent
                  quiz={fullQuiz}
                  submission={submission}
                  loading={loadingResults}
                />
              )
            )}
          </TabPanel>
        </Box>
      </>
    );
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 8 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 4 }}>
          {error}
        </Alert>
      )}

      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : selectedQuiz ? (
        renderQuizDetail()
      ) : (
        renderQuizSelection()
      )}

      {/* Disclaimer and Feedback Section */}
      <Paper 
        elevation={2} 
        sx={{ 
          p: 3, 
          mt: 4,
          borderRadius: 2,
          background: theme => theme.palette.mode === 'dark' 
            ? 'rgba(66, 66, 66, 0.8)' 
            : 'rgba(255, 244, 229, 0.8)',
          borderLeft: theme => `4px solid ${theme.palette.warning.main}`
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ color: theme => theme.palette.warning.main }}>
          Disclaimer & Feedback
        </Typography>
        <Typography variant="body2" paragraph>
          While we strive to provide accurate and engaging quizzes, there might be occasional errors or imperfections 
          in the questions, answers, or scoring system. The quizzes are designed for entertainment and self-assessment 
          purposes only.
        </Typography>
        <Typography variant="body2" paragraph>
          If you are logged in, we store your quiz results to track your progress. All data is handled in accordance with our privacy policy and data 
          protection standards.
        </Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>
          Please note the following:
        </Typography>
        <ul>
          <li>Quiz content may be updated or modified over time</li>
          <li>Results should not be considered as professional assessment or advice</li>
          <li>Some quizzes may have time limits that require a stable internet connection</li>
          <li>If you are logged in, your results are saved to help you track your progress, but you can delete them at any time</li>
          <li>The difficulty ratings are approximate and may vary based on individual experience</li>
        </ul>
        <Box sx={{ mt: 3, pt: 2, borderTop: 1, borderColor: 'divider' }}>
          <Typography variant="body2">
            Found an error or have suggestions for new quizzes? We'd love to hear from you! Contact us at:{' '}
            <MuiLink href="mailto:admin@yeysus.com" target="_blank" rel="noopener">
              admin@yeysus.com
            </MuiLink>
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default QuizzesPage;