import { ThemeContextProvider } from './contexts/ThemeContext';
import { AuthProvider } from './contexts/AuthContext';
import { LayoutProvider } from './contexts/LayoutContext';
import Router from './Router';
import CookieConsent from './components/CookieConsent';
import { CssBaseline } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <ThemeContextProvider>
      <AuthProvider>
        <LayoutProvider>
          <CssBaseline />
          <BrowserRouter>
            <Router />
            <CookieConsent />
          </BrowserRouter>
        </LayoutProvider>
      </AuthProvider>
    </ThemeContextProvider>
  );
}

export default App;
