import { api } from './api';

export interface Announcement {
  id: string;
  name: string;
  short_description: string;
  long_description?: string;
  announcement_type: string;
  announcement_area: string;
  event_start_date: Date;
  event_end_date: Date;
  announcement_start_date: Date;
  announcement_end_date: Date;
  is_active: boolean;
  image_url?: string;
  venue?: string;
  city_id?: string;
  country_id?: string;
  created_at: Date;
  updated_at: Date;
  author_id: string;
  author?: {
    id: string;
    first_name: string;
    last_name: string;
  };
  city?: {
    id: string;
    name: string;
  };
  country?: {
    id: string;
    name: string;
  };
}

export interface AnnouncementsResponse {
  items: Announcement[];
  total: number;
  page: number;
  pages: number;
}

export const fetchAnnouncements = async (): Promise<AnnouncementsResponse> => {
  try {
    const response = await api.get('/announcements');
    return response.data;
  } catch (error) {
    console.error('Error fetching announcements:', error);
    throw error;
  }
};

export const createAnnouncement = async (data: Partial<Announcement>): Promise<Announcement> => {
  try {
    const response = await api.post('/announcements', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating announcement:', error);
    throw error;
  }
};

export const updateAnnouncement = async (id: string, data: Partial<Announcement>): Promise<Announcement> => {
  try {
    const response = await api.put(`/announcements/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating announcement:', error);
    throw error;
  }
};

export const deleteAnnouncement = async (id: string): Promise<void> => {
  try {
    await api.delete(`/announcements/${id}`);
  } catch (error) {
    console.error('Error deleting announcement:', error);
    throw error;
  }
};

export const fetchAnnouncement = async (id: string): Promise<Announcement> => {
  try {
    const response = await api.get(`/announcements/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching announcement:', error);
    throw error;
  }
};