import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  Typography,
  Box
} from '@mui/material';

interface ImportProgressProps {
  open: boolean;
  progress: number;
  total: number;
}

const ImportProgress: React.FC<ImportProgressProps> = ({ open, progress, total }) => {
  const percentage = total > 0 ? Math.round((progress / total) * 100) : 0;

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Importing Contacts</DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%', mb: 2 }}>
          <LinearProgress 
            variant="determinate" 
            value={percentage} 
            sx={{ height: 10, borderRadius: 5 }}
          />
        </Box>
        <Typography variant="body1" align="center">
          Processing {progress} out of {total} contacts ({percentage}%)
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default ImportProgress; 