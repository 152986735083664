import { useState, useCallback } from 'react';
import { api } from '../services/api';
import { getImageUrl } from '../utils/imageUtils';

export enum EntityType {
  PROFILE = 'profile',
  POST = 'post',
  ANNOUNCEMENT = 'announcement'
}

interface UseTemporaryImageOptions {
  entityType: EntityType;
  entityId: string;
  initialImageUrl?: string | null;
  onError?: (error: string) => void;
  onSuccess?: (message: string) => void;
}

interface TemporaryImageState {
  currentImageUrl: string | null;
  originalImageUrl: string | null;
  tempFileName: string | null;
  imagePreview: string | undefined;
  imageKey: number;
  isLoading: boolean;
  isImageRemoved: boolean;
}

/**
 * Custom hook for handling temporary image uploads
 * This implementation allows for a consistent approach to image handling:
 * 1. Upload to temporary location
 * 2. Preview before save
 * 3. Commit only on form submission
 * 4. Automatic cleanup on cancel
 */
export function useTemporaryImage({
  entityType,
  entityId,
  initialImageUrl = null,
  onError,
  onSuccess
}: UseTemporaryImageOptions) {
  const [state, setState] = useState<TemporaryImageState>({
    currentImageUrl: initialImageUrl,
    originalImageUrl: initialImageUrl,
    tempFileName: null,
    imagePreview: initialImageUrl ? getImageUrl(initialImageUrl) : undefined,
    imageKey: Date.now(),
    isLoading: false,
    isImageRemoved: false
  });

  // Update initial image URL if it changes (e.g., when data loads)
  // Use useCallback to memoize this function so it doesn't change on every render
  const updateInitialImage = useCallback((imageUrl: string | null) => {
    if (imageUrl === state.originalImageUrl) {
      return; // Skip if the URL hasn't changed
    }
    
    console.log('Updating initial image:', imageUrl);
    
    // Add a timestamp to ensure images are loaded fresh from server
    const transformedUrl = imageUrl 
      ? getImageUrl(imageUrl) + (getImageUrl(imageUrl).includes('?') ? '&' : '?') + 't=' + Date.now()
      : undefined;
    
    setState(prev => ({
      ...prev,
      currentImageUrl: imageUrl,
      originalImageUrl: imageUrl,
      imagePreview: transformedUrl,
      imageKey: Date.now() // Update the key to force re-renders
    }));
  }, [state.originalImageUrl]);

  // Handle image selection and temporary upload
  const uploadTemporaryImage = async (file: File) => {
    try {
      setState(prev => ({ ...prev, isLoading: true }));
      
      // Create temporary preview immediately for instant feedback
      const tempPreview = URL.createObjectURL(file);
      setState(prev => ({ ...prev, imagePreview: tempPreview }));
      
      const formData = new FormData();
      formData.append('image', file);
      
      console.log(`Uploading temporary ${entityType} image for entity ${entityId}`);
      
      // Upload to temporary endpoint
      let endpoint;
      switch (entityType) {
        case EntityType.PROFILE:
          endpoint = `/users/${entityId}/temp-profile-image`;
          break;
        case EntityType.POST:
          endpoint = `/posts/${entityId}/temp-image`;
          break;
        case EntityType.ANNOUNCEMENT:
          endpoint = `/announcements/${entityId}/temp-image`;
          break;
        default:
          throw new Error(`Unsupported entity type: ${entityType}`);
      }
      
      const response = await api.post(
        endpoint,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      
      console.log('Temporary image upload response:', response.data);
      
      // Clean up temporary object URL
      URL.revokeObjectURL(tempPreview);
      
      // Get temporary image URL and filename
      const newImageUrl = response.data.imageUrl;
      const tempFileName = response.data.fileName;
      
      if (response.data && newImageUrl) {
        console.log('New temporary image URL:', newImageUrl);
        console.log('Temporary filename:', tempFileName);
        
        setState(prev => ({
          ...prev,
          currentImageUrl: newImageUrl,
          tempFileName: tempFileName,
          imagePreview: newImageUrl,
          imageKey: Date.now(),
          isLoading: false
        }));
        
        if (onSuccess) {
          onSuccess('Image uploaded and ready to be saved');
        }
      } else {
        console.error('Invalid response format:', response.data);
        setState(prev => ({ ...prev, isLoading: false }));
        
        if (onError) {
          onError('Invalid response from server');
        }
      }
    } catch (error: any) {
      console.error('Failed to upload image:', error);
      setState(prev => ({ ...prev, isLoading: false }));
      
      if (onError) {
        onError(error.response?.data?.details || error.response?.data?.error || `Failed to upload ${entityType} image`);
      }
    }
  };

  // Commit temporary image
  const commitTemporaryImage = async () => {
    if (!state.tempFileName) {
      console.log('No temporary image to commit');
      return null;
    }

    try {
      setState(prev => ({ ...prev, isLoading: true }));
      
      let endpoint;
      switch (entityType) {
        case EntityType.PROFILE:
          endpoint = `/users/${entityId}/commit-profile-image`;
          break;
        case EntityType.POST:
          endpoint = `/posts/${entityId}/commit-image`;
          break;
        case EntityType.ANNOUNCEMENT:
          endpoint = `/announcements/${entityId}/commit-image`;
          break;
        default:
          throw new Error(`Unsupported entity type: ${entityType}`);
      }
      
      const response = await api.post(endpoint, {
        tempFileName: state.tempFileName
      });
      
      console.log('Image commit response:', response.data);
      
      setState(prev => ({
        ...prev,
        tempFileName: null,
        isLoading: false
      }));
      
      return response.data.imageUrl;
    } catch (error: any) {
      console.error(`Failed to commit ${entityType} image:`, error);
      setState(prev => ({ ...prev, isLoading: false }));
      
      if (onError) {
        onError(error.response?.data?.message || `Failed to save ${entityType} image`);
      }
      
      return null;
    }
  };

  // Discard temporary image
  const discardTemporaryImage = async () => {
    if (!state.tempFileName) {
      console.log('No temporary image to discard');
      return;
    }

    try {
      setState(prev => ({ ...prev, isLoading: true }));
      
      let endpoint;
      switch (entityType) {
        case EntityType.PROFILE:
          endpoint = `/users/${entityId}/temp-profile-image`;
          break;
        case EntityType.POST:
          endpoint = `/posts/${entityId}/temp-image`;
          break;
        case EntityType.ANNOUNCEMENT:
          endpoint = `/announcements/${entityId}/temp-image`;
          break;
        default:
          throw new Error(`Unsupported entity type: ${entityType}`);
      }
      
      const response = await api.delete(endpoint, {
        data: { tempFileName: state.tempFileName }
      });
      
      console.log('Image discard response:', response.data);
      
      // Reset image state to original
      setState(prev => ({
        ...prev,
        tempFileName: null,
        currentImageUrl: prev.originalImageUrl,
        imagePreview: prev.originalImageUrl ? getImageUrl(prev.originalImageUrl) : undefined,
        imageKey: Date.now(),
        isLoading: false
      }));
    } catch (error: any) {
      console.error(`Failed to discard temporary ${entityType} image:`, error);
      setState(prev => ({ ...prev, isLoading: false }));
      
      if (onError) {
        onError(`Failed to discard temporary ${entityType} image`);
      }
    }
  };

  // Handle image removal
  const removeImage = () => {
    // Log detailed state for debugging
    console.log('Removing image with current state:', {
      tempFileName: state.tempFileName,
      currentImageUrl: state.currentImageUrl,
      originalImageUrl: state.originalImageUrl
    });

    if (state.tempFileName) {
      // If there's a temporary image, discard it
      discardTemporaryImage();
    } else {
      // If removing an original (non-temporary) image, mark it for removal
      setState(prev => ({
        ...prev,
        imagePreview: undefined,
        isImageRemoved: true, // Set to true when user removes their profile image
        currentImageUrl: null // Clear the current URL
      }));
      
      // Log the new state after removal
      console.log('Image removed - state updated:', {
        isImageRemoved: true,
        imagePreview: undefined,
        currentImageUrl: null
      });
      
      if (onSuccess) {
        onSuccess('Image will be removed when you save changes');
      }
    }
  };

  // Handle cancel (e.g., form cancellation)
  const handleCancel = async () => {
    if (state.tempFileName) {
      await discardTemporaryImage();
    } else if (state.isImageRemoved) {
      setState(prev => ({
        ...prev,
        isImageRemoved: false,
        currentImageUrl: prev.originalImageUrl,
        imagePreview: prev.originalImageUrl ? getImageUrl(prev.originalImageUrl) : undefined
      }));
    }
  };

  return {
    // State
    currentImageUrl: state.currentImageUrl,
    imagePreview: state.imagePreview,
    imageKey: state.imageKey,
    hasTemporaryImage: !!state.tempFileName,
    isLoading: state.isLoading,
    isImageRemoved: state.isImageRemoved,
    
    // Actions
    uploadTemporaryImage,
    commitTemporaryImage,
    discardTemporaryImage,
    removeImage,
    handleCancel,
    updateInitialImage
  };
} 