import { api } from "./api";
import { useMatomo } from '../hooks/useMatomo';

// Types
export interface Suggestion {
  id: string;
  type: 'network' | 'content';
  status: 'pending' | 'accepted' | 'rejected';
  score: number;
  data: {
    userId?: string;
    contentId?: string;
    reason: string;
    matchScore: number;
    commonInterests?: string[];
    commonSkills?: string[];
  };
  created_at: string;
}

export interface SuggestionParams {
  type?: 'network' | 'content';
  status?: 'pending' | 'accepted' | 'rejected' | 'all';
  page?: number;
  pageSize?: number;
}

export interface SuggestionSettings {
  engine_enabled: boolean;
  network_suggestions: boolean;
  content_suggestions: boolean;
  industry_id: string | null;
  enabled_modules: Array<{ module_id: string; module: { name: string; description: string } }>;
}

export interface Industry {
  id: string;
  name: string;
  description: string;
}

export interface SuggestionModule {
  id: string;
  name: string;
  description: string;
  is_core: boolean;
  industry_id: string | null;
}

// Direct exports for commonly used functions
export const fetchSuggestions = async (params: SuggestionParams): Promise<Suggestion[]> => {
  const { trackEvent } = useMatomo();
  const response = await api.get('/suggestions', { params });
  trackEvent({
    category: 'Suggestions',
    action: 'Fetch',
    name: params.type || 'all'
  });
  return response.data;
};

export const updateSuggestionStatus = async (params: { suggestionId: string; status: 'accepted' | 'rejected' }) => {
  const { trackEvent } = useMatomo();
  const response = await api.put(`/suggestions/${params.suggestionId}/status`, { status: params.status });
  trackEvent({
    category: 'Suggestions',
    action: 'UpdateStatus',
    name: params.status
  });
  return response.data;
};

export const fetchSuggestionSettings = async (): Promise<SuggestionSettings> => {
  const response = await api.get('/suggestions/settings');
  return response.data;
};

export const updateSuggestionSettings = async (settings: Partial<SuggestionSettings>): Promise<SuggestionSettings> => {
  const response = await api.put('/suggestions/settings', settings);
  return response.data;
};

export const fetchAvailableIndustries = async (): Promise<Industry[]> => {
  const response = await api.get('/suggestions/industries');
  return response.data;
};

export const fetchAvailableModules = async (): Promise<SuggestionModule[]> => {
  const response = await api.get('/suggestions/modules');
  return response.data;
};

// Service object for less commonly used functions
export const suggestionService = {
  fetchSuggestions,
  updateSuggestionStatus,
  fetchSuggestionSettings,
  updateSuggestionSettings,
  fetchAvailableIndustries,
  fetchAvailableModules,

  async updateEnabledModules(moduleIds: string[]): Promise<void> {
    await api.put('/suggestions/modules', { moduleIds });
  }
};
