import React, { useState, useEffect } from 'react';
import {
  Box,
  Chip,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Label as LabelIcon,
} from '@mui/icons-material';
import { Tag } from '../../types/contact';
import {
  fetchTags,
  fetchTagCategories,
  createTag,
  createTagCategory,
  addTagsToContact,
  removeTagFromContact,
} from '../../services/contacts';

interface TagManagerProps {
  contactId: string;
  currentTags: Tag[];
  onUpdate: () => void;
}

interface TagCategory {
  id: string;
  name: string;
}

const TagManager: React.FC<TagManagerProps> = ({
  contactId,
  currentTags,
  onUpdate,
}) => {
  const [tags, setTags] = useState<Tag[]>([]);
  const [categories, setCategories] = useState<TagCategory[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>(
    currentTags.map(tag => tag.id)
  );
  const [isNewTagDialogOpen, setIsNewTagDialogOpen] = useState(false);
  const [isNewCategoryDialogOpen, setIsNewCategoryDialogOpen] = useState(false);
  const [newTagData, setNewTagData] = useState({
    name: '',
    category_id: '',
    color: '#000000',
  });
  const [newCategoryName, setNewCategoryName] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      const [tagsData, categoriesData] = await Promise.all([
        fetchTags(),
        fetchTagCategories(),
      ]);
      setTags(tagsData);
      setCategories(categoriesData);
    } catch (error) {
      console.error('Error loading tags data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTagToggle = async (tagId: string) => {
    try {
      if (selectedTags.includes(tagId)) {
        await removeTagFromContact(contactId, tagId);
        setSelectedTags(prev => prev.filter(id => id !== tagId));
      } else {
        await addTagsToContact(contactId, [tagId]);
        setSelectedTags(prev => [...prev, tagId]);
      }
      onUpdate();
    } catch (error) {
      console.error('Error toggling tag:', error);
    }
  };

  const handleCreateTag = async () => {
    try {
      const newTag = await createTag(newTagData);
      setTags(prev => [...prev, newTag]);
      setIsNewTagDialogOpen(false);
      setNewTagData({
        name: '',
        category_id: '',
        color: '#000000',
      });
    } catch (error) {
      console.error('Error creating tag:', error);
    }
  };

  const handleCreateCategory = async () => {
    try {
      const newCategory = await createTagCategory(newCategoryName);
      setCategories(prev => [...prev, newCategory]);
      setIsNewCategoryDialogOpen(false);
      setNewCategoryName('');
    } catch (error) {
      console.error('Error creating category:', error);
    }
  };

  const groupedTags = tags.reduce((acc, tag) => {
    const categoryId = tag.category_id;
    if (!acc[categoryId]) {
      acc[categoryId] = [];
    }
    acc[categoryId].push(tag);
    return acc;
  }, {} as Record<string, Tag[]>);

  if (loading) {
    return <Typography>Loading tags...</Typography>;
  }

  return (
    <Box>
      <Box sx={{ mb: 2, display: 'flex', gap: 1 }}>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => setIsNewTagDialogOpen(true)}
        >
          New Tag
        </Button>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => setIsNewCategoryDialogOpen(true)}
        >
          New Category
        </Button>
      </Box>

      {categories.map(category => (
        <Box key={category.id} sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            {category.name}
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {groupedTags[category.id]?.map(tag => (
              <Chip
                key={tag.id}
                label={tag.name}
                onClick={() => handleTagToggle(tag.id)}
                onDelete={
                  selectedTags.includes(tag.id)
                    ? () => handleTagToggle(tag.id)
                    : undefined
                }
                color={selectedTags.includes(tag.id) ? 'primary' : 'default'}
                style={{
                  backgroundColor: tag.color || undefined,
                  color: tag.color ? 'white' : undefined,
                }}
              />
            ))}
          </Box>
        </Box>
      ))}

      {/* New Tag Dialog */}
      <Dialog
        open={isNewTagDialogOpen}
        onClose={() => setIsNewTagDialogOpen(false)}
      >
        <DialogTitle>Create New Tag</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Tag Name"
                value={newTagData.name}
                onChange={(e) =>
                  setNewTagData(prev => ({ ...prev, name: e.target.value }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Category</InputLabel>
                <Select
                  value={newTagData.category_id}
                  label="Category"
                  onChange={(e) =>
                    setNewTagData(prev => ({
                      ...prev,
                      category_id: e.target.value,
                    }))
                  }
                >
                  {categories.map(category => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="color"
                label="Color"
                value={newTagData.color}
                onChange={(e) =>
                  setNewTagData(prev => ({ ...prev, color: e.target.value }))
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsNewTagDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleCreateTag}
            variant="contained"
            disabled={!newTagData.name || !newTagData.category_id}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* New Category Dialog */}
      <Dialog
        open={isNewCategoryDialogOpen}
        onClose={() => setIsNewCategoryDialogOpen(false)}
      >
        <DialogTitle>Create New Category</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Category Name"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
            sx={{ mt: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsNewCategoryDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleCreateCategory}
            variant="contained"
            disabled={!newCategoryName}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TagManager;
