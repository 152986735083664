import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Paper,
  Typography,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { api } from '../services/api';
import ImageUploader from '../components/images/ImageUploader';
import { ImageType } from '../components/images/constants';

interface FormData {
  content: string;
}

const CreatePostPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    content: '',
  });
  const [image, setImage] = useState<File | null>(null);
  const [imageError, setImageError] = useState<string>('');
  const [imagePreview, setImagePreview] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append('content', formData.content.trim());
      if (image) {
        formDataToSend.append('image', image);
      }

      const response = await api.post('/posts', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true
      });

      if (response.data) {
        navigate('/posts');
      } else {
        throw new Error('No response data received');
      }
    } catch (error: any) {
      console.error('Error creating post:', error);
      setError(error.response?.data?.message || 'Failed to create post. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImageSelect = (file: File) => {
    setImage(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const handleImageRemove = () => {
    setImage(null);
    setImagePreview('');
  };

  return (
    <Box component={Paper} sx={{ p: 3, maxWidth: 800, mx: 'auto', mt: 3 }}>
      <Typography variant="h5" gutterBottom>
        Create New Post
      </Typography>

      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Content"
            value={formData.content}
            onChange={(e) => setFormData({ ...formData, content: e.target.value })}
            error={!formData.content.trim()}
            helperText={!formData.content.trim() ? 'Content is required' : ''}
            required
          />

          <Box>
            <ImageUploader
              onImageSelect={handleImageSelect}
              onImageRemove={handleImageRemove}
              imagePreview={imagePreview}
              imageType={ImageType.POST}
              aspectRatio={16/9}
            />
          </Box>

          {error && (
            <Alert severity="error">
              {error}
            </Alert>
          )}

          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
            <Button
              onClick={() => navigate('/posts')}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting || !formData.content.trim()}
              startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
            >
              Create Post
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default CreatePostPage; 