import { IMAGE_UPLOAD_PATHS, ImageType } from '../components/images/constants';

// Use the environment variable for MinIO URL
const MINIO_PUBLIC_URL = import.meta.env.VITE_MINIO_PUBLIC_URL || '';
console.log('MINIO_PUBLIC_URL from env:', MINIO_PUBLIC_URL);

/**
 * Default avatar path for users without profile images
 */
export const DEFAULT_AVATAR_PATH = '/assets/illustrations/miscellaneous/default-avatar.png';

/**
 * Normalize a path to ensure it has a leading slash
 */
const normalizePath = (path: string): string => {
  return path.startsWith('/') ? path : `/${path}`;
};

/**
 * Get a properly formatted image URL based on path
 * @param path Raw image path or URL
 * @returns Complete URL to the image
 */
export const getImageUrl = (path: string | undefined | null): string => {
  if (!path) return DEFAULT_AVATAR_PATH;
  
  // If it's already a full URL, return it as is
  if (path.startsWith('http')) return path;
  
  // If it's a relative path starting with /assets, use it as is
  if (path.startsWith('/assets') || path.startsWith('assets')) {
    return path.startsWith('/') ? path : `/${path}`;
  }
  
  // Normalize path to ensure it has a leading slash
  const normalizedPath = path.startsWith('/') ? path : `/${path}`;
  
  // For MinIO stored images (uploads and profile images)
  if (normalizedPath.includes('profile-images') || normalizedPath.includes('uploads')) {
    if (!MINIO_PUBLIC_URL) {
      console.warn('MinIO URL not configured in environment, using relative path:', normalizedPath);
      return normalizedPath;
    }
    
    // Ensure profile images have the correct path structure
    let cleanPath = normalizedPath;
    
    // Debug logging
    console.log('imageUtils.ts - URL transformation:', {
      original: normalizedPath,
      cleaned: cleanPath,
      hasTimestamp: cleanPath.includes('?t='),
      finalUrl: `${MINIO_PUBLIC_URL}${cleanPath}`
    });
    
    // Check if it's a profile image (either with the prefix already or just a UUID filename)
    if (cleanPath.includes('profile-images')) {
      // Extract just the filename from any profile-images path
      const parts = cleanPath.split('/');
      const filename = parts[parts.length - 1];
      cleanPath = `/profile-images/${filename}`;
    } else if (/^\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\.[a-z]+$/i.test(cleanPath)) {
      // This is a UUID filename pattern - it's a profile image that's missing its prefix
      // Assume it's a profile image if it's just a UUID with extension
      console.log('Found profile image with missing prefix:', cleanPath);
      cleanPath = `/profile-images${cleanPath}`;
    }
    
    // Return the URL with the existing timestamp or without one - let components add timestamps if needed
    return `${MINIO_PUBLIC_URL}${cleanPath}`;
  }
  
  // Default case: assume it's a relative path
  return normalizedPath;
};

/**
 * Constructs an image URL for specific entity types
 * @param type Image type (POST, ANNOUNCEMENT, PROFILE)
 * @param entityId ID of the entity (post id, announcement id, user id)
 * @param filename Filename including extension
 * @returns Complete URL to the image
 */
export const getEntityImageUrl = (
  type: ImageType, 
  entityId?: string, 
  filename?: string
): string => {
  if (!entityId || !filename) return DEFAULT_AVATAR_PATH;
  
  const basePath = IMAGE_UPLOAD_PATHS[type];
  
  // Special case for profile images
  if (type === ImageType.PROFILE) {
    return getImageUrl(`${basePath}/${entityId}.${getExtension(filename)}`);
  }
  
  // For posts and announcements
  return getImageUrl(`${basePath}/${entityId}/${filename}`);
};

/**
 * Extract file extension from filename
 * @param filename Filename including extension
 * @returns Extension without the dot
 */
const getExtension = (filename: string): string => {
  return filename.split('.').pop()?.toLowerCase() || 'jpg';
}; 