import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Button, 
  Paper, 
  TextField, 
  Box, 
  IconButton, 
  Tooltip, 
  Snackbar,
  Alert,
  Link as MuiLink
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Link } from 'react-router-dom';

// Native UUID4 implementation using Web Crypto API
const generateUuid4 = (): string => {
  return ([1e7] as any + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
};

const UuidGeneratorPage: React.FC = () => {
  const [uuid, setUuid] = useState<string>('');
  const [copied, setCopied] = useState<boolean>(false);

  // Generate a new UUID when the page loads
  useEffect(() => {
    generateNewUuid();
  }, []);

  // Generate a new UUID
  const generateNewUuid = () => {
    setUuid(generateUuid4());
  };

  // Copy UUID to clipboard
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(uuid);
      setCopied(true);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  // Handle snackbar close
  const handleSnackbarClose = () => {
    setCopied(false);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 8 }}>
      {/* Breadcrumb navigation */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="body2">
          <Link to="/tools" style={{ textDecoration: 'none', color: 'inherit' }}>Tools</Link>
          {' / UUID Generator'}
        </Typography>
      </Box>

      <Typography variant="h4" component="h1" gutterBottom>
        UUID4 Generator
      </Typography>

      <Typography variant="body1" paragraph sx={{ mb: 4 }}>
        Generate random universally unique identifiers (UUID version 4).
        These are 128-bit identifiers that are unique across both space and time with minimal effort.
        Learn more about UUIDs on <MuiLink href="https://en.wikipedia.org/wiki/Universally_unique_identifier" target="_blank" rel="noopener">Wikipedia</MuiLink>.
      </Typography>

      <Paper 
        elevation={3} 
        sx={{ 
          p: 3, 
          mb: 4,
          borderRadius: 2,
          background: theme => theme.palette.background.paper
        }}
      >
        <Typography variant="subtitle1" gutterBottom>
          Your generated UUID4:
        </Typography>
        
        <Box sx={{ display: 'flex', mb: 3 }}>
          <TextField
            fullWidth
            value={uuid}
            InputProps={{
              readOnly: true,
              sx: { 
                fontFamily: 'monospace',
                fontSize: '1.1rem',
                letterSpacing: '0.5px'
              }
            }}
            variant="outlined"
          />
          <Tooltip title="Copy to clipboard">
            <IconButton 
              onClick={copyToClipboard} 
              color="primary"
              sx={{ ml: 1 }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Button 
          variant="contained" 
          color="primary" 
          onClick={generateNewUuid}
          startIcon={<RefreshIcon />}
          fullWidth
          sx={{ 
            py: 1.5,
            textTransform: 'none',
            fontWeight: 600
          }}
        >
          Generate New UUID4
        </Button>
      </Paper>

      <Paper 
        elevation={2} 
        sx={{ 
          p: 3, 
          mb: 4,
          borderRadius: 2,
          background: theme => theme.palette.mode === 'dark' 
            ? 'rgba(66, 66, 66, 0.6)' 
            : 'rgba(240, 240, 240, 0.6)'
        }}
      >
        <Typography variant="h6" gutterBottom>
          About UUID Version 4
        </Typography>
        <Typography variant="body2">
          UUID version 4 uses random numbers as the source of the UUID. Specifically, 
          the first character in the 3rd group is set to '4' (indicating version 4) and the first character 
          of the 4th group has its most significant bits set to the binary value '10'. All other bits 
          are set randomly, giving approximately 5.3×10³⁶ possible variants.
        </Typography>
      </Paper>

      <Paper 
        elevation={2} 
        sx={{ 
          p: 3, 
          mb: 4,
          borderRadius: 2,
          background: theme => theme.palette.mode === 'dark' 
            ? 'rgba(66, 66, 66, 0.8)' 
            : 'rgba(255, 244, 229, 0.8)',
          borderLeft: theme => `4px solid ${theme.palette.warning.main}`
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ color: theme => theme.palette.warning.main }}>
          Disclaimer
        </Typography>
        <Typography variant="body2" paragraph>
          While UUID4 values are designed to be unique, they are not guaranteed to be so. The probability of a duplicate 
          is extremely small but not zero.
        </Typography>
        <Typography variant="body2" paragraph>
          The probability of generating a duplicate UUID4 is approximately 1 in 5.3×10³⁶. For perspective, you would need to generate about 
          2.71 quintillion UUIDs to reach a 50% probability of at least one collision (this is known as the "birthday paradox").
          This number is astronomical and in most practical applications, collisions are purely theoretical concerns.
        </Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>
          For critical systems or when generating very large numbers of IDs (billions+), consider using:
        </Typography>
        <ul>
          <li>UUID version 1 (time-based) or version 5 (namespace-based)</li>
          <li>Database-generated sequential IDs</li>
          <li>ULID (Universally Unique Lexicographically Sortable Identifier)</li>
        </ul>
      </Paper>

      {/* Success message when copied */}
      <Snackbar 
        open={copied} 
        autoHideDuration={3000} 
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity="success" 
          sx={{ width: '100%' }}
        >
          UUID copied to clipboard!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UuidGeneratorPage; 